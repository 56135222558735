import ContactUsLink from "@/components/shared/ContactUsLink";

function PillarSummary() {
  return (
    <div className="marketing-card-container">
      <div className="section-header">Pillar Summary</div>
      <div className="sub-header">
        The first big step in gaining Page One rankings for your
        mission-critical Pillar Topics is understanding the competitive
        landscape.
      </div>
      <div className="image-text-container">
        <div className="image">
          <img
            src={require("../../../assets/manage_pillar_topics_organic_market_share@4x.png")}
            alt="Pillar Summary"
            style={{
              width: "646px",
              height: "340px",
              margin: "33px 33px 0 0"
            }}
          />
        </div>
        <div className="text-button">
          <div className="text">
            <p>
              Our Pillar Summary dashboard provides insights into which keywords
              you and your competitors are ranking for within a chosen Pillar
              Topic Network so you know who’s winning—and what it will take to
              overcome the competition.
            </p>
            <p>
              Use the Pillar Summary dashboard for a quick rundown of the most
              important keywords and questions within a Pillar Topic Network,
              and find actionable recommendations for improving your rankings
              immediately.
            </p>
          </div>
          <ContactUsLink />
        </div>
      </div>
    </div>
  );
}

export default PillarSummary;
