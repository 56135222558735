export function textToKeywords(data) {
  if (typeof data === "string") {
    return data
      .split("\n")
      .map((line) => {
        return line
          .trim()
          .split(",")
          .filter((k) => !!k)
          .map((k) => k.trim());
      })
      .reduce(
        (final, keywordArr) => [...final, ...keywordArr.filter((k) => !!k)],
        []
      );
  } else {
    return data;
  }
}

export function findNonASCII(keywordArray) {
  const nonAllowed = [];
  keywordArray.forEach((keyword) => {
    if (!/^[\x00-\xFF]*$/.test(keyword)) {
      nonAllowed.push(keyword);
    }
  });
  return nonAllowed;
}

export function countTotalKeywords(keywordsData, updatedList) {
  let totalKeywords = 0;
  if (!updatedList.id) {
    const newKeywordLength =
      typeof updatedList.keywords === "string"
        ? textToKeywords(updatedList.keywords).length
        : updatedList.keywords.length;
    totalKeywords += newKeywordLength;
  }
  keywordsData.forEach((list) => {
    if (list?.list_type?.name !== "Blocklist") {
      let keywordsLength = 0;
      if (updatedList.id && list.id === updatedList.id) {
        keywordsLength =
          typeof updatedList.keywords === "string"
            ? textToKeywords(updatedList.keywords).length
            : updatedList.keywords.length;
      } else {
        keywordsLength =
          typeof updatedList.keywords === "string"
            ? textToKeywords(list.keywords).length
            : list.keywords.length;
      }
      totalKeywords += keywordsLength;
    }
  });
  return totalKeywords;
}

export function countTotalKeywordLists(kwls) {
  let totalKWL = 0;
  kwls.forEach((list) => {
    if (
      list.list_type.name !== "Blocklist" &&
      list.name === "Select a keyword list" &&
      list.name === "Create a new list"
    ) {
      totalKWL++;
    }
  });
  return totalKWL;
}

export function countTotalKeywordsForGtm(keywordsData) {
  let totalKeywords = 0;
  keywordsData.forEach((list) => {
    if (list?.list_type?.name !== "Blocklist") {
      let keywordsLength = 0;
      keywordsLength =
        typeof keywordsData.keywords === "string"
          ? textToKeywords(list.keywords).length
          : list.keywords.length;
      totalKeywords += keywordsLength;
    }
  });
  return totalKeywords;
}

export function hasDuplicateTitle(kwList, kwLists) {
  return kwLists.some(
    (thisList) =>
      kwList.id !== thisList.id &&
      thisList.name.toLowerCase().trim() === kwList.name.toLowerCase().trim()
  );
}

export function sortLists(lists) {
  const sortedList = [];
  const newList = lists?.filter((list) => list?.name === "Create a new list");
  if (newList.length > 0) {
    sortedList?.unshift(newList[0]);
  }
  const blocklist = lists?.filter(
    (list) => list?.list_type?.name === "blocklist"
  );
  if (blocklist.length > 0) {
    sortedList.push(blocklist[0]);
  }
  const kwLists = lists
    ?.filter(
      (list) =>
        list?.list_type?.name === "generic" &&
        list?.name !== "Create a new list"
    )
    ?.sort((a, b) => b?.listId - a?.listId);
  sortedList.push(...kwLists);
  sortedList.forEach(function (element) {
    element.label = element.name;
    element.value = element.name;
  });
  return sortedList;
}

export const blocklist = {
  list_type: { name: "blocklist" },
  name: "Blocklist",
  keywords: [],
  location: { loc_id: "United States", loc_name: "United States" },
  keyword_id_list_ids: []
};
