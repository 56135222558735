import graphOne from "../../../../assets/v2_1st_pg_keyword_rank_chart_(light)_@4x.png";
import graphTwo from "../../../../assets/v2_Top_100_Keyword_rank_chart_(light)_@4x.png";

const imageStyle: React.CSSProperties = {
  width: "100%"
};

const divStyle: React.CSSProperties = {
  float: "left",
  width: "45%",
  position: "relative"
};

import { Grid } from "@mui/material";

const WindowOne = () => (
  <div>
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <div style={{ ...divStyle, left: "-15%" }}>
        <img
          style={{ ...imageStyle, zIndex: 1 }}
          src={graphOne}
          alt="Manage pillar topics 1st page keyword rank chart"
        />
      </div>
      <div style={{ ...divStyle, left: "20%", marginTop: "-25%" }}>
        <img
          style={{ ...imageStyle, zIndex: 2 }}
          src={graphTwo}
          alt="Manage pillar topics top 100 keyword rank chart"
        />
      </div>
    </Grid>
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <div style={{ width: "80%", color: "white", textAlign: "center" }}>
        <h3>
          “I don’t see anyone else in this space providing the level of insight
          DemandJump brings to the table. They understand data beyond the vanity
          metrics often provided – true impactful results.”
        </h3>
        <p style={{ marginBottom: "0px" }}>
          <small>Kevin Smith</small>
        </p>
        <p style={{ marginTop: "5px" }}>
          <small>eCommerce Merchandising Market Manager at Orkin</small>
        </p>
      </div>
    </Grid>
  </div>
);

export default WindowOne;
