import CSS from "csstype";

const loadingPill: CSS.Properties = {
  fontWeight: "bolder",
  left: "50%",
  position: "absolute",
  textAlign: "center",
  top: "25%",
  zIndex: "10",
  height: "20px"
};

const LoadingElement = () => (
  <div
    className="d-sm-flex d-sm-block bg-white justify-content-center col-sm-12"
    style={{ minHeight: "100px" }}
  >
    <div style={loadingPill}>
      <div
        className="loader-wrapper d-flex justify-content-center"
        style={{ marginTop: "20px" }}
      >
        <div className="loader">
          <div className="ball-pulse-sync">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
LoadingElement.propTypes = {};

LoadingElement.defaultProps = {};

export default LoadingElement;
