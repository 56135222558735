import DisplayAds from "./components/DisplayAds";
import Header from "./components/Header";
import PaidSearch from "./components/PaidSearch";
import ShoppingAds from "./components/ShoppingAds";
import SubHeader from "./components/SubHeader";

function PaidChannels() {
  return (
    <div>
      <div className="header-first-component-container gradient">
        <Header />
        <SubHeader title="Paid Channels" />
        <PaidSearch />
      </div>
      <DisplayAds />
      <ShoppingAds background="gradient" />
    </div>
  );
}

export default PaidChannels;
