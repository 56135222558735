/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { getAccountPdomain } from "../../../../api/get";
import { addCompetitors, postNewAccount } from "../../../../api/post";
import Spinner from "react-bootstrap/Spinner";
import CompetitorModal from "./CompetitorModal";
import { ICompetitorsItem } from "@/interfaces/competitors.interface";

const inputStyle = { width: "200px", marginRight: "5px" };

export interface IProps {
  setAllItems: any;
  allItems: any;
  handleChange(): any;
}

function AccountForm(props: IProps) {
  const [company, setCompany] = useState("");
  const [domain, setDomain] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [subscription, setSubscription] = useState("freemium");
  const [newPid, setNewPid] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [competitors, setCompetitors] = useState([
    { name: "", priority_rank: 1, is_direct_competitor: true },
    { name: "", priority_rank: 2, is_direct_competitor: true },
    { name: "", priority_rank: 3, is_direct_competitor: true }
  ]);

  const showCompetitorModal = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowModal(true);
  };

  const submitAccount = async () => {
    setLoading(true);
    setShowSuccess(false);

    const data = {
      company_name: company,
      domain_url: domain,
      display_name: displayName,
      chargify_handle: subscription
    };
    const fetchData = async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const apdb = await getAccountPdomain().catch((err: any) => {
        console.error(err);
        return null;
      });
      if (apdb) {
        props.setAllItems(apdb.accounts);
      } else {
        props.setAllItems([]);
      }
      setLoading(false);
    };
    if (loading) {
      fetchData();
    }

    const res = await postNewAccount(data)
      .then(async (response) => {
        const filteredComps: ICompetitorsItem[] = competitors.filter(
          (comp) => comp.name.length > 0
        );
        await addCompetitors({
          pid: response.data.pdomain.id,
          competitors: filteredComps
        });
        setCompetitors([
          { name: "", priority_rank: 1, is_direct_competitor: true },
          { name: "", priority_rank: 2, is_direct_competitor: true },
          { name: "", priority_rank: 3, is_direct_competitor: true }
        ]);
        setShowModal(false);
        setCompany("");
        setDisplayName("");
        setDomain("");
        setSubscription("");
        setLoading(false);
        setShowSuccess(true);
        fetchData();
        return response.data;
      })
      .catch((err: Error) => {
        setShowSuccess(false);
        setLoading(false);
        setShowModal(false);
        setCompetitors([
          { name: "", priority_rank: 1, is_direct_competitor: true },
          { name: "", priority_rank: 2, is_direct_competitor: true },
          { name: "", priority_rank: 3, is_direct_competitor: true }
        ]);
        console.error(err);
      });
    setNewPid(res.pdomain.id);
    const newAccountItem = {
      ...res.account,
      pdomains: [{ ...res.pdomain, domain: res.domain }]
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props.setAllItems([newAccountItem, ...props.allItems]);
  };

  return (
    <div style={{ padding: "15px" }}>
      <h6>Add new Account:</h6>
      <form onSubmit={(e) => showCompetitorModal(e)}>
        <label>
          Company:
          <input
            className="form-control"
            style={inputStyle}
            type="text"
            placeholder="Nike"
            value={company}
            required
            onChange={(e) => setCompany(e.target.value)}
          />
        </label>
        <label>
          Display Name:
          <input
            className="form-control"
            style={inputStyle}
            type="text"
            placeholder="Nike Shoes"
            value={displayName}
            required
            onChange={(e) => setDisplayName(e.target.value)}
          />
        </label>
        <label>
          Domain:
          <input
            className="form-control"
            style={inputStyle}
            type="text"
            placeholder="nike.com"
            value={domain}
            required
            onChange={(e) => setDomain(e.target.value)}
          />
        </label>
        <label>
          Chargify Subscription:
          <select
            className="form-control"
            style={inputStyle}
            value={subscription}
            onChange={(e) => setSubscription(e.target.value)}
          >
            <option value="freemium">Freemium</option>
            <option value="pro-v4-demo">Pro v4 Demo</option>
          </select>
        </label>
        <input className="btn btn-success" type="submit" value="Submit" />
      </form>
      {showSuccess && (
        <span>
          New Account and Pdomain have been created! The new PID is {newPid}.
        </span>
      )}
      {loading && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
      <CompetitorModal
        competitors={competitors}
        setCompetitors={setCompetitors}
        submitFunction={submitAccount}
        loading={loading}
        setLoading={setLoading}
        show={showModal}
        handleClose={() => setShowModal(false)}
      />
    </div>
  );
}

export default AccountForm;
