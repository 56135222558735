// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton-shape {
  height: 100%;
  width: 100%;
  display: block;
  background-color: #ccc;
  opacity: 0.6 !important;
  border-radius: 4px 4px 4px 4px;
  background-image: linear-gradient(90deg, rgba(221, 221, 221, 0.863) 0px, rgba(236, 235, 235, 0.9450980392) 40px, rgba(221, 221, 221, 0.863) 80px);
  background-size: 40vw;
  animation: ghost-lines 1500ms infinite linear;
}`, "",{"version":3,"sources":["webpack://./src/components/manage_content/content_brief_list/components/components/SkeletonRow.scss"],"names":[],"mappings":"AAkBA;EACE,YAAA;EACA,WAAA;EACA,cAAA;EACA,sBAAA;EACA,uBAAA;EACA,8BAAA;EAlBA,iJAAA;EAIA,qBAAA;EAKA,6CAAA;AALF","sourcesContent":["$base-color: rgba(221, 221, 221, 0.863);\n$ghost-color: #ecebebf1;\n$animation-duration: 1500ms;\n$gradient-offset: 52 + 16;\n\n@mixin background-gradient {\n  background-image: linear-gradient(90deg,\n      $base-color 0px,\n      $ghost-color 40px,\n      $base-color 80px);\n  background-size: 40vw;\n}\n\n@mixin animate-ghost-line {\n  @include background-gradient;\n  animation: ghost-lines $animation-duration infinite linear;\n}\n\n.skeleton-shape {\n  height: 100%;\n  width: 100%;\n  display: block;\n  background-color: #ccc;\n  opacity: 0.6 !important;\n  border-radius: 4px 4px 4px 4px;\n  @include animate-ghost-line;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
