import * as React from "react";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useMediaQuery } from "react-responsive";
import { IUser } from "../../../../interfaces/user.interface";
import { useAppStore } from "../../../../hooks";
import {
  IconHelpCenter,
  NavigationArrowBack,
  IconFileTicket,
  IconTrackTicket,
  IconDjUniversity
} from "@/assets/icons/svgr";
import { Dispatch, SetStateAction } from "react";
import { IAppStore } from "@/interfaces/app-store.interface";
import { getDjUniUrl, getHubspotUrl } from "@/api/get";
import { IAlertBannerOptions } from "@/interfaces/alert-banner-options";

const HelpMenu = ({
  setHelpMenuOpen
}: {
  setHelpMenuOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const {
    user,
    setShowAlertBanner
  }: {
    user: IUser;
    setShowAlertBanner(
      bool: boolean,
      alertBannerOptions?: IAlertBannerOptions
    ): void;
  } = useAppStore((store: IAppStore) => ({
    user: store.user,
    setShowAlertBanner: store.setShowAlertBanner
  }));
  const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
  const handleHubspotClick = async (path = "") => {
    await getHubspotUrl(path)
      .then((res) => {
        window.open(res.url, "_blank", "noopener,noreferrer");
      })
      .catch((error) => {
        console.error(error);
        setShowAlertBanner(true, {
          message: `Error opening our help center, please try again later. If problem persists please contact customer success.`,
          severity: "error"
        });
      });
  };
  const DjUniSso = async () => {
    await getDjUniUrl()
      .then((res) => {
        window.open(res.url, "_blank", "noopener,noreferrer");
      })
      .catch((error) => {
        console.error(error);
        setShowAlertBanner(true, {
          message: `Error opening DJ University, please try again later. If problem persists please contact customer success.`,
          severity: "error"
        });
      });
  };
  const helpMenuOptions = [
    {
      text: "Help Center",
      click: () => handleHubspotClick("path=/knowledge")
    },
    {
      text: "File a Support Ticket",
      click: () =>
        handleHubspotClick(`path=/tickets-view/new?email=${user.unique_id}`)
    },
    {
      text: "Track Ticket Progress",
      click: () => handleHubspotClick(`path=/tickets-view?offset=0`)
    },
    {
      text: "DJ University",
      click: () => DjUniSso()
    }
  ];
  return (
    <List
      sx={{
        bottom: { sm: "0" }
      }}
    >
      <ListItem
        disablePadding
        sx={{
          display: "block",
          "&:hover": {
            background: isMobile ? "#E1F5FF" : "#FFFFFF",
            color: "#008CCE"
          }
        }}
        onClick={() => setHelpMenuOpen(false)}
      >
        {isMobile ? (
          <ListItemButton
            sx={{
              minHeight: 37,
              height: "37px",
              justifyContent: "initial",
              px: 2.5,
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent"
              }
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "24px",
                mr: "15px",
                justifyContent: "center",
                color: "#373F50"
              }}
            >
              <NavigationArrowBack style={{ width: "24px" }} />
            </ListItemIcon>
            <ListItemText
              primary="Help Menu"
              primaryTypographyProps={{
                fontSize: "17px",
                fontFamily: "Source Sans Pro",
                lineHeight: "21px",
                letterSpacing: "-0.015em",
                color: "#008CCE"
              }}
            />
          </ListItemButton>
        ) : (
          <ListItemText
            primary="Help Menu"
            primaryTypographyProps={{
              justifyContent: "initial",
              px: "30px",
              paddingTop: "20px",
              fontSize: "17px",
              fontFamily: "Source Sans Pro",
              lineHeight: "21px",
              letterSpacing: "-0.015em",
              color: "#008CCE"
            }}
          />
        )}
      </ListItem>

      {helpMenuOptions.map((obj) => {
        const [isHovered, setIsHovered] = React.useState<boolean>(false);
        return (
          <ListItem
            key={obj.text}
            sx={{
              display: "block",
              "&:hover": {
                background: "#E1F5FF",
                color: "#008CCE"
              }
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <ListItemButton
              sx={{
                width: "100%",
                minHeight: 37,
                height: "37px",
                justifyContent: "initial",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent"
                }
              }}
              onClick={() => obj?.click()}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: "15px",
                  justifyContent: "center",
                  color: isHovered ? "#008CCE" : "initial"
                }}
              >
                {obj.text === "Help Center" && (
                  <IconHelpCenter style={{ width: "24px" }} />
                )}
                {obj.text === "File a Support Ticket" && (
                  <IconFileTicket style={{ width: "24px" }} />
                )}
                {obj.text === "Track Ticket Progress" && (
                  <IconTrackTicket style={{ width: "24px" }} />
                )}
                {obj.text === "DJ University" && (
                  <IconDjUniversity style={{ width: "24px" }} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={obj.text}
                primaryTypographyProps={{
                  fontSize: "17px",
                  fontFamily: "Source Sans Pro",
                  fontWeight: isHovered ? "bold" : "normal",
                  lineHeight: "21px",
                  letterSpacing: "-0.015em"
                }}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};
export default HelpMenu;
