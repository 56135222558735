import { IManageUsersStore } from "../interfaces/manage-users-store.interface";
import { IAccountUser } from "../interfaces/account-user.interface";

const createManageUsersStore: () => IManageUsersStore = () => ({
  selectedPidAccountUsers: [] as IAccountUser[],
  setSelectedPidAccountUsers(users: IAccountUser[]) {
    this.selectedPidAccountUsers = users;
  }
});

export default createManageUsersStore;
