import {
  getAllInsights,
  getCompetitorsByPid,
  getKeywordLists,
  getStats
} from "../../api/get";
import { countTotalKeywordsForGtm } from "../settings/Keywords/settings-utils";
import TagManager from "react-gtm-module";
import { filterForUniqueInsights } from "./utils";

export function GoogleTagManager(
  selectedPidObject,
  domainName,
  user,
  selectedSubscriptionObject,
  selectedAccountObject
) {
  let competitorCount = 0;
  let insightsCount = 0;
  let uniqueInsightsCount = 0;
  let keywordCount = 0;
  let keywords = [];

  if (selectedPidObject.id) {
    const fetchKeywords = async () => {
      const stats = await getStats(selectedPidObject.id);
      competitorCount = stats.competitors.length;
      insightsCount = stats.insights.length;
      keywords = stats.keyword_lists;
      const filteredUniqueInsights = filterForUniqueInsights(stats.insights);
      uniqueInsightsCount = filteredUniqueInsights.length;
    };

    fetchKeywords()
      .then(() => {
        if (keywords.length > 1) {
          keywordCount = countTotalKeywordsForGtm(keywords);
        }
        const tagManagerArgs = {
          gtmId: process.env.REACT_APP_GTM_ID
        };
        if (tagManagerArgs) {
          TagManager.initialize(tagManagerArgs);
          window.dataLayer.push({
            event: "Page View",
            user_name: user.name,
            user_email: user.unique_id,
            user_avatar: user.avatar_url,
            user_id: user.id,
            pid: selectedPidObject.id,
            pdomain_name: domainName,
            pdomain_label: domainName,
            plan: selectedSubscriptionObject?.cb_plan_family,
            persona: user.user_persona,
            employee: user.dj_employee,
            company_id: selectedPidObject.account_id,
            company_name: selectedAccountObject.company_name,
            company_status: selectedSubscriptionObject?.cb_status,
            verified: user.verified,
            is_sso_user: user.is_sso_user,
            needs_new_password: user.needs_new_password,
            entered_keyword_count: keywordCount,
            entered_competitor_count: competitorCount,
            successful_cbm_count: insightsCount,
            successful_unique_cbm_count: uniqueInsightsCount,
            page: {
              url: location
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
