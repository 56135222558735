import PropTypes from "prop-types";
import "../competitors.scss";

const CompetitorRow = (props) => (
  <div className="d-flex " style={{ padding: "0px" }}>
    <div
      className="demandjump-competitors-control col-7"
      style={{ padding: "0px" }}
    >
      <label
        className="col-form-label competitor-label"
        style={{ width: "25px" }}
      >
        {props.rowNumber}.
      </label>
      <input
        type={"text"}
        value=""
        disabled
        style={{ maxWidth: "90%", display: "initial" }}
        className="form-control competitors-form-control"
        placeholder="competitor domain (ex: nike.com)"
      ></input>
    </div>
  </div>
);

CompetitorRow.propTypes = {
  rowNumber: PropTypes.number
};

CompetitorRow.defaultProps = {
  rowNumber: 1
};

export default CompetitorRow;
