import { Grid, Skeleton } from "@mui/material";

export default function LoadingSkeletons() {
  return (
    <div data-testid="loading-skeletons">
      {[1, 2, 3].map((num) => (
        <Grid
          key={num}
          container
          sx={{ padding: "5px 0px", alignItems: "center" }}
        >
          <Grid item sx={{ padding: "0px 5px" }} className="w-75">
            <Skeleton animation="wave" sx={{ width: "40%" }} />
          </Grid>
          <Grid item sx={{ padding: "0px 5px" }} className="w-25">
            <Skeleton animation="wave" />
          </Grid>
        </Grid>
      ))}
    </div>
  );
}
