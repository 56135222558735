import { useState, useEffect } from "react";
import "./keyword.scss";

import { EditorState } from "draft-js";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { IKeyword } from "../../../../interfaces/saved_outline.interface";

const IncludedKeywords = (props: {
  keywords: string[];
  editor_state: EditorState;
}) => {
  const [keywordsObj, setKeywordObj] = useState([] as IKeyword[]);

  useEffect(() => {
    const plainText = props.editor_state.getCurrentContent().getPlainText("");
    const regex = /(?:\r\n|\r|\n)/g;
    const cleanString = plainText.replace(regex, " ").trim();

    const kwoa = [] as IKeyword[];
    props.keywords.forEach((keyword) => {
      try {
        const escaped_keyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const re = new RegExp(escaped_keyword, "g");
        const count = (cleanString.match(re) || []).length;
        const kwo = {
          phrase: keyword,
          included: count > 0
        } as IKeyword;
        kwoa.push(kwo);
      } catch (e) {
        console.error(`There was an error with ${keyword}`, e);
      }
    });
    setKeywordObj(kwoa);
  }, [props.keywords, props.editor_state]);

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="row" style={{ padding: "0 15px 15px 15px" }}>
          <div className="col-sm-12">
            <span className="keyword_header">
              <b>Keywords to Include:</b>
            </span>
          </div>
        </div>
        <div className="row keyword_container">
          <div className="col-sm-12" id="suggested_keywords">
            {keywordsObj.map((keyword) => (
              <div
                className="row keyword_row"
                key={keyword.phrase.replace(/ /g, "_").toLowerCase()}
                id={keyword.phrase.replace(/ /g, "_").toLowerCase()}
              >
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-2 keyword_circle">
                      <CheckCircleOutlineIcon
                        id={
                          keyword.phrase.replace(/ /g, "_").toLowerCase() +
                          "-circle"
                        }
                        style={{
                          color: keyword.included ? "blue" : "grey"
                        }}
                        aria-hidden="true"
                      />
                    </div>
                    <div className="col-sm-10" style={{ alignSelf: "center" }}>
                      <span className="keyword_text">{keyword.phrase}</span>
                      <br></br>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncludedKeywords;
