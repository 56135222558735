import Cookies from "universal-cookie";

import { loginGoogle } from "../api/post";

const GoogleAuthorize = async (
  id_token: string,
  duration = 1
): Promise<void> => {
  const cookies = new Cookies();
  await loginGoogle(id_token, duration)
    .then((response) => {
      if (response.status === 200 && response.data?.access_token) {
        cookies.remove("Access_Token", { path: "/" });
        cookies.set("Access_Token", response.data.access_token, {
          path: "/",
          domain: process.env.REACT_APP_SUBDOMAIN
        });
      }
    })
    .catch((error) => {
      throw error;
    });
};

export default GoogleAuthorize;
