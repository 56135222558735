import djLogo from "../../../../assets/icons/demandjump-96x96.png";

const CardHeader = () => (
  <>
    <div
      className="card-header d-flex"
      style={{
        paddingLeft: "0",
        paddingBottom: "21px",
        borderBottomColor: "#E9EBF0",
        height: "auto",
        alignItems: "flex-end",
        backgroundColor: "white"
      }}
    >
      <div
        className="page-title-icon"
        style={{
          width: "initial",
          marginRight: "5px"
        }}
      >
        <img
          src={djLogo}
          alt="DemandJump Logo"
          style={{
            width: "44px",
            maxWidth: "120px",
            height: "50px"
          }}
        />
      </div>
      <div
        className="card-title"
        style={{
          fontWeight: "normal",
          fontSize: "30px",
          color: "#373F50",
          textTransform: "none",
          marginBottom: "-5px",
          alignSelf: "flex-end"
        }}
      >
        Competitors
      </div>
    </div>

    <p>
      Enter your competitors below. Enter each competitor as a website domain
      name without specific pages (<i>competitor.com</i>). For more information
      on competitors and how to submit each, read{" "}
      <i>
        <a
          href="https://support.demandjump.com/knowledge/demandjump-competitors"
          id="competitors-help"
        >
          DemandJump Competitors
        </a>
      </i>
      .
    </p>
    <div
      className="demandjump-competitors-control"
      style={{
        display: "flex",
        justifyContent: "space-between"
      }}
    >
      <p style={{ fontSize: "14px" }}>
        <i>
          Changes to competitors can take up to 15 minutes to show in your
          reports.
        </i>
      </p>
    </div>
    <br></br>
  </>
);
export default CardHeader;
