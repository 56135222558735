const ContactUsLink = () => (
  <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_SALES_SCHED}>
    <button
      className="btn btn-success demandjump-button-success"
      style={{ marginBottom: "10px" }}
    >
      Contact Us
    </button>
  </a>
);

export default ContactUsLink;
