import { useContentBriefsStore } from "@/hooks";
import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";

const Uncategorized = () => {
  const {
    draggedItems,
    targetedKeywords,
    targetedQuestions,
    setUnusedItems
  }: IContentBriefsStore = useContentBriefsStore(
    (store: IContentBriefsStore) => ({
      draggedItems: store.draggedItems,
      targetedKeywords: store.targetedKeywords,
      targetedQuestions: store.targetedQuestions,
      setUnusedItems: (value: string[]) => store.setUnusedItems(value)
    })
  );
  const keywords = targetedKeywords.map((item) => item.query);
  const questions = targetedQuestions.map((item) => item.query);
  const unusedKeywords = keywords.filter(
    (keyword) => !draggedItems.includes(keyword)
  );
  const unusedQuestions = questions.filter(
    (keyword) => !draggedItems.includes(keyword)
  );
  if (unusedKeywords || unusedQuestions) {
    const unusedItems = [...unusedKeywords, ...unusedQuestions];
    setUnusedItems(unusedItems);
  }

  return (
    <div
      style={{
        borderRadius: 10,
        margin: "40px 36px 40px 36px",
        padding: 20,
        backgroundColor: "#F8F8FA",
        height: "fit-content"
      }}
    >
      <div
        style={{
          color: "#56627C",
          textTransform: "uppercase",
          fontSize: "16px"
        }}
      >
        Uncategorized
      </div>
      <div
        style={{
          color: "#56627C",
          fontStyle: "italic",
          fontSize: "14px"
        }}
      >
        Any unused search terms will automatically appear here in next step.
      </div>
      <div style={{ marginTop: 10 }}>
        <div>
          {unusedQuestions.map((item, index) => (
            <li
              key={index}
              style={{
                color: "#56627C",
                fontSize: "16px"
              }}
            >
              {item}
            </li>
          ))}
          {unusedKeywords.map((item, index) => (
            <li
              key={index}
              style={{
                color: "#56627C",
                fontSize: "16px"
              }}
            >
              {item}
            </li>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Uncategorized;
