import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const tooltip = <Tooltip id="tooltip">New</Tooltip>;
function NewItemAlert() {
  return (
    <OverlayTrigger placement="bottom" overlay={tooltip}>
      <span
        style={{
          height: "10px",
          width: "10px",
          backgroundColor: "#008cce",
          borderRadius: "50%",
          display: "inline-block",
          marginLeft: "3px",
          marginBottom: "6px"
        }}
      ></span>
    </OverlayTrigger>
  );
}

export default NewItemAlert;
