import "./HTTPError.scss";
import { DjLogoD } from "@/assets/icons/svgr";

function PortalError() {
  return (
    <div className="fzf-parent">
      <DjLogoD style={{ width: "200px" }} />
      <div className="main-message">
        Oops... There was a problem with the billing portal.
      </div>
      <div className="small-message">
        Please contact{" "}
        <a href="mailto: success@demandjump.com">Customer Success</a> to upgrade
        your plan.
      </div>
    </div>
  );
}

export default PortalError;
