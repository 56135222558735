export const formatDomain = (domain: string): string => {
  // eslint-disable-next-line prefer-named-capture-group
  const domainRegex = /^(http:\/\/|https:\/\/|www\.)/iu;
  if (domainRegex.test(domain)) {
    return formatDomain(domain.replace(domainRegex, ""));
  }
  return domain;
};

export const checkDomain = (domain: string) => {
  // eslint-disable-next-line prefer-named-capture-group
  const checkRegex = /^(?:(ftp|http|https):\/\/)?(?:[\w-]+\.)+[a-z]{2,63}$/u;
  if (domain) {
    return checkRegex.test(domain);
  }
  return false;
};
