import { getDjUniUrl } from "@/api/get";
import { useEffectAsync } from "@/hooks";

const DJUiniversitySSOComponent = () => {
  useEffectAsync(async () => {
    await getDjUniUrl().then((res) => {
      window.open(res.url, "_self", "noopener,noreferrer");
    });
  }, []);

  return <></>;
};

export default DJUiniversitySSOComponent;
