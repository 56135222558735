import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const ShowConfirmPassword = () => {
  const [eyeIconConfirmPassword, setEyeIconConfirmPassword] =
    useState(faEyeSlash);
  const confirmPasswordField = document.querySelector("#confirmpassword");

  const eyeIconConfirmPasswordClick = () => {
    const type =
      confirmPasswordField?.getAttribute("type") === "password"
        ? "text"
        : "password";
    confirmPasswordField?.setAttribute("type", type);
    if (type === "text") {
      setEyeIconConfirmPassword(faEye);
    }
    if (type === "password") {
      setEyeIconConfirmPassword(faEyeSlash);
    }
  };
  return (
    <button
      type="button"
      id="show-or-hide-password-eye-icon"
      aria-label="show-or-hide-password-eye-icon"
      style={{
        position: "relative",
        float: "right",
        bottom: "38px",
        padding: "6px",
        cursor: "pointer",
        color: "#9E9E9E",
        backgroundColor: "transparent",
        border: "none"
      }}
      onClick={eyeIconConfirmPasswordClick}
    >
      <FontAwesomeIcon icon={eyeIconConfirmPassword} />
    </button>
  );
};

export default ShowConfirmPassword;
