import PropTypes from "prop-types";

function CustomBadge(props) {
  return (
    <span className="dj-badge" style={{ backgroundColor: props.bg }}>
      {props.text}
    </span>
  );
}

CustomBadge.propTypes = {
  bg: PropTypes.string,
  text: PropTypes.string
};

export default CustomBadge;
