export const toLowerCase = (value?: string): string =>
  value ? value.toLowerCase() : "";

export const toUpperCase = (value?: string): string =>
  value ? value.toUpperCase() : "";

export const toUpperCaseFirstLetter = (value: string): string =>
  value.charAt(0).toUpperCase() +
  value.slice(1).toLowerCase().replace("_", " ");

export const round10 = (value: number) => Math.round(value * 10) / 10;
