import React, { SyntheticEvent, useState } from "react";
import Cookies from "universal-cookie";
import { DjInput } from "../../shared/DjInput";
import { ISignupDetails } from "../../../interfaces/signup-details.interface";
import { submitSignup } from "../../../api/post";
import { checkPassword } from "../../../utilities/password";
import { CircularProgress } from "@material-ui/core";
import ShowPassword from "@/components/shared/ShowPassword";

interface stepTwoProps {
  setStep: (value: number) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  signupDetails: ISignupDetails;
  setCompetitors: (value: string[]) => void;
  emailAlreadyExists: boolean;
  setEmailAlreadyExists: (value: boolean) => void;
  setPid: (value: number) => void;
}
const StepTwo = ({
  setStep,
  handleChange,
  signupDetails,
  setCompetitors,
  emailAlreadyExists,
  setEmailAlreadyExists,
  setPid
}: stepTwoProps) => {
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);
  const cookies = new Cookies();

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    if (checkPassword(signupDetails.password)) {
      setLoading(true);
      setPasswordError(false);
      const hsCookie = cookies.get("hubspotutk");

      await submitSignup({ ...signupDetails, hsCookie })
        .then((response) => {
          if (response.status === 200 && response.data) {
            if (response.data.access_token) {
              cookies.remove("Access_Token", { path: "/" });
              cookies.set("Access_Token", response.data.access_token, {
                path: "/",
                domain: process.env.REACT_APP_SUBDOMAIN
              });
            }
            if (response.data.competitors) {
              setCompetitors(response.data.competitors);
            }
            if (response.data.pid) {
              setPid(response.data.pid);
            }
            setLoading(false);
            setStep(3);
          }
        })
        .catch((error) => {
          console.error(error.response);

          if (error.response.status === 409) {
            setEmailAlreadyExists(true);
          }
          setLoading(false);
        });
    } else {
      setPasswordError(true);
      setLoading(false);
    }
  };
  return (
    <div className="step-container">
      <div className="su-sub-header">Step 2 of 3</div>
      <div className="help-text">
        While your insight report is generating, please provide your email so we
        can save your data for access at any time.
      </div>
      <form className="su-form" onSubmit={handleSubmit}>
        <div className="name-inputs-container mb-31">
          <DjInput
            type="text"
            width="195px"
            height="48px"
            name="firstName"
            placeholder="First Name"
            value={signupDetails.firstName}
            onChange={handleChange}
            disabled={loading}
            required={true}
          />
          <DjInput
            type="text"
            width="195px"
            height="48px"
            name="lastName"
            placeholder="Last Name"
            value={signupDetails.lastName}
            onChange={handleChange}
            disabled={loading}
            required={true}
          />
        </div>
        <div className="mb-31">
          <DjInput
            type="email"
            width="402px"
            height="48px"
            name="email"
            placeholder="Business Email"
            value={signupDetails.email}
            onChange={handleChange}
            required={true}
            disabled={loading}
            error={emailAlreadyExists}
          />
          {emailAlreadyExists && (
            <div className="error-label">
              This email already exists. Please <a href="/login">Sign In</a>
            </div>
          )}
        </div>
        <div className="mb-31">
          <DjInput
            id="password"
            type="password"
            width="402px"
            height="48px"
            name="password"
            placeholder="Password"
            value={signupDetails.password}
            onChange={handleChange}
            required={true}
            disabled={loading}
            error={passwordError}
          />
          <ShowPassword />
          <div
            className={
              passwordError
                ? "password-req-text password-error"
                : "password-req-text"
            }
          >
            Password must contain at least one lowercase character, one
            uppercase character, one of the following characters (! # $ % & + -
            _ ? @ ~), and must be 8 or more characters.
          </div>
        </div>

        <div className="button-no-text">
          <button
            className="signup-button"
            disabled={loading}
            type="submit"
            style={{ justifySelf: "flex-end" }}
          >
            {loading ? <CircularProgress /> : "Next"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default StepTwo;
