import * as React from "react";

const VerticalBar = () => (
  <svg
    width="2"
    height="150"
    viewBox="0 0 2 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.26099 242.002V0" stroke="#E9EBF0" />
  </svg>
);

export default VerticalBar;
