import React, { Component } from "react";

export default class DetailWrapper extends Component {
  render() {
    return (
      <div
        className="detail-wrapper"
        style={{
          padding: "22px 0 0 0",
          fontSize: "16px",
          marginBottom: "62px"
        }}
      >
        <p className="paragraph">
          Enter your pillar topics below. Pillar topics ensure your content is
          focused on your target audience. You may specify a geographical region
          for your audience by entering a country, state or province, or zip
          code.
        </p>
        <p>
          Our technology is going to work mapping the entire network of search
          behavior surrounding your pillar. Depending on the pillar topic, a
          report might take 10-15 minutes to complete.
        </p>
      </div>
    );
  }
}
