import { getSavedBrief } from "@/api/get";
import { useAppStore, useContentBriefsStore, useEffectAsync } from "@/hooks";
import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";
import {
  ISavedBrief,
  ISavedBriefCTA
} from "@/interfaces/saved_outline.interface";
import { useState } from "react";
import { IAppStore } from "@/interfaces/app-store.interface";
import { addOrUpdateCTAs } from "@/api/post";
import "../../step-three.scss";

interface IProps {
  setOpenAddCTAModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const AddCTAModal = ({ setOpenAddCTAModal }: IProps) => {
  const { briefId, CTAs, setCTAs }: IContentBriefsStore = useContentBriefsStore(
    (store: IContentBriefsStore) => ({
      briefId: store.briefId,
      CTAs: store.CTAs,
      setCTAs: (value: ISavedBriefCTA[]) => store.setCTAs(value)
    })
  );
  const { setShowAlertBanner } = useAppStore((store: IAppStore) => ({
    setShowAlertBanner: store.setShowAlertBanner
  }));
  const [brief, setBrief] = useState<ISavedBrief>();
  const [CTAText, setCTAText] = useState("");
  const [CTAurl, setCTAurl] = useState("");

  useEffectAsync(async () => {
    if (briefId) {
      const foundBrief = await getSavedBrief(briefId);
      if (foundBrief) {
        setBrief(foundBrief);
      }
    }
  }, [CTAs, briefId]);
  const closeModal = () => {
    setOpenAddCTAModal(false);
  };

  const handleAddNote = async () => {
    if (brief?.id && CTAText && CTAurl) {
      const resp = await addOrUpdateCTAs(null, brief.id, CTAText, CTAurl);
      if (resp) {
        const allNotes = [resp.data, ...CTAs];
        setCTAs(allNotes);
        setOpenAddCTAModal(false);
        setShowAlertBanner(true, {
          message: `Successfully added a new CTA`,
          severity: "success"
        });
      } else {
        setOpenAddCTAModal(false);
        setShowAlertBanner(true, {
          message: `Error saving the CTA, please try again. If problem persists please contact customer success.`,
          severity: "error"
        });
      }
    } else if (brief?.id && !CTAText && CTAurl) {
      setShowAlertBanner(true, {
        message: `To add a CTA, Display Text is required`,
        severity: "error"
      });
    } else if (brief?.id && CTAText && !CTAurl) {
      setShowAlertBanner(true, {
        message: `To add a CTA, a Link is required`,
        severity: "error"
      });
    } else if (brief?.id && !CTAText && !CTAurl) {
      setShowAlertBanner(true, {
        message: `To add a CTA, Display Text and a Link are required`,
        severity: "error"
      });
    }
  };

  return (
    <>
      <div
        className="shadow bg-body rounded"
        style={{
          position: "fixed",
          width: "548px",
          height: "310px",
          left: "calc(50% - 182px)",
          top: "calc(50% - 167px)",
          background: "#FFFFFF",
          borderRadius: "3px",
          border: "1px solid #E9EBF0",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          zIndex: 4
        }}
      >
        <div
          style={{
            display: "flex",
            width: "548px",
            height: 20,
            justifyContent: "flex-end"
          }}
        >
          <button
            className="btn btn-close"
            style={{
              padding: 15,
              fontSize: 14
            }}
            onClick={closeModal}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <div
            style={{
              color: "#373F50",
              paddingBottom: "10px",
              fontSize: 24
            }}
          >
            <div>Add New Note</div>
          </div>
          <div
            style={{
              alignSelf: "flex-start",
              fontStyle: "italic",
              color: "#56627C"
            }}
          >
            Display Text
          </div>
          <textarea
            onChange={(e) => setCTAText(e.target.value)}
            style={{
              width: "431px",
              height: "35px",
              fontSize: "14px",
              color: "#373F50",
              border: "1px solid #D3D7E0",
              borderRadius: 4,
              resize: "none",
              outline: "none",
              paddingTop: "10px",
              paddingLeft: "10px",
              margin: "10px 0px"
            }}
            className="italic-placeholder"
          ></textarea>
          <div
            style={{
              alignSelf: "flex-start",
              fontStyle: "italic",
              color: "#56627C"
            }}
          >
            Link
          </div>
          <textarea
            onChange={(e) => setCTAurl(e.target.value)}
            style={{
              width: "431px",
              height: "35px",
              fontSize: "14px",
              color: "#373F50",
              border: "1px solid #D3D7E0",
              borderRadius: 4,
              resize: "none",
              outline: "none",
              paddingTop: "10px",
              paddingLeft: "10px"
            }}
            className="italic-placeholder"
          ></textarea>
        </div>
        <div
          style={{
            position: "relative",
            paddingTop: "34px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "240px"
          }}
        >
          <button
            style={{
              width: "100px",
              height: "48px",
              background: "#FFFFFF",
              fontFamily: "Source Sans Pro",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "20px",
              textAlign: "center",
              letterSpacing: "0.005em",
              color: "#373F50",
              borderRadius: 3
            }}
            className="btn btn-secondary"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            style={{
              width: "121px",
              height: "48px",
              background: "#008CCE",
              borderRadius: "3px",
              fontFamily: "Source Sans Pro",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "20px",
              textAlign: "center",
              letterSpacing: "0.005em",
              color: "#FFFFFF"
            }}
            className="btn"
            onClick={handleAddNote}
          >
            Add Note
          </button>
        </div>
      </div>
    </>
  );
};

export default AddCTAModal;
