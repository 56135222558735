import CircularProgress from "@mui/material/CircularProgress";

const LoadingElement = () => (
  <div style={{ minHeight: "100px" }}>
    <div
      style={{
        fontWeight: "bolder",
        left: "50%",
        textAlign: "center",
        height: "20px"
      }}
    >
      <div style={{ marginTop: "20px" }}>
        <CircularProgress />
      </div>
    </div>
  </div>
);

export default LoadingElement;
