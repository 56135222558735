/* eslint-disable indent */
import { useState, useEffect, useRef } from "react";
import "./pillar_strategy_tabs.scss";
import { NavigationArrowLeft, NavigationArrowRight } from "@/assets/icons/svgr";
import { IContentPillars } from "@/interfaces/content-pillars.interface";
import { usePillarStrategyStore } from "@/hooks";
import { IPillarStrategyStore } from "@/interfaces/pillar-strategy-store.interface";

// accepts an array of pillar topic objects and a setSelectedPillarTopic function
// returns a carousel of pillar strategy tabs
// each tab displays the name of the pillar strategy
// when a tab is clicked, the setSelectedPillarTopic function is called with the index of the tab that was clicked
// the tab that was clicked is highlighted
// if there are more than 3 tabs, a scroll button is displayed
// when the scroll button is clicked, the carousel scrolls to the next tab
// if there are more than 4 tabs, the scroll button is always displayed
const PillarStrategyTabs = ({
  items,
  selectedTopicIndex
}: {
  items: IContentPillars[];
  selectedTopicIndex: number;
}) => {
  // USE STATE DECLARATIONS
  const [selectedTab, setSelectedTab] = useState(0);
  const [visibleTabs, setVisibleTabs] = useState(3);
  const carouselRef = useRef<HTMLDivElement>(null);
  const [showLeftScrollBtn, setShowLeftScrollBtn] = useState(false);
  // STORE DECLARATIONS
  const {
    setSelectedTopicTabIndex,
    setSearchedTopic,
    setExpandAll,
    setCollapseAll
  }: IPillarStrategyStore = usePillarStrategyStore(
    (store: IPillarStrategyStore) => ({
      setSearchedTopic: (value: string) => store.setSearchedTopic(value),
      setExpandAll: (value: boolean) => store.setExpandAll(value),
      setCollapseAll: (value: boolean) => store.setCollapseAll(value),
      setSelectedTopicTabIndex: (value: number) =>
        store.setSelectedTopicTabIndex(value)
    })
  );

  useEffect(() => {
    if (items.length > 3) {
      setVisibleTabs(4);
    }
  }, [items]);

  const handleTabClick = (index: number) => {
    setSelectedTab(index);
    setSelectedTopicTabIndex(index);
    setSearchedTopic("");
    setExpandAll(true);
    setCollapseAll(false);
  };

  const handleScrollClick = (direction: "forward" | "back") => {
    const scrollAmount = direction === "forward" ? 300 : -300;

    if (carouselRef?.current?.scrollBy) {
      carouselRef.current.scrollBy({
        left: scrollAmount
      });
    }
    setShowLeftScrollBtn((carouselRef.current?.scrollLeft || 0) > 0);
  };

  const handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setShowLeftScrollBtn(document.getElementById("carousel")?.scrollLeft !== 0);
    const scrollAmount = event.deltaY;
    if (carouselRef?.current?.scrollBy) {
      carouselRef.current.scrollBy({
        left: scrollAmount
      });
    }
  };

  // if there is a selectedTopicIndex on pageload, scroll to that tab
  // this is for when a new pillar was just added from the looker dashboard
  useEffect(() => {
    if (carouselRef?.current?.scrollBy) {
      carouselRef.current.scrollBy({
        left: selectedTopicIndex * 300
      });
    }
    // highlight the selected tab
    setSelectedTab(selectedTopicIndex);
  }, [selectedTopicIndex]);

  return (
    <div className="carousel-container ">
      {/* // if the carouselRef is scrolled by some offset then display the scroll button */}
      {items.length > visibleTabs && showLeftScrollBtn && (
        <button
          className="scroll-btn"
          onClick={() => handleScrollClick("back")}
          style={{
            position: "absolute",
            // left: 12,
            top: 0,
            zIndex: 1,
            // marginLeft: "12px",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          {
            <NavigationArrowLeft
              style={{
                height: "24px",
                width: "24px"
              }}
            />
          }
        </button>
      )}
      <div
        className="carousel"
        id="carousel"
        ref={carouselRef}
        // style={{ maxWidth: visibleTabs * 300 }}
        onWheelCapture={(event) => handleScroll(event)}
      >
        {items.map((item, index) => (
          <div
            key={index}
            className={`carousel-tab ${
              selectedTab === index ? "selected" : ""
            }`}
            style={{
              borderRight:
                index === items.length - 1 && visibleTabs <= 4 ? "" : "none"
            }}
            onClick={() => handleTabClick(index)}
            data-testid="pillar-strategy-tab"
          >
            {item.keyword}
          </div>
        ))}
      </div>
      {items.length > visibleTabs && (
        <button
          className="scroll-btn"
          onClick={() => handleScrollClick("forward")}
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          {
            <NavigationArrowRight
              style={{
                height: "24px",
                width: "24px"
              }}
            />
          }
        </button>
      )}
    </div>
  );
};

export default PillarStrategyTabs;
