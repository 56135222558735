import { useState, useEffect } from "react";
import useContentBriefsStore from "@/hooks/useContentBriefsStore";
import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";
import { stepThreeStyles } from "./utils/style";
const MetaInformation = () => {
  const { term, typeOfTerm, metaDescription }: IContentBriefsStore =
    useContentBriefsStore((store: IContentBriefsStore) => ({
      term: store.term,
      typeOfTerm: store.typeOfTerm,
      metaDescription: store.metaDescription
    }));
  const [contentLength, setContentLength] = useState<string>("");
  useEffect(() => {
    if (typeOfTerm === "Pillar") {
      setContentLength("3,000");
    } else if (typeOfTerm === "Sub Pillar") {
      setContentLength("2,000");
    } else if (typeOfTerm === "Blog") {
      setContentLength("750");
    } else {
      setContentLength("750");
    }
  }, [metaDescription]);

  return (
    <div
      data-testid="meta-information-section"
      style={{ padding: "40px 75px 40px  75px " }}
    >
      <div
        className="title-photo-title-line-2"
        style={stepThreeStyles.titlePhotoTitleLine2}
      >
        Meta Information
      </div>
      <div
        style={{
          paddingTop: "20px",
          paddingBottom: "20px"
        }}
      >
        <div
          style={{
            backgroundColor: "#E45D28",
            height: "4px",
            width: "120px"
          }}
        ></div>
        <div className="row row-padding-top-20">
          <div className="col-4">
            <div className="row">
              <div
                className="meta-info-label"
                style={stepThreeStyles.metaInfoLabel}
              >
                <b>Focus Search Term</b>
              </div>
            </div>
            <div className="row">
              <div
                className="meta-info-field"
                style={stepThreeStyles.metaInfoField}
              >
                {term}
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="row">
              <div
                className="meta-info-label"
                style={stepThreeStyles.metaInfoLabel}
              >
                <b>Target Word Count</b>
              </div>
            </div>
            <div className="row">
              <div
                className="meta-info-field"
                style={stepThreeStyles.metaInfoField}
              >
                {contentLength} words
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="row">
              <div
                className="meta-info-label"
                style={stepThreeStyles.metaInfoLabel}
              >
                <b>Article Type</b>
              </div>
            </div>
            <div className="row">
              <div
                className="meta-info-field"
                style={stepThreeStyles.metaInfoField}
              >
                {typeOfTerm}
              </div>
            </div>
          </div>
        </div>
        <div className="row row-padding-top-20">
          <div className="col-12">
            <div className="row">
              <div
                className="meta-info-label"
                style={stepThreeStyles.metaInfoLabel}
              >
                <b>Title Tag</b>
              </div>
            </div>
            <div className="row">
              <div
                className="meta-info-field"
                style={stepThreeStyles.metaInfoField}
              >
                {term}
              </div>
            </div>
          </div>
        </div>
        <div className="row row-padding-top-20">
          <div className="col-12">
            <div className="row">
              <div
                className="meta-info-label"
                style={stepThreeStyles.metaInfoLabel}
              >
                <b>Meta Description</b>
              </div>
            </div>
            <div className="row">
              <div
                className="meta-info-field"
                style={stepThreeStyles.metaInfoField}
              >
                {metaDescription}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetaInformation;
