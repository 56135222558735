import SettingsMenu from "./SettingsMenu";
import HelpMenu from "./HelpMenu";
import Bookmarks from "./Bookmarks/Bookmarks";
import { useMediaQuery } from "react-responsive";
import { Dispatch, SetStateAction } from "react";
import ProfileSettingsMenu from "./ProfileSettingsMenu";
import { motion, AnimatePresence } from "framer-motion";

const ExpandedItemsContainer = ({
  sideMenuOpen,
  helpMenuOpen,
  settingsMenuOpen,
  bookmarksMenuOpen,
  profileSettingsMenuOpen,
  setHelpMenuOpen,
  setOpenItemsStack,
  setSettingsMenuOpen,
  setBookmarksMenuOpen,
  setProfileSettingsMenuOpen
}: {
  sideMenuOpen: boolean;
  helpMenuOpen?: boolean;
  bookmarksMenuOpen?: boolean;
  profileSettingsMenuOpen?: boolean;
  setHelpMenuOpen: Dispatch<SetStateAction<boolean>>;
  settingsMenuOpen?: boolean;
  setOpenItemsStack?: Dispatch<SetStateAction<number[]>>;
  setSettingsMenuOpen: Dispatch<SetStateAction<boolean>>;
  setBookmarksMenuOpen: Dispatch<SetStateAction<boolean>>;
  setProfileSettingsMenuOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
  const closeModals = () => {
    setSettingsMenuOpen(false);
    setHelpMenuOpen(false);
    setBookmarksMenuOpen(false);
    setProfileSettingsMenuOpen(false);
  };
  return (
    <>
      {/* the container div acts as an invisible background surrounding the Cards.
			 If you click on the container div it will close the open card */}
      {(helpMenuOpen ||
        settingsMenuOpen ||
        bookmarksMenuOpen ||
        profileSettingsMenuOpen) && (
        <div
          data-testid="expanded-items-container"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100vw",
            width: "100vw",
            zIndex: 9999
          }}
          onClick={() => closeModals()}
        />
      )}
      {helpMenuOpen && !isMobile && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              backgroundColor: "white"
            }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.3,
              ease: "linear"
            }}
            style={{
              position: "fixed",
              marginLeft: sideMenuOpen ? "270px" : "80px",
              bottom: "130px",
              zIndex: 10000,
              borderRadius: "4px",
              boxShadow:
                " 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
            }}
          >
            <HelpMenu setHelpMenuOpen={setHelpMenuOpen} />
          </motion.div>
        </AnimatePresence>
      )}
      {settingsMenuOpen && !isMobile && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              backgroundColor: "white"
            }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.3,
              ease: "linear"
            }}
            style={{
              position: "fixed",
              marginLeft: sideMenuOpen ? "270px" : "80px",
              bottom: "90px",
              zIndex: 10000,
              borderRadius: "4px",
              boxShadow:
                " 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
            }}
          >
            <SettingsMenu
              setSettingsMenuOpen={setSettingsMenuOpen}
              setOpenItemsStack={setOpenItemsStack}
            />
          </motion.div>
        </AnimatePresence>
      )}
      {bookmarksMenuOpen && !isMobile && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.3,
              ease: "linear"
            }}
            style={{
              position: "fixed",
              marginLeft: sideMenuOpen ? "270px" : "80px",
              bottom: "165px",
              zIndex: 10000,
              borderRadius: "4px",
              boxShadow:
                " 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
            }}
          >
            <Bookmarks setBookmarksMenuOpen={setBookmarksMenuOpen} />
          </motion.div>
        </AnimatePresence>
      )}
      {profileSettingsMenuOpen && !isMobile && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1
            }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.3,
              ease: "linear"
            }}
            style={{
              position: "fixed",
              marginLeft: sideMenuOpen ? "270px" : "80px",
              bottom: "55px",
              zIndex: 10000,
              borderRadius: "4px",
              boxShadow:
                " 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
            }}
          >
            <ProfileSettingsMenu
              setProfileSettingsMenuOpen={setProfileSettingsMenuOpen}
              setOpenItemsStack={setOpenItemsStack}
            />
          </motion.div>
        </AnimatePresence>
      )}
    </>
  );
};

export default ExpandedItemsContainer;
