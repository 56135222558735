import { postLogout } from "../api/post";
import Cookies from "universal-cookie";

export const logout = async () => {
  const cookies = new Cookies();

  await postLogout()
    .then(() => {
      cookies.remove("Access_Token", {
        path: "/",
        domain: process.env.REACT_APP_SUBDOMAIN
      });
      cookies.remove("Access_Token", { path: "/" });
      cookies.remove("Topic", { path: "/" });
      cookies.remove("PillarTopic", { path: "/" });
    })
    .catch((err) => {
      cookies.remove("Access_Token", {
        path: "/",
        domain: process.env.REACT_APP_SUBDOMAIN
      });
      cookies.remove("Access_Token", { path: "/" });
      throw err;
    });
};

export default logout;
