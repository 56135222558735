/* eslint-disable no-confusing-arrow */
import { IBodyDroppableArea } from "@/interfaces/outline.interface";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { useHoverState, handleDeleteFromIntroOrConclusion } from "./utility";
import useContentBriefsStore from "@/hooks/useContentBriefsStore";
import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";
import { useAppStore } from "@/hooks";
import { IAppStore } from "@/interfaces/app-store.interface";

// eslint-disable-next-line arrow-body-style
const ConclusionArea = () => {
  const {
    hoveredStates,
    setHoveredStates,
    handleMouseEnter,
    handleMouseLeave
  } = useHoverState();
  const {
    setDraggedItems,
    draggedItems,
    bodySectionDroppableAreas,
    setBodySectionDroppableAreas,
    targetedQuestions,
    targetedKeywords,
    briefId,
    setShowSaved
  }: IContentBriefsStore = useContentBriefsStore(
    (store: IContentBriefsStore) => ({
      briefId: store.briefId,
      targetedQuestions: store.targetedQuestions,
      targetedKeywords: store.targetedKeywords,
      bodySectionDroppableAreas: store.bodySectionDroppableAreas,
      draggedItems: store.draggedItems,
      setShowSaved: (value: boolean) => store.setShowSaved(value),
      setDraggedItems: (value: string[]) => store.setDraggedItems(value),
      setBodySectionDroppableAreas: (value: IBodyDroppableArea[]) =>
        store.setBodySectionDroppableAreas(value)
    })
  );
  const { selectedPidObject } = useAppStore((store: IAppStore) => ({
    selectedPidObject: store.selectedPidObject
  }));

  return (
    <div>
      <div>
        <div
          style={{
            borderRadius: 10,
            margin: "40px 36px 40px 36px",
            padding: 20,
            backgroundColor: "#F8F8FA",
            height: "fit-content"
          }}
        >
          <div
            style={{
              color: "#56627C",
              textTransform: "uppercase",
              fontSize: "16px"
            }}
          >
            Conclusion
          </div>
          <div
            style={{
              color: "#56627C",
              fontStyle: "italic",
              fontSize: "14px"
            }}
          >
            This is where your CTA’s will most likely go. You may also add
            search terms here.{" "}
          </div>
          {bodySectionDroppableAreas.map((section) =>
            section.name === "Conclusion" ? (
              <Droppable
                data-testid="body-droppable"
                droppableId={`${section.stringUUID}-bottom`}
                key={section.stringUUID}
              >
                {(droppableProvided, droppableSnapshot) => (
                  <div
                    className={
                      section.bottomItems.length > 0
                        ? ""
                        : "body-droppable-placeholder"
                    }
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                    style={{
                      background: droppableSnapshot.isDraggingOver
                        ? "#FBFBFD"
                        : "#F8F8FA",
                      width: "91%",
                      height: "fit-content",
                      borderRadius: 12,
                      paddingTop: 10
                    }}
                  >
                    {section.bottomItems?.map((item, index) => {
                      let draggableId = "";
                      if (item.id) {
                        draggableId = item.id.toString();
                      }
                      return (
                        <Draggable
                          key={item.id}
                          draggableId={draggableId}
                          index={index}
                        >
                          {(draggableProvided) => (
                            <div
                              onMouseEnter={() => handleMouseEnter(draggableId)}
                              onMouseLeave={() => handleMouseLeave(draggableId)}
                              key={item.id}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                              ref={draggableProvided.innerRef}
                              style={{
                                userSelect: "none",
                                borderRadius: 12,
                                color: "#373F50",
                                ...draggableProvided.draggableProps.style
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  margin: "10px 0px 10px 25px"
                                }}
                              >
                                <div>
                                  <div
                                    style={{
                                      width: 40,
                                      height: 40,
                                      backgroundColor: "#2ABBFF",
                                      borderTopLeftRadius: 12,
                                      borderBottomLeftRadius: 12,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-around"
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      color: "white",
                                      fontSize: 16
                                    }}
                                  ></div>
                                </div>
                                <div
                                  style={{
                                    padding: "5px 0 0 20px",
                                    borderBottomRightRadius: 12,
                                    borderTopRightRadius: 12,
                                    width: "90%",
                                    borderTop: "1px solid #E9EBF0",
                                    borderBottom: "1px solid #E9EBF0",
                                    borderRight: "1px solid #E9EBF0",
                                    backgroundColor: "white"
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    <div
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                      }}
                                    >
                                      {item.query}
                                    </div>
                                    {hoveredStates[draggableId] && (
                                      <div
                                        onClick={() =>
                                          handleDeleteFromIntroOrConclusion(
                                            draggableId,
                                            section.stringUUID,
                                            bodySectionDroppableAreas,
                                            setBodySectionDroppableAreas,
                                            setHoveredStates,
                                            setDraggedItems,
                                            draggedItems,
                                            selectedPidObject.id,
                                            briefId,
                                            targetedKeywords,
                                            targetedQuestions,
                                            setShowSaved
                                          )
                                        }
                                        style={{
                                          color: "#909BB3",
                                          marginRight: "20px",
                                          paddingLeft: 10
                                        }}
                                      >
                                        x
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {section?.bottomItems.length > 0 && (
                      <div className="body-droppable-div">
                        Drop a term here...
                      </div>
                    )}
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            ) : null
          )}
        </div>
      </div>
    </div>
  );
};

export default ConclusionArea;
