/* eslint-disable max-lines */
import { useState, useEffect, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { Divider, Grid } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ClearIcon from "@mui/icons-material/Clear";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import DJForm from "@/components/shared/DJForm";
import DJTextField from "@/components/shared/DJTextField";
import Button from "@/components/shared/Button";
import authorize from "../../../routines/authorize";
import GetUserByToken from "../../../routines/get-user-by-token";
import GoogleLoginEl from "./GoogleLoginEl";
import {
  getPidSubscription,
  getUserAccountsAndPidsPaginated
} from "../../../api/get";
import { IUser } from "../../../interfaces/user.interface";
import { IPDomain } from "../../../interfaces/pdomain.interface";
import { IAppStore } from "../../../interfaces/app-store.interface";
import { IAccount } from "../../../interfaces/account.interface";
import { useAppStore } from "../../../hooks";
import { ICbSubscription } from "../../../interfaces/subscription.interface";
import ShowPassword from "@/components/shared/ShowPassword";

interface emailLoginProps {
  setLoading: (arg0: boolean) => void;
}

const EmailLogin = ({ setLoading }: emailLoginProps) => {
  const navigate = useNavigate();
  const [Email, SetEmail] = useState("");
  const [Password, SetPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [extendedLogin, setExtendedLogin] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openResetSuccessAlert, setOpenResetSuccessAlert] = useState(false);

  const {
    setUser,
    setSelectedPidObject,
    setUserAccounts,
    setSelectedAccountObject,
    setSelectedSubscriptionObject,
    passwordResetNotification,
    setPasswordResetNotification,
    passwordResetSuccessNotification,
    setPasswordResetSuccessNotification
  } = useAppStore((store: IAppStore) => ({
    setUser: (value: IUser) => store.setUser(value),
    setSelectedPidObject: (value: IPDomain) =>
      store.setSelectedPidObject(value),
    setUserAccounts: (value: IAccount[]) => store.setUserAccounts(value),
    passwordResetNotification: store.passwordResetNotification,
    setPasswordResetNotification: (value: boolean) =>
      store.setPasswordResetNotification(value),
    passwordResetSuccessNotification: store.passwordResetSuccessNotification,
    setPasswordResetSuccessNotification: (value: boolean) =>
      store.setPasswordResetSuccessNotification(value),
    setSelectedAccountObject: (value: IAccount) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      store.setSelectedAccountObject(value),
    setSelectedSubscriptionObject: (value: ICbSubscription) =>
      store.setSelectedSubscriptionObject(value)
  }));

  const login = async () => {
    const QueryString = window.location.search;
    const urlParams = new URLSearchParams(QueryString);
    const location = urlParams.get("location");
    const duration = extendedLogin ? 30 : 1;
    await authorize(Email, Password, duration)
      .then(async () => {
        setLoading(true);
        setShowError(false);
        setErrorText("");
        try {
          const udb = await GetUserByToken();
          setUser(udb);
          const accountsPidsResponse = await getUserAccountsAndPidsPaginated(
            1,
            10
          );
          const accountspids = accountsPidsResponse.data;
          setUserAccounts(accountspids);
          setSelectedAccountObject(accountspids[0]);
          setSelectedPidObject(accountspids[0].pdomains[0]);
          const pdomainData = accountspids[0].pdomains[0];
          // get the subscription for the pid
          const pidSubscriptions = await getPidSubscription(pdomainData.id);
          setSelectedSubscriptionObject(pidSubscriptions[0]);
          // stop loading
          setLoading(false);

          // direct them to mfa if they have it enabled
          if (udb.otp_enabled) {
            navigate("/mfa");
          } else {
            const final_location = location ? location : "/home";
            navigate(final_location);
          }
        } catch (error) {
          console.error(error);
          setShowError(true);
          setErrorText(
            "There was a problem fetching your user. Please ensure you entered the correct user email and password."
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setShowError(true);
        setErrorText(
          "There was a problem authenticating you. Please ensure you entered the correct user email and password."
        );
        setLoading(false);
      });
  };

  const dismissError = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setShowError(false);
    setErrorText("");
  };

  useEffect(() => {
    if (passwordResetNotification) {
      setOpenAlert(true);
      setPasswordResetNotification(false);
    }
    if (passwordResetSuccessNotification) {
      setOpenResetSuccessAlert(true);
      setPasswordResetSuccessNotification(false);
    }
  }, [passwordResetNotification][passwordResetSuccessNotification]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={() => setOpenAlert(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Your request has been sent to the email on file. If you do not get an
          email within a few minutes, please check your spam filters or contact
          success@demandjump.com.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openResetSuccessAlert}
        autoHideDuration={4000}
        onClose={() => setOpenResetSuccessAlert(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          You have successfully reset you password.
        </Alert>
      </Snackbar>
      <div style={{ width: "65%", textAlign: "left" }}>
        {showError && (
          <div
            style={{
              width: "100%",
              backgroundColor: "#FFEDED",
              color: "#820101",
              padding: "10px"
            }}
          >
            <Grid container>
              <Grid item xs={11}>
                <ErrorOutlineIcon
                  style={{ verticalAlign: "middle" }}
                ></ErrorOutlineIcon>{" "}
                <span style={{ verticalAlign: "middle" }}>{errorText}</span>
              </Grid>
              <Grid item xs={1}>
                <Grid container justifyContent="flex-end" alignItems="flex-end">
                  <a
                    style={{ verticalAlign: "middle" }}
                    href="#"
                    onClick={(e) => dismissError(e)}
                  >
                    <ClearIcon></ClearIcon>
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
        <h2>Sign In</h2>
        <small>
          Don't have an account? <a href="/signup">Sign Up Now</a>
        </small>
        <DJForm
          initialValues={{ email: "", password: "" }}
          onSubmit={() => login()}
        >
          <Grid item xs={12} style={{ paddingBottom: "16px" }}>
            <DJTextField
              type="email"
              placeholder="Email"
              name="email"
              required={true}
              onChange={(event: {
                target: { value: SetStateAction<string> };
              }) => SetEmail(event.target.value)}
            />
          </Grid>
          <div>
            <Grid item xs={12}>
              <DJTextField
                id="password"
                type="password"
                placeholder="Password"
                name="password"
                required={true}
                onChange={(event: {
                  target: { value: SetStateAction<string> };
                }) => SetPassword(event.target.value)}
              />
              <ShowPassword />
            </Grid>
          </div>
          <Grid container style={{ paddingTop: "20px", paddingBottom: "30px" }}>
            <Grid item xs={12} sm={5} md={12} lg={12} xl={5}>
              <p>
                <input
                  type={"checkbox"}
                  autoComplete="on"
                  onChange={() => setExtendedLogin(!extendedLogin)}
                ></input>{" "}
                Stay logged in for 30 days
              </p>
              <p style={{ marginBottom: "0px" }}>
                <small>
                  Forgot password? <a href="/forgot-password">Reset password</a>
                </small>
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={7}
              md={12}
              lg={12}
              xl={7}
              style={{
                alignSelf: "flex-end",
                paddingTop: "10px"
              }}
            >
              <Grid
                container
                justifyContent={{
                  xs: "flex-start",
                  sm: "flex-end",
                  md: "flex-start",
                  lg: "flex-start",
                  xl: "flex-end"
                }}
                alignItems="flex-end"
              >
                <Button
                  type="submit"
                  size="large"
                  color="primary"
                  variant="contained"
                  text="Sign In"
                ></Button>
              </Grid>
            </Grid>
          </Grid>
        </DJForm>
        <Divider
          sx={{
            color: "#909BB3",
            fontFamily: "Source Sans Pro, sans-serif",
            fontSize: "16px"
          }}
        >
          Or sign in with
        </Divider>
        <div
          style={{
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "center",
            marginTop: "30px"
          }}
          className=""
        >
          <GoogleLoginEl
            extendedLogin={extendedLogin}
            setShowError={setShowError}
            setErrorText={setErrorText}
            setLoading={setLoading}
          ></GoogleLoginEl>
        </div>
      </div>
    </Grid>
  );
};
export default EmailLogin;
