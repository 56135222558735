import "./SkeletonRow.scss";

const SkeletonRow = (props: { columns: number }) => {
  const maxWidth = {
    height: "20px",
    maxWidth: "150px",
    minWidth: "50px"
  };

  return (
    <tr>
      {[...Array(props.columns)].map((_elementInArray, index) => (
        <td style={maxWidth} key={index}>
          <span className="skeleton-shape" style={maxWidth}></span>
        </td>
      ))}
    </tr>
  );
};

export default SkeletonRow;
