import Cookies from "universal-cookie";
import { decodeToken } from "react-jwt";

import { getUserById } from "./../api/get";
import { IUser } from "../interfaces/user.interface";

const GetUserByToken = async (): Promise<IUser> => {
  const cookies = new Cookies();
  const access_token = cookies.get("Access_Token");
  const adt = decodeToken(access_token) as { user_id: number };
  if (adt?.user_id) {
    const id = adt.user_id;

    const user = await getUserById(id)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
    return user as IUser;
  }
  throw new Error("No access token detected");
};

export default GetUserByToken;
