/* eslint-disable max-lines */
import { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { IconEdit, ActionTrash, IconAddUser } from "../../../assets/icons/svgr";
import {
  useAppStore,
  useEffectAsync,
  useManageUsersStore
} from "../../../hooks";
import {
  getAccountAdmins,
  getAccountUsers,
  getUserAccountAndPid
} from "../../../api/get";
import Chip from "@material-ui/core/Chip";
import InviteUserModal from "./components/InviteUserModal";
import EditUserModal from "./components/EditUserModal";
import DeleteUserModal from "./components/DeleteUserModal";
import { IAccountUser } from "../../../interfaces/account-user.interface";
import { IManageUsersStore } from "../../../interfaces/manage-users-store.interface";
import { IAppStore } from "../../../interfaces/app-store.interface";
import { IAlertBannerOptions } from "../../../interfaces/alert-banner-options";
import DJLoadingGif from "../../shared/DJLoadingGif";
import { IAccount } from "@/interfaces/account.interface";
import { useNavigate } from "react-router-dom";

function ManageUsers(): JSX.Element {
  const [inviteModalShow, setInviteModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [requestLoading, setRequestLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkingAdminStatus, setCheckingAdminStatus] = useState(true);

  const navigate = useNavigate();

  const {
    selectedPidAccountUsers,
    setSelectedPidAccountUsers
  }: IManageUsersStore = useManageUsersStore((store: IManageUsersStore) => ({
    selectedPidAccountUsers: store.selectedPidAccountUsers,
    setSelectedPidAccountUsers: (value: IAccountUser[]) =>
      store.setSelectedPidAccountUsers(value)
  }));

  const {
    user,
    isAdmin,
    selectedPidObject,
    selectedAccountObject,
    setSelectedAccountObject,
    setShowAlertBanner,
    setIsAdmin
  }: IAppStore = useAppStore((store: IAppStore) => ({
    user: store.user,
    isAdmin: store.isAdmin,
    selectedPidObject: store.selectedPidObject,
    selectedAccountObject: store.selectedAccountObject,
    setSelectedAccountObject: (value: IAccount) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      store.setSelectedAccountObject(value),
    setShowAlertBanner: (
      bool: boolean,
      alertBannerOptions: IAlertBannerOptions
    ) => store.setShowAlertBanner(bool, alertBannerOptions),
    setIsAdmin: (value: boolean) => store.setIsAdmin(value)
  }));

  // determine the account for the selected pid
  useEffectAsync(async () => {
    try {
      setLoading(true);
      if (!isEmpty(selectedPidObject)) {
        const account_users = await getAccountUsers(
          selectedPidObject.account_id
        );
        setSelectedPidAccountUsers(account_users);
      }
      setLoading(false);
      // This endpoint only gets the pids the user has access to, not all pids
      // for the account (dj users get all pids for account)
      if (selectedPidObject.id) {
        const accountAndPid = await getUserAccountAndPid(selectedPidObject.id);
        setSelectedAccountObject(accountAndPid);
      }
    } catch {
      setShowAlertBanner(true, {
        message: "Failed to load account users",
        severity: "error"
      });
      setLoading(false);
    }
  }, [selectedPidObject]);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!isAdmin) {
        setCheckingAdminStatus(true);
        const admins = await getAccountAdmins(selectedAccountObject.id);
        if (admins.includes(user.id)) {
          setIsAdmin(true);
          setCheckingAdminStatus(false);
          return;
        }
        setIsAdmin(false);
        navigate("/unauthorized");
      }
    };
    if (user && !user.dj_employee) {
      checkAdminStatus();
    } else if (user.dj_employee) {
      setCheckingAdminStatus(false);
    }
  }, [isAdmin, user]);

  if (loading) {
    return (
      <div
        className="main-loading-container"
        style={{ position: "absolute", top: "100px", left: "100px" }}
      >
        <DJLoadingGif />
      </div>
    );
  }
  return (
    <>
      {(!checkingAdminStatus || isAdmin) && (
        <div className="container p-3">
          <div className="d-flex justify-content-between">
            <h5
              className="card-title mt-2 mb-2"
              style={{
                display: "flex",
                alignItems: "center",
                whiteSpace: "nowrap",
                color: "rgba(13,27,62,0.7)",
                fontSize: "1.1rem !important",
                fontWeight: "400 !important"
              }}
            >
              Manage Account Users {selectedAccountObject?.company_name}
            </h5>
            <div className="">
              <button
                className="btn-icon btn-pill btn btn-primary btn-shadow"
                onClick={() => setInviteModalShow(true)}
              >
                <IconAddUser style={{ width: "16px" }} /> Invite New User
              </button>
            </div>
          </div>
          <hr />
          <div className="container flex">
            {selectedPidAccountUsers?.map((accountUser, i) => (
              <div key={i} style={{ padding: "1rem 5px" }}>
                <div key={i} className="row">
                  <div style={{ width: "60%" }}>
                    <div className="">{accountUser.name}</div>
                    <div
                      style={{
                        color: "rgba(21,36,43,0.26)"
                      }}
                    >
                      {accountUser.email}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "25%",
                      paddingRight: "16px"
                    }}
                    className="d-flex justify-content-end"
                  >
                    <div style={{ marginRight: "16px" }}>
                      {accountUser.status === 1 ? (
                        <Chip
                          label={"Pending Invitation"}
                          style={{
                            backgroundColor: "#d92550",
                            color: "#fff",
                            padding: "6px 0px 7px",
                            textTransform: "uppercase",
                            fontWeight: "700",
                            fontSize: "75%"
                          }}
                          size="small"
                        />
                      ) : null}
                    </div>
                    {accountUser.admin === 1 ? (
                      <Chip
                        label="Admin"
                        style={{
                          backgroundColor: "#6c757d",
                          color: "#fff",
                          padding: "6px 24px 7px",
                          textTransform: "uppercase",
                          fontWeight: "700",
                          fontSize: "75%",
                          marginRight: "16px"
                        }}
                        size="small"
                      />
                    ) : null}
                    <Chip
                      label={`${accountUser?.primaryDomains?.length} Domain${
                        accountUser?.primaryDomains &&
                        accountUser.primaryDomains.length > 1
                          ? "s"
                          : ""
                      }`}
                      style={{
                        backgroundColor: "#3ac47d",
                        color: "#fff",
                        padding: "6px 24px 7px",
                        textTransform: "uppercase",
                        fontWeight: "700",
                        fontSize: "75%"
                      }}
                      size="small"
                    />
                  </div>
                  <div
                    style={{
                      borderLeftStyle: "dashed",
                      borderLeftWidth: "1px",
                      borderColor: "rgba(0,0,0,0.23)",
                      width: "15%"
                    }}
                  >
                    <button
                      id="edit-user"
                      type="button"
                      style={{
                        borderRadius: "20px",
                        marginRight: "10px"
                      }}
                      className="p-0 btn btn-pill btn-outline-primary"
                      disabled={accountUser.status === 1}
                      onClick={() => {
                        setCurrentUser(accountUser);
                        setEditModalShow(true);
                      }}
                    >
                      <span
                        className="icon-wrapper icon-wrapper-alt rounded-circle"
                        style={{
                          height: "32px",
                          width: "32px",
                          borderRadius: "8px"
                        }}
                      >
                        <IconEdit
                          style={{
                            height: "32px",
                            width: "32px"
                          }}
                        />
                      </span>
                    </button>
                    <button
                      id="remove-user"
                      type="button"
                      style={{
                        borderRadius: "20px"
                      }}
                      className="p-0  btn btn-pill btn-outline-danger"
                      onClick={() => {
                        setCurrentUser(accountUser);
                        setDeleteModalShow(true);
                      }}
                    >
                      <span
                        className="icon-wrapper icon-wrapper-alt rounded-circle"
                        style={{
                          height: "32px",
                          width: "32px",
                          borderRadius: "8px"
                        }}
                      >
                        <ActionTrash
                          style={{
                            height: "32px",
                            width: "32px"
                          }}
                        />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {editModalShow && (
            <EditUserModal
              show={editModalShow}
              pids={selectedAccountObject.pdomains}
              user={currentUser}
              onHide={() => setEditModalShow(false)}
              loadingRequest={requestLoading}
              setLoading={setRequestLoading}
              account_id={selectedAccountObject.id}
              selectedPidAccountUsers={selectedPidAccountUsers}
              setSelectedPidAccountUsers={setSelectedPidAccountUsers}
            />
          )}
          <DeleteUserModal
            show={deleteModalShow}
            user={currentUser}
            onHide={() => setDeleteModalShow(false)}
            loadingRequest={requestLoading}
            setLoading={setRequestLoading}
            account_id={selectedAccountObject.id}
            selectedPidAccountUsers={selectedPidAccountUsers}
            setSelectedPidAccountUsers={setSelectedPidAccountUsers}
          />

          {inviteModalShow && (
            <InviteUserModal
              setSelectedPidAccountUsers={setSelectedPidAccountUsers}
              propspids={selectedAccountObject.pdomains}
              setInviteModalShow={setInviteModalShow}
              account_id={selectedAccountObject.id}
            />
          )}
        </div>
      )}
    </>
  );
}

export default ManageUsers;
