// react component that renders a card with a message that there are no insights
import { useNavigate } from "react-router-dom";

interface IProps {
  page: string;
}

const NoCompetitorsComponent = ({ page }: IProps) => {
  const navigate = useNavigate();
  return (
    <div className="text-center p-5" data-testid="no-competitors">
      {/* h2 with text */}
      <h2 className="">{page} are Incomplete</h2>
      <h5 className="p-3">
        You must have at least one competitor to view {page.toLowerCase()}
      </h5>
      <button
        className="btn btn-primary demandjump-button-primary"
        onClick={() => navigate("/settings/competitors")}
      >
        Add Competitor
      </button>
    </div>
  );
};

export default NoCompetitorsComponent;
