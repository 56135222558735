/* eslint-disable max-lines */
import { useAppStore, usePillarStrategyStore } from "@/hooks";
import { IAppStore } from "@/interfaces/app-store.interface";
import { IAlertBannerOptions } from "@/interfaces/alert-banner-options";
import { Dispatch, SetStateAction, useState } from "react";
import {
  IBlogs,
  IContentPillars,
  ISubPillars
} from "@/interfaces/content-pillars.interface";
import { putMoveBlogs } from "@/api/put";
import {
  SelectedItem,
  IPillarStrategyStore
} from "@/interfaces/pillar-strategy-store.interface";

const selectStyle = {
  backgroundColor: "#E1F5FF",
  border: "1px solid #008cce"
};

const MoveItemsModal = ({
  items,
  sub_pillars,
  pillar,
  setShowMoveItemsModal
}: {
  items: SelectedItem;
  sub_pillars: ISubPillars[] | [] | undefined;
  pillar: IContentPillars;
  setShowMoveItemsModal: Dispatch<SetStateAction<boolean | undefined>>;
}) => {
  const [selectedSubPillarIndex, setSelectedSubPillarIndex] = useState(-1);
  const [selectedIsSubPillar, setSelectedIsSubPillar] = useState(false);
  const [itemSelected, setItemSelected] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [search, setSearch] = useState("");

  const { setSelectedTopics } = usePillarStrategyStore(
    (store: IPillarStrategyStore) => ({
      setSelectedTopics: (value: SelectedItem) => store.setSelectedTopics(value)
    })
  );

  const { setShowAlertBanner } = useAppStore((store: IAppStore) => ({
    setShowAlertBanner: (bool: boolean, value: IAlertBannerOptions) =>
      store.setShowAlertBanner(bool, value)
  }));

  const updatePillar = (
    selected_items: SelectedItem,
    current_pillar: IContentPillars,
    type: "sub_pillar" | "pillar" | "promote" | "demote",
    id: number
  ): IContentPillars => {
    const final_moved_blogs = [] as IBlogs[];
    const final_moved_sub_pillars = [] as ISubPillars[];
    const current_pillar_blogs = current_pillar.blogs?.filter(
      (blog) => blog?.id && !selected_items?.blog?.includes(blog.id)
    );
    const moved_pillar_blogs = current_pillar.blogs?.filter(
      (blog) => blog?.id && selected_items?.blog?.includes(blog.id)
    );
    if (moved_pillar_blogs) {
      final_moved_blogs.push(...moved_pillar_blogs);
    }
    current_pillar.blogs = current_pillar_blogs;
    current_pillar?.sub_pillars?.forEach((sp, index) => {
      const moved_sub_pillars = current_pillar.sub_pillars?.filter(
        (sub_pillar) =>
          sub_pillar?.id && selected_items?.sub_pillar?.includes(sub_pillar.id)
      );
      if (moved_sub_pillars) {
        final_moved_sub_pillars.push(...moved_sub_pillars);
      }
      const current_sp_blogs = sp.blogs?.filter(
        (blog) => blog?.id && !selected_items?.blog?.includes(blog.id)
      );
      const moved_sp_blogs = sp.blogs?.filter(
        (blog) => blog?.id && selected_items?.blog?.includes(blog.id)
      );
      if (moved_sp_blogs) {
        final_moved_blogs.push(...moved_sp_blogs);
      }
      if (current_pillar?.sub_pillars) {
        current_pillar.sub_pillars[index].blogs = current_sp_blogs;
      }
    });

    if (type === "pillar") {
      current_pillar.blogs = current_pillar.blogs?.concat(final_moved_blogs);
    } else if (type === "sub_pillar") {
      current_pillar.sub_pillars?.forEach((sp, index) => {
        if (sp.id === id) {
          if (current_pillar.sub_pillars) {
            current_pillar.sub_pillars[index].blogs =
              current_pillar.sub_pillars[index].blogs?.concat(
                final_moved_blogs
              );
          }
        }
      });
    }

    return current_pillar;
  };

  const handleMoveItems = async () => {
    setShowMoveItemsModal(false);
    setShowAlertBanner(true, {
      message: "Moving Blog(s)...",
      severity: "info"
    });
    const submittedItems = JSON.parse(JSON.stringify(items));
    const body = {
      type: selectedIsSubPillar ? "sub_pillar" : "pillar",
      id: selectedId,
      items: submittedItems,
      pillar_id: pillar.id
    };
    setSelectedTopics({ blog: [], sub_pillar: [] });
    updatePillar(
      submittedItems,
      pillar,
      selectedIsSubPillar ? "sub_pillar" : "pillar",
      selectedId
    );

    await putMoveBlogs(body)
      .then(() => {
        setShowAlertBanner(true, {
          message: "Your blogs have been successfully moved",
          severity: "success"
        });
      })
      .catch((e) => {
        console.error(e);
        setShowAlertBanner(true, {
          message:
            "There was a problem moving your Blogs, please try again. If the error persists contact support.",
          severity: "error"
        });
      });
  };

  const setSelected = (
    type: "pillar" | "sub_pillar",
    index: number,
    id: number | undefined | null
  ) => {
    setItemSelected(true);
    if (id) {
      setSelectedId(id);
    }
    if (type === "pillar") {
      setSelectedIsSubPillar(false);
      setSelectedSubPillarIndex(-1);
    } else {
      setSelectedSubPillarIndex(index);
      setSelectedIsSubPillar(true);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value.toLowerCase());
  };

  return (
    <div
      className="shadow bg-body rounded"
      style={{
        position: "fixed",
        width: "380px",
        height: "500px",
        left: "calc(50% - 182px)",
        top: "calc(50% - 167px)",
        background: "#FFFFFF",
        borderRadius: "3px",
        border: "1px solid #E9EBF0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: "10"
      }}
    >
      <button
        style={{
          position: "relative",
          left: "165px",
          top: "16px"
        }}
        className="btn btn-close"
        onClick={() => setShowMoveItemsModal(false)}
      />
      <div
        style={{
          position: "relative",
          width: "317px",
          height: "36px",
          fontFamily: "Source Sans Pro",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "34px",
          lineHeight: "43px",
          alignItems: "center",
          textAlign: "center",
          paddingTop: "16px",
          letterSpacing: "0.0025em",
          color: "#373F50"
        }}
      >
        Move {items.blog.length || items.sub_pillar.length} page(s) to...
      </div>
      <div style={{ position: "relative", top: "32px" }}>
        <div style={{ paddingLeft: "30px" }}>
          <input
            id="search"
            type="text"
            className={"fa form-control"}
            placeholder="&#xF002; Search..."
            style={{ maxWidth: "90%" }}
            onChange={handleSearch}
          ></input>
        </div>
        <div
          style={{
            padding: "30px",
            overflowY: "scroll",
            height: "285px"
          }}
        >
          <p style={{ color: "grey", marginBottom: "5px" }}>Pillar</p>
          <p style={{ marginBottom: "5px" }}>
            <button
              className="pillar_sub_pillar_btn"
              onClick={() => setSelected("pillar", -1, pillar.id)}
              style={!selectedIsSubPillar && itemSelected ? selectStyle : {}}
            >
              {pillar.keyword}
            </button>
          </p>
          <p style={{ color: "grey", marginBottom: "5px" }}>Sub-Pillars</p>
          {sub_pillars &&
            sub_pillars?.length > 0 &&
            sub_pillars
              .filter((item) => item.topic.toLowerCase().includes(search))
              .sort((a, b) => {
                if (a.id && b.id) {
                  return b.id - a.id;
                }
                return 0;
              })
              .map((sp, index) => (
                <p key={sp.id} style={{ marginBottom: "5px" }}>
                  <button
                    className="pillar_sub_pillar_btn"
                    onClick={() => setSelected("sub_pillar", index, sp.id)}
                    style={
                      selectedIsSubPillar &&
                      itemSelected &&
                      selectedSubPillarIndex === index
                        ? selectStyle
                        : {}
                    }
                  >
                    {sp.topic}
                  </button>
                </p>
              ))}
        </div>
      </div>
      <div
        style={{
          position: "relative",
          paddingTop: "30px",
          top: "30px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "307px"
        }}
      >
        <button
          style={{
            width: "135px",
            height: "40px",
            background: "#FFFFFF",
            border: "none",
            fontFamily: "Source Sans Pro",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "20px",
            textAlign: "center",
            letterSpacing: "0.005em",
            color: "#373F50"
          }}
          className="btn btn-secondary"
          onClick={() => setShowMoveItemsModal(false)}
        >
          Cancel
        </button>
        <button
          style={{
            width: "135px",
            height: "40px",
            background: "#008cce",
            borderRadius: "3px",
            fontFamily: "Source Sans Pro",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "20px",
            textAlign: "center",
            letterSpacing: "0.005em",
            color: "#FFFFFF"
          }}
          className="btn btn-primary"
          onClick={() => handleMoveItems()}
        >
          Move
        </button>
      </div>
    </div>
  );
};

export default MoveItemsModal;
