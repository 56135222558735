import { Row, Col } from "react-bootstrap";

const StepOne = (props: {
  briefType: "blog" | "sub-pillar" | "pillar" | "ad-hoc";
  termQuantity: number;
}) => (
  <Row style={{ marginLeft: "5px" }}>
    <Col xs={12}>
      <>
        <p style={{ color: "grey", marginTop: "55px" }}>STEP 1</p>
        <h5>Select Your Questions and Keywords</h5>
        {props.briefType !== "ad-hoc" && (
          <p>
            For {props.briefType}s, we recommend {props.termQuantity} questions
            and {props.termQuantity} keywords.
          </p>
        )}
      </>
    </Col>
  </Row>
);

export default StepOne;
