// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row-padding-top-20 {
  padding-top: 20px;
}

.row-padding-top-40 {
  padding-top: 40px;
}

.outline-title {
  color: var(--secondary, #373F50);
  font-family: Source Sans Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.outline-subtitle {
  color: var(--secondary, #373F50);
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.outline-text {
  color: var(--secondary, #373F50);
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/components/manage_content/content_brief/content_brief_workflow/step_three/components/Outline/outline.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;AACD;;AAEA;EACC,iBAAA;AACD;;AAEA;EACC,gCAAA;EACA,4BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACD;;AAEA;EACC,gCAAA;EACA,4BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACD;;AAEA;EACC,gCAAA;EACA,4BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACD","sourcesContent":[".row-padding-top-20 {\n\tpadding-top: 20px;\n}\n\n.row-padding-top-40 {\n\tpadding-top: 40px;\n}\n\n.outline-title {\n\tcolor: var(--secondary, #373F50);\n\tfont-family: Source Sans Pro;\n\tfont-size: 24px;\n\tfont-style: normal;\n\tfont-weight: 400;\n\tline-height: normal;\n}\n\n.outline-subtitle {\n\tcolor: var(--secondary, #373F50);\n\tfont-family: Source Sans Pro;\n\tfont-size: 16px;\n\tfont-style: normal;\n\tfont-weight: 600;\n\tline-height: normal;\n}\n\n.outline-text {\n\tcolor: var(--secondary, #373F50);\n\tfont-family: Source Sans Pro;\n\tfont-size: 16px;\n\tfont-style: normal;\n\tfont-weight: 400;\n\tline-height: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
