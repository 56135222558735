import { useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { NavigationCollapse } from "@/assets/icons/svgr";

const CollapseItem = ({
  sideMenuOpen,
  handleDrawerOpen,
  handleDrawerClose
}: {
  sideMenuOpen: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  return (
    <ListItem
      disablePadding
      sx={{
        background: isHovered ? "#E1F5FF" : "",
        display: { xs: "none", sm: "block" },
        "&:active": {
          background: "#E1F5FF",
          color: "#008CCE"
        }
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ListItemButton
        color="inherit"
        aria-label="open drawer"
        onClick={sideMenuOpen ? handleDrawerClose : handleDrawerOpen}
        sx={{
          minHeight: 37,
          height: "37px",
          justifyContent: sideMenuOpen ? "initial" : "center",
          "&.MuiButtonBase-root:hover": {
            bgcolor: "transparent"
          },
          px: 2.5
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: sideMenuOpen ? "15px" : "auto",
            justifyContent: "center",
            color: isHovered ? "#008CCE" : "#373F50"
          }}
        >
          <NavigationCollapse
            style={
              sideMenuOpen
                ? { width: "24px" }
                : { width: "24px", transform: "rotate(180deg)" }
            }
          />
        </ListItemIcon>
        <ListItemText
          primary="Collapse"
          sx={{
            opacity: sideMenuOpen ? 1 : 0,
            color: isHovered ? "#008CCE" : ""
          }}
          primaryTypographyProps={{
            fontSize: "17px",
            fontFamily: "Source Sans Pro",
            fontWeight: isHovered ? "bold" : "normal",
            lineHeight: "21px",
            letterSpacing: "-0.015em"
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default CollapseItem;
