import "./HTTPError.scss";
import { DjLogoD } from "@/assets/icons/svgr";

interface HTTPErrorProps {
  title: string;
}

function HTTPError({ title }: HTTPErrorProps) {
  return (
    <div className="fzf-parent">
      <DjLogoD style={{ width: "200px" }} />
      <div className="main-message">{title}</div>
      <div className="small-message">
        Please contact{" "}
        <a href="mailto: success@demandjump.com">Customer Success</a> with any
        questions.
      </div>
    </div>
  );
}

export default HTTPError;
