import useContentBriefsStore from "@/hooks/useContentBriefsStore";
import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";
import { IDroppableArea } from "@/interfaces/outline.interface";
import { Droppable, Draggable } from "react-beautiful-dnd";

import { AiOutlineCheck } from "react-icons/ai";
import { RxDragHandleDots2 } from "react-icons/rx";

// eslint-disable-next-line arrow-body-style
const KeywordsArea = ({
  areasYouCanDropItemsFrom
}: {
  areasYouCanDropItemsFrom: IDroppableArea[];
}) => {
  const { keywordsLength, termQuantity, draggedItems } = useContentBriefsStore(
    (store: IContentBriefsStore) => ({
      keywordsLength: store.keywordsLength,
      termQuantity: store.termQuantity,
      draggedItems: store.draggedItems
    })
  );

  return (
    <div>
      <div style={{ margin: "50px 40px" }}>
        {areasYouCanDropItemsFrom.map((section) =>
          section.name === "Keywords" ? (
            <div key={section.stringUUID}>
              <Droppable droppableId={section.stringUUID}>
                {(droppableProvided) => (
                  <div
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                    style={{
                      background: "white",
                      paddingRight: 30
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: "20px"
                      }}
                    >
                      <div
                        style={{
                          textTransform: "uppercase",
                          color: "#56627C",
                          fontSize: "14px"
                        }}
                      >
                        Keywords
                      </div>
                      <div style={{ color: "#56627C", fontSize: "14px" }}>
                        <span
                          style={{
                            color:
                              keywordsLength >= termQuantity
                                ? "black"
                                : "#B00020"
                          }}
                        >
                          {keywordsLength}
                        </span>
                        /{termQuantity} Targeted
                      </div>
                    </div>
                    {section.items?.map((item, index) => {
                      let draggableId = "";
                      if (item.id) {
                        draggableId = item.id.toString();
                      }
                      return item.term_type === "keyword" ? (
                        <Draggable
                          key={item.id}
                          draggableId={draggableId}
                          index={index}
                        >
                          {(draggableProvided) => (
                            <div
                              key={item.id}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                              ref={draggableProvided.innerRef}
                              style={{
                                userSelect: "none",
                                padding: "6px",
                                margin: "0 0 8px 0",
                                height: "fit-content",
                                border: "1px solid #E9EBF0",
                                borderRadius: 10,
                                backgroundColor: "white",
                                color: "#373F50",
                                ...draggableProvided.draggableProps.style
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between"
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center"
                                  }}
                                >
                                  <div>
                                    <RxDragHandleDots2
                                      style={{ color: "#D9D9D9" }}
                                    />
                                  </div>
                                  <div style={{ padding: "0 5px 0 10px" }}>
                                    {item.query}
                                  </div>
                                </div>
                                {draggedItems.includes(item.query) && (
                                  <div style={{ color: "#44AF69" }}>
                                    <AiOutlineCheck />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ) : null;
                    })}
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export default KeywordsArea;
