import { useAppStore, useInsightsStore } from "@/hooks";
import { IAppStore } from "@/interfaces/app-store.interface";
import { IInsights, IInsightsStore } from "@/interfaces/insights.interface";
import { deleteInsight } from "@/api/delete";
import { IAlertBannerOptions } from "@/interfaces/alert-banner-options";
import { Dispatch, SetStateAction } from "react";
import trashIcon from "../../../assets/images/trash.png";

const ConfirmDeleteInsightModal = ({
  insightId,
  setShowDeleteModal
}: {
  insightId: number | undefined;
  setShowDeleteModal: Dispatch<SetStateAction<boolean | undefined>>;
}) => {
  const { setShowAlertBanner } = useAppStore((store: IAppStore) => ({
    setShowAlertBanner: (bool: boolean, value: IAlertBannerOptions) =>
      store.setShowAlertBanner(bool, value)
  }));

  // setInsights
  const { insights, setInsights }: IInsightsStore = useInsightsStore(
    (store: IInsightsStore) => ({
      insights: store.insights,
      setInsights: (value: IInsights[]) => store.setInsights(value)
    })
  );

  const handleInsightDelete = async () => {
    setShowDeleteModal(false);
    if (!insightId) {
      setShowAlertBanner(true, {
        message: "Something went wrong. Please try again.",
        type: "error"
      });
      return;
    }
    try {
      const i = insights.find((insight: IInsights) => insight.id === insightId);
      const topic = i?.query_string;
      const pid = i?.pid;
      if (topic && pid) {
        const deleteResponse = await deleteInsight(
          insightId,
          topic,
          Number(pid)
        );
        setInsights(
          insights.filter((insight: IInsights) => insight.id !== insightId)
        );
        if (deleteResponse.status === 200) {
          // display success message with AlertBanner
          setShowAlertBanner(true, {
            message: "Insight deleted successfully",
            severity: "success"
          });
        } else {
          // display error message with AlertBanner
          setShowAlertBanner(true, {
            message: "Something went wrong. Please try again.",
            severity: "error"
          });
        }
      } else {
        // display error message with AlertBanner
        setShowAlertBanner(true, {
          message: "Something went wrong. Please try again.",
          severity: "error"
        });
      }
    } catch (error) {
      // display error message with AlertBanner
      setShowAlertBanner(true, {
        message: "Something went wrong. Please try again.",
        severity: "error"
      });
      console.error(error);
    }
  };

  return (
    <div
      className="shadow bg-body rounded"
      style={{
        position: "fixed",
        width: "364px",
        height: "334px",
        left: "calc(50% - 182px)",
        top: "calc(50% - 167px)",
        background: "#FFFFFF",
        borderRadius: "3px",
        border: "1px solid #E9EBF0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <button
        style={{
          position: "relative",
          left: "155px",
          top: "16px"
        }}
        className="btn btn-close"
        onClick={() => setShowDeleteModal(false)}
      />
      <div
        style={{
          position: "relative",
          top: "16px",
          width: "67px",
          height: "67px",
          background: "#FFEDED",
          borderRadius: "50%"
        }}
      >
        <div
          style={{
            position: "relative",
            left: "16px",
            top: "16px"
          }}
        >
          <img src={trashIcon} alt="trash" />
        </div>
      </div>
      <div
        style={{
          position: "relative",
          width: "307px",
          height: "36px",
          fontFamily: "Source Sans Pro",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "34px",
          lineHeight: "43px",
          alignItems: "center",
          textAlign: "center",
          paddingTop: "16px",
          letterSpacing: "0.0025em",
          color: "#373F50"
        }}
      >
        Delete Insight?
      </div>
      <div style={{ position: "relative", top: "32px" }}>
        <p>
          "
          {
            insights.find((insight: IInsights) => insight.id === insightId)
              ?.query_string
          }
          "
        </p>
      </div>
      <div
        style={{
          position: "relative",
          width: "307px",
          height: "41px",
          fontFamily: "Source Sans Pro",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "20px",
          textAlign: "center",
          letterSpacing: "0.005em",
          color: "#000000",
          paddingTop: "32px"
        }}
      >
        This action is permanent and cannot be undone. It may take up to an hour
        for this insight to be removed from your dashboards.
      </div>
      <div
        style={{
          position: "relative",
          paddingTop: "32px",
          top: "32px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "307px"
        }}
      >
        <button
          style={{
            width: "135px",
            height: "40px",
            background: "#FFFFFF",
            border: "none",
            fontFamily: "Source Sans Pro",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "20px",
            textAlign: "center",
            letterSpacing: "0.005em",
            color: "#373F50"
          }}
          className="btn btn-secondary"
          onClick={() => setShowDeleteModal(false)}
        >
          Cancel
        </button>
        <button
          style={{
            width: "135px",
            height: "40px",
            background: "#B00020",
            borderRadius: "3px",
            fontFamily: "Source Sans Pro",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "20px",
            textAlign: "center",
            letterSpacing: "0.005em",
            color: "#FFFFFF"
          }}
          className="btn btn-danger"
          onClick={() => handleInsightDelete()}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default ConfirmDeleteInsightModal;
