import BlogText from "./BlogText";
import PillarText from "./PillarText";
import SubPillarText from "./SubPillarText";

function HelpText(props: { type: string | null }) {
  return (
    <div>
      {props?.type?.toLowerCase() === "pillar" && <PillarText />}
      {props?.type?.toLowerCase() === "sub pillar" && <SubPillarText />}
      {props?.type?.toLowerCase() === "blog" && <BlogText />}
    </div>
  );
}

export default HelpText;
