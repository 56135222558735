import {
  IContentPillars,
  ISubPillars
} from "@/interfaces/content-pillars.interface";
import { useEffect, useState } from "react";
import BlogRowSecondTier from "./BlogRowSecondTier";
import { NavigationArrowDown } from "@/assets/icons/svgr";
import StatusBadge from "./StatusBadge";
import UrlCell from "./UrlCell";
import { useAppStore, usePillarStrategyStore } from "@/hooks";
import { IAppStore } from "@/interfaces/app-store.interface";
import { postBriefToStrategy } from "@/api/post";
import AddRow from "./AddRow";
import EditRow from "./EditRow";
import { IPillarStrategyStore } from "@/interfaces/pillar-strategy-store.interface";
import { putContentUrl } from "@/api/put";
import dayjs from "dayjs";
import NewItemAlert from "./NewItemAlert";
import { Form } from "react-bootstrap";

interface SubPillarProps {
  subPillar: ISubPillars;
  writeAccess: boolean;
  parentPillar: IContentPillars;
  selected: Function;
  selectAll: String;
}
type HandleChange = (event: React.ChangeEvent<HTMLInputElement>) => void;

function SubPillarRow({
  subPillar,
  writeAccess,
  parentPillar,
  selected,
  selectAll
}: SubPillarProps) {
  const [checked, setChecked] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [hovered, setHovered] = useState(false);
  const [edit, setEdit] = useState(false);
  const [submittingBrief, setSubmittingBrief] = useState<boolean>(false);
  const [savingUrl, setSavingUrl] = useState<boolean>(false);
  const [subPillarData, setSubPillarData] = useState<ISubPillars>({
    id: null,
    topic: "",
    pillar_id: 0,
    url: "",
    outline_status: null
  });
  const [addRow, setAddRow] = useState<boolean>(false);

  const { setShowAlertBanner, selectedPidObject } = useAppStore(
    (store: IAppStore) => ({
      setShowAlertBanner: store.setShowAlertBanner,
      selectedPidObject: store.selectedPidObject
    })
  );

  const {
    selectedPidPillarTopics,
    setSelectedPidPillarTopics,
    selectedTopics,
    searchedTopic,
    expandAll,
    collapseAll
  } = usePillarStrategyStore((store: IPillarStrategyStore) => ({
    selectedPidPillarTopics: store.selectedPidPillarTopics,
    searchedTopic: store.searchedTopic,
    selectedTopics: store.selectedTopics,
    expandAll: store.expandAll,
    collapseAll: store.collapseAll,
    setSelectedPidPillarTopics: (value: IContentPillars[]) =>
      store.setSelectedPidPillarTopics(value)
  }));

  useEffect(() => {
    if (expandAll) {
      setIsExpanded(true);
    }
    if (collapseAll) {
      setIsExpanded(false);
    }
  }, [expandAll, collapseAll]);

  useEffect(() => {
    setSubPillarData({ ...subPillar });
    return () => {
      setSubPillarData({
        id: 0,
        topic: "",
        pillar_id: 0,
        url: "",
        outline_status: null
      });
    };
  }, [subPillar]);

  useEffect(() => {
    const s = selectAll === "true";
    if (s !== checked) {
      setChecked(s);
      selected(subPillar, "sub_pillar");
    }
  }, [selectAll]);

  useEffect(() => {
    setChecked(false);
    selected(subPillar, "sub_pillar", false);
  }, [searchedTopic]);

  useEffect(() => {
    if (selectedTopics.sub_pillar.length === 0) {
      setChecked(false);
      selected(subPillar, "sub_pillar", false);
    }
  }, [selectedTopics]);

  const handleRowSelect = () => {
    setChecked(!checked);
    selected(subPillar, "sub_pillar");
    subPillar?.blogs?.forEach((blog) => {
      selected(blog, "blog");
    });
  };

  const handleChange: HandleChange = (e) => {
    const { name, value } = e.target;
    setSubPillarData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCancel = () => {
    setSubPillarData({ ...subPillar });
    setEdit(false);
  };

  const addBriefToStrategy = async () => {
    setSubmittingBrief(true);
    try {
      if (subPillarData.id) {
        const body = {
          id: subPillarData.id,
          type: "subPillar",
          topic: subPillarData.topic,
          pid: selectedPidObject.id
        };
        const outlineStatus = await postBriefToStrategy(body);
        setSelectedPidPillarTopics(
          selectedPidPillarTopics.map((pillar: IContentPillars) => {
            if (pillar.id === parentPillar.id) {
              return {
                ...pillar,
                sub_pillars: pillar?.sub_pillars?.map((sp: ISubPillars) => {
                  if (sp.id === subPillarData.id) {
                    return {
                      ...sp,
                      outline_status: outlineStatus
                    };
                  }
                  return sp;
                })
              };
            }
            return pillar;
          })
        );
        setShowAlertBanner(true, {
          message: "Brief added to strategy.",
          severity: "success"
        });
      } else {
        setShowAlertBanner(true, {
          message: "Error creating brief, please try again later",
          severity: "error"
        });
      }
    } catch (error) {
      console.error(error);
      setShowAlertBanner(true, {
        message: "Error creating brief, please try again later",
        severity: "error"
      });
    }
    setSubmittingBrief(false);
  };

  const handleSaveUrl = async () => {
    setSavingUrl(true);
    try {
      if (subPillarData.id) {
        const body = {
          id: subPillarData.id,
          type: "subPillar",
          url: subPillarData.url
        };
        const response = await putContentUrl(body, selectedPidObject.id);
        if (response) {
          setSelectedPidPillarTopics(
            selectedPidPillarTopics.map((pillar: IContentPillars) => {
              if (pillar.id === parentPillar.id) {
                return {
                  ...pillar,
                  sub_pillars: pillar?.sub_pillars?.map((sp: ISubPillars) => {
                    if (sp.id === subPillarData.id) {
                      return {
                        ...sp,
                        url: subPillarData.url
                      };
                    }
                    return sp;
                  })
                };
              }
              return pillar;
            })
          );
          setShowAlertBanner(true, {
            message: "Url saved.",
            severity: "success"
          });
        } else {
          setShowAlertBanner(true, {
            message: "Error saving url.",
            severity: "error"
          });
          setSubPillarData({ ...subPillar, url: subPillar.url });
        }
      } else {
        setShowAlertBanner(true, {
          message: "Error saving url, please try again later",
          severity: "error"
        });
        setSubPillarData({ ...subPillar, url: subPillar.url });
      }
    } catch (error) {
      console.error(error);
      setShowAlertBanner(true, {
        message: "Error saving url, please try again later",
        severity: "error"
      });
      setSubPillarData({ ...subPillar, url: subPillar.url });
    }
    setSavingUrl(false);
  };

  return (
    <>
      {(!"blog".includes(searchedTopic) ||
        subPillar.topic.toLocaleLowerCase().includes(searchedTopic)) && (
        <tr
          style={{ height: "58px" }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          className="align-middle"
          data-testid="sub-pillar-row"
        >
          {edit ? (
            <EditRow
              setEdit={setEdit}
              originalSubPillar={subPillarData}
              originalType="subPillar"
              parentType="pillar"
              handleCancel={handleCancel}
              handleChange={handleChange}
              parentPillar={parentPillar}
              url={subPillarData.url ? subPillarData.url : ""}
            />
          ) : (
            <>
              <th
                onClick={() => {
                  setIsExpanded(!isExpanded);
                }}
                style={{
                  paddingLeft: "18px"
                }}
                className="fw-400"
              >
                <Form.Check
                  style={{
                    position: "relative",
                    left: "0px",
                    float: "left"
                  }}
                  className="rowCheckbox"
                  id="flexCheckDefault"
                  type={"checkbox"}
                  checked={checked}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={handleRowSelect}
                />

                <div style={{ display: "flex" }}>
                  {subPillar.blogs && subPillar.blogs.length > 0 && (
                    <div>
                      <span
                        data-testid="arrow-icon"
                        style={{
                          paddingLeft: "18px"
                        }}
                      >
                        {isExpanded ? (
                          <NavigationArrowDown
                            style={{
                              width: "24px",
                              color: "black",
                              transition: "all 300ms linear 0s"
                            }}
                          />
                        ) : (
                          <NavigationArrowDown
                            style={{
                              width: "24px",
                              color: "black",
                              transform: "rotate(-90deg)",
                              transition: "all 300ms linear 0s"
                            }}
                          />
                        )}
                      </span>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      paddingLeft:
                        subPillar.blogs && subPillar.blogs.length > 0
                          ? "14px"
                          : "57px"
                    }}
                  >
                    {subPillar.created_at &&
                      Math.abs(
                        dayjs().utc().diff(dayjs(subPillar.created_at), "day")
                      ) < 1 && (
                        <div
                          style={{
                            width: "20px",
                            float: "left",
                            marginLeft: "-15px"
                          }}
                        >
                          <NewItemAlert />
                        </div>
                      )}
                    <div style={{ float: "left" }}>{subPillar?.topic} </div>
                  </div>
                </div>
              </th>
              <th
                onClick={() => {
                  setIsExpanded(!isExpanded);
                }}
                className="fw-400"
              >
                Sub-Pillar
              </th>
              <th className="fw-400">
                <StatusBadge
                  submittingBrief={submittingBrief}
                  hovered={hovered}
                  callbackFunction={addBriefToStrategy}
                  briefData={subPillarData.outline_status}
                />
              </th>
              <UrlCell
                saveUrl={handleSaveUrl}
                savingUrl={savingUrl}
                isEditableRow={
                  !subPillarData.blogs || subPillarData.blogs.length === 0
                }
                handleEdit={
                  subPillar.blogs?.length ? () => null : () => setEdit(true)
                }
                handleAdd={() => setAddRow(true)}
                writeAccess={writeAccess}
                type="subPillar"
                id={subPillar.id || 0}
                hovered={hovered}
                handleCancel={handleCancel}
                handleChange={handleChange}
                url={subPillarData.url ? subPillarData.url : ""}
              />
            </>
          )}
        </tr>
      )}
      {addRow && (
        <AddRow
          parentType="subPillar"
          setAddRow={setAddRow}
          pillar={parentPillar}
          subPillar={subPillarData}
          setSubPillarData={setSubPillarData}
        />
      )}

      {subPillar?.blogs
        ?.filter((blog) => {
          if ("blog".includes(searchedTopic)) {
            return true;
          }
          return (
            blog?.topic?.toLowerCase().includes(searchedTopic) ||
            blog?.url?.toLowerCase().includes(searchedTopic)
          );
        })
        .map((blog, index) => (
          <BlogRowSecondTier
            parentSubPillar={subPillarData}
            parentPillar={parentPillar}
            writeAccess={writeAccess}
            expanded={isExpanded}
            blog={blog}
            key={index}
            selected={selected}
            selectAll={selectAll}
          />
        ))}
    </>
  );
}

export default SubPillarRow;
