import { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import Confetti from "react-confetti";
import { useNavigate } from "react-router-dom";

import {
  IBriefSearchTerm,
  IOrderedSections
} from "@/interfaces/outline.interface";
import useContentBriefsStore from "@/hooks/useContentBriefsStore";
import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";
import Header from "./components/Header";
import TitlePhoto from "./components/TitlePhoto";
import Instructions from "./components/Instructions";
import Notes from "./components/Notes";
import MetaInformation from "./components/MetaInformation";
import Outline from "./components/Outline/Outline";
import BreadcrumbsHeader from "../components/BreadcrumbsHeader";
import { getOrderedSearchTerms, getSavedSearchTerms } from "@/api/get";
import { useAppStore, useEffectAsync } from "@/hooks";
import { IAppStore } from "@/interfaces/app-store.interface";

const StepThree = () => {
  const { selectedPidObject } = useAppStore((store: IAppStore) => ({
    selectedPidObject: store.selectedPidObject
  }));
  const {
    setPillar,
    setBriefId,
    setTerm,
    setTypeOfTerm,
    setIsStepThreeActive,
    metaDescription,
    setMetaDescription,
    typeOfTerm,
    setUnusedItems,
    isStepThreeComplete
  }: IContentBriefsStore = useContentBriefsStore(
    (store: IContentBriefsStore) => ({
      isStepThreeActive: store.isStepThreeActive,
      isStepThreeComplete: store.isStepThreeComplete,
      metaDescription: store.metaDescription,
      typeOfTerm: store.typeOfTerm,
      setIsStepThreeActive: (value: boolean) =>
        store.setIsStepThreeActive(value),
      setTypeOfTerm: (value: string) => store.setTypeOfTerm(value),
      setTerm: (value: string) => store.setTerm(value),
      setBriefId: (value: number) => store.setBriefId(value),
      setTargetedQuestions: (value: IBriefSearchTerm[]) =>
        store.setTargetedQuestions(value),
      setPillar: (value: string) => store.setPillar(value),
      setMetaDescription: (value: string) => store.setMetaDescription(value),
      setUnusedItems: (value: string[]) => store.setUnusedItems(value)
    })
  );
  const navigate = useNavigate();

  // step 3 needs to pull in all of the data on its own if the user comes from a share link
  // parse the url
  const urlParams = new URLSearchParams(window.location.search);
  const phrase = urlParams.get("phrase");
  const type = urlParams.get("type");
  const originationFromShareLink = window.location.pathname
    .split("/")
    .includes("public");
  const pid = urlParams.get("pid");
  // get the briefId from the last part of the url
  const briefId = window.location.pathname.split("/").pop();
  const [orderedSections, setOrderedSections] = useState<IOrderedSections>();
  const [activeConfetti, setActiveConfetti] = useState(false);
  useEffectAsync(async () => {
    if (briefId && pid && selectedPidObject.id) {
      const brief = await getSavedSearchTerms(
        selectedPidObject.id,
        Number(briefId)
      );
      if (brief.pid !== Number(pid)) {
        navigate("/pillar-strategy");
        return;
      }

      setBriefId(parseInt(briefId));
      if (metaDescription === "") {
        setMetaDescription("N/A");
      }

      // fetch the ordered sections for this brief and pid
      const orderedSearchTermsPayload = await getOrderedSearchTerms(
        parseInt(pid),
        parseInt(briefId)
      );
      if (orderedSearchTermsPayload?.ordered_sections) {
        const osp = orderedSearchTermsPayload;
        setOrderedSections(osp.ordered_sections);

        // get unused keywords
        const targeted_terms = [] as IBriefSearchTerm[];
        targeted_terms.push(
          ...osp.targeted_keywords,
          ...osp.targeted_questions
        );
        const unusedKeywords = [] as string[];
        targeted_terms.map((searchTerm) => {
          let is_used = false;
          if (searchTerm.targeted) {
            osp.ordered_sections.body.map((ostf) => {
              if (ostf.search_term_id === searchTerm.id) {
                is_used = true;
              }
            });
            osp.ordered_sections.conclusion.map((ostf) => {
              if (ostf.search_term_id === searchTerm.id) {
                is_used = true;
              }
            });
            osp.ordered_sections.introduction.map((ostf) => {
              if (ostf.search_term_id === searchTerm.id) {
                is_used = true;
              }
            });
          } else {
            is_used = true;
          }

          if (!is_used) {
            unusedKeywords.push(searchTerm.query);
          }
        });
        setUnusedItems(unusedKeywords);
      }
    }
  }, [typeOfTerm, selectedPidObject]);

  const [wordCount, setWordCount] = useState("0");
  useEffect(() => {
    if (typeOfTerm === "Pillar") {
      setWordCount("3,000");
    } else if (typeOfTerm === "Sub Pillar") {
      setWordCount("2,000");
    } else if (typeOfTerm === "Blog") {
      setWordCount("750");
    } else {
      setWordCount("750");
    }
  }, [typeOfTerm]);

  if (originationFromShareLink) {
    // if the user is coming from a share link, we need to set the store to the values in the url
    setIsStepThreeActive(true);
    if (briefId) {
      setBriefId(parseInt(briefId));
    }
    if (phrase) {
      setTerm(phrase);
    }
    if (type) {
      setTypeOfTerm(type);
    }
    // if they are coming from a share link, the pillar will be in the url
    const pillarFromURL = urlParams.get("pillar");
    if (pillarFromURL) {
      setPillar(pillarFromURL);
    }
  }

  useEffect(() => {
    setActiveConfetti(isStepThreeComplete);
    if (isStepThreeComplete) {
      setTimeout(() => {
        setActiveConfetti(false);
      }, 5000);
    }
  }, [isStepThreeComplete]);

  // eslint-disable-next-line no-empty-function
  const handleSubmit = () => {};

  return originationFromShareLink ? (
    <div style={{ width: "100%" }}>
      <>
        <div
          style={{
            width: "100%",
            maxWidth: "1200px",
            paddingLeft: "5%",
            paddingRight: "5%",
            margin: "auto"
          }}
        >
          {/* TitlePhoto uses term, typeOfTerm, from the store and brief.created_at from ???  */}
          <TitlePhoto></TitlePhoto>
          {/* Instructions needs the pillar from the store */}
          <Instructions
            wordCount={wordCount}
            typeOfTerm={typeOfTerm}
          ></Instructions>
          {/* MetaInformation needs the term , typeOfTerm, title tag, meta description */}
          <MetaInformation></MetaInformation>
          <Notes></Notes>
          {/* Outline needs the orderedSections, pillar,  from the store */}
          {!isEmpty(orderedSections) && (
            <Outline orderedSections={orderedSections}></Outline>
          )}
        </div>
      </>
    </div>
  ) : (
    <>
      {
        <div style={{ width: "100%" }}>
          <>
            <BreadcrumbsHeader handleSubmit={handleSubmit} />
            {activeConfetti && (
              <Confetti
                numberOfPieces={1300}
                wind={0}
                gravity={0.34}
                initialVelocityX={6.6}
                initialVelocityY={16.4}
                opacity={0.8}
                recycle={false}
              ></Confetti>
            )}

            <div
              style={{
                paddingTop: "86px"
              }}
            >
              {!isEmpty(orderedSections) && pid && (
                <Header
                  orderedSections={orderedSections}
                  pid={pid}
                  wordCount={wordCount}
                ></Header>
              )}
              <div
                style={{
                  width: "100%",
                  maxWidth: "1200px",
                  paddingLeft: "5%",
                  paddingRight: "5%",
                  margin: "auto"
                }}
                id="step-three-container"
              >
                {/* TitlePhoto uses term, typeOfTerm, from the store and brief.created_at from ???  */}
                <TitlePhoto></TitlePhoto>
                {/* Instructions needs the pillar from the store */}
                <Instructions
                  wordCount={wordCount}
                  typeOfTerm={typeOfTerm}
                ></Instructions>
                <MetaInformation></MetaInformation>
                <Notes></Notes>

                {!isEmpty(orderedSections) && (
                  <Outline orderedSections={orderedSections}></Outline>
                )}
              </div>
            </div>
          </>
        </div>
      }
    </>
  );
};

export default StepThree;
