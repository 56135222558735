import ContactUsLink from "@/components/shared/ContactUsLink";

interface ShoppingAdsProps {
  background: string;
}
function ShoppingAds({ background }: ShoppingAdsProps) {
  return (
    <div
      className={`marketing-card-container ${
        background === "gradient" ? "gradient" : null
      }`}
    >
      <div className="section-header">Shopping Ads</div>
      <div className="image-text-container">
        <div className="image-stack-container">
          <div className="image-stack">
            <div className="image image-bottom">
              <img
                src={require("./../../../assets/rd_paid_channels_top 20_brands_by_keyword_count@4x.png")}
                alt="Top Brands Keyword Count"
                style={{ width: "374px", height: "255px" }}
              />
            </div>
            <div className="image image-top">
              <img
                src={require("./../../../assets/rd_paid_channels_top 20_brands_by_product_count@4x.png")}
                alt="Top Brands Product Count"
                style={{ width: "374px", height: "255px" }}
              />
            </div>
          </div>
        </div>
        <div className="text-button">
          <div className="text">
            <div className="text-header">
              Shopping Ads represent an extremely competitive market, one that
              remains shrouded in mystery based on most of the tools currently
              available.
            </div>
            <br />

            <p>
              DemandJump clears up the smoke with our Shopping Ads dashboard,
              giving you a clear look at which brands are showing up the most
              for Shopping Ads, and which brands are marketing the most
              products.
            </p>
            <p>
              Easily track which keywords trigger your competitors’ product ads
              to better adjust your product feed to improve market share. Also,
              uncover which positions your competitors are ranking for
              organically with product content so you can see how you stack up
              in specific auctions.
            </p>
          </div>
          <ContactUsLink />
        </div>
      </div>
    </div>
  );
}

export default ShoppingAds;
