import useContentBriefsStore from "@/hooks/useContentBriefsStore";
import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";
import IntroductionLinks from "@/components/manage_content/content_brief/content_brief_workflow/step_three/components/Outline/IntroductionLinks";
import { IOrderedSections } from "@/interfaces/outline.interface";
import isEmpty from "lodash/isEmpty";
import "./outline.scss";
import { stepThreeStyles } from "../utils/style";

const Introduction = ({
  orderedSections
}: {
  orderedSections: IOrderedSections;
}) => {
  const { term, typeOfTerm }: IContentBriefsStore = useContentBriefsStore(
    (store: IContentBriefsStore) => ({
      term: store.term,
      typeOfTerm: store.typeOfTerm
    })
  );

  return (
    <div
      className="row row-padding-top-20"
      style={stepThreeStyles.rowPaddingTop20}
    >
      <div className="col-12">
        <div className="row">
          <div className="outline-title" style={stepThreeStyles.outlineTitle}>
            Introduction Section
          </div>
        </div>
        <IntroductionLinks />
        {!isEmpty(orderedSections.introduction) && (
          <>
            <div
              className="row row-padding-top-20"
              style={stepThreeStyles.rowPaddingTop20}
            >
              <div
                className="outline-subtitle"
                style={stepThreeStyles.outlineSubtitle}
              >
                Terms to include:
              </div>
            </div>
            <div
              className="row row-padding-top-20"
              style={(stepThreeStyles.rowPaddingTop20, { marginLeft: "20px" })}
            >
              {" "}
              <ul>
                {orderedSections.introduction.map((introTerm) => (
                  <li
                    className="outline-text"
                    style={stepThreeStyles.outlineText}
                  >
                    {introTerm?.search_term?.query && (
                      <span data-testid="introduction-term">
                        {introTerm.search_term.query}
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
        {typeOfTerm === "Ad Hoc" && (
          <>
            <div
              className="row row-padding-top-20"
              style={stepThreeStyles.rowPaddingTop20}
            >
              <div
                className="outline-subtitle"
                style={stepThreeStyles.outlineSubtitle}
              >
                Internal Links to Include:
              </div>
            </div>
            <div
              className="row row-padding-top-20"
              style={(stepThreeStyles.rowPaddingTop20, { marginLeft: "20px" })}
            >
              <ul>
                <li
                  className="outline-text"
                  style={stepThreeStyles.outlineText}
                >
                  Within the intro, include the term “
                  {term && <span data-testid="introduction-term">{term}</span>}”
                  in bold and underline style. This will serve as anchor text
                  for an internal hyperlink to the finished{" "}
                  {typeOfTerm && (
                    <span data-testid="introduction-typeOfTerm">
                      {typeOfTerm}
                    </span>
                  )}{" "}
                  Page in this Pillar Strategy.
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Introduction;
