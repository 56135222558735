import ContactUsLink from "@/components/shared/ContactUsLink";

function Products() {
  return (
    <div className="marketing-card-container">
      <div className="section-header">Products</div>
      <div className="sub-header">
        For eCommerce companies, it can be tough to identify the entire scope of
        products, prices, and reviews that competitors are putting into their
        product feed.
      </div>
      <div className="image-text-container">
        <div className="text-button jfs">
          <div className="text">
            <p>
              DemandJump’s Products dashboard allows you to easily filter on any
              competitor’s domain and see all of the products that are ranking
              for relevant keywords, which keywords they’re ranking for, the
              average price of products, and more.
            </p>
          </div>
          <ContactUsLink />
        </div>
        <div className="image">
          <img
            src={require("../../../assets/rd_organic_channels_product_price_details_for_shopping_ads@4x.png")}
            alt="Price Details Shopping Ads"
            style={{
              width: "634.2px",
              height: "213px",
              margin: "-95px 0 0 37px"
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Products;
