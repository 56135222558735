import ContactUsLink from "@/components/shared/ContactUsLink";

function ContentRecommendations() {
  return (
    <div>
      <div className="marketing-card-container">
        <div className="section-header">Content Recommendations</div>
        <div className="sub-header">
          The best way to win Page One rankings for important terms within your
          mission-critical Pillar Topics is to recreate the real-world network
          of search behavior with your own content strategy.
        </div>
        <div className="image-text-container">
          <div className="text-button">
            <div className="text">
              <p>
                Our Content Recommendations dashboard automates the work of
                identifying the Pillar Pages, Sub-Pillar Pages, and Supporting
                Blog posts you’ll need to produce in order to drive fast
                results.
              </p>

              <br />
              <p>
                Get a quick look at the most important articles to produce, then
                download your data to finalize your plan and share with your
                creative team and hit the ground running in minutes—not weeks.
              </p>
            </div>
            <ContactUsLink />
          </div>
          <div className="image">
            <img
              src={require("../../../assets/manage_pillar_topics_pillar_strategy_product_market_design@4x.png")}
              alt="ContentRecommendations"
              style={{
                width: "587px",
                height: "311px",
                margin: "36px 0 0 39px"
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentRecommendations;
