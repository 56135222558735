/* eslint-disable max-params */
import { INotes } from "@/interfaces/notes.interface";
import {
  IOrderedSections,
  IOrderedSearchtermFormatted
} from "@/interfaces/outline.interface";
import { ISavedBriefCTA } from "@/interfaces/saved_outline.interface";

export const outlineAsText = (
  orderedSections: IOrderedSections,
  term: string,
  typeOfTerm: string,
  pillarTopic: string,
  subPillar: string,
  notes: INotes[],
  CTAs: ISavedBriefCTA[],
  Unused: string[]
) => {
  const tempArray = [] as string[];
  if (notes.length > 0) {
    tempArray.push("Notes:");
    notes.forEach((el) => {
      tempArray.push(`-${el.note}`);
    });
  }
  tempArray.push("\n");

  tempArray.push("Introduction:");
  if (typeOfTerm !== "Pillar") {
    tempArray.push("Internal Links to Include:");
    if (pillarTopic !== "") {
      tempArray.push(
        `Within the intro, include the term “${pillarTopic}" in bold and underline style.`
      );
      tempArray.push(
        `This will serve as anchor text for an internal hyperlink to the finished Pillar Page in this Pillar Strategy.`
      );
      tempArray.push("\n");
    }
    if (subPillar !== "" && typeOfTerm !== "Sub Pillar") {
      tempArray.push(
        `Within the intro, include the term “${subPillar}" in bold and underline style.`
      );
      tempArray.push(
        `This will serve as anchor text for an internal hyperlink to the finished Sub-Pillar Page in this Pillar Strategy.`
      );
    }
  }

  if (orderedSections.introduction.length > 0) {
    tempArray.push("Search Terms to Include:  ");
    // list over the conclusion search terms
    orderedSections.introduction.forEach((el) => {
      tempArray.push(`-- ${el.search_term.query}`);
    });
  }
  tempArray.push("\n");

  // extract the H2s and their associated search terms from the bodySectionDroppableAreas

  const list = [] as IOrderedSearchtermFormatted[][];
  const listNew = [] as IOrderedSearchtermFormatted[];
  orderedSections.body.forEach((outterLoopEl) => {
    const Templist = {
      search_term_id: outterLoopEl.search_term_id,
      search_term: outterLoopEl.search_term,
      body_order: outterLoopEl.body_order,
      order_within_section: outterLoopEl.order_within_section,
      subList: [] as IOrderedSearchtermFormatted[]
    };
    if (outterLoopEl.order_within_section === 0) {
      const subList = [] as IOrderedSearchtermFormatted[];
      orderedSections.body.forEach((innerLoopEl) => {
        if (outterLoopEl.body_order === innerLoopEl.body_order) {
          subList.push(innerLoopEl);
          Templist.subList.push(innerLoopEl);
        }
      });
      list.push(subList);
      listNew.push(Templist);
    }
  });

  // loop over the array object of search terms and build your H2 and unordered list of search terms under the H2s
  listNew.forEach((subList) => {
    tempArray.push(`H2: ${subList.search_term.query}`);
    if (subList.subList) {
      subList.subList.forEach((subListItem) => {
        if (subListItem.order_within_section !== 0) {
          tempArray.push(`-- ${subListItem.search_term.query}`);
        }
      });
      tempArray.push("\n");
    }
  });

  tempArray.push("Conclusion:");
  tempArray.push(
    "Internal Links to Include:  At the end of your article, include at least one relevant call to action."
  );
  if (CTAs.length > 0) {
    tempArray.push(
      "Call to action: At the end of your article, include at least one relevant call to action."
    );
    tempArray.push("Links to include in CTA: ");
    // list over the CTAs
    CTAs.forEach((el) => {
      tempArray.push(`-${el.display_text} ${el.href}`);
    });
  }
  tempArray.push("\n");

  if (orderedSections.conclusion.length > 0) {
    tempArray.push("Search Terms to Include:  ");
    // list over the conclusion search terms
    orderedSections.conclusion.forEach((el) => {
      tempArray.push(`-- ${el.search_term.query}`);
    });
  }

  if (Unused.length > 0) {
    tempArray.push("\n");
    tempArray.push("Unassigned Keywords");
    tempArray.push(
      "The following keywords have not been assigned to a specific section of this outline, but should be used at your discretion somewhere within the article."
    );
    tempArray.push("Search Terms to Include:");
    Unused.forEach((el) => {
      tempArray.push(el);
    });
  }

  return tempArray;
};
