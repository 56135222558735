import React from "react";
import BriefTableSwitch from "./components/BriefTableSwitch";

function ContentBriefsList() {
  return (
    <div className="mtlr-30-r">
      <BriefTableSwitch></BriefTableSwitch>
    </div>
  );
}

export default ContentBriefsList;
