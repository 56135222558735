import { Row, Col } from "react-bootstrap";

import { IBriefSearchTerm } from "@/interfaces/outline.interface";
import "./tooltip.scss";

import QuestionClipIcon from "@/assets/icons/clipboard-question.svg";
import RankingIcon from "@/assets/icons/ranking-star.svg";
import CompetitorIcon from "@/assets/icons/competitors.svg";
import BullseyeIcon from "@/assets/icons/bullseye.svg";

const StepOneToolTip = (props: { term: IBriefSearchTerm }) => (
  <div>
    <Row>
      <Col xs={12}>"{props.term.query}"</Col>
    </Row>
    <Row>
      <Col xs={12} style={{ paddingTop: "3px" }}>
        <img
          src={QuestionClipIcon}
          alt="question clipboard icons"
          style={{ paddingRight: "5px" }}
        ></img>
        {props.term.term_type?.charAt(0).toUpperCase()}
        {props.term.term_type?.slice(1)}
      </Col>
    </Row>
    <Row style={{ padding: "5px", marginTop: "10px" }}>
      <Col xs={2}>
        <img
          src={RankingIcon}
          alt="your ranking icon"
          style={{ paddingLeft: "4px" }}
        ></img>
      </Col>
      <Col xs={8} style={{ textAlign: "left" }}>
        Your Rank
      </Col>
      <Col xs={2} style={{ textAlign: "right" }}>
        {props.term.your_rank || "-"}
      </Col>
    </Row>
    <Row style={{ padding: "5px", alignItems: "center" }}>
      <Col xs={2}>
        <img
          src={CompetitorIcon}
          alt="competitor ranking icon"
          style={{ marginTop: "8px" }}
        ></img>
      </Col>
      <Col xs={8} style={{ textAlign: "left" }}>
        Best Competitor Rank
      </Col>
      <Col xs={2} style={{ textAlign: "right" }}>
        {props.term.competitor_rank || "-"}
      </Col>
    </Row>
    <Row style={{ padding: "5px" }}>
      <Col xs={2}>
        <img
          src={BullseyeIcon}
          alt="targeted icon"
          style={{ paddingLeft: "6px" }}
        ></img>
      </Col>
      <Col xs={8} style={{ textAlign: "left" }}>
        Targeted in Other Briefs
      </Col>
      <Col xs={2} style={{ textAlign: "right" }}>
        {props.term.total_targeted || 0}
      </Col>
    </Row>
  </div>
);

export default StepOneToolTip;
