import { Paper } from "@mui/material";
import { useState } from "react";
import AlertMessage from "../../insights/components/AlertMessage";
import { AlertColor } from "@mui/material/Alert";
import { getDjUniUrl } from "../../../api/get";

export default function DjUniCard() {
  const [alertOpen, setAlertOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState<AlertColor>("success");

  const DjUniSso = async () => {
    await getDjUniUrl()
      .then((res) => {
        window.open(res.url, "_blank", "noopener,noreferrer");
      })
      .catch((error) => {
        console.error(error);
        setMessage("Oops- can't open DJ University, please try again later.");
        setAlertType("error");
        setAlertOpen(true);
      });
  };
  return (
    <div id="dj-uni-card-container">
      <Paper id="paper-gray-border" elevation={0}>
        <div className="flex-container">
          <div className="card-header">DemandJump University</div>
          <div className="body-text">
            Get started with the DemandJump platform to implement Pillar- Based
            Marketing best practices and drive remarkable marketing outcomes.{" "}
            <a onClick={() => DjUniSso()} className="card-link">
              Learn more{" "}
            </a>
          </div>
        </div>
      </Paper>
      <AlertMessage
        alertOpen={alertOpen}
        message={message}
        alertType={alertType}
        setOpen={setAlertOpen}
      />
    </div>
  );
}
