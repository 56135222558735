import React, { useEffect, useState } from "react";
import { IContentPillars } from "@/interfaces/content-pillars.interface";
import StatusBadge from "./StatusBadge";
import UrlCell from "./UrlCell";
import { IAppStore } from "@/interfaces/app-store.interface";
import { useAppStore, usePillarStrategyStore } from "@/hooks";
import { postBriefToStrategy } from "@/api/post";
import AddRow from "./AddRow";
import { IPillarStrategyStore } from "@/interfaces/pillar-strategy-store.interface";
import { putContentUrl } from "@/api/put";
import dayjs from "dayjs";
import NewItemAlert from "./NewItemAlert";

interface PRowProps {
  pillar: IContentPillars;
  writeAccess: boolean;
}
type HandleChange = (event: React.ChangeEvent<HTMLInputElement>) => void;

function PillarRow({ pillar, writeAccess }: PRowProps) {
  const [hovered, setHovered] = useState(false);
  const [submittingBrief, setSubmittingBrief] = useState<boolean>(false);
  const [savingUrl, setSavingUrl] = useState<boolean>(false);
  const [pillarData, setPillarData] = useState<IContentPillars>({
    pid: 0,
    keyword: "",
    location: "",
    updated_at: "",
    url: "",
    outline_status: null
  });
  const [addRow, setAddRow] = useState<boolean>(false);

  const { setShowAlertBanner, selectedPidObject } = useAppStore(
    (store: IAppStore) => ({
      setShowAlertBanner: store.setShowAlertBanner,
      selectedPidObject: store.selectedPidObject
    })
  );

  const { selectedPidPillarTopics, setSelectedPidPillarTopics } =
    usePillarStrategyStore((store: IPillarStrategyStore) => ({
      selectedPidPillarTopics: store.selectedPidPillarTopics,
      setSelectedPidPillarTopics: (value: IContentPillars[]) =>
        store.setSelectedPidPillarTopics(value)
    }));

  useEffect(() => {
    setPillarData({ ...pillar, id: pillar.id });
    return () => {
      setPillarData({
        pid: 0,
        keyword: "",
        location: "",
        updated_at: "",
        url: "",
        outline_status: null
      });
    };
  }, [pillar]);

  const handleChange: HandleChange = (e) => {
    const { name, value } = e.target;
    setPillarData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCancel = () => {
    setPillarData({ ...pillar });
  };

  const addBriefToStrategy = async () => {
    setSubmittingBrief(true);
    try {
      if (pillarData.id) {
        const body = {
          id: pillarData.id,
          type: "pillar",
          topic: pillarData.keyword,
          pid: selectedPidObject.id
        };
        const outlineStatus = await postBriefToStrategy(body);
        if (outlineStatus) {
          setSelectedPidPillarTopics(
            selectedPidPillarTopics.map((p: IContentPillars) => {
              if (p.id === pillarData.id) {
                return { ...p, outline_status: outlineStatus };
              }
              return p;
            })
          );
        }
        setShowAlertBanner(true, {
          message: "Brief added to strategy.",
          severity: "success"
        });
      } else {
        setPillarData((prevState) => ({ ...prevState }));
        setShowAlertBanner(true, {
          message: "Error creating brief, please try again later",
          severity: "error"
        });
      }
    } catch (error) {
      console.error(error);
      setShowAlertBanner(true, {
        message: "Error creating brief, please try again later",
        severity: "error"
      });
    }
    setSubmittingBrief(false);
  };

  const handleSaveUrl = async () => {
    setSavingUrl(true);
    try {
      if (pillarData.id && pillarData.url) {
        const body = {
          id: pillarData.id,
          url: pillarData.url,
          type: "pillar"
        };
        const response = await putContentUrl(body, selectedPidObject.id);
        if (response) {
          setSelectedPidPillarTopics(
            selectedPidPillarTopics.map((p: IContentPillars) => {
              if (p.id === pillarData.id) {
                return { ...p, url: pillarData.url };
              }
              return p;
            })
          );
          setShowAlertBanner(true, {
            message: "URL saved.",
            severity: "success"
          });
        } else {
          setPillarData((prevState) => ({
            ...prevState,
            url: pillarData.url
          }));
          setShowAlertBanner(true, {
            message: "Error saving URL, please try again later",
            severity: "error"
          });
        }
      } else {
        setPillarData((prevState) => ({
          ...prevState,
          url: pillarData.url
        }));
        setShowAlertBanner(true, {
          message: "Error saving URL, please try again later",
          severity: "error"
        });
      }
    } catch (error) {
      console.error(error);
      setPillarData((prevState) => ({
        ...prevState,
        url: pillarData.url
      }));
      setShowAlertBanner(true, {
        message: "Error saving URL, please try again later",
        severity: "error"
      });
    }
    setSavingUrl(false);
  };

  return (
    <>
      <tr
        style={{ height: "58px" }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className="align-middle"
        data-testid="pillar-row"
      >
        <th style={{ paddingLeft: "22px" }} className="dj-blue-important">
          {" "}
          <div
            style={{
              display: "flex",
              textAlign: "left",
              paddingLeft: "45px"
            }}
          >
            {pillarData.created_at &&
              Math.abs(
                dayjs().utc().diff(dayjs(pillarData.created_at), "day")
              ) < 1 && (
                <div
                  style={{
                    width: "20px",
                    float: "left",
                    marginLeft: "-20px"
                  }}
                >
                  <NewItemAlert />
                </div>
              )}
            <div style={{ float: "left" }}>{pillarData?.keyword} </div>
          </div>
        </th>
        <th className="dj-blue-important">Pillar</th>
        <th>
          <StatusBadge
            submittingBrief={submittingBrief}
            hovered={hovered}
            callbackFunction={addBriefToStrategy}
            briefData={pillarData.outline_status}
          />
        </th>
        <UrlCell
          saveUrl={handleSaveUrl}
          savingUrl={savingUrl}
          isEditableRow={false}
          handleEdit={() => null}
          handleAdd={() => setAddRow(true)}
          writeAccess={writeAccess}
          type="pillar"
          id={pillarData.id}
          hovered={hovered}
          handleCancel={handleCancel}
          handleChange={handleChange}
          url={pillarData.url ? pillarData.url : ""}
        />
      </tr>
      {addRow && (
        <AddRow parentType="pillar" setAddRow={setAddRow} pillar={pillar} />
      )}
    </>
  );
}

export default PillarRow;
