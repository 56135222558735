import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function InfoMessage(props: { query: string; length: number }) {
  return (
    <>
      {props.query?.length > props.length && (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip placement="bottom" className="in">
              {props.query?.toLowerCase()}
            </Tooltip>
          }
        >
          <span>{props.query?.substring(0, props.length)}...</span>
        </OverlayTrigger>
      )}
      {props.query?.length < props.length + 1 && props.query?.toLowerCase()}
    </>
  );
}
