/* eslint-disable max-depth */
/* eslint-disable max-lines */
import {
  useState,
  useEffect,
  useRef,
  MutableRefObject,
  FormEvent
} from "react";
import Spinner from "react-bootstrap/Spinner";

import "../../../App.css";
import "./keywords.scss";
import "../../../components/iframe.scss";

import { useAppStore } from "../../../hooks";
import { getEntitlements, getKeywordLists } from "../../../api/get";
import { postKeywordList } from "../../../api/post";

import {
  blocklist,
  countTotalKeywords,
  hasDuplicateTitle,
  textToKeywords,
  sortLists,
  findNonASCII,
  countTotalKeywordLists
} from "./settings-utils";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LocationSelect from "../../shared/LocationSelect";
import DJLogo from "../../../assets/DJ-Horizontal-registered.png";
import DJLoadingGif from "../../shared/DJLoadingGif";
import {
  IChildData,
  IKeywordIdList,
  IKeywordList,
  IListType
} from "../../../interfaces/keywords.interface";
import { IEntitlements } from "../../../interfaces/entitlements.interface";
import { IAppStore } from "../../../interfaces/app-store.interface";
import SelectDropDown from "../../shared/SelectDropDown";

const createNewList = {
  name: "Create a new list",
  list_type: { name: "generic" },
  location: { loc_id: "United States", loc_name: "United States" },
  keywords: [],
  keyword_id_list_ids: [] as IKeywordIdList[]
};

function Keywords(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [keywordLists, setKeywordLists] = useState<IKeywordList[]>([
    createNewList
  ]);
  const [keywordsLimit, setKeywordsLimit] = useState(500);
  const [keywordListsLimit, setKeywordListsLimit] = useState<number | string>(
    "" || 0
  );
  const [location, setLocation] = useState({
    value: "United States",
    label: "United States"
  });
  const [selectedKeywordList, setSelectedKeywordList] =
    useState<IKeywordList>(createNewList);
  const [cancelState, setCancelState] = useState<IKeywordList>(createNewList);
  const inputFile = useRef(
    null
  ) as unknown as MutableRefObject<HTMLInputElement>;

  const { selectedPidObject, setShowAlertBanner } = useAppStore(
    (store: IAppStore) => ({
      selectedPidObject: store.selectedPidObject,
      setShowAlertBanner: store.setShowAlertBanner
    })
  );

  useEffect(() => {
    const fetchData = async () => {
      let entitlements: IEntitlements;
      let data: IKeywordList[];
      if (selectedPidObject.id) {
        setKeywordLists([createNewList]);
        setSelectedKeywordList(createNewList);
        data = await getKeywordLists(selectedPidObject.id);
        entitlements = await getEntitlements(selectedPidObject.id);
        if (entitlements) {
          const keywordsData = data;
          const hasBlocklist = keywordsData.some(
            (keywordList: { list_type: IListType }) =>
              keywordList.list_type.name === "blocklist"
          );
          let scopedListsLimit: number | string = 0 || "";

          if (entitlements?.limits?.keywords) {
            const kwLimit = entitlements?.limits?.keywords;
            setKeywordsLimit(kwLimit);
          }

          if (entitlements?.limits?.keyword_lists) {
            scopedListsLimit = entitlements?.limits.keyword_lists;
            if (hasBlocklist && typeof scopedListsLimit !== "string") {
              scopedListsLimit += 1;
              setKeywordListsLimit(scopedListsLimit);
            } else {
              setKeywordListsLimit(scopedListsLimit);
            }
            // Blocklist doesnt count towards list limits
          }

          if (hasBlocklist) {
            setKeywordLists([createNewList, ...keywordsData]);
          } else if (!hasBlocklist) {
            setKeywordLists([createNewList, ...keywordsData, blocklist]);
          }

          if (typeof scopedListsLimit !== "string") {
            setKeywordListsLimit(scopedListsLimit + 1);
          }

          if (keywordsData[0]) {
            setSelectedKeywordList(keywordsData[0]);
            if (keywordsData[0]?.location?.loc_name) {
              setLocation({
                label: keywordsData[0].location.loc_name,
                value: keywordsData[0].location.loc_name
              });
            } else {
              setLocation({
                label: "United States",
                value: "United States"
              });
            }
            setCancelState(keywordsData[0]);
          }
        }
        setInitialLoading(false);
      }
    };
    fetchData();
  }, [selectedPidObject]);

  const handleChange = (event: { target: { name: string; value: string } }) => {
    const { name, value } = event.target;
    if (selectedKeywordList) {
      setSelectedKeywordList((prevState) => ({
        ...prevState,
        [name]: value
      }));
    } else {
      setSelectedKeywordList({
        name: value,
        list_type: { name: "generic" },
        location: {
          loc_id: "United States",
          loc_name: "United States"
        },
        keywords: [],
        keyword_id_list_ids: [],
        label: value,
        value
      });
    }
  };

  const handleSelect = (selectedOption: IKeywordList) => {
    let newListItem = selectedOption;
    if (newListItem?.name === "Create a new list") {
      newListItem = {
        name: "",
        list_type: { name: "generic" },
        location: {
          loc_id: "United States",
          loc_name: "United States"
        },
        keywords: [],
        keyword_id_list_ids: [] as IKeywordIdList[]
      };
    }
    if (newListItem.location.loc_name && newListItem.location.loc_name) {
      setLocation({
        label: newListItem.location.loc_name,
        value: newListItem.location.loc_name
      });
      setCancelState(newListItem);
      setSelectedKeywordList(newListItem);
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files?.length) {
      const filename = files[0].name;

      const parts = filename.split(".");
      const fileType = parts[parts.length - 1];
      if (fileType === "csv") {
        const thisFile = files[0];
        const reader = new FileReader();
        reader.readAsText(thisFile);
        reader.onload = () => {
          const text = reader.result;
          let newList = textToKeywords(text).join(", ");
          newList =
            selectedKeywordList?.keywords &&
            selectedKeywordList?.keywords?.length > 0
              ? ", " + newList
              : newList;
          setSelectedKeywordList((prevState) => ({
            ...prevState,
            keywords: prevState.keywords + newList,
            listName: filename
          }));
        };
        event.target.value = "";
      } else {
        setShowAlertBanner(true, {
          message:
            "Looks like the file you uploaded is not formatted as a csv. Please update your file and try again. If you countinue to experience issues, please email success@demandjump.com",
          severity: "error"
        });
      }
    }
    return;
  };

  const onUploadClick = () => {
    inputFile.current.click();
  };

  const setListLocation = (childData: IChildData) => {
    setSelectedKeywordList((prevState) => ({
      ...prevState,
      location: {
        loc_id: childData.location_name,
        loc_name: childData.location_name
      }
    }));
    setLocation((prevState) => ({
      ...prevState,
      value: childData.location_name,
      label: childData.location_name
    }));
  };

  const handleCancel = () => {
    setSelectedKeywordList(cancelState);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setShowAlertBanner(false);
    const kwl = keywordLists.filter(
      (obj) =>
        obj.name !== "Create a new list" && obj.name !== "Select a keyword list"
    );
    const formattedKeywords =
      typeof selectedKeywordList.keywords === "string"
        ? textToKeywords(selectedKeywordList.keywords)
        : selectedKeywordList.keywords;
    const totalKeywords = countTotalKeywords(kwl, selectedKeywordList);
    const totalKWL = countTotalKeywordLists(kwl);
    const nonAllowed = findNonASCII(formattedKeywords);
    const newListName = selectedKeywordList.name;
    const newKeywordCount = selectedKeywordList.keywords
      ? selectedKeywordList.keywords.length
      : 0;
    const isNewList = !selectedKeywordList.id;

    if (totalKeywords > keywordsLimit) {
      setShowAlertBanner(true, {
        message: `Your current plan only allows for ${keywordsLimit} total tracked keywords. Please upgrade plan to track additional keywords.`,
        severity: "error"
      });
      setLoading(false);
    } else if (nonAllowed.length > 0) {
      setShowAlertBanner(true, {
        message: `The following keyword${
          nonAllowed.length > 1 ? "s" : ""
        } have unsupported characters: [${nonAllowed.join(", ")}].`,
        severity: "error"
      });
      setLoading(false);
    } else if (location === null) {
      setShowAlertBanner(true, {
        message:
          "All keyword lists require a location. Please select a location.",
        severity: "error"
      });
      setLoading(false);
    } else if (newListName === "") {
      setShowAlertBanner(true, {
        message: "All keyword lists require a list name.",
        severity: "error"
      });
      setLoading(false);
    } else if (newKeywordCount === 0) {
      setShowAlertBanner(true, {
        message: "All keyword lists require keywords.",
        severity: "error"
      });
      setLoading(false);
    } else if (hasDuplicateTitle(selectedKeywordList, kwl)) {
      setShowAlertBanner(true, {
        message: `The list name '${selectedKeywordList.name}' is already the name of another list. All lists must have distict list names.`,
        severity: "error"
      });
      setLoading(false);
    } else if (Number(keywordListsLimit) < totalKWL) {
      setShowAlertBanner(true, {
        message: `Your current plan only allows for ${keywordListsLimit} total keyword lists. Please upgrade plan to add more keyword lists.`,
        severity: "error"
      });
      setLoading(false);
    } else {
      const payload = [{ ...selectedKeywordList, keywords: formattedKeywords }];
      try {
        const response = await postKeywordList(payload, selectedPidObject.id);
        if (response) {
          const resKwl = response[0] as IKeywordList;
          setSelectedKeywordList(resKwl);
          if (isNewList) {
            setKeywordLists([...keywordLists, resKwl]);
          } else {
            const updateKwl = keywordLists.map((obj) =>
              resKwl.id === obj.id ? resKwl : obj
            );
            setKeywordLists([...updateKwl]);
          }
          setShowAlertBanner(true, {
            message: "Successfully saved keyword list.",
            severity: "success"
          });
        }
      } catch (err) {
        setShowAlertBanner(true, {
          message:
            "A server error occurred. Please try again later. If problem persists please contact customer success.",
          severity: "error"
        });
      }

      setLoading(false);
    }
  };

  return (
    <>
      {initialLoading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "center"
          }}
        >
          <DJLoadingGif />
        </div>
      ) : (
        <div id="demandjumpKeywords">
          <div
            className="card mb-3"
            style={{
              padding: "1.25rem",
              boxShadow:
                "0 0.46875rem 2.1875rem rgb(4 9 20 / 3%), 0 0.9375rem 1.40625rem rgb(4 9 20 / 3%), 0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%)",
              borderWidth: 0,
              transition: "all .2s"
            }}
          >
            <div
              className="card-header"
              style={{
                marginBottom: "1px",
                backgroundColor: "white",
                paddingLeft: 0
              }}
            >
              <div className="page-title-icon">
                <img
                  src={DJLogo}
                  alt="<%= integration_name %>"
                  style={{
                    width: "20vw",
                    marginRight: "10px"
                  }}
                />
              </div>
              Keyword Lists
            </div>
            <form onSubmit={handleSubmit}>
              <div className="header" style={{ marginBottom: "32px" }}>
                <p>
                  <i>
                    Changes to your keywords will not show in reports until
                    Keyword Lists are run every Sunday.
                  </i>
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <div className="name-location-container">
                    <input
                      className="input-style"
                      value={selectedKeywordList?.name || ""}
                      name="name"
                      type="text"
                      placeholder="Name your Keyword List"
                      onChange={handleChange}
                      required={true}
                      disabled={
                        selectedKeywordList?.list_type?.name === "blocklist" ||
                        loading
                      }
                    />
                    <div>
                      <LocationSelect
                        parentCallback={setListLocation}
                        style={{
                          height: "48px",
                          width: "272px",
                          fontSize: ".88rem",
                          fontFamily: "'Source Sans Pro', sans-serif",
                          color: "#373F50",
                          marginRight: "16px"
                        }}
                        location={location}
                        setLocation={setLocation}
                        isDisabled={
                          (selectedKeywordList &&
                            selectedKeywordList.list_type.name ===
                              "blocklist") ||
                          loading
                        }
                      />
                    </div>
                  </div>
                  <SelectDropDown
                    placeholder="Select a keyword list"
                    name="list-select"
                    options={sortLists(keywordLists)}
                    isDisabled={loading}
                    onChange={handleSelect}
                  />
                </div>
              </div>

              <div id="formWrapper" style={{ marginBottom: "20px" }}>
                <p className="section-title" style={{ marginBottom: "8px" }}>
                  Enter or paste your keywords (one word or phrase per line, or
                  separated by commas). Please list them in priority order.
                </p>
                <div
                  style={{
                    backgroundColor: "#f0f0f0",
                    color: "inherit",
                    outline: "none",
                    borderRadius: "4px",
                    border: "none",
                    width: "100%",
                    height: "285px",
                    padding: "8px",
                    fontSize: "16px",
                    lineHeight: "1.5",
                    transition: "all 0.2s ease-out",
                    resize: "none",
                    marginBottom: "4px"
                  }}
                >
                  <textarea
                    style={{
                      border: "0px",
                      outline: "none",
                      height: "100%",
                      width: "100%",
                      backgroundColor: "#f0f0f0",
                      fontSize: "16px",
                      color: "inherit",
                      lineHeight: "1.5",
                      resize: "none"
                    }}
                    name="keywords"
                    rows={10}
                    value={
                      selectedKeywordList?.keywords
                        ? selectedKeywordList.keywords
                        : ""
                    }
                    onChange={handleChange}
                    disabled={loading}
                    required
                  />
                </div>
                <input
                  name="keywordsFileInput"
                  type="file"
                  ref={inputFile}
                  onChange={handleFileUpload}
                  style={{ display: "none" }}
                />
                <button
                  type="button"
                  className="keywords-upload-button"
                  onClick={onUploadClick}
                >
                  <CloudUploadIcon /> Upload a file
                </button>
              </div>
              <div className="divider"></div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%"
                }}
              >
                <button
                  type="button"
                  className="keywords-cancel-button"
                  onClick={handleCancel}
                  style={{ width: "92px" }}
                  disabled={loading}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="keywords-save-button"
                  style={{ width: "92px" }}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden"></span>
                    </>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default Keywords;
