import { Link } from "react-router-dom";

const ContentPillarsHeader = () => (
  <div
    className="card-header"
    style={{
      padding: "0px 10px",
      borderBottomColor: "#E9EBF0",
      height: "70px",
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "space-between"
    }}
  >
    <div
      style={{
        fontWeight: "normal",
        fontSize: "30px",
        color: "#373F50",
        textTransform: "none"
      }}
    >
      Set Pillar Topics
    </div>
    <div className="pilar-strategy-link">
      <Link
        className="dj-blue source-sans-pro"
        to="/pillar-strategy"
        style={{ fontSize: "16px" }}
      >
        {" "}
        Return To Pillar Strategy
      </Link>
    </div>
  </div>
);

export default ContentPillarsHeader;
