const languageDictionary = {
  af: "Afrikaans",
  ak: "Akan",
  sq: "Albanian",
  am: "Amharic",
  ar: "Arabic",
  hy: "Armenian",
  az: "Azerbaijani",
  eu: "Basque",
  be: "Belarusian",
  bem: "Bemba",
  bn: "Bengali",
  bh: "Bihari",
  bs: "Bosnian",
  br: "Breton",
  bg: "Bulgarian",
  km: "Cambodian",
  ca: "Catalan",
  chr: "Cherokee",
  ny: "Chichewa",
  "zh-cn": "Chinese (Simplified)",
  "zh-tw": "Chinese (Traditional)",
  co: "Corsican",
  hr: "Croatian",
  cs: "Czech",
  da: "Danish",
  nl: "Dutch",
  en: "English",
  eo: "Esperanto",
  et: "Estonian",
  ee: "Ewe",
  fo: "Faroese",
  tl: "Filipino",
  fi: "Finnish",
  fr: "French",
  fy: "Frisian",
  gaa: "Ga",
  gl: "Galician",
  ka: "Georgian",
  de: "German",
  el: "Greek",
  gn: "Guarani",
  gu: "Gujarati",
  ht: "Haitian Creole",
  ha: "Hausa",
  haw: "Hawaiian",
  iw: "Hebrew",
  hi: "Hindi",
  hu: "Hungarian",
  is: "Icelandic",
  ig: "Igbo",
  value: "Indonesian",
  ia: "Interlingua",
  ga: "Irish",
  it: "Italian",
  ja: "Japanese",
  jw: "Javanese",
  kn: "Kannada",
  kk: "Kazakh",
  rw: "Kinyarwanda",
  rn: "Kirundi",
  kg: "Kongo",
  ko: "Korean",
  kri: "Krio (Sierra Leone)",
  ku: "Kurdish",
  ckb: "Kurdish (Soranî)",
  ky: "Kyrgyz",
  lo: "Laothian",
  la: "Latin",
  lv: "Latvian",
  ln: "Lingala",
  lt: "Lithuanian",
  loz: "Lozi",
  lg: "Luganda",
  ach: "Luo",
  mk: "Macedonian",
  mg: "Malagasy",
  ms: "Malay",
  ml: "Malayalam",
  mt: "Maltese",
  mi: "Maori",
  mr: "Marathi",
  mfe: "Mauritian Creole",
  mo: "Moldavian",
  mn: "Mongolian",
  "sr-me": "Montenegrin",
  ne: "Nepali",
  pcm: "Nigerian Pvaluegin",
  nso: "Northern Sotho",
  no: "Norwegian",
  nn: "Norwegian (Nynorsk)",
  oc: "Occitan",
  or: "Oriya",
  om: "Oromo",
  ps: "Pashto",
  fa: "Persian",
  pl: "Polish",
  pt: "Portuguese",
  "pt-br": "Portuguese (Brazil)",
  "pt-pt": "Portuguese (Portugal)",
  pa: "Punjabi",
  qu: "Quechua",
  ro: "Romanian",
  rm: "Romansh",
  nyn: "Runyakitara",
  ru: "Russian",
  gd: "Scots Gaelic",
  sr: "Serbian",
  sh: "Serbo-Croatian",
  st: "Sesotho",
  tn: "Setswana",
  crs: "Seychellois Creole",
  sn: "Shona",
  sd: "Sindhi",
  si: "Sinhalese",
  sk: "Slovak",
  sl: "Slovenian",
  so: "Somali",
  es: "Spanish",
  "es-419": "Spanish (Latin American)",
  su: "Sundanese",
  sw: "Swahili",
  sv: "Swedish",
  tg: "Tajik",
  ta: "Tamil",
  tt: "Tatar",
  te: "Telugu",
  th: "Thai",
  ti: "Tigrinya",
  to: "Tonga",
  lua: "Tshiluba",
  tum: "Tumbuka",
  tr: "Turkish",
  tk: "Turkmen",
  tw: "Twi",
  ug: "Uighur",
  uk: "Ukrainian",
  ur: "Urdu",
  uz: "Uzbek",
  vi: "Viettextse",
  cy: "Welsh",
  wo: "Wolof",
  xh: "Xhosa",
  yi: "Yvaluedish",
  yo: "Yoruba",
  zu: "Zulu"
};

export const languageConvert = (abbreviation: string): string =>
  languageDictionary[abbreviation as keyof typeof languageDictionary];
