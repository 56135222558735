import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import dayjs from "dayjs";
export const getUpdatedPillars = (initialPillars, newPillars) => {
  let diff = [];
  for (const i in newPillars) {
    let isUpdatedPillar = true
    for (const j in initialPillars){
      if(initialPillars[j].keyword ===newPillars[i].keyword ){
        let initialLocation =""
        if (typeof initialPillars[j].location === "string") {
          initialLocation= initialPillars[j].location
        } else{
          initialLocation= initialPillars[j].location.value
        }
        let newLocation =""
        if (typeof newPillars[i].location === "string") {
          newLocation= newPillars[i].location
        } else{
          newLocation= newPillars[i].location.value
        }
        if(newLocation === initialLocation){
          isUpdatedPillar = false
        }

      }
    }
    diff.push({...newPillars[i], isUpdatedPillar});
  }
  diff = diff.filter(
    pillar =>
      pillar.hasOwnProperty("keyword") &&
      pillar.keyword.length > 0 &&
      pillar.hasOwnProperty("location") &&
      !isEmpty(pillar.location)
  );
  return diff;
};

export const getChangedPillars = (initialPillars, newPillars) => {
  let diff = [];
  for (const i in newPillars) {
    if (initialPillars.hasOwnProperty(i)) {
      if (!isEqual(initialPillars[i], newPillars[i])) {
        diff.push(newPillars[i]);
      }
    } else {
      diff.push(newPillars[i]);
    }
  }
  diff = diff.filter(
    pillar =>
      pillar.hasOwnProperty("keyword") &&
      pillar.keyword.length > 0 &&
      pillar.hasOwnProperty("location") &&
      !isEmpty(pillar.location)
  );
  return diff;
};

export const getDeletedPillars = (initialPillars, updatedPillarKeywordsArray) =>{
  const deleted = [];
  initialPillars.forEach((pillar)=>{
    if (!updatedPillarKeywordsArray.includes(pillar.keyword)) {
      deleted.push(pillar)
    }
  })
  return deleted
}

// utility function for deciding what to return
const dropdownIndicatorColorizer = (base, state) => {
  if (state.selectProps.isDisabled || state.isDisabled) {
    return {
      ...base,
      color: "#909BB3",
      borderRadius: "4px",
    };
  } else if (state.isFocused) {
    return {
      ...base,
      color: "#008CCE",
      borderRadius: "4px",
      transform: state.selectProps.menuIsOpen && "rotate(180deg)",
    };
  } else if (state.selectProps.showReadyToUpdateText) {
    return {
      ...base,
      color: "#373F50",
      borderRadius: "4px",
      transform: state.selectProps.menuIsOpen && "rotate(180deg)",
    };
  } else if (state.selectProps.isError || state.isError) {
    return {
      ...base,
      "color": "#B00020",
      "borderRadius": "4px",
      "transform": state.selectProps.menuIsOpen && "rotate(180deg)",
      "&:hover": {
        color: "#B00020",
        borderRadius: "4px",
      },
      "&:active": {
        color: "black",
        borderRadius: "4px",
      },
    };
  } else {
    return {
      ...base,
      "color": "#373F50",
      "borderRadius": "4px",
      "boxShadow": "none",
      "&:hover": {
        color: "#373F50",
        borderRadius: "4px",
        boxShadow: "none",
      },
    };
  }

  // return an object which contains the style options we want for the dropdownIndicator based on the state of the dropdown
};

// utility function for deciding what to return
const controlColorizer = (base, state) => {
  if (state.selectProps.isDisabled || state.isDisabled) {
    return {
      ...base,
      borderColor: "#909BB3",
      backgroundColor: "white",
      borderRadius: "4px",
      boxShadow: "none",
    };
  } else if (state.isFocused) {
    return {
      ...base,
      "borderColor": "#008CCE",
      "backgroundColor": "white",
      "borderRadius": "4px",
      "boxShadow": "none",
      "&:hover": {
        borderColor: "#008CCE",
        backgroundColor: "white",
        borderRadius: "4px",
        boxShadow: "none",
      },
    };
  } else if (state.selectProps.showReadyToUpdateText) {
    return {
      ...base,
      "borderColor": "#373F50",
      "backgroundColor": "white",
      "borderRadius": "4px",
      "boxShadow": "none",
      "&:hover": {
        borderColor: "#373F50",
        backgroundColor: "white",
        borderRadius: "4px",
        boxShadow: "none",
      },
    };
  } else if (state.selectProps.isError || state.isError) {
    return {
      ...base,
      "borderColor": "#B00020",
      "backgroundColor": "white",
      "borderRadius": "4px",
      "boxShadow": "none",
      "&:hover": {
        borderColor: "#B00020",
        backgroundColor: "white",
        borderRadius: "4px",
        boxShadow: "none",
      },
    };
  } else {
    return {
      ...base,
      "borderColor": "#373F50",
      "backgroundColor": "white",
      "borderRadius": "4px",
      "boxShadow": "none",
      "&:hover": {
        borderColor: "#373F50",
        backgroundColor: "white",
        borderRadius: "4px",
        boxShadow: "none",
      },
    };
  }

  // return an object which contains the style options we want for the control based on the state of the dropdown
};

// utility function for deciding what to return
const singleValueColorizer = (base, state) => {
  if (state.selectProps.isDisabled || state.isDisabled) {
    return {
      ...base,
      color: "#909BB3",
      fontSize: "16px",
    };
  } else if (state.isFocused) {
    return {
      ...base,
      "color": "#373F50",
      "fontSize": "16px",
      "&:focus": {
        color: "#373F50",
        fontSize: "16px",
      },
    };
  } else if (state.selectProps.showReadyToUpdateText) {
    return {
      ...base,
      color: "#373F50",
      fontSize: "16px",
    };
  } else if (state.selectProps.isError || state.isError) {
    return {
      ...base,
      "color": "#B00020",
      "fontSize": "16px",
      "&:hover": {
        color: "#B00020",
        fontSize: "16px",
      },
    };
  } else {
    return {
      ...base,
      color: "#373F50",
      fontSize: "16px",
    };
  }

  // return an object which contains the style options we want for the singlevalue based on the state of the dropdown
};

// utility function for deciding what to return
const indicatorSeparatorColorizer = (base, state) => {
  if (state.selectProps.isDisabled || state.isDisabled) {
    return {
      ...base,
      backgroundColor: "#909BB3",
    };
  } else if (state.isFocused) {
    return {
      ...base,
      backgroundColor: "#008CCE",
    };
  } else if (state.selectProps.showReadyToUpdateText) {
    return {
      ...base,
      backgroundColor: "#373F50",
    };
  } else if (state.selectProps.isError || state.isError) {
    return {
      ...base,
      "backgroundColor": "#B00020",
      "&:hover": {
        backgroundColor: "#B00020",
      },
    };
  } else {
    return {
      ...base,
      backgroundColor: "#373F50",
    };
  }

  // return an object which contains the style options we want for the indicatorSeparator based on the state of the dropdown
};

// utility function for deciding what to return
const clearIndicatorColorizer = (base, state) => {
  if (state.selectProps.isDisabled || state.isDisabled) {
    return {
      ...base,
      color: "none",
    };
  } else if (state.isFocused) {
    return {
      ...base,
      color: "#008CCE",
    };
  } else if (state.selectProps.showReadyToUpdateText) {
    return {
      ...base,
      color: "#373F50",
    };
  } else if (state.selectProps.isError || state.isError) {
    return {
      ...base,
      "color": "#B00020",
      "&:hover": {
        color: "#B00020",
      },
    };
  } else {
    return {
      ...base,
      "color": "#373F50",
      "&:hover": {
        color: "#373F50",
      },
    };
  }

  // return an object which contains the style options we want for the indicatorSeparator based on the state of the dropdown
};

const placeholderColorizer = (base, state) => {
  if (state.selectProps.isDisabled || state.isDisabled) {
    return {
      ...base,
      color: "#909BB3",
      fontSize: "16px",
    };
  } else if (state.isFocused) {
    return {
      ...base,
      color: "#373F50",
      fontSize: "16px",
    };
  } else if (state.selectProps.showReadyToUpdateText) {
    return {
      ...base,
      color: "#373F50",
      fontSize: "16px",
    };
  } else if (state.selectProps.isError || state.isError) {
    return {
      ...base,
      "color": "#B00020",
      "fontSize": "16px",
      "&:hover": {
        color: "#B00020",
        fontSize: "16px",
      },
    };
  } else {
    return {
      ...base,
      "color": "#373F50",
      "fontSize": "16px",
      "&:hover": {
        color: "#373F50",
        fontSize: "16px",
      },
    };
  }
};

export const customSelectLocationsStyles = {
  control: (base, state) => {
    return controlColorizer(base, state);
  },
  singleValue: (base, state) => {
    return singleValueColorizer(base, state);
  },
  dropdownIndicator: (base, state) => {
    return dropdownIndicatorColorizer(base, state);
  },
  clearIndicator: (base, state) => {
    return clearIndicatorColorizer(base, state);
  },
  indicatorSeparator: (base, state) => {
    return indicatorSeparatorColorizer(base, state);
  },
  placeholder: (base, state) => {
    return placeholderColorizer(base, state);
  },
  option: base => ({
    ...base,
    "backgroundColor": "white",
    "color": "black",
    "width": "100%",
    "&:hover": {
      backgroundColor: "#E1F5FF",
      color: "black",
    },
  }),
  width: "98%",
};

export const limitPillars = (pillars, limit) => {
  // order pillars in an array by updatedAt, desc
  const sorted = pillars.sort(
    (a, b) => dayjs(b.updatedAt).valueOf() - dayjs(a.updatedAt).valueOf()
  );
  // removing trailing spaces
  const formattedSorted = sorted.map((pillar) => {
    return {
      ...pillar,
      keyword: pillar.keyword.trim(),
    };
  });

  // return the first X pillars, where X = content pillars limit
  return formattedSorted.slice(0, limit);
};
