import { IBrief } from "@/interfaces/brief.interface";

export const determineBriefType = (brief: IBrief) => {
  if (brief.legacy_type === "legacy pillar") {
    return "Legacy Pillar";
  } else if (brief.legacy_type === "legacy sub pillar") {
    return "Legacy Sub Pillar";
  } else if (brief.legacy_type === "legacy blog") {
    return "Legacy Blog";
  } else if (brief.legacy_type === "legacy ad hoc") {
    return "Legacy Ad Hoc";
  } else if (brief.blog_id) {
    return "Blog";
  } else if (brief.sub_pillar_id) {
    return "Sub Pillar";
  } else if (brief.pillar_id) {
    return "Pillar";
  }
  return "Ad Hoc";
};

export const formatUrl = (url: string) => {
  if (!/^https:\/\//i.test(url)) {
    // URL does not start with "https://", so add it
    url = "https://" + url;
  }
  return url;
};
