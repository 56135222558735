import React, { memo } from "react";

import styles from "./ScoreNumber.module.scss";
import { round10 } from "@/utilities/format";

interface IScoreNumberProps {
  value?: number;
  text?: string;
}

function ScoreNumber({ value, text = "Last week" }: IScoreNumberProps) {
  return (
    <div>
      <div className={styles.param}>{value ? round10(value) : "-"}</div>
      <div className={styles.paramText}>{text}</div>
    </div>
  );
}

export default memo(ScoreNumber);
