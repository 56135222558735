import { Grid } from "@mui/material";
import AcceptInvitationForm from "./AcceptInvitationForm";
import SideGallery from "../Component/SideGallery";
import AcceptInvitationHeader from "./AcceptInvitationHeader";
import NoEmailComponent from "./NoEmailComponent";

const AcceptInvitation = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const emailEncoded = urlParams.get("email");
  const email = emailEncoded ? decodeURIComponent(emailEncoded) : null;

  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item md={7} xs={12}>
          {email ? (
            <>
              <AcceptInvitationHeader />
              <AcceptInvitationForm email={email} />
            </>
          ) : (
            <NoEmailComponent />
          )}
        </Grid>
        <Grid item md={5} xs={12}>
          <SideGallery></SideGallery>
        </Grid>
      </Grid>
    </div>
  );
};

export default AcceptInvitation;
