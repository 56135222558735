import { useState, useEffect } from "react";
import isValidDomain from "is-valid-domain";
import { Row, Col } from "react-bootstrap";

import { getCompetitorsByPid, getLimits } from "@/api/get";
import { addCompetitors } from "@/api/post";
import { useAppStore } from "@/hooks";

import CompetitorRow from "./components/CompetitorRow";
import LoadingRow from "./components/LoadingRow";
import FakeRow from "./components/FakeRow";
import { getChangedCompetitors } from "./common/utils";
import CardHeader from "./components/CardHeader";
import UpgradeConfirmCancelColumn from "./components/UpgradeConfirmCancelColumn";
import "./competitors.scss";

const Competitors = () => {
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [competitorsUpdated, setCompetitorsUpdated] = useState(false);
  const [competitors, setCompetitors] = useState([]);
  const [initialCompetitors, setInitialCompetitors] = useState([]);
  const [competitorLimit, setCompetitorLimit] = useState(0);
  const [duplicateCompetitor, setDuplicateCompetitor] = useState(false);

  const { selectedPidObject, setShowAlertBanner, isAdmin } = useAppStore(
    (store) => ({
      isAdmin: store.isAdmin,
      selectedPidObject: store.selectedPidObject,
      setShowAlertBanner: (bool, alertBannerOptions) =>
        store.setShowAlertBanner(bool, alertBannerOptions)
    })
  );

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const entitlements = await getLimits(selectedPidObject.id);
      const competitorsResponse = await getCompetitorsByPid(
        selectedPidObject.id
      );
      let c = [];
      if (competitorsResponse.length > 0) {
        c = competitorsResponse
          .sort((v1, v2) => v1.priority_rank - v2.priority_rank)
          .filter((n) => n.is_direct_competitor);
      }
      setCompetitors([...c]);
      setInitialCompetitors([...c]);
      if (entitlements?.limits?.competitors) {
        setCompetitorLimit(entitlements.limits.competitors);
      }
      setLoading(false);
    };
    if (selectedPidObject) {
      fetchData();
    }
  }, [selectedPidObject]);

  const updateCompetitorArray = (name, row) => {
    const c = competitors;
    const updatedCompetitor = {
      name,
      priority_rank: row,
      is_direct_competitor: true
    };
    c[row - 1] = updatedCompetitor;
    if (name === "") {
      c.splice(row - 1, 1);
    }
    const newCompetitors = c.map((item) => item.name);
    const duplicates = newCompetitors.filter(
      (item, index) => newCompetitors.indexOf(item) !== index
    );
    if (duplicates.length > 0) {
      setShowAlertBanner(true, {
        message: `${duplicates} is already listed as a competitor`,
        severity: "error"
      });
      setDuplicateCompetitor(true);
      setDisabled(true);
    } else {
      setDuplicateCompetitor(false);
      setDisabled(false);
    }
    setCompetitors(c);
    let allValidDomains = true;
    c.forEach((item) => {
      if (!isValidDomain(item.name)) {
        allValidDomains = false;
      }
    });
    if (
      getChangedCompetitors(initialCompetitors, c).length > 0 &&
      allValidDomains
    ) {
      setCompetitorsUpdated(true);
    } else {
      setCompetitorsUpdated(false);
    }
  };

  const saveCompetitors = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    setDisabled(true);
    await addCompetitors({ pid: selectedPidObject.id, competitors })
      .then(() => {
        setIsSaving(false);
        setDisabled(false);
        setInitialCompetitors([...competitors]);
        setShowAlertBanner(true, {
          message:
            "Your competitors have been updated! Please allow up to 24 hours for the update to reflect in your insight reports.",
          severity: "success"
        });
      })
      .catch((err) => {
        console.error(err);
        setIsSaving(false);
        setDisabled(false);
        setShowAlertBanner(true, {
          message:
            "We have experienced an issue saving your competitors. Please ensure you entered valid domains (ie: example.com) and try again. If this issue persists please contact customer support.",
          severity: "error"
        });
      });
  };

  return (
    <Row
      style={{
        width: "100%"
      }}
    >
      <Col>
        {isSaving && (
          <div
            className="d-flex justify-content-center position-fixed h-100 align-items-center"
            style={{ zIndex: 2 }}
          >
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
        <div
          style={{
            padding: "20px 30px 30px 22px",
            fontSize: "16px"
          }}
        >
          <CardHeader
            disabled={disabled}
            competitorsUpdated={competitorsUpdated}
          />
          {loading && (
            <div className="form-wrapper">
              {[...Array(10)].map((key, value) => (
                <div key={value + 1}>
                  <LoadingRow rowNumber={value + 1}></LoadingRow>
                  <br></br>
                </div>
              ))}
            </div>
          )}

          <Row>
            <Col sm={6}>
              {!loading && (
                <>
                  <div
                    className="demandjump-competitors-control"
                    style={{
                      padding: "0",
                      justifyContent: "space-between"
                    }}
                  ></div>
                  <div className="competitors-partition">
                    <form
                      onSubmit={(e) => saveCompetitors(e)}
                      id="competitor_form"
                    >
                      {[...Array(competitorLimit)].map((key, value) => (
                        <div key={value + 1}>
                          <CompetitorRow
                            rowNumber={value + 1}
                            competitor={competitors[value]}
                            updateCompetitorArray={updateCompetitorArray}
                            initialCompetitors={initialCompetitors}
                            isSaving={isSaving}
                            duplicateCompetitor={duplicateCompetitor}
                            setDuplicateCompetitor={setDuplicateCompetitor}
                          ></CompetitorRow>
                          <br></br>
                        </div>
                      ))}
                      {competitorLimit < 10 &&
                        [...Array(10 - competitorLimit)].map((nada, idx) => (
                          <div key={competitorLimit + idx + 1}>
                            <FakeRow rowNumber={competitorLimit + idx + 1} />
                            <br></br>
                          </div>
                        ))}
                    </form>
                  </div>
                </>
              )}
            </Col>
            <Col sm={6}>
              <button
                style={{ width: "170px", height: "40px" }}
                className="competitors-button-primary"
                id="submitCompetitors"
                disabled={disabled || !competitorsUpdated}
                value="Submit"
                form="competitor_form"
                type="submit"
              >
                Update Competitors
              </button>
              {isAdmin && (
                <UpgradeConfirmCancelColumn
                  className="col-12 col-md-4"
                  disabled={disabled}
                  competitorsUpdated={competitorsUpdated}
                />
              )}
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};
export default Competitors;
