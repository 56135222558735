// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body-droppable-placeholder:before {
  content: "Drop a term here...";
  display: flex;
  flex-direction: column-reverse;
  color: #909BB3;
  width: 88%;
  margin: 10px 0px 10px 30px;
  background-color: white;
  border: 2px dashed #E9EBF0;
  border-radius: 12px;
  padding: 10px 10px 10px 30px;
}

.body-droppable-div {
  display: flex;
  flex-direction: column-reverse;
  color: #909BB3;
  margin: 20px 0px 10px 24px;
  background-color: white;
  border: 2px dashed #E9EBF0;
  border-radius: 12px;
  padding: 10px 10px 10px 30px;
}

.save-button {
  background: #008CCE !important;
  color: #FFFFFF !important;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  border: none;
}
.save-button:active {
  background: #2ABBFF !important;
  box-shadow: 0px 0px 3px #008CCE !important;
}
.save-button:disabled {
  background: #D3D7E0 !important;
  color: rgba(55, 63, 80, 0.3) !important;
}

.body-sections {
  background-color: #F8F8FA;
}`, "",{"version":3,"sources":["webpack://./src/components/manage_content/content_brief/content_brief_workflow/step_two/stepTwo.scss"],"names":[],"mappings":"AACA;EACE,8BAAA;EACA,aAAA;EACA,8BAAA;EACA,cAAA;EACA,UAAA;EACA,0BAAA;EACA,uBAAA;EACA,0BAAA;EACA,mBAAA;EACA,4BAAA;AAAF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,cAAA;EACA,0BAAA;EACA,uBAAA;EACA,0BAAA;EACA,mBAAA;EACA,4BAAA;AACF;;AACA;EACE,8BAAA;EACA,yBAAA;EACA,0CAAA;EACA,eAAA;EACA,YAAA;AAEF;AADE;EACE,8BAAA;EACA,0CAAA;AAGJ;AADE;EACE,8BAAA;EACA,uCAAA;AAGJ;;AACA;EACE,yBAAA;AAEF","sourcesContent":["\n.body-droppable-placeholder:before{\n  content: \"Drop a term here...\";\n  display: flex;\n  flex-direction: column-reverse;\n  color: #909BB3;  \n  width: 88%;\n  margin: 10px 0px 10px 30px;\n  background-color: white;\n  border: 2px dashed #E9EBF0;\n  border-radius: 12px;\n  padding: 10px 10px 10px 30px;\n}\n.body-droppable-div{\n  display: flex;\n  flex-direction: column-reverse;\n  color: #909BB3;\n  margin: 20px 0px 10px 24px;\n  background-color: white;\n  border: 2px dashed #E9EBF0;\n  border-radius: 12px;\n  padding: 10px 10px 10px 30px;\n}\n.save-button {\n  background: #008CCE !important;\n  color: #FFFFFF !important;\n  font-family: 'Source Sans Pro', sans-serif;\n  font-size: 20px;\n  border: none;\n  &:active{\n    background: #2ABBFF !important;\n    box-shadow: 0px 0px 3px #008CCE !important;\n  }\n  &:disabled{\n    background: #D3D7E0 !important;\n    color: rgba(55, 63, 80, 0.3) !important;\n  }\n}\n\n.body-sections{\n  background-color: #F8F8FA;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
