export const instructionsSectionTextBlock = (
  pillarTopic: string,
  wordCount: string,
  typeOfTerm: string
) => {
  const tempArray = [];
  if (typeOfTerm === "Blog") {
    tempArray.push(
      `This article will be a Supporting Blog post as part of a broader Pillar Strategy. As one part of a network of content related to the Pillar Topic of “${pillarTopic}”, it is designed to answer one specific and important question the market asks search engines. The information contained within this content brief is designed to help you write an approximately ${wordCount}-word article that covers the specific facets of this topic that people are most interested in learning about.`
    );
  }

  if (typeOfTerm === "Pillar") {
    tempArray.push(
      `This article will be a Pillar Page as part of a broader Pillar Strategy. As one part of a network of content related to the Pillar Topic of "${pillarTopic}", it is designed to offer your broadest possible survey of content related to that core topic. This page, in turn, will serve as a “network hub” and destination for internal links from other, related pieces of content that dive deeper into the broader topic. The information contained within this content brief is designed to help you write an approximately ${wordCount}-word article that covers the specific facets of this topic that people are most interested in learning about.`
    );
  }

  if (typeOfTerm === "Sub Pillar") {
    tempArray.push(
      `This article will be a Sub-Pillar Page as part of a broader Pillar Strategy. As one part of a network of content related to the Pillar Topic of “${pillarTopic}”, it is designed to offer an overview of a particular aspect of this broader topic. The information contained within this content brief is designed to help you write an approximately ${wordCount}-word article that covers the specific facets of this topic that people are most interested in learning about.`
    );
  }

  if (typeOfTerm === "Ad Hoc") {
    tempArray.push(
      `The information contained within this content brief is designed to help you write an approximately ${wordCount}-word article that covers the specific facets of this topic that people are most interested in learning about.`
    );
  }
  tempArray.push("\n");

  tempArray.push(
    "Below, you’ll find the meta information for this article, including its focus keyword which doubles as its title. You’ll find notes to help you understand your audience and the brand’s voice, as well as a detailed outline of the final article."
  );
  tempArray.push("\n");
  tempArray.push(
    `It is critical that the finished article follows this outline closely, using the exact heading titles and keywords where noted in each section of the outline.` +
      `${
        typeOfTerm === "Ad Hoc"
          ? " As you write, pay special attention to any quotes or impactful data points you can denote as candidates for simple, custom graphics to be created around and published in the body copy of the final article."
          : " Additionally, the outline will contain recommended links to include in this article in order to construct the proper internal linking structure that creates the network effect intended by the Pillar-Based Marketing methodology for this Pillar Strategy." +
            " As you write, pay special attention to any quotes or impactful data points you can denote as candidates for simple, custom graphics to be created around and published in the body copy of the final article."
      }`
  );
  tempArray.push("\n");
  return tempArray;
};
