import Cookies from "universal-cookie";

import { refresh } from "./../api/post";

const Authorization = async (): Promise<void> => {
  const cookies = new Cookies();
  const access_token = cookies.get("Access_Token");
  if (access_token) {
    await refresh()
      .then((res) => {
        if (res.status < 200 || res.status > 299) {
          throw new Error(`Error: Status ${res.status}`);
        }
        cookies.remove("Access_Token", { path: "/" });
        cookies.set("Access_Token", res.data.access_token, {
          path: "/",
          domain: process.env.REACT_APP_SUBDOMAIN
        });
      })
      .catch((err: Error) => {
        throw err;
      });
  } else {
    throw new Error("No Access Token found");
  }
};

export default Authorization;
