import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import Select from "react-select";
import { putManageUser } from "../../../../api/put";
import PropTypes from "prop-types";
import { convertPdomainsToPidsDropdownMenu } from "../utils";
import { useAppStore } from "@/hooks";

function EditUserModal(props) {
  const [formData, setFormData] = useState({
    id: "",
    pids: convertPdomainsToPidsDropdownMenu(props.user.primaryDomains),
    is_admin: props.user.admin === 1
  });
  const [pidError, setPidError] = useState(false);
  const [pidsList, setPidsList] = useState([]);
  const checkLabelStyle = { cursor: "pointer", marginLeft: "5px" };

  const { setShowAlertBanner } = useAppStore((store) => ({
    setShowAlertBanner: store.setShowAlertBanner
  }));

  useEffect(() => {
    if (props.user) {
      const userOriginal = {
        ...props.user,
        originalAdminState: props.user.admin === 1,
        is_admin: props.user.admin
      };
      setFormData(userOriginal);
    }
  }, [props.user, props.show]);

  useEffect(() => {
    if (props.pids) {
      setPidsList(convertPdomainsToPidsDropdownMenu(props.pids));
    }
  }, [props.pids]);

  const handleChange = (e) => {
    const value = e.target.checked;
    const name = e.target.name;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSelectPidChange = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      updatedPids: selectedOption
    }));
  };

  const handleSave = async () => {
    if (formData.updatedPids && !formData.updatedPids.length) {
      setPidError(true);
    } else {
      props.setLoading(true);
      const added_pids = formData.updatedPids
        ? formData.updatedPids
            .filter(
              (pid) =>
                !props.user.primaryDomains.map((p) => p.id).includes(pid.id)
            )
            .map((non_original_pdomain) => non_original_pdomain.id)
        : [];
      const deleted_pids = formData.updatedPids
        ? props.user.primaryDomains
            .filter(
              (pid) => !formData.updatedPids.map((p) => p.id).includes(pid.id)
            )
            .map((deleted_pdomain) => deleted_pdomain.id)
        : [];

      const payload = {
        user_id: props.user.id,
        admin: formData.is_admin ? 1 : 0,
        added_pids,
        deleted_pids,
        email_address: props.user.email,
        final_pids: formData.updatedPids
          ? formData.updatedPids.map((pid) => pid.id)
          : props.user.primaryDomains.map((pid) => pid.id),
        update_is_admin: Boolean(props.user.admin) !== formData.is_admin,
        is_admin: formData.is_admin ? 1 : 0,
        account_id: props.account_id
      };
      try {
        const response = await putManageUser(payload);
        const updatedUser = response.data;
        // update the user's attributes in the user accounts list so that the changes are reflected on the main screen (index.tsx)
        const updatedAccountUser = {
          admin: payload.is_admin,
          code: props.user.code,
          email: props.user.email,
          id: props.user.id,
          name: props.user.name,
          primaryDomains: updatedUser.pdomains,
          status: props.user.status
        };
        const updatedAccountUsers = props.selectedPidAccountUsers.map(
          (user) => {
            if (user.id === updatedUser.id) {
              return updatedAccountUser;
            }
            return user;
          }
        );
        props.setSelectedPidAccountUsers(updatedAccountUsers);
        setShowAlertBanner(true, {
          message: "User was updated successfully.",
          severity: "success"
        });
      } catch (error) {
        console.error(error);
        setShowAlertBanner(true, {
          message: "An error occurred while updating user. Please try again.",
          severity: "error"
        });
      }

      props.setLoading(false);
      props.onHide();
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      style={{ paddingTop: "100px" }}
    >
      <Modal.Header>
        <Modal.Title>Edit User Access Permissions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="user-name">
          <div className="form-group">
            <label htmlFor="userName" className="col-form-label">
              User:
            </label>
            <input
              type="text"
              style={{ fontSize: "14px" }}
              className="form-control"
              id="userName"
              value={
                props.user.name?.length ? props.user.name : props.user.email
              }
              disabled
            />
          </div>
        </div>
        <form>
          <div className="form-group">
            <input
              name="is_admin"
              checked={formData.is_admin}
              type="checkbox"
              onChange={handleChange}
              disabled={props.loadingRequest}
            />
            <label
              htmlFor="userRole"
              className="col-form-label"
              style={checkLabelStyle}
            >
              Admin User
            </label>
            <br />
            <small>
              *Note: Changing Admin status will change the user&apos;s admin
              status for all domains.
            </small>
          </div>

          <div className="form-group pdomain-access">
            <label htmlFor="pids-select" className="col-form-label">
              Select the domains that these users should have access to:
            </label>
            <Select
              defaultValue={
                props.user?.primaryDomains
                  ? props.user.primaryDomains.map((p) => {
                      let name = `ID - ${p.domainName}`;
                      if (p.displayName) {
                        name += ` (${p.displayName})`;
                      }
                      return {
                        ...p,
                        id: p.id,
                        label: name,
                        value: p.id
                      };
                    })
                  : null
              }
              isMulti
              id="pids-select"
              name="pids"
              options={pidsList}
              value={formData.pids}
              onChange={handleSelectPidChange}
              isDisabled={props.loadingRequest}
              closeMenuOnSelect={false}
            />
            {pidError &&
              formData.updatedPids &&
              !formData.updatedPids.length && (
                <p className="text-danger">
                  Please select at least one domain. To remove user from all
                  domains please use the delete button.
                </p>
              )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={props.onHide}
          variant="secondary"
          disabled={props.loadingRequest}
        >
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave}>
          {props.loadingRequest ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Save"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
EditUserModal.propTypes = {
  user: PropTypes.object,
  show: PropTypes.bool,
  setLoading: PropTypes.func,
  onHide: PropTypes.func,
  pids: PropTypes.array,
  loadingRequest: PropTypes.bool,
  ssoAvailable: PropTypes.bool,
  account_id: PropTypes.number,
  selectedPidAccountUsers: PropTypes.array,
  setSelectedPidAccountUsers: PropTypes.function
};

export default EditUserModal;
