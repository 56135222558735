import { resendVerificationEmail } from "../../api/post";
import { IAppStore } from "../../interfaces/app-store.interface";
import { useAppStore } from "../../hooks";

import "./application.scss";

function VerifiedEmailBanner() {
  const { user, setShowAlertBanner } = useAppStore((store: IAppStore) => ({
    user: store.user,
    setShowAlertBanner: store.setShowAlertBanner
  }));

  const sendEmail = async (event: React.MouseEvent) => {
    event.preventDefault();
    const email = user.unique_id;

    await resendVerificationEmail(email)
      .then((res) => {
        setShowAlertBanner(true, {
          message: "Resent Verification Email!",
          severity: "success"
        });
        return res;
      })
      .catch((err) => {
        console.error(err);
        setShowAlertBanner(true, {
          message:
            "An error has occurred. Please try again later. If problem persists please contact customer success.",
          severity: "error"
        });
      });
  };
  return (
    <div
      className="banner"
      style={{
        color: "#9A6608",
        backgroundColor: "#FFA40026",
        textAlign: "center",
        padding: "5px",
        position: "static",
        width: "-webkit-fill-available"
      }}
    >
      Your email address is not verified.
      <span className="verification-email-link" onClick={sendEmail}>
        Click here to resend the verification email
      </span>
    </div>
  );
}
export default VerifiedEmailBanner;
