import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { updateUnfinishedPillars } from "../../../../api/post";
import { useEffect } from "react";

function RetrieveWorkingPillars(props) {
  const formik = useFormikContext();

  useEffect(() => {
    const checkIncompletes = setTimeout(async () => {
      if (props.incompletePillars.length && !formik.dirty) {
        try {
          const response = await updateUnfinishedPillars(
            props.incompletePillars,
            props.pid
          );
          const tempContentPillars = props.contentPillars.map((pillar) => {
            let tempPillar = JSON.parse(JSON.stringify(pillar));
            response.forEach((workingPillar) => {
              if (workingPillar.keyword === pillar.keyword) {
                tempPillar = JSON.parse(JSON.stringify(workingPillar));
                if (workingPillar.cbmInfo?.status === "complete") {
                  tempPillar.cbmInfo.newPillar = true;
                }
              }
            });
            return tempPillar;
          });
          props.setContentPillars(tempContentPillars);
          const workingStatuses = ["pending", "working"];
          props.setIncompletePillars(
            response.filter((workingPillar) =>
              workingStatuses.includes(workingPillar.cbmInfo?.status)
            )
          );
        } catch (error) {
          console.error(error);
        }
      }
    }, 5000);
    return () => clearTimeout(checkIncompletes);
  }, [props.incompletePillars, formik.dirty]);
  return null;
}
RetrieveWorkingPillars.propTypes = {
  incompletePillars: PropTypes.array,
  contentPillars: PropTypes.array,
  setContentPillars: PropTypes.func,
  setIncompletePillars: PropTypes.func
};

RetrieveWorkingPillars.defaultProps = {
  incompletePillars: [],
  contentPillars: []
};
export default RetrieveWorkingPillars;
