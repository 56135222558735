/* eslint-disable @typescript-eslint/no-explicit-any, no-undefined */
import {
  IInsightMessage,
  IInsights,
  IInsightsStore,
  IPendingInsight
} from "../interfaces/insights.interface";

const createInsightsStore: () => IInsightsStore = () => ({
  insights: [] as IInsights[],
  pendingInsights: [] as IPendingInsight[],
  unfinishedInsights: [] as number[],
  insightPid: -1,
  insightPage: 1,
  insightHasMore: true,
  insightMessage: { message: "", show: false, type: "success" },
  setInsights(value: IInsights[]) {
    this.insights = value;
  },
  setPendingInsights(value: IPendingInsight[]) {
    this.pendingInsights = value;
  },
  setUnfinishedInsights(value: number[]) {
    this.unfinishedInsights = value;
  },
  setInsightPid(value: number) {
    this.insightPid = value;
  },
  setInsightPage(value: number) {
    this.insightPage = value;
  },
  setInsightHasMore(value: boolean) {
    this.insightHasMore = value;
  },
  setInsightMessage(value: IInsightMessage) {
    this.insightMessage = value;
  }
});

export default createInsightsStore;
