import Header from "./components/Header";
import "../shared/marketingPages.scss";
import ContactUsLink from "../shared/ContactUsLink";

function KeywordTrends() {
  return (
    <div
      className="header-first-component-container gradient"
      style={{ height: "100vh" }}
    >
      <Header />
      <div className="marketing-card-container">
        <div className="section-header">Keyword Trends</div>
        <div className="sub-header">
          Interested in keeping track of your ranking progress for your own
          customized list of keywords?
        </div>
        <div className="image-text-container-vertical">
          <div className="text-button">
            <div className="text">
              <p>
                Our Keyword Trends dashboard provides a snapshot of your
                rankings—and your competitors’ rankings—for each keyword on your
                user-generated list.
              </p>
              <p>
                Learn which keywords have the most potential to drive qualified
                traffic to your domain and identify opportunities to move to
                page one with optimized content.
              </p>
            </div>
            <div className="image wide-image">
              <img
                src={require("../../assets/top-keyword-charts@3x.png")}
                alt="Estimated Daily Traffic"
              />
              <ContactUsLink />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KeywordTrends;
