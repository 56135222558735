const NoEmailComponent = () => (
  <div className="d-flex justify-content-center p-5">
    <div style={{ width: "70%" }}>
      <div className="card">
        <div className="card-header">
          <h3> Whoops! We've encountered an error</h3>
        </div>
        <p className="p-3">
          No email address was found in the link you used to reach this page.
          Please verify that the email address is in the url.
        </p>
      </div>
    </div>
  </div>
);

export default NoEmailComponent;
