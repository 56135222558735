/* eslint-disable @typescript-eslint/ban-types */
import { useState } from "react";
import Spinner from "react-bootstrap/Spinner";

import "../../../App.css";
import "./manageProfile.scss";

import { useAppStore } from "../../../hooks";

import { IAppStore } from "../../../interfaces/app-store.interface";
import { IUpdateUser } from "../../../interfaces/user.interface";
import { updateProfile } from "../../../api/post";
import Cookies from "universal-cookie";
import { checkPassword } from "../../../utilities/password";
import ShowPassword from "@/components/shared/ShowPassword";
import ShowConfirmPassword from "@/components/shared/ShowConfirmPassword";

function ProfileUpdate(props: { setUserProfile: Function }): JSX.Element {
  const cookies = new Cookies();
  const { user, setShowAlertBanner } = useAppStore((store: IAppStore) => ({
    user: store.user,
    setShowAlertBanner: store.setShowAlertBanner
  }));
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(user);
  const [passwordError, setPasswordError] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleGetNewInfo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (userInfo.password !== userInfo.confirmPassword) {
      setPasswordError(true);
    }
    if (checkPassword(value)) {
      setPasswordError(false);
    }
    if (value === "") {
      setPasswordError(false);
    }
    setDisabled(false);
    setUserInfo((prevState: IUpdateUser) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const passwordInput = document.getElementById(
      "password"
    ) as HTMLInputElement;
    const confirmPasswordInput = document.getElementById(
      "confirmpassword"
    ) as HTMLInputElement;

    if (userInfo.password || userInfo.confirmPassword) {
      if (userInfo.password !== userInfo.confirmPassword) {
        setShowAlertBanner(true, {
          message: "Passwords do not match",
          severity: "error"
        });
        setDisabled(true);
      }
      if (
        userInfo.password === userInfo.confirmPassword &&
        checkPassword(userInfo.password)
      ) {
        setLoading(true);
        await updateProfile(userInfo, user.id)
          .then((res) => {
            passwordInput.value = "";
            confirmPasswordInput.value = "";
            setLoading(false);
            setDisabled(true);
            setShowAlertBanner(true, {
              message: "You have successfully updated your profile.",
              severity: "success"
            });
            if (res.status === 200 && res.data?.access_token) {
              cookies.remove("Access_Token", { path: "/" });
              cookies.set("Access_Token", res.data.access_token, {
                path: "/",
                domain: process.env.REACT_APP_SUBDOMAIN
              });
            }
          })
          .catch((e) => {
            console.error(e);
            setShowAlertBanner(true, {
              message:
                "There has been an error updating your profile. Please try again later.",
              severity: "error"
            });
          });
        props.setUserProfile(userInfo);
      }
    }
    if (!userInfo.password && !userInfo.confirmPassword) {
      setLoading(true);
      await updateProfile(userInfo, user.id)
        .then((res) => {
          setLoading(false);
          setDisabled(true);
          setShowAlertBanner(true, {
            message: "You have successfully updated your profile.",
            severity: "success"
          });
          if (res.status === 200 && res.data?.access_token) {
            cookies.remove("Access_Token", { path: "/" });
            cookies.set("Access_Token", res.data.access_token, {
              path: "/",
              domain: process.env.REACT_APP_SUBDOMAIN
            });
          }
        })
        .catch((e) => {
          console.error(e);
          setShowAlertBanner(true, {
            message:
              "There has been an error updating your profile. Please try again later.",
            severity: "error"
          });
        });
      props.setUserProfile(userInfo);
    }
  };

  return (
    <div className="card-style" style={{ width: "90%", paddingBottom: "60px" }}>
      <form onSubmit={handleSubmit}>
        <div>
          <div style={{ justifyContent: "left" }}>Name</div>
          <input
            className="form-control"
            type="text"
            placeholder="Enter Name"
            defaultValue={user.name}
            name="name"
            onChange={handleGetNewInfo}
          />
          <hr
            style={{
              borderTop: "1px solid #000000",
              opacity: ".1"
            }}
          />
        </div>
        <div>
          <div>Profile Image URL</div>
          <input
            className="form-control"
            type="text"
            placeholder="Enter an image url for profile image"
            defaultValue={user.avatar_url}
            onChange={handleGetNewInfo}
            name="avatar_url"
          />
          <div className="reminder-text" style={{ fontSize: "80%" }}>
            For best results use a square image.
          </div>
          <hr
            style={{
              borderTop: "1px solid #000000",
              opacity: ".1",
              marginBottom: ".5rem",
              marginTop: 0
            }}
          />
          <div className="change-password-wrapper">
            <div>New Password</div>
            <input
              className="form-control"
              id="password"
              type="password"
              name="password"
              placeholder="Enter New Password"
              onChange={handleGetNewInfo}
            />
            <ShowPassword />
            <div className={passwordError ? "password-error" : "reminder-text"}>
              Password must contain at least one lowercase character, one
              uppercase character, one number, one of the following special
              characters, !#$%&+-_?@~, and must be 8 or more characters.
            </div>
            <div>Confirm Password</div>
            <input
              className="form-control"
              id="confirmpassword"
              type="password"
              placeholder="Confirm New Password"
              name="confirmPassword"
              onChange={handleGetNewInfo}
            />
            <ShowConfirmPassword />
            <hr
              style={{
                borderTop: "1px solid #000000",
                opacity: ".1"
              }}
            />
            <button
              disabled={disabled}
              type="submit"
              className={
                disabled
                  ? "disabled-profile-update-button"
                  : "profile-update-button"
              }
              style={{ width: "92px" }}
            >
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden"></span>
                </>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ProfileUpdate;
