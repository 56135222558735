/* eslint-disable @typescript-eslint/no-explicit-any, no-undefined */
import { IUser, IUserGroup } from "../interfaces/user.interface";
import { IAppStore } from "../interfaces/app-store.interface";
import { IPDomain } from "../interfaces/pdomain.interface";
import { IAccount } from "../interfaces/account.interface";
import { IAlertBannerOptions } from "../interfaces/alert-banner-options";
import {
  ICbSubscription,
  IPortalURL
} from "../interfaces/subscription.interface";
import { IEntitlements } from "../interfaces/entitlements.interface";

const createAppStore: () => IAppStore = () => ({
  userAccounts: [] as IAccount[],
  userGroups: [] as IUserGroup[],
  selectedPidObject: {} as IPDomain,
  selectedAccountObject: {} as IAccount,
  passwordResetNotification: false,
  passwordResetSuccessNotification: false,
  selectedSubscriptionObject: {} as ICbSubscription,
  user: {} as IUser,
  isAdmin: false,
  sideMenuOpen: true,
  showAlertBanner: false,
  portalUrl: { account_id: null, url: "/billing-portal-error" },
  allowedUrls: [] as string[],
  alertBannerOptions: {} as IAlertBannerOptions,
  selectedPidEntitlements: {} as IEntitlements,
  setShowAlertBanner(bool: boolean, alertBannerOptions?: IAlertBannerOptions) {
    if (bool && alertBannerOptions) {
      this.showAlertBanner = true;
      this.alertBannerOptions = alertBannerOptions;
      setTimeout(() => {
        this.showAlertBanner = false;
        this.alertBannerOptions = {} as IAlertBannerOptions;
      }, 10000);
    } else {
      this.showAlertBanner = false;
      this.alertBannerOptions = {} as IAlertBannerOptions;
    }
  },
  setSelectedAccountObject(value: IAccount) {
    this.selectedAccountObject = value;
  },
  setUserAccounts(value: IAccount[]) {
    this.userAccounts = value;
  },
  setSelectedPidObject(value: IPDomain) {
    this.selectedPidObject = value;
  },
  setUser(value: IUser) {
    this.user = value;
  },
  setIsAdmin(value: boolean) {
    this.isAdmin = value;
  },
  setSideMenuOpen(value: boolean) {
    this.sideMenuOpen = value;
  },
  setPasswordResetNotification(value: boolean) {
    this.passwordResetNotification = value;
  },
  setPasswordResetSuccessNotification(value: boolean) {
    this.passwordResetSuccessNotification = value;
  },
  setPortalUrl(value: IPortalURL) {
    this.portalUrl = value;
  },
  setAllowedUrls(value: string[]) {
    this.allowedUrls = value;
  },
  setSelectedSubscriptionObject(value: ICbSubscription) {
    this.selectedSubscriptionObject = value;
  },
  setSelectedPidEntitlements(value: IEntitlements) {
    this.selectedPidEntitlements = value;
  },
  setUserGroups(value: IUserGroup[]) {
    this.userGroups = value;
  }
});

export default createAppStore;
