/* eslint-disable complexity */
import { postBlog, postSubPillar } from "@/api/post";
import { useAppStore, usePillarStrategyStore } from "@/hooks";
import { IAppStore } from "@/interfaces/app-store.interface";
import {
  IContentPillars,
  ISubPillars
} from "@/interfaces/content-pillars.interface";
import { IPillarStrategyStore } from "@/interfaces/pillar-strategy-store.interface";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Select from "react-select";

type axiosErrorMessage = {
  message: string;
};
type axiosResponseData = {
  errors: axiosErrorMessage[];
};

interface AddRowProps {
  setAddRow: (value: boolean) => void;
  pillar: IContentPillars;
  parentType: string;
  subPillar: ISubPillars;
  setSubPillarData?: (value: ISubPillars) => void;
}

type HandleChange = (event: React.ChangeEvent<HTMLInputElement>) => void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectOptionType = { label: string; value: any };

function AddRow({
  setAddRow,
  pillar,
  parentType,
  subPillar,
  setSubPillarData
}: AddRowProps) {
  const [postBody, setPostBody] = useState({
    id: null,
    url: "",
    topic: "",
    pillar_id: pillar.id,
    outline_status: null
  });
  const [selectedType, setSelectedType] = useState<SelectOptionType | null>(
    null
  );
  const [requestPending, setRequestPending] = useState(false);

  const { setShowAlertBanner, selectedPidObject } = useAppStore(
    (store: IAppStore) => ({
      setShowAlertBanner: store.setShowAlertBanner,
      selectedPidObject: store.selectedPidObject
    })
  );

  const { selectedPidPillarTopics, setSelectedPidPillarTopics } =
    usePillarStrategyStore((store: IPillarStrategyStore) => ({
      selectedPidPillarTopics: store.selectedPidPillarTopics,
      setSelectedPidPillarTopics: (value: IContentPillars[]) =>
        store.setSelectedPidPillarTopics(value)
    }));

  useEffect(() => {
    setPostBody((prevState) => ({
      ...prevState,
      pillar_id: pillar.id
    }));
    return () => {
      setPostBody({
        id: null,
        url: "",
        topic: "",
        pillar_id: pillar.id,
        outline_status: null
      });
    };
  }, [pillar]);

  const handlePostRow = async () => {
    setRequestPending(true);
    if (!postBody.topic?.length) {
      setShowAlertBanner(true, {
        message: `Error adding ${
          selectedType?.value === "blog" ? "Blog" : "Sub-Pillar"
        }, please enter a topic.`,
        severity: "error"
      });
      setRequestPending(false);
      return;
    }
    const pillarCopy = { ...pillar };
    if (parentType === "subPillar") {
      const subPillarCopy = { ...subPillar };
      try {
        const response = await postBlog(
          {
            ...postBody,
            pillar_id: pillar.id ? pillar.id : 0,
            sub_pillar_id: subPillar?.id ? subPillar.id : null
          },
          selectedPidObject.id
        );
        if (subPillarCopy.blogs) {
          subPillarCopy.blogs.push(response);
        }
        if (setSubPillarData) {
          setSubPillarData(subPillarCopy);
        }

        setAddRow(false);
      } catch (e) {
        console.error(e);
        const err = e as AxiosError;
        const data = err.response?.data as axiosResponseData;
        if (data?.errors?.length > 0) {
          const message = data.errors[0].message;
          if (message.includes("topic must be unique")) {
            setShowAlertBanner(true, {
              message: `Error adding blog, topic must be unique.`,
              severity: "error"
            });
            setRequestPending(false);
            return;
          }
        }

        setShowAlertBanner(true, {
          message: `Error adding blog, please try again. If problem persists please contact engineering team`,
          severity: "error"
        });
      }
    } else if (parentType === "pillar" && selectedType?.value === "subPillar") {
      try {
        const subPillarResponse = await postSubPillar(
          {
            ...postBody,
            pillar_id: pillar.id || 0
          },
          selectedPidObject.id
        );
        if (pillarCopy?.sub_pillars) {
          pillarCopy.sub_pillars.push(subPillarResponse);
        }
        setSelectedPidPillarTopics(
          selectedPidPillarTopics.map((item: IContentPillars) => {
            if (item.id === pillar.id) {
              return pillarCopy;
            }
            return item;
          })
        );
        setPostBody({
          id: null,
          url: "",
          topic: "",
          pillar_id: pillar.id,
          outline_status: null
        });
        setAddRow(false);
      } catch (e) {
        console.error(e);
        console.error(e);
        const err = e as AxiosError;
        const data = err.response?.data as axiosResponseData;
        if (data?.errors?.length > 0) {
          const message = data.errors[0].message;
          if (message.includes("topic must be unique")) {
            setShowAlertBanner(true, {
              message: `Error adding sub pillar, topic must be unique.`,
              severity: "error"
            });
            setRequestPending(false);
            return;
          }
        }
        setShowAlertBanner(true, {
          message: `Error saving sub pillar. Please contact engineering team if problem persists.`,
          severity: "error"
        });
      }
    } else if (parentType === "pillar" && selectedType?.value === "blog") {
      try {
        const blogResponse = await postBlog(
          {
            ...postBody,
            pillar_id: pillar.id || 0,
            sub_pillar_id: null
          },
          selectedPidObject.id
        );
        if (pillarCopy?.blogs) {
          pillarCopy.blogs.push(blogResponse);
        }
        setSelectedPidPillarTopics(
          selectedPidPillarTopics.map((item: IContentPillars) => {
            if (item.id === pillar.id) {
              return pillarCopy;
            }
            return item;
          })
        );
        setAddRow(false);
      } catch (e) {
        console.error(e);
        const err = e as AxiosError;
        const data = err.response?.data as axiosResponseData;
        if (data?.errors?.length > 0) {
          const message = data.errors[0].message;
          if (message.includes("topic must be unique")) {
            setShowAlertBanner(true, {
              message: `Error adding blog, topic must be unique.`,
              severity: "error"
            });
            setRequestPending(false);
            return;
          }
        }
        setShowAlertBanner(true, {
          message: `Error saving blog. Please contact engineering team if problem persists.`,
          severity: "error"
        });
      }
    }
    setRequestPending(false);
  };

  const handleNewChildChange: HandleChange = (e) => {
    const { name, value } = e.target;
    setPostBody((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleSelectType = (newValue: SelectOptionType | null) => {
    if (newValue) {
      setSelectedType(newValue);
    }
  };
  return (
    <tr style={{ height: "58px" }} data-testid="add-row">
      <th
        style={{
          paddingLeft: parentType === "pillar" ? "22px" : "83px"
        }}
      >
        <input
          type="text"
          name="topic"
          id="topic"
          placeholder="Name"
          value={postBody.topic}
          onChange={handleNewChildChange}
        />
      </th>
      <th className="fw-400">
        {parentType === "pillar" ? (
          <div className="fw-400">
            <Select
              id="type-select"
              name=""
              options={[
                { value: "subPillar", label: "Sub-Pillar" },
                { value: "blog", label: "Blog" }
              ]}
              defaultValue={{ value: "blog", label: "Blog" }}
              isDisabled={requestPending}
              onChange={handleSelectType}
              value={selectedType}
              classNamePrefix="pillar-strategy-select"
            />
          </div>
        ) : (
          <div>Blog</div>
        )}
      </th>
      <th></th>
      <th>
        <input
          type="text"
          name="url"
          id="url"
          placeholder="URL"
          value={postBody.url}
          onChange={handleNewChildChange}
        />
        {requestPending ? (
          <span
            style={{
              color: "#373f50",
              width: "20px",
              height: "20px",
              margin: "0px 9px"
            }}
          >
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          </span>
        ) : (
          <span
            onClick={() => {
              handlePostRow();
            }}
            style={{ padding: "0px 5px" }}
          >
            <FontAwesomeIcon
              className="icon-button"
              icon={faCheck}
              style={{ width: "24px" }}
            />
          </span>
        )}
        <span
          onClick={() => {
            setAddRow(false);
          }}
          style={{ padding: "5px" }}
        >
          <FontAwesomeIcon
            className="icon-button"
            icon={faX}
            style={{ width: "24px" }}
          />
        </span>
      </th>
    </tr>
  );
}

AddRow.defaultProps = {
  subPillar: {
    id: null,
    topic: "",
    pillar_id: 0,
    url: "",
    outline_status: null
  }
};

export default AddRow;
