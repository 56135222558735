import { getSavedBrief } from "@/api/get";
import { useAppStore, useContentBriefsStore, useEffectAsync } from "@/hooks";
import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";
import { ISavedBrief } from "@/interfaces/saved_outline.interface";
import { useState } from "react";
import "../step-three.scss";
import { IAppStore } from "@/interfaces/app-store.interface";
import { INotes } from "@/interfaces/notes.interface";
import { addOrUpdateNotes } from "@/api/post";

const AddNoteModal = () => {
  const {
    showAddNoteModal,
    setShowAddNoteModal,
    briefId,
    notes,
    setNotes
  }: IContentBriefsStore = useContentBriefsStore(
    (store: IContentBriefsStore) => ({
      showAddNoteModal: store.showAddNoteModal,
      briefId: store.briefId,
      notes: store.notes,
      setNotes: (value: INotes[]) => store.setNotes(value),
      setShowAddNoteModal: (value: boolean) => store.setShowAddNoteModal(value)
    })
  );
  const { setShowAlertBanner } = useAppStore((store: IAppStore) => ({
    setShowAlertBanner: store.setShowAlertBanner
  }));
  const [brief, setBrief] = useState<ISavedBrief>();
  const [note, setNote] = useState("");

  useEffectAsync(async () => {
    if (briefId) {
      const foundBrief = await getSavedBrief(briefId);
      if (foundBrief) {
        setBrief(foundBrief);
      }
    }
  }, [notes, briefId]);
  const closeModal = () => {
    setShowAddNoteModal(false);
  };

  const handleAddNote = async () => {
    if (brief?.outline_status_id && note) {
      const resp = await addOrUpdateNotes(brief.outline_status_id, null, note);
      if (resp) {
        const allNotes = [resp.data, ...notes];
        setNotes(allNotes);
        setShowAddNoteModal(false);
        setShowAlertBanner(true, {
          message: `Successfully added a new note`,
          severity: "success"
        });
      } else {
        setShowAddNoteModal(false);

        setShowAlertBanner(true, {
          message: `Error saving note, please try again. If problem persists please contact customer success.`,
          severity: "error"
        });
      }
    } else if (brief?.outline_status_id && !note) {
      setShowAlertBanner(true, {
        message: `To save a note, it cannot be empty`,
        severity: "error"
      });
    }
  };

  return (
    <>
      {showAddNoteModal && (
        <div
          className="shadow bg-body rounded"
          style={{
            position: "fixed",
            width: "548px",
            height: "310px",
            left: "calc(50% - 182px)",
            top: "calc(50% - 167px)",
            background: "#FFFFFF",
            borderRadius: "3px",
            border: "1px solid #E9EBF0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 4
          }}
        >
          <div
            style={{
              display: "flex",
              width: "548px",
              height: 20,
              justifyContent: "flex-end"
            }}
          >
            <button
              className="btn btn-close"
              style={{
                padding: 15,
                fontSize: 14
              }}
              onClick={closeModal}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <div
              style={{
                color: "#373F50",
                paddingBottom: "10px",
                fontSize: 24
              }}
            >
              <div>Add New Note</div>
            </div>
            <textarea
              onChange={(e) => setNote(e.target.value)}
              style={{
                width: "431px",
                height: "128px",
                fontSize: "14px",
                color: "#373F50",
                border: "1px solid #D3D7E0",
                borderRadius: 4,
                resize: "none",
                outline: "none",
                paddingTop: "10px",
                paddingLeft: "10px"
              }}
              placeholder="Add notes for your writer..."
              className="italic-placeholder"
            ></textarea>
          </div>
          <div
            style={{
              position: "relative",
              paddingTop: "34px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "240px"
            }}
          >
            <button
              style={{
                width: "100px",
                height: "48px",
                background: "#FFFFFF",
                fontFamily: "Source Sans Pro",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20px",
                textAlign: "center",
                letterSpacing: "0.005em",
                color: "#373F50",
                borderRadius: 3
              }}
              className="btn btn-secondary"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              style={{
                width: "121px",
                height: "48px",
                background: "#008CCE",
                borderRadius: "3px",
                fontFamily: "Source Sans Pro",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20px",
                textAlign: "center",
                letterSpacing: "0.005em",
                color: "#FFFFFF"
              }}
              className="btn"
              onClick={handleAddNote}
            >
              Add Note
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default AddNoteModal;
