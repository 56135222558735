import PropTypes from "prop-types";

interface SubHeaderProps {
  title: string;
}
function SubHeader({ title }: SubHeaderProps) {
  return <div className="rd-subheader">{title}</div>;
}
SubHeader.propTypes = {
  title: PropTypes.string
};

SubHeader.defaultProps = {
  title: ""
};
export default SubHeader;
