import { useState, useEffect } from "react";

import { Badge } from "react-bootstrap";

const ProgressBadge = (props: {
  status: string;
  percentage: number;
  queryString: string;
}) => {
  const [processing, useProcessing] = useState<boolean>(false);
  const [percentageComplete, usePercentageComplete] = useState(
    props.percentage
  );
  const [badgeClass, useBadgeClass] = useState("success");
  useEffect(() => {
    if (props.status === "complete") {
      useBadgeClass("success");
      useProcessing(false);
    } else if (props.status === "pending") {
      useBadgeClass("primary");
    } else if (props.status === "working") {
      useProcessing(true);
      if (props.percentage <= 0) {
        usePercentageComplete(1);
      }
      if (props.percentage >= 100) {
        usePercentageComplete(99);
      } else {
        usePercentageComplete(props.percentage);
      }
    } else {
      useBadgeClass("danger");
    }
  }, [props.percentage, props.status]);

  return (
    <div className="float-left" style={{ paddingRight: "10px", float: "left" }}>
      {!processing && <Badge bg={badgeClass}>{props.status}</Badge>}
      {processing && (
        <div>
          <div className="progress-bar-lg progress">
            <div
              className="progress-bar progress-bar-animated progress-bar-striped"
              role="progressbar"
              style={{
                width: `${percentageComplete}%`
              }}
            ></div>
          </div>
          <span style={{ fontWeight: "bold" }}>
            Working... {percentageComplete}%
          </span>
        </div>
      )}
    </div>
  );
};

export default ProgressBadge;
