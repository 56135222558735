import { useState, useEffect } from "react";
import {
  IOrderedSearchtermFormatted,
  IOrderedSections
} from "@/interfaces/outline.interface";
import { stepThreeStyles } from "../utils/style";

const H2List = ({ orderedSections }: { orderedSections: IOrderedSections }) => {
  // loop the the ordered sections, making a nested array of the search terms
  // [ [{body_order: 0, order_within_section:0, search_term: "something", search_term_id: 123}, {body_order: 0, order_within_section: 1, ...}], [],  []]
  const [searchTerms, setSearchTerms] = useState<
    IOrderedSearchtermFormatted[][]
  >([]);
  // we are looping through the search terms, making a nested array of the search terms that have the same body_order
  useEffect(() => {
    if (orderedSections) {
      const list = [] as IOrderedSearchtermFormatted[][];
      orderedSections.body.forEach((outterLoopEl) => {
        if (outterLoopEl.order_within_section === 0) {
          const subList = [] as IOrderedSearchtermFormatted[];
          orderedSections.body.forEach((innerLoopEl) => {
            if (outterLoopEl.body_order === innerLoopEl.body_order) {
              subList.push(innerLoopEl);
            }
          });
          list.push(subList);
        }
      });
      setSearchTerms(list);
    }
  }, [orderedSections]);
  return (
    <div data-testid="outline-h2-list">
      <>
        {searchTerms.map((groupedSearchTerms, index) => (
          <div key={index}>
            <div
              className="row row-padding-top-20"
              style={stepThreeStyles.rowPaddingTop20}
            >
              <div
                className="outline-title"
                style={stepThreeStyles.outlineTitle}
              >
                H2:{" "}
                {groupedSearchTerms?.map((searchTerm, idx) => {
                  if (searchTerm?.order_within_section === 0) {
                    return (
                      <span data-testid="h2-list-primary" key={idx}>
                        {searchTerm?.search_term?.query}
                      </span>
                    );
                  }
                  return false;
                })}
              </div>
            </div>
            {groupedSearchTerms?.length > 1 && (
              <>
                <div
                  className="row row-padding-top-20"
                  style={stepThreeStyles.rowPaddingTop20}
                >
                  <div
                    className="outline-subtitle"
                    style={stepThreeStyles.outlineSubtitle}
                  >
                    Search terms to include:
                  </div>
                </div>
                <div
                  className="row row-padding-top-20"
                  style={
                    (stepThreeStyles.rowPaddingTop20, { marginLeft: "20px" })
                  }
                >
                  <ul>
                    {groupedSearchTerms?.map((searchTerm, idx) => {
                      if (searchTerm?.order_within_section !== 0) {
                        return (
                          <li key={idx}>{searchTerm?.search_term?.query}</li>
                        );
                      }
                      return false;
                    })}
                  </ul>
                </div>
              </>
            )}
          </div>
        ))}
      </>
    </div>
  );
};

export default H2List;
