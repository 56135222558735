import { useNavigate } from "react-router-dom";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./accept-invitation.scss";
import { postAcceptInvite } from "../../../api/post";
import { useAppStore } from "../../../hooks";
import { IAppStore } from "../../../interfaces/app-store.interface";
import { IAlertBannerOptions } from "../../../interfaces/alert-banner-options";
import Cookies from "universal-cookie";
import isEmpty from "lodash/isEmpty";
import ShowPassword from "@/components/shared/ShowPassword";
import ShowConfirmPassword from "@/components/shared/ShowConfirmPassword";
interface IAcceptInvitationForm {
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  stayLoggedIn: boolean;
  acceptTOS: boolean;
}

const validationSchema = Yup.object({
  firstName: Yup.string()
    .max(100, "Must be 100 characters or less")
    .required("Enter your first name"),
  lastName: Yup.string()
    .max(100, "Must be 100 characters or less")
    .required("Enter your last name"),
  password: Yup.string()
    .required("Enter your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!#$%&+\\-_?@~])(?=.{8,})/u,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: Yup.string()
    .required("Confirm your password")
    .oneOf([Yup.ref("password"), ""], "Passwords must match")
});

const AcceptInvitationForm = ({ email }: { email: string }) => {
  const { setShowAlertBanner }: IAppStore = useAppStore((store: IAppStore) => ({
    setShowAlertBanner: (
      bool: boolean,
      alertBannerOptions: IAlertBannerOptions
    ) => store.setShowAlertBanner(bool, alertBannerOptions)
  }));

  const navigate = useNavigate();

  const handleSubmit = async ({
    firstName,
    lastName,
    password,
    confirmPassword,
    stayLoggedIn,
    acceptTOS
  }: IAcceptInvitationForm) => {
    const body = {
      firstName,
      lastName,
      password,
      confirmPassword,
      stayLoggedIn,
      acceptTOS,
      email
    };
    try {
      const response = await postAcceptInvite(body);
      if (response?.data?.access_token) {
        const cookies = new Cookies();
        cookies.remove("Access_Token", { path: "/" });
        cookies.set("Access_Token", response.data.access_token, {
          path: "/",
          domain: process.env.REACT_APP_SUBDOMAIN
        });
        navigate("/home");
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
      setShowAlertBanner(true, {
        message: "Failed to validate invitation",
        severity: "error"
      });
    }
  };
  return (
    <div className="d-flex justify-content-center align-items-center">
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          password: "",
          confirmPassword: "",
          stayLoggedIn: false,
          acceptTOS: false
        }}
        validateOnBlur
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue }) => (
          <Form style={{ width: "65%" }}>
            <div className="form-group p-2">
              <label htmlFor="firstname">First Name:</label>
              <Field
                type="text"
                className="form-control"
                id="firstname"
                name="firstName"
              />
              <ErrorMessage name="firstName">
                {(msg) => <small className="error-message">{msg}</small>}
              </ErrorMessage>
            </div>
            <div className="form-group p-2">
              <label htmlFor="lastname">Last Name:</label>
              <Field
                type="text"
                className="form-control"
                id="lastname"
                name="lastName"
              />
              <ErrorMessage name="lastName">
                {(msg) => <small className="error-message">{msg}</small>}
              </ErrorMessage>
            </div>
            <div className="form-group p-2">
              <label htmlFor="password">Password:</label>
              <Field
                type="password"
                className="form-control"
                id="password"
                name="password"
              />
              <ShowPassword />
              <ErrorMessage name="password">
                {(msg) => <small className="error-message">{msg}</small>}
              </ErrorMessage>
              <small className="form-text text-muted p-2">
                Password must contain at least one lowercase character, one
                uppercase character, one number, one of the following special
                characters, !#$%&amp;+-_?@~, and must be 8 or more characters.
              </small>
            </div>

            <div className="form-group p-2">
              <label htmlFor="confirmpassword">Confirm Password:</label>
              <Field
                type="password"
                className="form-control"
                id="confirmpassword"
                name="confirmPassword"
              />
              <ShowConfirmPassword />
              <ErrorMessage name="confirmPassword">
                {(msg) => <small className="error-message">{msg}</small>}
              </ErrorMessage>
            </div>
            <div className="form-group p-2">
              <input
                className="form-check-input mr-3"
                type="checkbox"
                name="stayLoggedIn"
                checked={values.stayLoggedIn}
                onChange={(e) =>
                  setFieldValue("stayLoggedIn", e.target.checked)
                }
              />
              {"  "}
              <span className="ml-3">Stay logged in for 30 days</span>
            </div>
            <div className="form-group p-2">
              <input
                className="form-check-input mr-3"
                type="checkbox"
                name="acceptTOS"
                checked={values.acceptTOS}
                onChange={(e) => setFieldValue("acceptTOS", e.target.checked)}
              />
              {"  "}
              <span className="ml-3">
                Accept our{" "}
                <a href="https://www.demandjump.com/legal" target="_blank">
                  Terms and Conditions
                </a>{" "}
                and our{" "}
                <a href="https://www.demandjump.com/legal" target="_blank">
                  Privacy Policy
                </a>{" "}
              </span>
            </div>
            <button
              disabled={
                isEmpty(values.firstName) ||
                isEmpty(values.lastName) ||
                isEmpty(values.password) ||
                isEmpty(values.confirmPassword) ||
                !values.acceptTOS
              }
              type="submit"
              className="btn primary signup-buttons w-100"
            >
              Activate Account
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AcceptInvitationForm;
