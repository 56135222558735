import jsPDF from "jspdf";
import { convert } from "html-to-text";
import { asBlob } from "html-docx-js-typescript";
import { saveAs } from "file-saver";

const downloadTxt = (markup: HTMLElement, title: string) => {
  const options = {
    wordwrap: 130,
    selectors: [{ selector: "img", format: "skip" }]
  };
  const html = markup.outerHTML;
  const text = convert(html, options);

  const link = document.createElement("a");
  const file = new Blob([text], { type: "text/plain" });
  link.href = URL.createObjectURL(file);
  link.download = title;
  link.click();
  URL.revokeObjectURL(link.href);
};

const downloadDocx = (markup: HTMLElement, title: string) => {
  const htmlString = `<!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    <title>${title}</title>
  </head>
  <body>
    ${markup.outerHTML}
  </body>
  </html>`;

  asBlob(htmlString).then((data) => {
    saveAs(data as Blob, title);
  });
};

const downloadPDF = (markup: HTMLElement, title: string) => {
  const doc = new jsPDF("p", "pt", "a4");
  doc.setFontSize(14);
  doc.setFont("Inter-Regular", "normal");

  doc.html(`<div style="width:860px;>${markup.outerHTML}</div>`, {
    margin: [10, 10, 20, 10],
    autoPaging: "text",
    html2canvas: {
      scale: 0.7
    },
    x: 0,
    y: 0,
    callback(pdf) {
      pdf.save(title);
    }
  });
};

export const downloadBrief = (
  contentTitle: string,
  markup: HTMLElement,
  format: string
) => {
  let title = contentTitle.replace(/ /g, "_");

  switch (format) {
    case "docx":
      title += ".docx";
      break;
    case "pdf":
      title += ".pdf";
      break;
    case "txt":
      title += ".txt";
      break;
    default:
      return;
  }

  if (format === "docx") {
    downloadDocx(markup, title);
  } else if (format === "pdf") {
    downloadPDF(markup, title);
  } else if (format === "txt") {
    downloadTxt(markup, title);
  }
};
