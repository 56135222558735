import React, { SyntheticEvent, useRef, useState } from "react";
import { DjInput } from "../../shared/DjInput";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { ISignupDetails } from "../../../interfaces/signup-details.interface";
import { checkDomain, formatDomain } from "../../../utilities/domain";
import { checkRecaptcha } from "../../../api/post";
import CircularProgress from "@mui/material/CircularProgress";

interface stepOneProps {
  setStep: (value: number) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  signupDetails: ISignupDetails;
  handleDomainFormatChange: (value: string) => void;
}
const StepOne = ({
  setStep,
  handleChange,
  signupDetails,
  handleDomainFormatChange
}: stepOneProps) => {
  const [domainError, setDomainError] = useState(false);
  const [loading, setLoading] = useState(false);
  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY || "";
  const captchaRef = useRef<ReCAPTCHA>(null);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    if (checkDomain(signupDetails.domain)) {
      setDomainError(false);
      if (captchaRef?.current) {
        const token = captchaRef.current.getValue();
        captchaRef.current.reset();
        if (token) {
          handleDomainFormatChange(formatDomain(signupDetails.domain));
          await checkRecaptcha(token)
            .then((resp) => {
              if (resp.status === 200) {
                setStep(2);
                return;
              }
            })
            .catch((err) => {
              console.error(err);
            });
          captchaRef.current.reset();
        }
      }
    } else {
      setDomainError(true);
      setLoading(false);
    }
  };
  return (
    <div className="step-container">
      <div className="su-header">Welcome To DemandJump</div>
      <div className="su-sub-header">Step 1 of 3</div>
      <div className="help-text">
        It only takes a minute to create your account, and no credit card is
        required.
      </div>
      <form className="su-form" onSubmit={handleSubmit}>
        <div style={{ marginBottom: "36px" }}>
          <DjInput
            type="text"
            width="402px"
            error={domainError}
            height="48px"
            placeholder="Company Website"
            name="domain"
            value={signupDetails.domain}
            onChange={handleChange}
            disabled={loading}
            required={true}
            autoFocus={true}
          />
          {domainError && (
            <div className="error-label">Please format as a valid domain</div>
          )}
        </div>
        <DjInput
          type="text"
          width="402px"
          height="48px"
          placeholder="ex: running shoes"
          name="keyword"
          value={signupDetails.keyword}
          onChange={handleChange}
          disabled={loading}
          required={true}
        />
        <div className="phrase-label">
          Enter keyword or phrase you want to rank for in search engines.
        </div>
        <div className="checkbox-label-container">
          <input
            className="tc-check"
            type="checkbox"
            id="terms"
            name="acceptedTOS"
            onChange={handleChange}
            disabled={loading}
            required
          />
          <label className="tc-label" htmlFor="terms">
            I agree to the{" "}
            <a href={process.env.REACT_APP_TAC_URL} target="_blank">
              Terms and Conditions
            </a>{" "}
            and{" "}
            <a href={process.env.REACT_APP_PP_URL} target="_blank">
              Privacy Policy
            </a>
            .
          </label>
        </div>
        <ReCAPTCHA sitekey={recaptchaKey} ref={captchaRef} />
        <div className="button-text">
          <div className="sign-in-text">
            Already have an account? <Link to={"/"}>Sign In</Link>
          </div>
          <button className="signup-button" disabled={loading} type="submit">
            {loading ? <CircularProgress /> : "Next"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default StepOne;
