import { Grid } from "@mui/material";
import { useState } from "react";

import DJLogo from "../../assets/DJ-Horizontal-registered.png";
import DJLoadingGif from "../shared/DJLoadingGif";

import EmailLogin from "./Component/EmailLogin";
import SideGallery from "./Component/SideGallery";
import "./login.scss";

const imageStyle: React.CSSProperties = {
  position: "absolute",
  top: "30px",
  left: "30px",
  width: "289px"
};

const Login = () => {
  const [loading, setLoading] = useState(false);

  return (
    <div id="login-container">
      {loading ? (
        <div
          style={{
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <DJLoadingGif />
        </div>
      ) : (
        <div>
          <img
            style={{ ...imageStyle }}
            className="login-logo"
            src={DJLogo}
            alt="DJ Logo"
          />
          <Grid container justifyContent="center" alignItems="center">
            <Grid item md={6} xs={12}>
              <EmailLogin setLoading={setLoading}></EmailLogin>
            </Grid>
            <Grid item md={6} xs={12}>
              <SideGallery></SideGallery>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default Login;
