/* eslint-disable complexity */
/* eslint-disable max-lines */
import { useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";

import appTheme from "./themes/theme";
import "./App.css";
import "./_global.scss";
import { useAppStore } from "./hooks";
import HealthCheck from "./healthcheck";
import PrivateRoute from "./PrivateRoute";
import { IAppStore } from "./interfaces/app-store.interface";

import MiniDrawer from "./components/navigation";
import Dashboard from "./components/Dashboard";
import Login from "./components/login/Login";
import HTTPError from "./components/HTTPError";
import PortalError from "./components/PortalError";
import ForgotPasswordContainer from "./components/login/ForgotPasswordContainer";
import ResetPasswordContainer from "./components/login/ResetPasswordContainer";
import MFAValidate from "./components/login/Component/MfaValidation";
import Home from "./components/home/Home";
import ManageProfile from "./components/manage_profile/ManageProfile";
import ContentBriefsList from "./components/manage_content/content_brief_list/ContentBriefsList";
import ContentBriefEditor from "./components/manage_content/content_brief/ContentBriefEditor";
import Subscription from "./components/settings/Subscription/Subscription";
import ManageUsers from "./components/settings/ManageUsers/index";
import Backoffice from "./components/settings/Backoffice/Backoffice";
import Keywords from "./components/settings/Keywords/Keywords";
import Competitors from "./components/settings/Competitors/Competitors";
import ContentPillars from "./components/settings/pillar_topics/ContentPillars";
import PillarStrategy from "./components/pillar_strategy/index";
import Insights from "./components/insights/Insights";
import Signup from "./components/signup/Signup";
import AcceptInvitation from "./components/login/AcceptInvitation/index";
import VerifyEmailLoader from "./components/login/Component/VerifyEmailLoader";
import ManagePillarTopics from "./components/pillar_marketing/ManagePillarTopics";
import KeywordTrends from "./components/keyword_marketing/KeywordTrends";
import PaidChannels from "./components/rd_marketing/PaidChannels";
import OrganicChannels from "./components/rd_marketing/OrganicChannels";
import { GoogleTagManager } from "./components/shared/GoogleTagManager";
import DJUniversitySSOComponent from "./components/shared/DJUniversitySSOComponent";
import ContentBriefWorkflowStepOne from "./components/manage_content/content_brief/content_brief_workflow/step_one/StepOne";
import ContentBriefWorkflowStepTwo from "./components/manage_content/content_brief/content_brief_workflow/step_two/StepTwo";
import ContentBriefWorkflowStepThree from "./components/manage_content/content_brief/content_brief_workflow/step_three/StepThree";
import EmailHelpForm from "./emailForm";

const cookies = new Cookies();
const App = () => {
  const {
    user,
    selectedPidObject,
    selectedSubscriptionObject,
    selectedAccountObject
  } = useAppStore((store: IAppStore) => ({
    user: store.user,
    selectedAccountObject: store.selectedAccountObject,
    selectedPidObject: store.selectedPidObject,
    selectedSubscriptionObject: store.selectedSubscriptionObject
  }));

  const location = useLocation();
  const domainName = selectedPidObject?.domain?.name;

  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    window.addEventListener("error", (event) => {
      event.preventDefault();
      if (event.message) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }

  useEffect(() => {
    // call google tag manager
    GoogleTagManager(
      selectedPidObject,
      domainName,
      user,
      selectedSubscriptionObject,
      selectedAccountObject
    );
  }, [domainName, selectedSubscriptionObject, selectedPidObject, location]);

  useEffect(() => {
    document.title = "DemandJump - Get Insights. Drive Outcomes.™";
  });

  useEffect(() => {
    cookies.remove("Topic", { path: "/" });
    cookies.remove("PillarTopic", { path: "/" });
    cookies.remove("BlockList", { path: "/" });
  }, [selectedPidObject.id]);

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline enableColorScheme />
      <Routes>
        <Route path="/healthcheck" element={<HealthCheck />} />
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route
          path="/public/content-brief/:id"
          element={<ContentBriefWorkflowStepThree />}
        />
        <Route path="/" element={<MiniDrawer />}>
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/help/dj-university"
            element={
              <PrivateRoute>
                <DJUniversitySSOComponent />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/subscription"
            element={
              <PrivateRoute>
                <Subscription />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/manage-users"
            element={
              <PrivateRoute>
                <ManageUsers />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/admin-panel/backoffice"
            element={
              <PrivateRoute>
                <Backoffice />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/keywords"
            element={
              <PrivateRoute>
                <Keywords />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/competitors"
            element={
              <PrivateRoute>
                <Competitors />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/content-pillars"
            element={
              <PrivateRoute>
                <ContentPillars />
              </PrivateRoute>
            }
          />
          <Route
            path="/insights"
            element={
              <PrivateRoute>
                <Insights />
              </PrivateRoute>
            }
          />
          <Route
            path="/manage-profile"
            element={
              <PrivateRoute>
                <ManageProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/pillar-strategy"
            element={
              <PrivateRoute>
                <PillarStrategy />
              </PrivateRoute>
            }
          />
          <Route
            path="/upgrade/manage-pillar-topics"
            element={
              <PrivateRoute>
                <ManagePillarTopics />
              </PrivateRoute>
            }
          />
          <Route
            path="/upgrade/keyword-trends"
            element={
              <PrivateRoute>
                <KeywordTrends />
              </PrivateRoute>
            }
          />
          <Route
            path="/upgrade/paid-channels"
            element={
              <PrivateRoute>
                <PaidChannels />
              </PrivateRoute>
            }
          />
          <Route
            path="/upgrade/organic-channels"
            element={
              <PrivateRoute>
                <OrganicChannels />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/:dashboard_name"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/content-briefs"
            element={
              <PrivateRoute>
                <ContentBriefsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/content_briefs"
            element={<Navigate to="/content-briefs" />}
          />
          <Route
            path="/content-briefs/:id"
            element={
              <PrivateRoute>
                <ContentBriefEditor />
              </PrivateRoute>
            }
          />
          <Route
            path="/content_briefs/:id"
            element={<Navigate to="/content-briefs" />}
          />
          <Route
            path="/content-briefs-workflow/step-one/:id"
            element={
              <PrivateRoute>
                <ContentBriefWorkflowStepOne />
              </PrivateRoute>
            }
          />
          <Route
            path="/content-briefs-workflow/step-two/:id"
            element={
              <PrivateRoute>
                <ContentBriefWorkflowStepTwo />
              </PrivateRoute>
            }
          />
          <Route
            path="/content-briefs-workflow/step-three/:id"
            element={
              <PrivateRoute>
                <ContentBriefWorkflowStepThree />
              </PrivateRoute>
            }
          />
          <Route
            path="/unauthorized"
            element={
              <HTTPError
                title={"Oops... Looks like you don't have access to this page."}
              />
            }
          />
          <Route path="/billing-portal-error" element={<PortalError />} />
        </Route>
        <Route
          path="/access/invitation/:invite_code"
          element={<AcceptInvitation />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/mfa" element={<MFAValidate />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/access/verify-email" element={<VerifyEmailLoader />} />
        <Route
          path="/dashboard/:dashboard_name"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route path="/forgot-password" element={<ForgotPasswordContainer />} />
        <Route path="/reset-password" element={<ResetPasswordContainer />} />
        <Route
          path="*"
          element={<HTTPError title={"Oops... This page doesn't exist."} />}
        />
      </Routes>
      <EmailHelpForm></EmailHelpForm>
    </ThemeProvider>
  );
};

export default App;
