import { useState } from "react";
import InsightsTable from "./components/InsightsTable";
import "./insights.scss";
import Title from "./components/Title";
import InfoMessage from "./components/InfoMessage";
import Divider from "@mui/material/Divider";
import GenerateInsight from "./components/GenerateInsight";
import { useAppStore, useCompetitorsStore, useEffectAsync } from "@/hooks";
import { ICompetitorsStore } from "@/interfaces/competitors-store.interface";
import NoCompetitorsComponent from "./components/NoCompetitorsComponent";
import { ICompetitorsItem } from "@/interfaces/competitors.interface";
import { IErrorInsightArray } from "@/interfaces/insights.interface";
import { getCompetitorsByPid } from "@/api/get";
import { IAppStore } from "@/interfaces/app-store.interface";
import DJLoadingGif from "../shared/DJLoadingGif";

const Insights = () => {
  const [loading, setLoading] = useState(true);
  const [errorArr, setErrorArr] = useState<IErrorInsightArray[]>([]);
  const updateErrorArr = (errorItem: IErrorInsightArray[]): void => {
    setErrorArr(errorItem);
  };
  const { competitors, setCompetitors } = useCompetitorsStore(
    (store: ICompetitorsStore) => ({
      competitors: store.competitors,
      setCompetitors: (value: ICompetitorsItem[]) => store.setCompetitors(value)
    })
  );
  const { selectedPidObject } = useAppStore((store: IAppStore) => ({
    selectedPidObject: store.selectedPidObject
  }));

  useEffectAsync(async () => {
    if (selectedPidObject.id) {
      setLoading(true);
      // get competitors from api
      const response = await getCompetitorsByPid(selectedPidObject.id);
      setCompetitors(response);
      setLoading(false);
    }
  }, [selectedPidObject]);

  return (
    <div className="insights-page-root">
      <Title />
      <div className="generate-insight-width-control">
        <GenerateInsight errorArr={errorArr} updateErrorArr={updateErrorArr} />
      </div>
      <Divider sx={{ color: "#D3D7E0", marginBottom: "24.5px" }} />
      {loading ? (
        <DJLoadingGif />
      ) : (
        <>
          {competitors.length === 0 ? (
            <NoCompetitorsComponent page="Insights" />
          ) : (
            <>
              <InfoMessage />
              <InsightsTable errorArr={errorArr} />
            </>
          )}
        </>
      )}
    </div>
  );
};
export default Insights;
