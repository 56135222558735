/* eslint-disable max-lines */
import { useAppStore, usePillarStrategyStore } from "@/hooks";
import { IAppStore } from "@/interfaces/app-store.interface";
import { IAlertBannerOptions } from "@/interfaces/alert-banner-options";
import { Dispatch, SetStateAction } from "react";
import {
  IBlogs,
  IContentPillars,
  ISubPillars
} from "@/interfaces/content-pillars.interface";
import { putPromoteBlogs, putDemoteSubPillar } from "@/api/put";
import {
  SelectedItem,
  IPillarStrategyStore
} from "@/interfaces/pillar-strategy-store.interface";

const MoveItemsModal = ({
  items,
  pillar,
  pid,
  setShowConvertItemsModal
}: {
  items: SelectedItem;
  pillar: IContentPillars;
  pid: number;
  setShowConvertItemsModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const { setSelectedPidPillarTopics }: IPillarStrategyStore =
    usePillarStrategyStore((store: IPillarStrategyStore) => ({
      setSelectedPidPillarTopics: (value: IContentPillars[]) =>
        store.setSelectedPidPillarTopics(value)
    }));

  const { setSelectedTopics } = usePillarStrategyStore(
    (store: IPillarStrategyStore) => ({
      setSelectedTopics: (value: SelectedItem) => store.setSelectedTopics(value)
    })
  );

  const { setShowAlertBanner } = useAppStore((store: IAppStore) => ({
    setShowAlertBanner: (bool: boolean, value: IAlertBannerOptions) =>
      store.setShowAlertBanner(bool, value)
  }));

  const updatePillar = (
    selected_items: SelectedItem,
    current_pillar: IContentPillars,
    type: "sub_pillar" | "pillar" | "promote" | "demote"
  ): IContentPillars => {
    const final_moved_blogs = [] as IBlogs[];
    const final_moved_sub_pillars = [] as ISubPillars[];
    const current_pillar_blogs = current_pillar.blogs?.filter(
      (blog) => blog?.id && !selected_items?.blog?.includes(blog.id)
    );
    const moved_pillar_blogs = current_pillar.blogs?.filter(
      (blog) => blog?.id && selected_items?.blog?.includes(blog.id)
    );
    if (moved_pillar_blogs) {
      final_moved_blogs.push(...moved_pillar_blogs);
    }
    current_pillar.blogs = current_pillar_blogs;
    current_pillar?.sub_pillars?.forEach((sp, index) => {
      const moved_sub_pillars = current_pillar.sub_pillars?.filter(
        (sub_pillar) =>
          sub_pillar?.id && selected_items?.sub_pillar?.includes(sub_pillar.id)
      );
      if (moved_sub_pillars) {
        final_moved_sub_pillars.push(...moved_sub_pillars);
      }
      const current_sp_blogs = sp.blogs?.filter(
        (blog) => blog?.id && !selected_items?.blog?.includes(blog.id)
      );
      const moved_sp_blogs = sp.blogs?.filter(
        (blog) => blog?.id && selected_items?.blog?.includes(blog.id)
      );
      if (moved_sp_blogs) {
        final_moved_blogs.push(...moved_sp_blogs);
      }
      if (current_pillar?.sub_pillars) {
        current_pillar.sub_pillars[index].blogs = current_sp_blogs;
      }
    });

    if (type === "promote") {
      const promptedSubPillars = final_moved_blogs as ISubPillars[];
      current_pillar.sub_pillars?.push(...promptedSubPillars);
    } else if (type === "demote") {
      const uniqueIds = [] as number[];
      const demotedSubPillars = final_moved_sub_pillars.filter((sp) => {
        if (sp.id && !uniqueIds.includes(sp.id)) {
          uniqueIds.push(sp.id);
          return true;
        }
        return false;
      }) as IBlogs[];
      if (current_pillar.blogs) {
        current_pillar.blogs.push(...demotedSubPillars);
      } else {
        current_pillar.blogs = demotedSubPillars;
      }
      const new_sub_pillars = current_pillar?.sub_pillars?.filter((sp) => {
        if (sp.id && uniqueIds.includes(sp.id)) {
          return false;
        }
        return true;
      });
      current_pillar.sub_pillars = new_sub_pillars;
    }

    return current_pillar;
  };

  const handlePromoteBlog = async () => {
    if (pillar.id) {
      setShowConvertItemsModal(false);
      setShowAlertBanner(true, {
        message: "Promoting Blog(s)...",
        severity: "info"
      });
      const submittedItems = JSON.parse(JSON.stringify(items));
      setSelectedTopics({ blog: [], sub_pillar: [] });
      const body = {
        items: submittedItems,
        pillar_id: pillar.id,
        pid
      };
      setSelectedTopics({ blog: [], sub_pillar: [] });
      updatePillar(submittedItems, pillar, "promote");
      await putPromoteBlogs(body)
        .then((res) => {
          setSelectedPidPillarTopics(res.data);
          setShowAlertBanner(true, {
            message:
              "Your blog(s) have been successfully promoted to Sub-Pillar(s)",
            severity: "success"
          });
        })
        .catch((e) => {
          console.error(e);
          setShowAlertBanner(true, {
            message:
              "There was a problem promoting your Blogs, please try again. If the error persists contact support.",
            severity: "error"
          });
        });
    }
  };

  const handleDemoteSubPillar = async () => {
    if (pillar.id) {
      setShowConvertItemsModal(false);
      setShowAlertBanner(true, {
        message: "Promoting Blog(s)...",
        severity: "info"
      });
      const submittedItems = JSON.parse(JSON.stringify(items));
      setSelectedTopics({ blog: [], sub_pillar: [] });
      const body = {
        items: submittedItems,
        pillar_id: pillar.id,
        pid
      };
      setSelectedTopics({ blog: [], sub_pillar: [] });
      updatePillar(submittedItems, pillar, "demote");
      await putDemoteSubPillar(body)
        .then((res) => {
          setSelectedPidPillarTopics(res.data);
          setShowAlertBanner(true, {
            message:
              "Your blog(s) have been successfully promoted to Sub-Pillar(s)",
            severity: "success"
          });
        })
        .catch((e) => {
          console.error(e);
          setShowAlertBanner(true, {
            message:
              "There was a problem promoting your Blogs, please try again. If the error persists contact support.",
            severity: "error"
          });
        });
    }
  };

  const handleChangeItems = async () => {
    if (items.blog.length > 0) {
      await handlePromoteBlog();
    } else if (items.sub_pillar.length > 0) {
      await handleDemoteSubPillar();
    }
  };

  return (
    <div
      className="shadow bg-body rounded"
      style={{
        position: "fixed",
        width: "380px",
        height: items.sub_pillar.length > 0 ? "270px" : "200px",
        left: "calc(50% - 182px)",
        top: "calc(50% - 167px)",
        background: "#FFFFFF",
        borderRadius: "3px",
        border: "1px solid #E9EBF0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: "10"
      }}
    >
      <button
        style={{
          position: "relative",
          left: "165px",
          top: "16px"
        }}
        className="btn btn-close"
        onClick={() => setShowConvertItemsModal(false)}
      />
      <div
        style={{
          position: "relative",
          width: "317px",
          height: "60px",
          fontFamily: "Source Sans Pro",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "30px",
          lineHeight: "43px",
          alignItems: "center",
          textAlign: "center",
          paddingTop: "16px",
          letterSpacing: "0.0025em",
          color: "#373F50"
        }}
      >
        {items.blog.length > 0 && (
          <span>Change {items.blog.length} Blog(s) to Sub-Pillar(s)</span>
        )}
        {items.sub_pillar.length > 0 && (
          <span>Change {items.sub_pillar.length} Sub-Pillar(s) to Blog(s)</span>
        )}
      </div>
      <div style={{ position: "relative", top: "32px" }}>
        {items.sub_pillar.length > 0 && (
          <div
            style={{
              padding: "30px",
              height: "70px"
            }}
          >
            {items.sub_pillar.length > 0 && (
              <p>All associated blogs will move directly under the Pillar.</p>
            )}
          </div>
        )}
      </div>
      <div
        style={{
          position: "relative",
          paddingTop: "30px",
          top: "30px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "307px"
        }}
      >
        <button
          style={{
            width: "135px",
            height: "40px",
            background: "#FFFFFF",
            border: "none",
            fontFamily: "Source Sans Pro",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "20px",
            textAlign: "center",
            letterSpacing: "0.005em",
            color: "#373F50"
          }}
          className="btn btn-secondary"
          onClick={() => setShowConvertItemsModal(false)}
        >
          Cancel
        </button>
        <button
          style={{
            width: "135px",
            height: "40px",
            background: "#008cce",
            borderRadius: "3px",
            fontFamily: "Source Sans Pro",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "20px",
            textAlign: "center",
            letterSpacing: "0.005em",
            color: "#FFFFFF"
          }}
          className="btn btn-primary"
          onClick={() => handleChangeItems()}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default MoveItemsModal;
