/* eslint-disable no-nested-ternary */

import { ICbAddon } from "../../../../interfaces/entitlements.interface";
import { ICbSubscription } from "../../../../interfaces/subscription.interface";
import { Skeleton } from "@mui/material";
import Card from "react-bootstrap/Card";

interface AddOnsCardProps {
  subscriptionLoading: boolean;
  entitlementsLoading: boolean;
  addons: ICbAddon[];
  localSubscriptionObject?: ICbSubscription;
}

function AddOnsCard({
  subscriptionLoading,
  entitlementsLoading,
  addons,
  localSubscriptionObject
}: AddOnsCardProps) {
  return (
    <Card style={{ marginTop: "38px" }}>
      <div className="card-heading">Recurring Add Ons</div>
      <div className="custom-table">
        <div className="t-row" style={{ marginTop: "35px" }}>
          <div className="sub-item-heading">Item</div>
          <div className="sub-item-heading">Quantity</div>
        </div>
        {addons?.length ? (
          addons.map((addon) => {
            if (addon.cb_addon_id.includes("competitor")) {
              return (
                <div className="t-row pad-15-0" key={addon.id}>
                  <div className="table-item">Competitor Add On</div>
                  <div className="table-item">
                    {JSON.parse(addon.cb_addon_details).values?.quantity}
                  </div>
                </div>
              );
            } else if (addon.cb_addon_id.includes("pillar")) {
              return (
                <div className="t-row pad-15-0" key={addon.id}>
                  <div className="table-item">Pillar Add On</div>
                  <div className="table-item">
                    {JSON.parse(addon.cb_addon_details).values?.quantity}
                  </div>
                </div>
              );
            } else if (addon.cb_addon_id.includes("keyword")) {
              return (
                <div className="t-row pad-15-0" key={addon.id}>
                  <div className="table-item">Keywords Add On</div>
                  <div className="table-item">
                    {JSON.parse(addon.cb_addon_details).values?.quantity}
                  </div>
                </div>
              );
            }
            return <></>;
          })
        ) : entitlementsLoading ? (
          <Skeleton animation="wave" sx={{ width: "200px", height: "30px" }} />
        ) : (
          <div className="t-row pad-15-0">
            <div className="table-item">No Purchased Addons</div>
          </div>
        )}
      </div>
      {subscriptionLoading ? (
        <div className="info" style={{ width: "100%" }}>
          {" "}
          <Skeleton
            animation="wave"
            sx={{ width: "70%", height: "30px" }}
          />{" "}
        </div>
      ) : (
        <div className="info">
          The add ons below are available for purchase{" "}
          {localSubscriptionObject?.cb_plan_id.includes("free")
            ? "on paid plans."
            : "in addition to your current plan."}{" "}
          Please{" "}
          {localSubscriptionObject?.cb_plan_id.includes("free") ? (
            <a
              style={{ color: "#008CCE" }}
              target="_blank"
              rel="noreferrer"
              href={process.env.REACT_APP_SALES_SCHED}
            >
              contact sales
            </a>
          ) : (
            "go to “Manage Subscription”"
          )}{" "}
          to purchase.
        </div>
      )}
      <div className="custom-table">
        <div className="t-row pad-15-0">
          <div className="table-item">Additional Pillar</div>
          <div className="table-item">$ 250.00/mo</div>
        </div>
        <div className="t-row pad-15-0">
          <div className="table-item">Competitors Bundle (5)</div>
          <div className="table-item">$ 50.00/mo</div>
        </div>
        <div className="t-row pad-15-0">
          <div className="table-item">Keywords Bundle (100)</div>
          <div className="table-item">$ 50.00/mo</div>
        </div>
      </div>
    </Card>
  );
}

export default AddOnsCard;
