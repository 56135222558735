import { Row, Col } from "react-bootstrap";
import { IUser } from "../../../interfaces/user.interface";
import { RxAvatar } from "react-icons/rx";

function UserProfilePanel(props: { user: IUser }): JSX.Element {
  const iconStyle = {
    width: "200px",
    height: "200px"
  };

  return (
    <div
      className="card-style"
      style={{ marginRight: "10px", height: "fit-content" }}
    >
      <div>
        <Row style={{ paddingBottom: "15px" }}>
          <Col sm={12} style={{ display: "flex", justifyContent: "center" }}>
            {props.user.avatar_url ? (
              <img
                width="200"
                height="200"
                className="rounded-circle"
                src={props.user.avatar_url}
                alt={`${props.user.name} profile image`}
              />
            ) : (
              <RxAvatar style={iconStyle} />
            )}
          </Col>
        </Row>
        <Row className="text-center">
          <Col sm={12}>
            <h3 style={{ fontSize: "1.75 rem" }}>{props.user.name}</h3>
            <p className="text-muted">{props.user.unique_id}</p>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default UserProfilePanel;
