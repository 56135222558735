import { useEffect, useState } from "react";
import "./Subscription.scss";
import { IAppStore } from "../../../interfaces/app-store.interface";
import { useAppStore } from "../../../hooks";
import { ICbSubscription } from "../../../interfaces/subscription.interface";
import {
  getAccountAdmins,
  getEntitlements,
  getPidSubscription
} from "../../../api/get";
import { ICbAddon, ILimits } from "../../../interfaces/entitlements.interface";
import SubscriptionCard from "./components/SubscriptionCard";
import AddOnsCard from "./components/AddOnsCard";
import { useNavigate } from "react-router-dom";

function Subscription() {
  const navigate = useNavigate();

  const [entitlementsLoading, setEntitlementsLoading] = useState(true);
  const [subscriptionLoading, setSubscriptionLoading] = useState(true);
  const [limits, setLimits] = useState<ILimits | null>(null);
  const [addons, setAddons] = useState<ICbAddon[]>([]);
  const [localSubscriptionObject, setLocalSubscriptionObject] =
    useState<ICbSubscription>();
  const [checkingAdminStatus, setCheckingAdminStatus] = useState(true);
  const {
    isAdmin,
    user,
    selectedPidObject,
    selectedAccountObject,
    selectedSubscriptionObject,
    setSelectedSubscriptionObject,
    setIsAdmin
  } = useAppStore((store: IAppStore) => ({
    isAdmin: store.isAdmin,
    user: store.user,
    selectedPidObject: store.selectedPidObject,
    selectedAccountObject: store.selectedAccountObject,
    setIsAdmin: (value: boolean) => store.setIsAdmin(value),
    selectedSubscriptionObject: store.selectedSubscriptionObject,
    setSelectedSubscriptionObject: (value: ICbSubscription) =>
      store.setSelectedSubscriptionObject(value)
  }));

  useEffect(() => {
    const fetchEntitlements = async () => {
      setEntitlementsLoading(true);
      try {
        if (selectedPidObject.id) {
          const entitlements = await getEntitlements(selectedPidObject.id);
          if (entitlements?.limits) {
            setLimits(entitlements?.limits);
          }
          if (entitlements?.addons) {
            setAddons(entitlements.addons);
          }
          setEntitlementsLoading(false);
        }
      } catch (e) {
        console.error("error:", e);
      }
    };
    const fetchSubscription = async (pid: number) => {
      setSubscriptionLoading(true);
      try {
        const response = await getPidSubscription(pid);
        if (response?.length) {
          setSelectedSubscriptionObject(response[0]);
          setLocalSubscriptionObject(response[0]);
        } else {
          const noSubscription: ICbSubscription = {
            id: null,
            pid,
            cb_customer_id: null,
            cb_plan_id: "freemium-no-subscription",
            cb_subscription_id: null,
            cb_plan_family: "v4_free",
            cb_status: "null",
            cb_recurring_type: "non-existent"
          };
          setSelectedSubscriptionObject(noSubscription);
          setLocalSubscriptionObject(noSubscription);
        }
      } catch (error) {
        console.error(error);
      }

      setSubscriptionLoading(false);
    };
    if (
      !selectedSubscriptionObject ||
      (selectedPidObject?.id &&
        selectedPidObject.id !== selectedSubscriptionObject.pid)
    ) {
      fetchSubscription(selectedPidObject.id);
    } else if (selectedSubscriptionObject?.cb_plan_id) {
      setLocalSubscriptionObject(selectedSubscriptionObject);
      setSubscriptionLoading(false);
    }
    fetchEntitlements();
  }, [selectedPidObject, isAdmin]);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!isAdmin) {
        setCheckingAdminStatus(true);
        const admins = await getAccountAdmins(selectedAccountObject.id);
        if (admins.includes(user.id)) {
          setIsAdmin(true);
          setCheckingAdminStatus(false);
          return;
        }
        setIsAdmin(false);
        navigate("/unauthorized");
      }
    };
    if (user && !user.dj_employee) {
      checkAdminStatus();
    } else if (user.dj_employee) {
      setCheckingAdminStatus(false);
    }
  }, [isAdmin, user]);

  return (
    <>
      {(!checkingAdminStatus || isAdmin) && (
        <div className="d-flex flex-column justify-content-start align-items-start w-100 subscription-container">
          <div className="heading">Subscription Management</div>
          <SubscriptionCard
            subscriptionLoading={subscriptionLoading}
            entitlementsLoading={entitlementsLoading}
            limits={limits}
            localSubscriptionObject={localSubscriptionObject}
          />
          <AddOnsCard
            subscriptionLoading={subscriptionLoading}
            entitlementsLoading={entitlementsLoading}
            addons={addons}
            localSubscriptionObject={localSubscriptionObject}
          />
        </div>
      )}
    </>
  );
}

export default Subscription;
