import { RxAvatar } from "react-icons/rx";
import { useAppStore } from "../../hooks";
import { IAppStore } from "../../interfaces/app-store.interface";
import "./userIcon.scss";

const UserIcon = () => {
  const { user } = useAppStore((store: IAppStore) => ({
    user: store.user
  }));
  return (
    <>
      <div>
        {user.avatar_url && (
          <img src={user.avatar_url} className="user-avatar"></img>
        )}
        {!user.avatar_url && (
          <RxAvatar style={{ width: "45px", height: "45px" }} />
        )}
      </div>
    </>
  );
};
export default UserIcon;
