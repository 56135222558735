import { useContentBriefsStore } from "@/hooks";
import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";
import { useEffect, useState } from "react";
import { createSlugUrl } from "./utility";
import { ISavedBrief } from "@/interfaces/saved_outline.interface";

const Summary = () => {
  const [slug, setSlug] = useState("");
  const [contentLength, setContentLength] = useState("");
  const [questionsAndKeywords, setQuestionsAndKeywords] = useState(0);
  const {
    pillar,
    typeOfTerm,
    term,
    setMetaDescription,
    metaDescription,
    briefId
  }: IContentBriefsStore = useContentBriefsStore(
    (store: IContentBriefsStore) => ({
      pillar: store.pillar,
      briefId: store.briefId,
      term: store.term,
      typeOfTerm: store.typeOfTerm,
      metaDescription: store.metaDescription,
      setMetaDescription: (value: string) => store.setMetaDescription(value)
    })
  );
  let savedBrief: ISavedBrief | undefined;
  // Fetch saved brief data and set state
  useEffect(() => {
    // takes the term and formats it to present the client with a slug
    const formattedTerm = createSlugUrl(term);
    setSlug(formattedTerm);
    // Set state for content recommendations based on the type of term
    if (typeOfTerm === "Pillar") {
      setContentLength("3,000");
      setQuestionsAndKeywords(10);
    } else if (typeOfTerm === "Sub Pillar") {
      setContentLength("2,000");
      setQuestionsAndKeywords(8);
    } else if (typeOfTerm === "Blog" || typeOfTerm === "Ad Hoc") {
      setContentLength("750");
      setQuestionsAndKeywords(4);
    }
  }, [term, typeOfTerm, briefId, savedBrief]);
  const handleSubmitMetaDescription = (value: string) => {
    setMetaDescription(value);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "40px 20px 20px 30px",
        justifyContent: "space-between"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginBottom: "50px"
        }}
      >
        <div
          style={{
            color: "#56627C",
            textTransform: "uppercase",
            fontSize: "16px",
            fontWeight: 520,
            marginBottom: 10
          }}
        >
          Context
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", marginBottom: 16 }}
        >
          <div
            style={{
              color: "#56627C",
              fontSize: "14px"
            }}
          >
            Target Search Term
          </div>
          <div style={{ color: "#373F50", fontSize: "16px" }}>{term}</div>
        </div>
        {pillar && (
          <div style={{ marginBottom: 16 }}>
            <div style={{ color: "#56627C", fontSize: "14px" }}>
              Parent Pillar
            </div>
            <div style={{ color: "#373F50", fontSize: "16px" }}>{pillar}</div>
          </div>
        )}
        <div>
          <div
            style={{
              color: "#56627C",
              fontSize: "14px"
            }}
          >
            Content Type
          </div>
          <div style={{ color: "#373F50", fontSize: "16px" }}>{typeOfTerm}</div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginBottom: "50px"
        }}
      >
        <div
          style={{
            color: "#56627C",
            textTransform: "uppercase",
            fontSize: "16px",
            fontWeight: 520,
            marginBottom: 10
          }}
        >
          Pillar-Based Marketing Recommendations
        </div>
        <div style={{ marginBottom: 16 }}>
          <div
            style={{
              color: "#56627C",
              fontSize: "14px"
            }}
          >
            Content Length
          </div>
          <div style={{ color: "#373F50", fontSize: "16px" }}>
            {contentLength} Words
          </div>
        </div>
        <div style={{ marginBottom: 16 }}>
          <div
            style={{
              color: "#56627C",
              fontSize: "14px"
            }}
          >
            Questions to Include
          </div>
          <div style={{ color: "#373F50", fontSize: "16px" }}>
            {questionsAndKeywords}
          </div>
        </div>
        <div>
          <div
            style={{
              color: "#56627C",
              fontSize: "14px"
            }}
          >
            Keywords to Include
          </div>
          <div style={{ color: "#373F50", fontSize: "16px" }}>
            {questionsAndKeywords}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginBottom: "10px"
        }}
      >
        <div
          style={{
            color: "#56627C",
            textTransform: "uppercase",
            fontSize: "16px",
            fontWeight: 520,
            marginBottom: 10
          }}
        >
          MetaData
        </div>
        <div style={{ marginBottom: 16 }}>
          <div
            style={{
              color: "#56627C",
              fontSize: "14px"
            }}
          >
            Title Tag
          </div>
          <div style={{ color: "#373F50", fontSize: "16px" }}>{term}</div>
        </div>
        <div style={{ marginBottom: 16 }}>
          <div
            style={{
              color: "#56627C",
              fontSize: "14px"
            }}
          >
            URL Slug
          </div>
          <div style={{ color: "#373F50", fontSize: "16px" }}>{slug}</div>
        </div>
        <div>
          <div
            style={{
              color: "#56627C",
              fontSize: "14px"
            }}
          >
            Meta Description
          </div>
          <textarea
            value={metaDescription}
            onChange={(e) => handleSubmitMetaDescription(e.target.value)}
            placeholder="Add a meta description..."
            style={{
              width: "16vw",
              fontSize: "14px",
              color: "#373F50",
              padding: "5px 5px 5px 8px",
              border: "1px solid #D3D7E0",
              borderRadius: 4,
              resize: "none",
              outline: "none"
            }}
          >
            {metaDescription}
          </textarea>
        </div>
      </div>
    </div>
  );
};
export default Summary;
