import PropTypes from "prop-types";
import { Row, Modal } from "react-bootstrap";
import dayjs from "dayjs";
import LocationSelect from "../../../shared/LocationSelect";

const ConfirmUpdateModal = (props) => {
  const hideModal = () => {
    props.setVisible(false);
  };

  return (
    <Modal
      show={props.visible}
      onHide={hideModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-dialogue-custom-60w"
      style={{ minWidth: "750px" }}
    >
      <Modal.Header
        style={{ backgroundColor: "white", borderBottom: "0px" }}
        closeButton
      >
        <Modal.Title className="w-75 mx-auto d-flex justify-content-center">
          You&apos;re about to update your pillars.
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="contentPillars" className="mtlr-30">
          <Row className="w-100 mx-auto">
            <div
              className="form-wrapper w-100"
              style={{ padding: "0 32px" }}
              id="contentPillarsFormWrapper"
            >
              {props.keywords.map((keyword, int) => (
                <div
                  key={int}
                  className="content-pillars-control row skeleton pb-3"
                >
                  <div className="col-sm-5">
                    <div className="inputWrapper">
                      <label className="col-form-label">#{int + 1}</label>
                      <input
                        disabled
                        name={`keyword${int + 1}.keyword`}
                        value={keyword.keyword}
                        type="text"
                        style={{
                          fontSize: "14px"
                        }}
                        className="form-control"
                      />
                    </div>

                    <small
                      className="form-text text-muted col-sm-10 position-absolute"
                      style={{
                        marginLeft: "20px",
                        marginTop: "-5px"
                      }}
                    >
                      Last updated: {dayjs().format("MM/DD/YY")}
                    </small>
                  </div>
                  <div className="locationWrapper col-sm-5">
                    <LocationSelect
                      isDisabled={true}
                      location={
                        typeof keyword.location === "string" ||
                        keyword.location instanceof String
                          ? {
                              value: keyword.location,
                              label: keyword.location
                            }
                          : keyword.location
                      }
                      setLocation={() => null}
                      style={{ width: "98%" }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </Row>
        </div>
        <Row className="w-75 mx-auto d-flex justify-content-center">
          Are you sure you want to use these keywords or phrases for your
          pillars?{" "}
        </Row>
      </Modal.Body>
      <Modal.Footer
        className="d-flex justify-content-around"
        style={{ backgroundColor: "white", borderTop: "0px" }}
      >
        <button
          className="btn btn-outline-primary demandjump-button-outline-primary mx-3"
          style={{ width: "128px", height: "40px" }}
          onClick={() => hideModal()}
        >
          Go Back
        </button>
        <button
          className="btn btn-primary demandjump-button-primary"
          style={{ width: "128px", height: "40px" }}
          onClick={() => {
            props.handleSubmit();
            hideModal();
          }}
        >
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmUpdateModal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  keywords: PropTypes.array,
  handleSubmit: PropTypes.func
};

ConfirmUpdateModal.defaultProps = {
  visible: false
};

export default ConfirmUpdateModal;
