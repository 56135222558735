/* eslint-disable max-lines */

import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import VerticalBar from "./VerticalBar";
import { useAppStore } from "../../../../hooks";
import { IAppStore } from "../../../../interfaces/app-store.interface";
import {
  IconKeywordList,
  NavigationArrowBack,
  IconCompetitorsSwords,
  IconTrackTicket,
  IconSubscription,
  IconUsers,
  IconGroups
} from "@/assets/icons/svgr";
import { Dispatch, SetStateAction, useState } from "react";

const settingsMenuLHSOptions = [
  {
    text: "Keywords",
    link: "/settings/keywords"
  },
  {
    text: "Competitors",
    link: "/settings/competitors"
  },
  {
    text: "Set Pillar Topics",
    link: "/settings/content-pillars"
  }
];
const settingsMenuRHSOptionsDJEmployee = [
  {
    text: "Subscription",
    link: "/settings/subscription"
  },
  {
    text: "Users",
    link: "/settings/manage-users"
  },
  {
    text: "Backoffice",
    link: "/settings/admin-panel/backoffice"
  }
];
const settingsMenuRHSOptions = [
  {
    text: "Subscription",
    link: "/settings/subscription"
  },
  {
    text: "Users",
    link: "/settings/manage-users"
  }
];
const SettingsMenu = ({
  setSettingsMenuOpen,
  setOpenItemsStack
}: {
  setSettingsMenuOpen: Dispatch<SetStateAction<boolean>>;
  setOpenItemsStack?: Dispatch<SetStateAction<number[]>>;
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState("");
  const { user, isAdmin } = useAppStore((store: IAppStore) => ({
    user: store.user,
    isAdmin: store.isAdmin
  }));

  return (
    <List
      sx={{
        bottom: { sm: "0" },
        width: isAdmin || user.dj_employee ? "455px" : "auto",
        height: "230px"
      }}
    >
      <div>
        <Grid item xs={12}>
          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                background: isMobile ? "#E1F5FF" : "#FFFFFF",
                color: "#008CCE"
              }
            }}
            onClick={() => {
              setSettingsMenuOpen(false);
            }}
          >
            {isMobile ? (
              <ListItemButton
                sx={{
                  minHeight: 37,
                  height: "37px",
                  justifyContent: "initial",
                  px: 2.5,
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "transparent"
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: "24px",
                    mr: "15px",
                    justifyContent: "center",
                    color: "#373F50"
                  }}
                >
                  <NavigationArrowBack style={{ width: "24px" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Settings"
                  primaryTypographyProps={{
                    fontSize: "17px",
                    fontFamily: "Source Sans Pro",
                    lineHeight: "21px",
                    letterSpacing: "-0.015em",
                    color: "#008CCE"
                  }}
                />
              </ListItemButton>
            ) : (
              <ListItemText
                primary="Settings"
                primaryTypographyProps={{
                  justifyContent: "initial",
                  px: "30px",
                  paddingTop: "20px",
                  fontSize: "17px",
                  fontFamily: "Source Sans Pro",
                  lineHeight: "21px",
                  letterSpacing: "-0.015em",
                  color: "#008CCE"
                }}
              />
            )}
          </ListItem>
        </Grid>
        <Grid container>
          <Grid sx={{ width: "225px", float: "left" }}>
            {settingsMenuLHSOptions.map((obj) => (
              <ListItem
                key={obj.text}
                sx={{
                  display: "block",
                  "&:hover": {
                    background: "#E1F5FF",
                    "&.MuiButtonBase-root:hover": {
                      bgcolor: "transparent"
                    },
                    color: "#008CCE"
                  }
                }}
                onMouseEnter={() => setIsHovered(obj.text)}
                onMouseLeave={() => setIsHovered("")}
                onClick={() => {
                  if (obj.link) {
                    navigate(obj.link);
                    setSettingsMenuOpen(false);
                    if (setOpenItemsStack) {
                      setOpenItemsStack([]);
                    }
                  }
                }}
              >
                <ListItemButton
                  disableTouchRipple
                  disableRipple
                  sx={{
                    width: "100%",
                    minHeight: 37,
                    height: "37px",
                    justifyContent: "initial",
                    "&.MuiButtonBase-root:hover": {
                      bgcolor: "transparent"
                    }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: "15px",
                      justifyContent: "center",
                      color: isHovered === obj.text ? "#008CCE" : "initial"
                    }}
                  >
                    {obj.text === "Keywords" && (
                      <IconKeywordList style={{ width: "24px" }} />
                    )}
                    {obj.text === "Competitors" && (
                      <IconCompetitorsSwords style={{ width: "24px" }} />
                    )}
                    {obj.text === "Set Pillar Topics" && (
                      <IconTrackTicket style={{ width: "24px" }} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={obj.text}
                    primaryTypographyProps={{
                      fontSize: "17px",
                      fontFamily: "Source Sans Pro",
                      fontWeight: isHovered === obj.text ? "bold" : "normal",
                      lineHeight: "21px",
                      letterSpacing: "-0.015em"
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </Grid>
          {(user.dj_employee || isAdmin) && (
            <>
              <Grid sx={{ float: "left", width: "1px" }}>
                <VerticalBar />
              </Grid>

              <Grid sx={{ width: "227px" }}>
                {(user.dj_employee
                  ? settingsMenuRHSOptionsDJEmployee
                  : settingsMenuRHSOptions
                ).map((obj) => (
                  <ListItem
                    key={obj.text}
                    sx={{
                      display: "block",
                      "&:hover": {
                        background: "#E1F5FF",
                        color: "#008CCE"
                      }
                    }}
                    onMouseEnter={() => setIsHovered(obj.text)}
                    onMouseLeave={() => setIsHovered("")}
                    onClick={() => {
                      if (obj.link) {
                        navigate(obj.link);
                        setSettingsMenuOpen(false);
                        if (setOpenItemsStack) {
                          setOpenItemsStack([]);
                        }
                      }
                    }}
                  >
                    <ListItemButton
                      disableTouchRipple
                      disableRipple
                      sx={{
                        width: "100%",
                        minHeight: 37,
                        height: "37px",
                        justifyContent: "initial",
                        "&.MuiButtonBase-root:hover": {
                          bgcolor: "transparent"
                        }
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: "15px",
                          justifyContent: "center",
                          color: isHovered === obj.text ? "#008CCE" : "initial"
                        }}
                      >
                        {obj.text === "Subscription" && (
                          <IconSubscription
                            style={{
                              width: "24px"
                            }}
                          />
                        )}
                        {obj.text === "Users" && (
                          <IconUsers
                            style={{
                              width: "24px"
                            }}
                          />
                        )}
                        {user.dj_employee && obj.text === "Backoffice" && (
                          <IconGroups
                            style={{
                              width: "24px"
                            }}
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={obj.text}
                        primaryTypographyProps={{
                          fontSize: "17px",
                          fontFamily: "Source Sans Pro",
                          fontWeight:
                            isHovered === obj.text ? "bold" : "normal",
                          lineHeight: "21px",
                          letterSpacing: "-0.015em"
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </List>
  );
};
export default SettingsMenu;
