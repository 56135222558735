/* eslint-disable max-lines */
import axios, { AxiosResponse } from "axios";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";
import { ILimits } from "../interfaces/limits.interface";
import { ICompetitorsResponse } from "../interfaces/competitors-response.interface";
const cookies = new Cookies();

import { IInsights } from "../interfaces/insights.interface";
import { navItemD, IBookmark } from "../interfaces/navigation.interface";
import { IAccount } from "../interfaces/account.interface";
import { ICbSubscription } from "../interfaces/subscription.interface";
import { IAccountUser } from "../interfaces/account-user.interface";
import { IEntitlements } from "../interfaces/entitlements.interface";
import { IUserGroup, IUser } from "../interfaces/user.interface";
import { ILocationResponse } from "../interfaces/location.interface";
import { ISsoResponse } from "../interfaces/sso.interface";
import {
  ISavedBrief,
  ISavedBriefCTA,
  ISavedOutline
} from "../interfaces/saved_outline.interface";
import { IBrief } from "../interfaces/brief.interface";
import { IContentPillars } from "../interfaces/content-pillars.interface";
import { IAccountsArray } from "../interfaces/accounts-array.interface";
import {
  ContentBriefs,
  ISearchTermPayload
} from "@/interfaces/outline.interface";
import { INotes } from "@/interfaces/notes.interface";

const getUrl = async (url: string, options = {}, authorization = true) => {
  // if the url that we are on contains /public/
  const public_url = window.location.href.includes("/public");
  const final_url = process.env.REACT_APP_NEO_URL + url;

  let device_uuid = cookies.get("device_id");
  if (!device_uuid) {
    device_uuid = uuidv4();
    cookies.set("device_id", device_uuid, { path: "/" });
  }
  let access_token = null;
  if (authorization) {
    access_token = cookies.get("Access_Token");
    if (!access_token && !public_url) {
      window.location.href = "/login";
    }
  }

  return await axios
    .get(final_url, {
      ...options,
      headers: authorization
        ? {
            authorization: access_token,
            "X-DeviceUUID": device_uuid
          }
        : {
            ...options
          }
    })
    .then((response) => {
      if (authorization && response?.headers?.["x-access-token"]) {
        if (cookies.get("Access_Token")) {
          cookies.remove("Access_Token", { path: "/" });
          cookies.set("Access_Token", response.headers["x-access-token"], {
            path: "/",
            domain: process.env.REACT_APP_SUBDOMAIN
          });
        } else {
          // direct them to login
          window.location.href = "/login";
        }
      }
      return response;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        cookies.remove("Access_Token", {
          path: "/",
          domain: process.env.REACT_APP_SUBDOMAIN
        });
        cookies.remove("Access_Token", { path: "/" });
        window.location.href = "/login";
      }
      throw err;
    });
};

export const getUserAccountsAndPids = async () => {
  const url = `/api/v1/user_accounts_pids`;
  return await getUrl(url)
    .then((response: AxiosResponse): IAccount[] => response.data as IAccount[])
    .catch((error) => {
      throw error;
    });
};

export const getUserAccountsAndPidsPaginated = async (page = 0, limit = 10) => {
  const url = `/api/v1/user_accounts_pids_paginated?page=${page}&limit=${limit}`;
  return await getUrl(url).then(
    (response: AxiosResponse): { data: IAccount[]; totalPages: number } =>
      response.data as { data: IAccount[]; totalPages: number }
  );
};

export const getUserAccountsAndPidsBySearchTerm = async (
  searchTerm: string
) => {
  const url = `/api/v1/user_accounts_pids_by_search_term?searchTerm=${searchTerm}`;
  return await getUrl(url).then(
    (response: AxiosResponse): IAccount[] => response.data as IAccount[]
  );
};

export const getUserAccountAndPid = async (pid: number) => {
  const url = `/api/v1/user_account_pid?pid=${pid}`;
  return await getUrl(url)
    .then((response: AxiosResponse): IAccount => response.data as IAccount)
    .catch((error) => {
      throw error;
    });
};

export const getBookmarks = async () => {
  const url = `/api/v1/user_bookmarks`;
  return await getUrl(url)
    .then(
      (response: AxiosResponse): IBookmark[] => response.data as IBookmark[]
    )
    .catch((error) => {
      throw error;
    });
};

export const getUserById = async (id: number) => {
  const url = `/api/v1/users/${id}`;
  return await getUrl(url)
    .then((response: AxiosResponse): IUser => response.data as IUser)
    .catch((error) => {
      throw error;
    });
};

export const getUserByEmail = async (email: string) => {
  const url = `/api/v1/user?email=${email}`;
  return await getUrl(url)
    .then((response: AxiosResponse): IUser => response.data as IUser)
    .catch((error) => {
      throw error;
    });
};

export const getNavJSON = async (pid: number): Promise<navItemD[]> => {
  const url = `/api/v1/navigation/${pid}`;
  return await getUrl(url)
    .then((response: AxiosResponse): navItemD[] => response.data as navItemD[])
    .catch((error) => {
      throw error;
    });
};

export const getOTP = async (opt_out = false) => {
  const url = `/api/v1/generate-otp-url?opt_out=${opt_out}`;
  return await getUrl(url);
};

export const getDashboardUrl = async (
  dashboard_name: string,
  pid: number,
  topic?: string,
  pillarTopic?: string,
  blockList?: string
) => {
  let url = `/api/v1/dashboard?dashboard_name=${dashboard_name}&pid=${pid}`;
  if (topic && topic !== "") {
    url += `&topic=${topic}`;
  }
  if (pillarTopic && pillarTopic !== "") {
    url += `&pillar_topic=${pillarTopic}`;
  }
  if (blockList && blockList !== "") {
    url += `&blocklist_filter=${blockList}`;
  }
  return await getUrl(url);
};

export const getPaginatedInsights = async (
  pid: number,
  page: number,
  limit: number | string
): Promise<IInsights[]> => {
  const url = `/api/v1/insights?pid=${pid}&page=${page}&limit=${limit}`;
  return await getUrl(url)
    .then(
      (response: AxiosResponse): IInsights[] => response.data as IInsights[]
    )
    .catch((error) => {
      throw error;
    });
};

export const getInsightsBySearch = async (
  pid: number,
  search: string
): Promise<IInsights[]> => {
  const url = `/api/v1/insights-search?pid=${pid}&search=${search}`;
  return await getUrl(url)
    .then(
      (response: AxiosResponse): IInsights[] => response.data as IInsights[]
    )
    .catch((error) => {
      throw error;
    });
};

export const getPaginatedBriefs = async (
  pid: number,
  page: number,
  limit: number | string,
  orderProp: string,
  orderDirection: string
): Promise<IBrief[]> => {
  const url = `/api/v1/get-paginated-briefs?pid=${pid}&page=${page}&limit=${limit}&orderProp=${orderProp}&orderDirection=${orderDirection}`;
  return await getUrl(url)
    .then((response: AxiosResponse): IBrief[] => response.data as IBrief[])
    .catch((error) => {
      throw error;
    });
};

export const getArchivedBriefs = async (
  pid: number,
  page: number,
  limit: number | string
): Promise<IBrief[]> => {
  const url = `/api/v1/get-archived-briefs?pid=${pid}&page=${page}&limit=${limit}`;
  return await getUrl(url)
    .then((response: AxiosResponse): IBrief[] => response.data as IBrief[])
    .catch((error) => {
      throw error;
    });
};

export const getUnfinishedBriefs = async (pid: number): Promise<IBrief[]> => {
  const url = `/api/v1/get-unfinished-briefs?pid=${pid}`;
  return await getUrl(url)
    .then((response: AxiosResponse): IBrief[] => response.data as IBrief[])
    .catch((error) => {
      throw error;
    });
};

export const getPidUsers = async (pid: number): Promise<IUser[]> => {
  const url = `/api/v1/account-users/${pid}`;
  return await getUrl(url)
    .then((response: AxiosResponse): IUser[] => response.data as IUser[])
    .catch((error) => {
      throw error;
    });
};

export const getPidSubscription = async (
  pid: number
): Promise<ICbSubscription[]> => {
  const url = `/api/v1/cb_subscriptions/${pid}`;
  return await getUrl(url)
    .then(
      (response: AxiosResponse): ICbSubscription[] =>
        response.data as ICbSubscription[]
    )
    .catch((error) => {
      throw error;
    });
};

export const getLocationData = async (
  inputValue: string
): Promise<ILocationResponse[]> => {
  const url = `/api/v1/location/${inputValue}`;
  return await getUrl(url, {}, false)
    .then(
      (response: AxiosResponse): ILocationResponse[] =>
        response.data as ILocationResponse[]
    )
    .catch((error) => {
      throw error;
    });
};

export const getUserGroups = async (): Promise<IUserGroup[]> => {
  const url = `/api/v1/user_groups`;
  return await getUrl(url)
    .then(
      (response: AxiosResponse): IUserGroup[] => response.data as IUserGroup[]
    )
    .catch((error) => {
      throw error;
    });
};

export const getHubspotUrl = async (
  queryParams = ""
): Promise<ISsoResponse> => {
  const url = `/api/v1/hubspot-sso?${queryParams}`;
  return await getUrl(url)
    .then(
      (response: AxiosResponse): ISsoResponse => response.data as ISsoResponse
    )
    .catch((error) => {
      throw error;
    });
};

export const getDjUniUrl = async (queryParams = ""): Promise<ISsoResponse> => {
  const url = `/api/v1/djuni-sso?${queryParams}`;
  return await getUrl(url)
    .then(
      (response: AxiosResponse): ISsoResponse => response.data as ISsoResponse
    )
    .catch((error) => {
      throw error;
    });
};

export const getLimits = async (pid: number): Promise<ILimits> => {
  const url = `/api/v1/cb_subscriptions/entitlements/${pid}`;
  return await getUrl(url)
    .then((response: AxiosResponse): ILimits => response.data as ILimits)
    .catch((error) => {
      throw error;
    });
};

export const getAccountUsers = async (
  accountId: number
): Promise<IAccountUser[]> => {
  const url = `/api/v1/account/${accountId}/users`;
  return await getUrl(url)
    .then(
      (response: AxiosResponse): IAccountUser[] =>
        response.data as IAccountUser[]
    )
    .catch((error) => {
      throw error;
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getKeywordLists = async (pid: number): Promise<any[]> => {
  const url = `/api/v1/keywords-list/${pid}`;
  return await getUrl(url)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: AxiosResponse): any[] => response.data as any[])
    .catch((error) => {
      throw error;
    });
};

export const getPidsByUserId = async (userId: number): Promise<number[]> => {
  const url = `/api/v1/user-pids/${userId}`;
  return await getUrl(url)
    .then((response: AxiosResponse): number[] => response.data as number[])
    .catch((error) => {
      throw error;
    });
};

export const getEntitlementsWithAddonsByPid = async (
  pid: number
): Promise<IEntitlements> => {
  const url = `/api/v1/cb_subscriptions/entitlements/${pid}`;
  return await getUrl(url)
    .then(
      (response: AxiosResponse): IEntitlements => response.data as IEntitlements
    )
    .catch((error) => {
      throw error;
    });
};

export const getSavedOutline = async (
  brief_id: number,
  phrase: string,
  pid: number,
  depth = 2,
  reload = "false"
): Promise<ISavedOutline> => {
  const url = `/api/v1/get-saved-outline?pid=${pid}&brief_id=${brief_id}&phrase=${phrase}&depth=${depth}&reload=${reload}`;
  return await getUrl(url)
    .then(
      (response: AxiosResponse): ISavedOutline => response.data as ISavedOutline
    )
    .catch((error) => {
      throw error;
    });
};

export const getBriefStatus = async (
  brief_id: number,
  pid: number
): Promise<IBrief> => {
  const url = `/api/v1/get-brief-status?pid=${pid}&brief_id=${brief_id}`;
  return await getUrl(url)
    .then((response: AxiosResponse): IBrief => response.data as IBrief)
    .catch((error) => {
      throw error;
    });
};

export const getCompetitorsByPid = async (
  pid: number
): Promise<ICompetitorsResponse> => {
  const url = `/api/v1/competitors/${pid}`;
  return await getUrl(url)
    .then(
      (response: AxiosResponse): ICompetitorsResponse =>
        response.data as ICompetitorsResponse
    )
    .catch((error) => {
      throw error;
    });
};

export const getAssignedUser = async (
  pid: number,
  brief_id: number
): Promise<IUser> => {
  const url = `/api/v1/user_assigned_brief?brief_id=${brief_id}`;
  return await getUrl(url)
    .then((response: AxiosResponse): IUser => response.data[0] as IUser)
    .catch((error) => {
      throw error;
    });
};

export const getEntitlements = async (pid: number): Promise<IEntitlements> => {
  const url = `/api/v1/cb_subscriptions/entitlements/${pid}`;
  return await getUrl(url)
    .then(
      (response: AxiosResponse): IEntitlements => response.data as IEntitlements
    )
    .catch((error) => {
      throw error;
    });
};

export const getPillars = async (pid: number): Promise<IContentPillars[]> => {
  const url = `/api/v1/content-pillars/${pid}`;
  return await getUrl(url)
    .then(
      (response: AxiosResponse): IContentPillars[] =>
        response.data as IContentPillars[]
    )
    .catch((error) => {
      throw error;
    });
};

export const getAccountPdomain = async (): Promise<IAccountsArray> => {
  const url = "/api/v1/accounts-pdomains-subscriptions";
  return await getUrl(url)
    .then(
      (response: AxiosResponse): IAccountsArray =>
        response.data as IAccountsArray
    )
    .catch((error) => {
      throw error;
    });
};

export const cronRun = async () => {
  const url = "/api/v1/cron/run";
  return await getUrl(url)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

export const getAllInsights = async (pid: number): Promise<IInsights[]> => {
  const url = `/api/v1/all-insights?pid=${pid}`;
  return await getUrl(url)
    .then(
      (response: AxiosResponse): IInsights[] => response.data as IInsights[]
    )
    .catch((error) => {
      throw error;
    });
};

export const getPillarStrategy = async (
  pid: number
): Promise<IContentPillars[]> => {
  const url = `/api/v1/pillar-strategy/${pid}`;
  return await getUrl(url)
    .then(
      (response: AxiosResponse): IContentPillars[] =>
        response.data as IContentPillars[]
    )
    .catch((error) => {
      throw error;
    });
};
export const getAccountAdmins = async (
  accountId: number
): Promise<Number[]> => {
  const url = `/api/v1/account_admins/${accountId}`;
  return await getUrl(url)
    .then((response: AxiosResponse): Number[] => response.data as Number[])
    .catch((error) => {
      throw error;
    });
};

export const getMfa = async (): Promise<{
  mfa_enabled: boolean;
  qr_code: string;
  url: string;
}> => {
  const url = "/api/v1/get-mfa";
  return await getUrl(url)
    .then(
      (
        response: AxiosResponse
      ): { mfa_enabled: boolean; qr_code: string; url: string } =>
        response.data as {
          mfa_enabled: boolean;
          qr_code: string;
          url: string;
        }
    )
    .catch((error) => {
      throw error;
    });
};

export const getMfaValidated = async (): Promise<AxiosResponse> => {
  const url = "/api/v1/get-mfa-validated";
  return await getUrl(url).catch((error) => {
    throw error;
  });
};

export const getStats = async (pid: number): Promise<AxiosResponse> => {
  const url = `/api/v1/get-stats?pid=${pid}`;
  return await getUrl(url)
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
};

export const getSavedSearchTerms = async (
  pid: number,
  brief_id: number
): Promise<ContentBriefs> => {
  const url = `/api/v1/saved-keywords?pid=${pid}&brief_id=${brief_id}`;
  return await getUrl(url)
    .then((res) => res.data as ContentBriefs)
    .catch((error) => {
      throw error;
    });
};

export const getOrderedSearchTerms = async (
  pid: number,
  brief_id: number
): Promise<ISearchTermPayload> => {
  const url = `/api/v1/get-ordered-search-terms?pid=${pid}&brief_id=${brief_id}`;
  return await getUrl(url, {}, false)
    .then((res) => res.data as ISearchTermPayload)
    .catch((error) => {
      throw error;
    });
};

export const getSavedBrief = async (brief_id: number): Promise<ISavedBrief> => {
  const url = `/api/v1/get-saved-briefs?brief_id=${brief_id}`;
  return await getUrl(url, {}, false)
    .then((res) => res.data as ISavedBrief)
    .catch((error) => {
      throw error;
    });
};

export const getNotes = async (
  outline_status_id: number
): Promise<INotes[]> => {
  const url = `/api/v1/get-notes?outline_status_id=${outline_status_id}`;
  return await getUrl(url, {}, false)
    .then((res) => res.data as INotes[])
    .catch((error) => {
      throw error;
    });
};

export const getCTAs = async (
  saved_brief_id: number
): Promise<ISavedBriefCTA[]> => {
  const url = `/api/v1/get-saved-brief-ctas?saved_brief_id=${saved_brief_id}`;
  return await getUrl(url, {}, false)
    .then((res) => res.data as ISavedBriefCTA[])
    .catch((error) => {
      throw error;
    });
};
