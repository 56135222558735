import useContentBriefsStore from "@/hooks/useContentBriefsStore";
import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";
import "../step-three.scss";
import { stepThreeStyles } from "./utils/style";
const Instructions = ({
  wordCount,
  typeOfTerm
}: {
  wordCount: string;
  typeOfTerm: string;
}) => {
  const {
    pillar,
    subPillar,
    subPillarPublishedUrl,
    pillarPublishedUrl
  }: IContentBriefsStore = useContentBriefsStore(
    (store: IContentBriefsStore) => ({
      pillar: store.pillar,
      subPillar: store.subPillar,
      subPillarPublishedUrl: store.subPillarPublishedUrl,
      pillarPublishedUrl: store.pillarPublishedUrl
    })
  );
  return (
    <div
      data-testid="instructions-section"
      style={{ padding: "40px 75px 40px  75px ", background: "#F7FDFF" }}
    >
      <div
        className="title-photo-title-line-2"
        style={stepThreeStyles.titlePhotoTitleLine2}
      >
        Instructions
      </div>
      <div
        style={{
          paddingTop: "20px",
          paddingBottom: "20px"
        }}
      >
        <div
          style={{
            backgroundColor: "#E45D28",
            height: "4px",
            width: "120px"
          }}
        ></div>
      </div>
      <div
        className="paragraph-text clipboard copy-text"
        style={stepThreeStyles.paragraphText}
      >
        {typeOfTerm === "Pillar" && (
          <p id="copy-text">
            This article will be a Pillar Page as part of a broader Pillar
            Strategy. As one part of a network of content related to the Pillar
            Topic of “
            {pillar && <span data-testid="instructions-pillar">{pillar}</span>}
            ”, it is designed to offer your broadest possible survey of content
            related to that core topic. This page, in turn, will serve as a
            “network hub” and destination for internal links from other, related
            pieces of content that dive deeper into the broader topic. The
            information contained within this content brief is designed to help
            you write an approximately{" "}
            {wordCount && (
              <span data-testid="instructions-content-length">{wordCount}</span>
            )}
            -word article that covers the specific facets of this topic that
            people are most interested in learning about.
          </p>
        )}
        {typeOfTerm === "Sub Pillar" && (
          <p id="copy-text">
            This article will be a Sub-Pillar Page as part of a broader Pillar
            Strategy. As one part of a network of content related to the Pillar
            Topic of “
            {pillar && <span data-testid="instructions-pillar">{pillar}</span>}
            ”, it is designed to offer an overview of a particular aspect of
            this broader topic. The information contained within this content
            brief is designed to help you write an approximately{" "}
            {wordCount && (
              <span data-testid="instructions-content-length">{wordCount}</span>
            )}
            -word article that covers the specific facets of this topic that
            people are most interested in learning about.
          </p>
        )}
        {typeOfTerm === "Blog" && (
          <p id="copy-text">
            This article will be a Supporting Blog post as part of a broader
            Pillar Strategy. As one part of a network of content related to the
            Pillar Topic of “
            {pillar && <span data-testid="instructions-pillar">{pillar}</span>}
            ”, it is designed to answer one specific and important question the
            market asks search engines. The information contained within this
            content brief is designed to help you write an approximately{" "}
            {wordCount && (
              <span data-testid="instructions-content-length">{wordCount}</span>
            )}
            -word article that covers the specific facets of this topic that
            people are most interested in learning about.
          </p>
        )}
        {typeOfTerm === "Ad Hoc" && (
          <p id="copy-text">
            The information contained within this content brief is designed to
            help you write an approximately{" "}
            {wordCount && (
              <span data-testid="instructions-content-length">{wordCount}</span>
            )}
            -word article that covers the specific facets of this topic that
            people are most interested in learning about.
          </p>
        )}
        <p>
          Below, you’ll find the meta information for this article, including
          its focus keyword which doubles as its title. You’ll find notes to
          help you understand your audience and the brand’s voice, as well as a
          detailed outline of the final article.
        </p>
        <p>
          <span>
            It is critical that the finished article follows this outline
            closely, using the exact heading titles and keywords where noted in
            each section of the outline.
          </span>
          {typeOfTerm !== "Ad Hoc" && (
            <>
              <span>
                {" "}
                Additionally, the outline will contain recommended links to
                include in this article in order to construct the proper
                internal linking structure that creates the network effect
                intended by the Pillar-Based Marketing methodology for this
                Pillar Strategy.
              </span>
              <br />
            </>
          )}
          <br />
          <span>
            {" "}
            As you write, pay special attention to any quotes or impactful data
            points you can denote as candidates for simple, custom graphics to
            be created around and published in the body copy of the final
            article.
          </span>
        </p>
        <p>
          {pillar && typeOfTerm !== "Pillar" && (
            <div>
              {pillarPublishedUrl ? (
                <div>
                  You will want to link to the parent Pillar: "{pillar}" with
                  the url {pillarPublishedUrl}.
                </div>
              ) : (
                <div>
                  You will want to link to the parent Pillar: "{pillar}" once
                  you have a url for that blog.
                </div>
              )}
            </div>
          )}

          {subPillar && typeOfTerm === "Blog" && (
            <div>
              {subPillarPublishedUrl ? (
                <div>
                  You will want to link to parent Sub Pillar: "{subPillar}" with
                  the url {subPillarPublishedUrl}.
                </div>
              ) : (
                <div>
                  You will want to link to parent Sub Pillar: "{subPillar}" once
                  you have a url for that blog.
                </div>
              )}
            </div>
          )}
        </p>
      </div>
    </div>
  );
};

export default Instructions;
