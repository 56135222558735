import { Card } from "react-bootstrap";
import ContactUsLink from "@/components/shared/ContactUsLink";

const UpgradeSubscriptionModal = () => (
  <Card style={{ minHeight: "255px" }}>
    <Card.Header
      style={{
        backgroundColor: "white",
        borderBottom: "0px",
        marginTop: "15px"
      }}
      closeButton
    >
      <Card.Title style={{ textTransform: "none" }} className="w-75 mx-auto">
        Do you want to add to your pillar topics?
      </Card.Title>
    </Card.Header>
    <Card.Body className="d-flex justify-content-center">
      Upgrade your plan to have more control over your pillar-based marketing
      strategy.
    </Card.Body>
    <Card.Footer
      className="d-flex justify-content-around"
      style={{ backgroundColor: "white", borderTop: "0px" }}
    >
      <ContactUsLink />
    </Card.Footer>
  </Card>
);

export default UpgradeSubscriptionModal;
