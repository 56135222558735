import ContactUsLink from "@/components/shared/ContactUsLink";

function DisplayAds() {
  return (
    <div className="marketing-card-container">
      <div className="section-header">Display Ads</div>

      <div className="image-text-container">
        <div className="text-button j-sb pr-25">
          <div className="text-header">
            In 2022, cookies have begun the transition into a true black box of
            utility—how long can marketers rely upon them to serve targeted
            content to hungry audiences?
          </div>
          <div className="text">
            <p>
              Avoid cookies altogether with a winning Display advertising
              campaign to ensure your ads are visible to people who are actually
              in the market for what your brand offers.
            </p>

            <br />
            <p>
              With our Display dashboard, identify the most relevant websites
              around your mission-critical Pillar Topics, so you know exactly
              where to meet your customers as they look for answers online.
            </p>
          </div>
          <ContactUsLink />
        </div>
        <div className="image-stack-container">
          <div className="image-stack">
            <div className="image image-bottom">
              <img
                src={require("../../../assets/rd_paid_channels_top_250_websites@4x.png")}
                alt="Top 250 Websites"
                style={{ width: "185.1px", height: "191.1px" }}
              />
            </div>
            <div className="image image-bottom-right">
              <img
                src={require("../../../assets/rd_paid_channels_consideration_websites_top_250@4x.png")}
                alt="Consideration Websites"
                style={{ width: "185.1px", height: "191.1px" }}
              />
            </div>
            <div className="image image-top-left">
              <img
                src={require("../../../assets/rd_paid_channels_awareness_websites_top_250@4x.png")}
                alt="Awareness Websites"
                style={{ width: "185.1px", height: "191.1px" }}
              />
            </div>
            <div className="image image-top-right">
              <img
                src={require("../../../assets/rd_paid_channels_decision_websites_top_250@4x.png")}
                alt="Decision Websites"
                style={{ width: "185.1px", height: "191.1px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DisplayAds;
