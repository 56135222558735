import { useEffect, useState } from "react";
import isValidDomain from "is-valid-domain";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import "../competitors.scss";
import IconSuccessCompleteLogo from "../../../../assets/icons/icon-success-complete.svg";
import IconErrorLogo from "../../../../assets/icons/icon-error.svg";
import { formatDomain } from "../../../../utilities/domain";
import { useAppStore } from "@/hooks";

dayjs.extend(utc);

const CompetitorRow = ({
  rowNumber,
  competitor,
  updateCompetitorArray,
  initialCompetitors,
  isSaving,
  duplicateCompetitor,
  setDuplicateCompetitor
}) => {
  const [name, setName] = useState(competitor?.name || "");
  const [isError, setIsError] = useState(false);
  const [initialCompetitor] = useState(competitor?.name);
  const [isChanged, setIsChanged] = useState(false);

  const { setShowAlertBanner } = useAppStore((store) => ({
    setShowAlertBanner: store.setShowAlertBanner
  }));
  useEffect(() => setIsChanged(false), [initialCompetitors]);
  const handleChange = (event) => {
    const sanitized_competitors = formatDomain(event.target.value);
    const allCompetitorNames = initialCompetitors.map(
      (competitorName) => competitorName.name
    );
    allCompetitorNames.every((item) => {
      if (item === event.target.value) {
        setShowAlertBanner(true, {
          message: `${event.target.value} is already listed as a competitor`,
          severity: "error"
        });
        setDuplicateCompetitor(true);
      } else {
        setDuplicateCompetitor(false);
      }
      return item !== event.target.value;
    });

    if (
      sanitized_competitors !== initialCompetitor &&
      event.target.value !== ""
    ) {
      setIsChanged(true);
    } else if (isChanged) {
      setIsChanged(false);
    }
    setName(sanitized_competitors);
    updateCompetitorArray(sanitized_competitors, rowNumber);
    if (sanitized_competitors && !duplicateCompetitor) {
      setIsError(!isValidDomain(sanitized_competitors));
    } else {
      setIsError(false);
    }
  };

  return (
    <div
      className="d-flex"
      style={{ marginRight: "-2px", padding: "0px", width: "100%" }}
    >
      <div
        className="demandjump-competitors-control col-7"
        style={{ padding: "0", width: "100%" }}
      >
        <label
          className="col-form-label competitor-label"
          style={{ width: "25px" }}
        >
          {rowNumber}.
        </label>
        <input
          type={"text"}
          value={name}
          onChange={handleChange}
          style={{ maxWidth: "90%", display: "initial" }}
          className={`form-control ${
            isError
              ? "competitors-form-control-error-invalid"
              : "competitors-form-control"
          }`}
        ></input>
        {isChanged && !isError && !duplicateCompetitor && !isSaving && (
          <small
            className="form-text text-success col-10 position-absolute"
            style={{
              marginLeft: "50px"
            }}
          >
            Ready to update
          </small>
        )}
        {isError && (
          <div>
            <small
              className="form-text error-invalid col-10 position-absolute"
              style={{ marginLeft: "25px" }}
            >
              Invalid domain
            </small>
          </div>
        )}
      </div>
      {isChanged && !isError && !duplicateCompetitor && !isSaving && (
        <img src={IconSuccessCompleteLogo} alt="Success Check Logo" />
      )}
      {isError && <img src={IconErrorLogo} alt="Error Exclamation Logo" />}
    </div>
  );
};

export default CompetitorRow;
