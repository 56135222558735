import * as React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "react-responsive";
import { useEffectAsync, useAppStore } from "../../../../../hooks";
import * as yup from "yup";
import { ActionTrash, NavigationArrowBack } from "@/assets/icons/svgr";
import DJForm from "@/components/shared/DJForm";
import DJTextField from "@/components/shared/DJTextField";
import Button from "@/components/shared/Button";
import { getBookmarks } from "../../../../../api/get";
import { postBookmark } from "../../../../../api/post";
import {
  IBookmark,
  IMenuItem
} from "../../../../../interfaces/navigation.interface";
import { IAppStore } from "../../../../../interfaces/app-store.interface";
import DeleteBookmarkModal from "./DeleteBookmarkModal";
import SelectDropDown from "../../../../shared/SelectDropDown";

const Bookmarks = ({
  setBookmarksMenuOpen
}: {
  setBookmarksMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [menuItems, setMenuItems] = React.useState<IMenuItem[]>([]);
  const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
  const [deleteBookmarkModalOpen, setDeleteBookmarkModalOpen] =
    React.useState<boolean>(false);
  const [selectedBookmarkToDelete, setSelectedBookmarkToDelete] =
    React.useState<IBookmark>({} as IBookmark);
  const { selectedPidObject, setShowAlertBanner } = useAppStore(
    (store: IAppStore) => ({
      selectedPidObject: store.selectedPidObject,
      setShowAlertBanner: store.setShowAlertBanner
    })
  );

  const handleOpenBookmarkModal = (bookmark: IBookmark) => {
    setSelectedBookmarkToDelete(bookmark);
    setDeleteBookmarkModalOpen(true);
  };
  useEffectAsync(async () => {
    const response = await getBookmarks();
    const temp: IMenuItem[] = [];
    response.forEach((bookmark: IBookmark) => {
      if (bookmark.is_morpheus) {
        temp.push({
          menuItemIcon: (
            <ActionTrash
              style={{ width: "24px" }}
              onClick={(e) => {
                e.stopPropagation();
                handleOpenBookmarkModal(bookmark);
              }}
            />
          ),
          id: bookmark.id,
          label: bookmark.name,
          value: bookmark.url
        });
      }
    });
    setMenuItems(temp);
  }, []);

  const validationSchema = yup.object({
    bookmarkName: yup.string().required("Enter a bookmark name")
  });

  type ValuesD = {
    bookmarkName: string;
  };

  const handleSubmit = async (values: ValuesD) => {
    try {
      const bookmark = await postBookmark(
        values.bookmarkName,
        window.location.href,
        selectedPidObject.id
      );
      if (bookmark) {
        setMenuItems([
          ...menuItems,
          {
            menuItemIcon: (
              <ActionTrash
                style={{ width: "24px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenBookmarkModal(bookmark);
                }}
              />
            ),
            id: bookmark.id,
            label: bookmark.name,
            value: bookmark.url
          }
        ]);
        setBookmarksMenuOpen(false);
        setShowAlertBanner(true, {
          message: "New Bookmark has successfully been saved.",
          severity: "success"
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
      setShowAlertBanner(true, {
        message: "Failed to validate invitation",
        severity: "error"
      });
    }
  };

  const handleSelect = (selectedOption: IMenuItem | null) => {
    if (selectedOption) {
      window.open(selectedOption.value);
    }
  };

  return deleteBookmarkModalOpen ? (
    <DeleteBookmarkModal
      selectedBookmarkToDelete={selectedBookmarkToDelete}
      setDeleteBookmarkModalOpen={setDeleteBookmarkModalOpen}
      menuItems={menuItems}
      setMenuItems={setMenuItems}
    />
  ) : (
    <Card
      sx={{
        width: "380px",
        height: "fit-content",
        paddingTop: isMobile ? "8px" : "30px",
        paddingBottom: "30px",
        overflow: "visible"
        // position: "absolute"
      }}
    >
      <Grid item xs={12}>
        <ListItem
          disablePadding
          sx={{
            display: "block",
            "&:hover": {
              background: isMobile ? "#E1F5FF" : "#FFFFFF",
              color: "#008CCE"
            }
          }}
          onClick={() => setBookmarksMenuOpen(false)}
        >
          {isMobile ? (
            <ListItemButton
              disableGutters
              sx={{
                minHeight: 37,
                px: 2.5,
                height: "37px",
                justifyContent: "initial",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent"
                }
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "24px",
                  mr: "15px",
                  justifyContent: "center",
                  color: "#373F50"
                }}
              >
                <NavigationArrowBack style={{ width: "24px" }} />
              </ListItemIcon>
              <ListItemText
                primary="Bookmarked Reports"
                primaryTypographyProps={{
                  fontFamily: "'Source Sans Pro'",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "20px",
                  letterSpacing: "0.005em",
                  color: "#008CCE"
                }}
              />
            </ListItemButton>
          ) : (
            <ListItemText
              sx={{ paddingX: "30px" }}
              primary="Bookmarked Reports"
              primaryTypographyProps={{
                fontFamily: "'Source Sans Pro'",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "20px",
                letterSpacing: "0.005em",
                color: "#008CCE"
              }}
            />
          )}
        </ListItem>
      </Grid>
      <Box sx={{ paddingTop: "20px", paddingX: "30px" }}>
        <DJForm
          onSubmit={(values: ValuesD) => {
            handleSubmit(values);
          }}
          validationSchema={validationSchema}
          initialValues={
            {
              bookmarkName: "",
              bookmark: ""
            } as ValuesD
          }
        >
          <DJTextField
            type="text"
            name="bookmarkName"
            labelText="Name Your Report"
            label="ex: Topics Last Quarter"
          />
          <Box
            sx={{
              width: "fit-content",
              marginLeft: "auto",
              paddingTop: "30px"
            }}
          >
            <Button
              type="submit"
              variant="contained"
              size="large"
              text="Save Report"
              sx={{
                width: "100px",
                height: "32px",
                fontFamily: "'Source Sans Pro'",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "18px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                letterSpacing: "0.0125em",
                textTransform: "capitalize",
                color: "#FFFFFF",
                padding: 0
              }}
            />
          </Box>
          <Box sx={{ paddingTop: "30px", position: "relative" }}>
            <SelectDropDown
              placeholder="Show Bookmarked Reports"
              options={menuItems}
              onChange={handleSelect}
            />
          </Box>
        </DJForm>
        {menuItems.length === 0 && (
          <div
            style={{
              paddingLeft: "10px",
              fontFamily: "'Source Sans Pro'",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "15px",
              letterSpacing: "0.004em",
              color: "#373F50"
            }}
          >
            No reports saved
          </div>
        )}
      </Box>
    </Card>
  );
};
export default Bookmarks;
