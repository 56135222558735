import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IPDomain } from "../../../../interfaces/pdomain.interface";
import { IAccount } from "../../../../interfaces/account.interface";

const PDomainBadge = ({
  account,
  pdomain
}: {
  account: IAccount;
  pdomain: IPDomain;
}) => (
  <Box sx={{ display: "flex", flexDirection: "column" }}>
    <Typography
      style={{
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "13px",
        lineHeight: "17px",
        textAlign: "right",
        color: "white"
      }}
    >
      {account?.company_name}
    </Typography>
    <Typography
      style={{
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "13px",
        lineHeight: "17px",
        textAlign: "right",
        color: "white"
      }}
    >
      {pdomain?.display_name}
    </Typography>
  </Box>
);
export default PDomainBadge;
