import { useState } from "react";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import Async from "react-select/async";
import { getLocationData } from "../../api/get";

const defaultOptions = [
  { value: "United States", label: "United States" },
  { value: "Brazil", label: "Brazil" },
  { value: "Mexico", label: "Mexico" },
  { value: "Japan", label: "Japan" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Russia", label: "Russia" },
  { value: "Buryatia,Russia", label: "Buryatia, Russia" },
  { value: "Germany", label: "Germany" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "California,United States", label: "California, United States" },
  {
    value: "Austin,Texas,United States",
    label: "Austin, Texas, United States"
  }
];

const LocationSelect = ({
  parentCallback,
  location,
  isDisabled,
  isError,
  style,
  showReadyToUpdateText,
  isClearable
}) => {
  const [locationArray, setLocationArray] = useState(defaultOptions);

  const callGetLocationData = async (inputValue, callback) => {
    if (!inputValue) {
      callback([]);
    }
    await getLocationData(inputValue)
      .then((res) => {
        const locArray = [];
        res.forEach((loc) => {
          const newLocObj = {
            value: loc.canonical_name,
            label: loc.canonical_name.replaceAll(",", ", ")
          };
          locArray.push(newLocObj);
        });
        setLocationArray(locArray);
        callback(locArray);
      })
      .catch((err) => {
        console.error(err);
        callback([]);
      });
  };

  const setLocationOverride = (e) => {
    const res = {
      location_name: e?.value ? e.value : "United States"
    };
    parentCallback(res);
  };
  const wait = 500;
  const loadLocations = debounce(callGetLocationData, wait);

  return (
    <div
      id="x"
      data-testid="location-select"
      style={{ boxShadow: "none", fontSize: "16px" }}
    >
      <Async
        menuPosition="fixed"
        loadOptions={loadLocations}
        defaultOptions={locationArray}
        placeholder="Search Location"
        onChange={setLocationOverride}
        value={location}
        noOptionsMessage={() => "No Locations Found."}
        isDisabled={isDisabled}
        isClearable={isClearable}
        styles={
          style && Object.keys(style).length > 0
            ? { ...style }
            : {
                indicatorSeparator: () => ({ display: "none" }),
                container: (styles) => ({
                  ...styles,
                  height: "48px",
                  width: "272px",
                  fontSize: "20px",
                  fontFamily: "'Source Sans Pro', sans-serif",
                  color: "#373F50",
                  marginRight: "16px"
                }),
                menu: (styles) => ({
                  ...styles,
                  padding: "0px",
                  boxShadow: "none",
                  marginTop: "0px"
                }),
                menuList: (base) => ({
                  ...base,
                  backgroundColor: "#FFFFF",
                  border: "none",
                  width: "272px",
                  maxHeight: "196px",
                  borderRadius: "4px",
                  padding: "0px",
                  boxShadow: "0px 4px 8px rgba(55, 63, 80, 0.25)",
                  "::-webkit-scrollbar": {
                    width: "5px",
                    opacity: "0"
                  },
                  "::-webkit-scrollbar-track": {
                    bordeRadius: "10px"
                  },
                  "::-webkit-scrollbar-thumb": {
                    background: "#909BB3",
                    borderRadius: "2.5px"
                  },
                  "::-webkit-scrollbar-thumb:hover": {
                    background: "#373F50"
                  }
                }),
                noOptionsMessage: (base) => ({
                  ...base,
                  color: "#373f50"
                }),
                control: (styles, { isFocused }) => ({
                  ...styles,
                  boxShadow: "none",
                  border: isFocused ? "1px solid #008CCE" : "1px solid #D3D7E0"
                }),
                option: (styles, { isFocused, isSelected }) => ({
                  ...styles,
                  backgroundColor:
                    isSelected || isFocused ? "#E1F5FF" : "#FFFFFF",
                  color: "#373F50;",
                  padding: "12px 8px"
                }),
                dropdownIndicator: (styles, state) => ({
                  ...styles,
                  color: state.selectProps.isDisabled
                    ? "hsl(0, 0%, 60%)"
                    : "#373F50",
                  transform: state.selectProps.menuIsOpen
                    ? "rotate(180deg)"
                    : ""
                })
              }
        }
        isError={isError}
        showReadyToUpdateText={showReadyToUpdateText}
      />
    </div>
  );
};

LocationSelect.propTypes = {
  parentCallback: PropTypes.func,
  setLocation: PropTypes.func,
  location: PropTypes.object,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  isError: PropTypes.bool,
  showReadyToUpdateText: PropTypes.bool,
  style: PropTypes.object
};

LocationSelect.defaultProps = {
  isDisabled: false,
  isClearable: false,
  style: {}
};

export default LocationSelect;
