/* eslint-disable max-depth */
import { useAppStore, useEffectAsync } from "@/hooks";
import useContentBriefsStore from "@/hooks/useContentBriefsStore";
import { IAppStore } from "@/interfaces/app-store.interface";
import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";
import { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";

import {
  IBriefSearchTerm,
  IDroppableArea,
  IBodyDroppableArea,
  IOrderedSearchtermFormatted,
  ISearchTermPayload
} from "@/interfaces/outline.interface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./stepTwo.scss";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import QuestionArea from "./components/QuestionArea";
import KeywordsArea from "./components/KeywordsArea";
import IntroductionArea from "./components/IntroductionArea";
import ConclusionArea from "./components/ConclusionArea";
import Summary from "./components/Summary";
import { postMetaDescription, updateContentBriefStep } from "@/api/post";
import { handleDragBodyItems } from "./components/utility";
import Uncategorized from "./components/Uncategorized";
import {
  getOrderedSearchTerms,
  getSavedBrief,
  getSavedSearchTerms
} from "@/api/get";
import BreadcrumbsHeader from "../components/BreadcrumbsHeader";
import { useNavigate, useLocation } from "react-router-dom";
import BodyArea from "./components/BodyArea";

const StepTwo = () => {
  const {
    isStepOneComplete,
    isStepTwoActive,
    briefId,
    setShowSaved,
    setBriefId,
    metaDescription,
    setIsStepOneComplete,
    setIsStepTwoComplete,
    setIsStepThreeComplete,
    setIsStepTwoActive,
    setIsStepThreeActive,
    draggedItems,
    setDraggedItems,
    setTargetedKeywords,
    setTargetedQuestions,
    targetedQuestions,
    targetedKeywords,
    areasYouCanDropItemsFrom,
    setAreasYouCanDropItemsFrom,
    bodySectionDroppableAreas,
    setMetaDescription,
    setBodySectionDroppableAreas,
    setKeywordsLength,
    setQuestionsLength
  }: IContentBriefsStore = useContentBriefsStore(
    (store: IContentBriefsStore) => ({
      isStepTwoActive: store.isStepTwoActive,
      briefId: store.briefId,
      setShowSaved: (value: boolean) => store.setShowSaved(value),
      draggedItems: store.draggedItems,
      metaDescription: store.metaDescription,
      targetedQuestions: store.targetedQuestions,
      targetedKeywords: store.targetedKeywords,
      isStepOneComplete: store.isStepOneComplete,
      areasYouCanDropItemsFrom: store.areasYouCanDropItemsFrom,
      bodySectionDroppableAreas: store.bodySectionDroppableAreas,
      setIsStepOneComplete: (value: boolean) =>
        store.setIsStepOneComplete(value),
      setIsStepTwoComplete: (value: boolean) =>
        store.setIsStepTwoComplete(value),
      setIsStepThreeComplete: (value: boolean) =>
        store.setIsStepThreeComplete(value),
      setIsStepThreeActive: (value: boolean) =>
        store.setIsStepThreeActive(value),
      setMetaDescription: (value: string) => store.setMetaDescription(value),
      setBriefId: (value: number) => store.setBriefId(value),
      setDraggedItems: (value: string[]) => store.setDraggedItems(value),
      setIsStepTwoActive: (value: boolean) => store.setIsStepTwoActive(value),
      setTargetedKeywords: (value: IBriefSearchTerm[]) =>
        store.setTargetedKeywords(value),
      setTargetedQuestions: (value: IBriefSearchTerm[]) =>
        store.setTargetedQuestions(value),
      setAreasYouCanDropItemsFrom: (value: IDroppableArea[]) =>
        store.setAreasYouCanDropItemsFrom(value),
      setBodySectionDroppableAreas: (value: IBodyDroppableArea[]) =>
        store.setBodySectionDroppableAreas(value),
      setKeywordsLength: (value: number) => store.setKeywordsLength(value),
      setQuestionsLength: (value: number) => store.setQuestionsLength(value)
    })
  );
  const { selectedPidObject } = useAppStore((store: IAppStore) => ({
    selectedPidObject: store.selectedPidObject
  }));
  const [searchTermsClicked, setSearchTermsClicked] = useState(true);
  const [summaryClicked, setSummaryClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [brief, setBrief] = useState<ISearchTermPayload>();
  const navigate = useNavigate();
  const location = useLocation();
  const viewHeight =
    window.innerHeight - 360 < 315 ? 315 : window.innerHeight - 200;

  // useEffect to create body sections from each of the targeted questions
  // and set the targeted questions and targeted keywords as draggable sections
  useEffectAsync(async () => {
    const path = location.pathname.split("/");
    const brief_id = parseInt(path[path.length - 1]) || 0;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bodyDroppableAreas: any = [...areasYouCanDropItemsFrom];

    if (isStepOneComplete) {
      setIsStepTwoActive(true);
    }

    setBriefId(brief_id);
    if (selectedPidObject.id && briefId) {
      const queries: string[] = [];
      const introItems: IBriefSearchTerm[] = [];
      const concItems: IBriefSearchTerm[] = [];
      const topItems: IBriefSearchTerm[] = [];
      if (selectedPidObject.id && briefId) {
        const content_brief = await getSavedSearchTerms(
          selectedPidObject.id,
          briefId
        );
        if (content_brief.pid !== selectedPidObject.id) {
          navigate("/pillar-strategy");
          return;
        }
        if ([2, 3, 4].includes(content_brief.current_step)) {
          setIsStepOneComplete(true);
        }
        if ([3, 4].includes(content_brief.current_step)) {
          setIsStepTwoComplete(true);
        }
        if (content_brief.current_step === 4) {
          setIsStepThreeComplete(true);
        }

        const foundbrief = await getOrderedSearchTerms(
          selectedPidObject.id,
          briefId
        );
        if (foundbrief && foundbrief.ordered_sections.body.length > 0) {
          foundbrief.ordered_sections.introduction.forEach((item) => {
            queries.push(item.search_term.query);
            const termWithNewId = {
              ...item.search_term,
              id: parseInt(uuidv4(), 16)
            };
            introItems.push(termWithNewId);
          });
          foundbrief.ordered_sections.conclusion.forEach((item) => {
            queries.push(item.search_term.query);
            const termWithNewId = {
              ...item.search_term,
              id: parseInt(uuidv4(), 16)
            };
            concItems.push(termWithNewId);
          });

          // this is where body section number is initially set
          const groupedSections: { [key: string]: IBodyDroppableArea } = {};
          foundbrief.ordered_sections.body.forEach((item) => {
            const key = `body_order ${item.body_order}`;
            if (!groupedSections[key]) {
              // If the key doesn't exist in the groupedSections object, create it
              groupedSections[key] = {
                stringUUID: uuidv4(),
                name: `Body Section ${Object.keys(groupedSections).length + 1}`,
                topItems: [],
                bottomItems: []
              };
            }
            const termWithNewId = {
              ...item.search_term,
              id: parseInt(uuidv4(), 16)
            };
            if (item.order_within_section === 0) {
              groupedSections[key].topItems?.push(termWithNewId);
            } else {
              groupedSections[key].bottomItems.push(termWithNewId);
            }
          });
          const objectsWithoutKeys = Object.values(groupedSections);
          bodyDroppableAreas.push(...objectsWithoutKeys);
          setBodySectionDroppableAreas(
            bodyDroppableAreas.concat(
              {
                stringUUID: uuidv4(),
                name: "Introduction",
                bottomItems: introItems
              },
              {
                stringUUID: uuidv4(),
                name: "Conclusion",
                bottomItems: concItems
              }
            )
          );
          // dragged items is to make sure the green check mark shows up if the term is used in the brief
          foundbrief.ordered_sections.body.forEach(
            (item: IOrderedSearchtermFormatted) => {
              queries.push(item.search_term.query);
              setDraggedItems(queries);
            }
          );
        } else {
          // take the targeted questions and make a new body section for each one with a new id so that drag and drop functions
          targetedQuestions.forEach((item: IBriefSearchTerm) => {
            queries.push(item.query);
            const questionWithNewId = { ...item, id: parseInt(uuidv4(), 16) };
            topItems.push(questionWithNewId);
          });
          setDraggedItems(queries);
        }
      }
      // modify the topItems array with the correctly formatted top items we get from the targeted questions
      topItems.forEach((item, index) => {
        bodyDroppableAreas.push({
          stringUUID: uuidv4(),
          name: `Body Section ${index + 1}`,
          topItems: [item],
          bottomItems: []
        });
      });

      if (topItems.length > 0) {
        setIsLoading(false);
      }
      // add the introduction and conclusion sections to the bodyDroppableAreas array
      // so the final bodyDroppableAreas array will have
      // 1. the targeted questions as body sections
      // 2. the introduction and conclusion sections
      setBodySectionDroppableAreas(
        bodyDroppableAreas.concat(
          {
            stringUUID: uuidv4(),
            name: "Introduction",
            bottomItems: introItems
          },
          {
            stringUUID: uuidv4(),
            name: "Conclusion",
            bottomItems: concItems
          }
        )
      );
      // Now, add the remaining static sections
      const staticSections = [
        {
          stringUUID: uuidv4(),
          name: "Keywords",
          items: targetedKeywords
        },
        {
          stringUUID: uuidv4(),
          name: "Questions",
          items: targetedQuestions
        }
      ];
      setAreasYouCanDropItemsFrom(staticSections);
    }
  }, [
    selectedPidObject.id,
    briefId,
    isStepOneComplete,
    isStepTwoActive,
    targetedQuestions,
    brief
  ]);

  // whenever bodySectionDroppableArea changes, update allBodyItems with the new order
  // and format the data to be sent to the drag and drop library.
  useEffect(() => {
    const formattedBodyBottomItems: IOrderedSearchtermFormatted[] = [];
    const formattedBodyTopItems: IOrderedSearchtermFormatted[] = [];
    // loop over the body section droppable sections and format the data
    bodySectionDroppableAreas.map((section, idx) => {
      if (section.name.includes("Body")) {
        const formattedBottomItems = section.bottomItems
          .map((bottomItem: IBriefSearchTerm, index: number) => {
            if (bottomItem) {
              return {
                search_term_id: bottomItem.id || 0,
                search_term: bottomItem,
                body_order: idx,
                order_within_section: index + 1
              } as IOrderedSearchtermFormatted;
            }
            return {} as IOrderedSearchtermFormatted;
          })
          .filter(Boolean);
        formattedBodyBottomItems.push(...formattedBottomItems);

        const formattedTopItems = section?.topItems
          ?.map((topItem: IBriefSearchTerm) => {
            if (topItem) {
              return {
                search_term_id: topItem.id || 0,
                search_term: topItem,
                body_order: idx - 2,
                order_within_section: 0
              } as IOrderedSearchtermFormatted;
            }
            return {} as IOrderedSearchtermFormatted;
          })
          .filter(Boolean);
        if (formattedTopItems) {
          formattedBodyTopItems.push(...formattedTopItems);
        }
      }
      return null;
    });
  }, [bodySectionDroppableAreas]);

  // useeffect for refreshing page to maintain new dropped data on the page
  useEffectAsync(async () => {
    if (selectedPidObject.id && briefId) {
      const foundbrief = await getOrderedSearchTerms(
        selectedPidObject.id,
        briefId
      );
      if (foundbrief) {
        setBrief(foundbrief);
      }
      const savedBrief = await getSavedBrief(briefId);
      // Set meta description from saved brief if available
      if (savedBrief.meta_description) {
        setMetaDescription(savedBrief.meta_description);
      } else {
        setMetaDescription("");
      }
      const keywords = foundbrief.targeted_keywords.filter(
        (item) => item.targeted
      );
      const questions = foundbrief.targeted_questions.filter(
        (item) => item.targeted
      );
      setTargetedKeywords(keywords);
      setTargetedQuestions(questions);
      setKeywordsLength(keywords.length);
      setQuestionsLength(questions.length);
    }
  }, [selectedPidObject.id, briefId]);

  // handles clicks
  const handleSearchTermsClick = () => {
    setSearchTermsClicked(true);
    setSummaryClicked(false);
    // post metadescription if clicked away from the summary tab
    postMetaDescription(briefId, metaDescription);
  };

  const handleSummaryClick = () => {
    setSearchTermsClicked(false);
    setSummaryClicked(true);
  };

  const handleSubmit = () => {
    postMetaDescription(briefId, metaDescription);
    setIsStepTwoActive(false);
    setIsStepThreeActive(true);
    updateContentBriefStep(briefId.toString(), 3);
    // set step two is complete and step three to active controls the header steps
    setIsStepThreeActive(true);
    navigate(
      `/content-briefs-workflow/step-three/${briefId}${location.search}`
    );
  };
  // when user clicks button to add a new body section, this function adds the data to the bodySectionDroppableArea
  const handleNewSection = () => {
    // get count of body sections to assign correct body section number
    const count = bodySectionDroppableAreas.filter((item) =>
      item.name.includes("Body")
    ).length;

    // append to array
    const newColumn = bodySectionDroppableAreas.concat({
      stringUUID: uuidv4(),
      name: `Body Section ${count + 1}`,
      topItems: [],
      bottomItems: []
    });

    setBodySectionDroppableAreas(newColumn);
  };
  return (
    <div style={{ width: "100%" }}>
      <>
        <BreadcrumbsHeader handleSubmit={handleSubmit} />
        {isStepOneComplete && !isLoading && isStepTwoActive && (
          <div style={{ display: "flex", paddingTop: "85px" }}>
            <DragDropContext
              onDragEnd={(result) =>
                handleDragBodyItems(
                  result,
                  areasYouCanDropItemsFrom,
                  setAreasYouCanDropItemsFrom,
                  setBodySectionDroppableAreas,
                  bodySectionDroppableAreas,
                  draggedItems,
                  setDraggedItems,
                  briefId,
                  selectedPidObject.id,
                  targetedKeywords,
                  targetedQuestions,
                  setShowSaved
                )
              }
            >
              <div
                className="col-sm-8"
                style={{
                  borderRight: "1px solid #E9EBF0",
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  height: `${viewHeight}px`,
                  overflowY: "scroll"
                }}
              >
                <IntroductionArea />
                <BodyArea />
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <a onClick={handleNewSection}>
                      <div
                        style={{
                          border: "2px dashed #909BB3",
                          height: 46,
                          backgroundColor: "#FFFFFF",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#909BB3",
                          cursor: "pointer",
                          width: "50vw"
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#909BB3", paddingRight: "10px" }}
                          icon={faPlus}
                        />
                        Add New Section{" "}
                      </div>
                    </a>
                  </div>
                </div>
                <ConclusionArea />
                <Uncategorized />
              </div>
              <div style={{ paddingTop: "30px" }}>
                <div
                  style={{
                    position: "fixed",
                    width: "460px"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      borderBottom: "1px solid #E9EBF0",
                      padding: "0 60px 0px 60px",
                      justifyContent: "space-between",
                      color: "#373F50"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        width: "150px",
                        alignItems: "center",
                        justifyContent: "center",
                        borderBottom: searchTermsClicked
                          ? "3px solid #2ABBFF"
                          : "3px solid white",
                        borderRadius: 1
                      }}
                    >
                      <a onClick={handleSearchTermsClick}>Search Terms</a>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        width: "150px",
                        alignItems: "center",
                        justifyContent: "center",
                        borderBottom: summaryClicked
                          ? "3px solid #2ABBFF"
                          : "3px solid white",
                        borderRadius: 1
                      }}
                    >
                      <a onClick={handleSummaryClick}>Summary</a>
                    </div>
                  </div>
                  <div
                    style={{
                      height: `${viewHeight - 120}px`,
                      overflowY: "scroll"
                    }}
                  >
                    {searchTermsClicked && (
                      <div>
                        <QuestionArea
                          areasYouCanDropItemsFrom={areasYouCanDropItemsFrom}
                        />
                        <KeywordsArea
                          areasYouCanDropItemsFrom={areasYouCanDropItemsFrom}
                        />
                      </div>
                    )}
                    {summaryClicked && (
                      <div>
                        <Summary />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </DragDropContext>
          </div>
        )}
      </>
    </div>
  );
};
export default StepTwo;
