// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widthBasedFlex {
  display: flex;
}
@media (max-width: 1199px) {
  .widthBasedFlex {
    align-items: left;
    justify-content: left;
  }
}
@media (min-width: 1200px) {
  .widthBasedFlex {
    align-items: right;
    justify-content: right;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/manage_content/content_brief/content_brief_workflow/components/BreadcrumbStyles.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAAI;EAFJ;IAGQ,iBAAA;IACA,qBAAA;EAGN;AACF;AAFI;EANJ;IAOQ,kBAAA;IACA,sBAAA;EAKN;AACF","sourcesContent":[".widthBasedFlex {\n    display: flex;\n    @media (max-width: 1199px) {\n        align-items: left;\n        justify-content: left;\n    }\n    @media (min-width: 1200px){\n        align-items: right;\n        justify-content: right;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
