import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";
import { INotes } from "@/interfaces/notes.interface";
import {
  IBodyDroppableArea,
  IBriefSearchTerm,
  IDroppableArea
} from "@/interfaces/outline.interface";
import { ISavedBriefCTA } from "@/interfaces/saved_outline.interface";

const createContentBriefsStore: () => IContentBriefsStore = () => ({
  isStepOneComplete: false,
  isStepTwoComplete: false,
  isStepThreeComplete: false,
  isStepOneActive: false,
  isStepTwoActive: false,
  isStepThreeActive: false,
  termQuantity: 4,
  questionsLength: 0,
  keywordsLength: 0,
  step: "",
  stepComplete: "",
  showSaved: false,
  showDeleteModal: false,
  setShowDeleteModal(value: boolean) {
    this.showDeleteModal = value;
  },
  setShowSaved(value: boolean) {
    this.showSaved = value;
  },
  showAddNoteModal: false,
  setShowAddNoteModal(value: boolean) {
    this.showAddNoteModal = value;
  },
  showEditNoteModal: false,
  setShowEditNoteModal(value: boolean) {
    this.showEditNoteModal = value;
  },
  briefId: 0,
  setBriefId(value: number) {
    this.briefId = value;
  },
  pillar: "",
  subPillar: "",
  blog: "",
  setBlog(value: string) {
    this.blog = value;
  },
  pillarPublishedUrl: "",
  subPillarPublishedUrl: "",
  blogPublishedUrl: "",
  typeOfTerm: "",
  term: "",
  metaDescription: "",
  setMetaDescription(value: string) {
    this.metaDescription = value;
  },
  setStep(value: string) {
    this.step = value;
  },
  setStepComplete(value: string) {
    this.stepComplete = value;
  },
  unusedItems: [] as string[],
  draggedItems: [] as string[],
  targetedKeywords: [] as IBriefSearchTerm[],
  targetedQuestions: [] as IBriefSearchTerm[],
  areasYouCanDropItemsFrom: [] as IDroppableArea[],
  bodySectionDroppableAreas: [] as IBodyDroppableArea[],
  setPillar(value: string) {
    this.pillar = value;
  },
  setBlogPublishedUrl(value: string) {
    this.blogPublishedUrl = value;
  },
  setSubPillar(value: string) {
    this.subPillar = value;
  },
  setPillarPublishedUrl(value: string) {
    this.pillarPublishedUrl = value;
  },
  setSubPillarPublishedUrl(value: string) {
    this.subPillarPublishedUrl = value;
  },
  setTerm(value: string) {
    this.term = value;
  },
  setTypeOfTerm(value: string) {
    this.typeOfTerm = value;
  },
  setUnusedItems(value: string[]) {
    this.unusedItems = value;
  },
  notes: [] as INotes[],
  setNotes(value: INotes[]) {
    this.notes = value;
  },
  CTAs: [] as ISavedBriefCTA[],
  setCTAs(value: ISavedBriefCTA[]) {
    this.CTAs = value;
  },
  setIsStepOneActive(value: boolean) {
    this.isStepOneActive = value;
  },
  setQuestionsLength(value: number) {
    this.questionsLength = value;
  },
  setDraggedItems(value: string[]) {
    this.draggedItems = value;
  },
  setKeywordsLength(value: number) {
    this.keywordsLength = value;
  },
  setIsStepTwoActive(value: boolean) {
    this.isStepTwoActive = value;
  },
  setIsStepThreeActive(value: boolean) {
    this.isStepThreeActive = value;
  },
  setTermQuantity(value: number) {
    this.termQuantity = value;
  },
  setIsStepOneComplete(value: boolean) {
    this.isStepOneComplete = value;
  },
  setIsStepTwoComplete(value: boolean) {
    this.isStepTwoComplete = value;
  },
  setIsStepThreeComplete(value: boolean) {
    this.isStepThreeComplete = value;
  },
  setTargetedKeywords(value: IBriefSearchTerm[]) {
    this.targetedKeywords = value;
  },
  setTargetedQuestions(value: IBriefSearchTerm[]) {
    this.targetedQuestions = value;
  },
  setAreasYouCanDropItemsFrom(value: IDroppableArea[]) {
    this.areasYouCanDropItemsFrom = value;
  },
  setBodySectionDroppableAreas(value: IBodyDroppableArea[]) {
    this.bodySectionDroppableAreas = value;
  }
});

export default createContentBriefsStore;
