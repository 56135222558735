import { useState, useEffect } from "react";
import { Grid, Paper, Stack } from "@mui/material";
import {
  IconHelpCenter,
  IconFileTicket,
  IconTrackTicket,
  IconKeywordList,
  IconSinglePillar,
  IconCompetitorsSwords
} from "@/assets/icons/svgr";
import { useAppStore } from "../../../hooks";
import { IAppStore } from "../../../interfaces/app-store.interface";
import { Link } from "react-router-dom";
import { getHubspotUrl } from "../../../api/get";
import { IUser } from "@/interfaces/user.interface";
import { IAlertBannerOptions } from "@/interfaces/alert-banner-options";

export default function HelpfulLinksCard() {
  const [email, setEmail] = useState("");

  const {
    user,
    setShowAlertBanner
  }: {
    user: IUser;
    setShowAlertBanner(
      bool: boolean,
      alertBannerOptions?: IAlertBannerOptions
    ): void;
  } = useAppStore((store: IAppStore) => ({
    user: store.user,
    setShowAlertBanner: store.setShowAlertBanner
  }));

  useEffect(() => {
    setEmail(user.unique_id);

    return () => {
      setEmail("");
    };
  }, [user]);

  const handleHubspotClick = async (path = "") => {
    await getHubspotUrl(path)
      .then((res) => {
        window.open(res.url, "_blank", "noopener,noreferrer");
      })
      .catch((error) => {
        console.error(error);
        setShowAlertBanner(true, {
          message: `Error opening our help center, please try again later. If problem persists please contact customer success.`,
          severity: "error"
        });
      });
  };

  const linkStyle = {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.0015em",
    textDecorationLine: "underline",
    color: "#008CCE",
    cursor: "pointer"
  };
  const flexRowAC = {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center"
  };
  return (
    <div id="help-links-container">
      <Paper id="paper-gray-border" elevation={0}>
        <Grid container rowSpacing="10px">
          <Grid item>
            <Stack spacing={"10px"}>
              <div className="link-row" style={flexRowAC}>
                <IconCompetitorsSwords
                  style={{
                    width: "24px",
                    marginRight: "8px"
                  }}
                />
                <Link to="/settings/competitors" style={linkStyle}>
                  Set Competitors
                </Link>
              </div>
              <div className="link-row" style={flexRowAC}>
                <IconSinglePillar
                  style={{
                    width: "24px",
                    marginRight: "8px"
                  }}
                />
                <Link to="/pillar-strategy" style={linkStyle}>
                  Manage Pillar Strategy
                </Link>
              </div>
              <div className="link-row" style={flexRowAC}>
                <IconKeywordList
                  style={{
                    width: "24px",
                    marginRight: "8px"
                  }}
                />
                <Link to="/settings/keywords" style={linkStyle}>
                  Create Keyword Lists
                </Link>
              </div>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={"10px"}>
              <div className="link-row" style={flexRowAC}>
                <IconHelpCenter
                  style={{
                    width: "24px",
                    marginRight: "8px"
                  }}
                />
                <div
                  onClick={() => {
                    handleHubspotClick("path=/knowledge");
                  }}
                  style={linkStyle}
                >
                  Help Center
                </div>
              </div>
              <div className="link-row" style={flexRowAC}>
                <IconFileTicket
                  style={{
                    width: "24px",
                    marginRight: "8px"
                  }}
                />
                <div
                  onClick={() => {
                    handleHubspotClick(`path=/tickets-view/new?email=${email}`);
                  }}
                  style={linkStyle}
                >
                  File a Support Ticket
                </div>
              </div>
              <div className="link-row" style={flexRowAC}>
                <IconTrackTicket
                  style={{
                    width: "24px",
                    marginRight: "8px"
                  }}
                />
                <a
                  onClick={() => {
                    handleHubspotClick();
                  }}
                  style={linkStyle}
                >
                  Track Support Ticket
                </a>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
