import useContentBriefsStore from "@/hooks/useContentBriefsStore";
import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";
import Step3Photo from "../../../../../../assets/images/ContentBriefWorkflowStep3Title.png";
import DJHorizontalRegistered from "../../../../../../assets/DJ-Horizontal-registered.png";
import { stepThreeStyles } from "./utils/style";

const TitlePhoto = () => {
  const { term, typeOfTerm }: IContentBriefsStore = useContentBriefsStore(
    (store: IContentBriefsStore) => ({
      term: store.term,
      typeOfTerm: store.typeOfTerm
    })
  );
  return (
    <div
      data-testid="title-photo-section"
      style={{ position: "relative", textAlign: "center" }}
      className="container title-photo"
    >
      <img
        style={{ width: "100%", position: "relative" }}
        src={Step3Photo}
        alt="Step 3 Title & Photo"
      ></img>
      <img
        style={{
          width: "200px",
          top: "50px",
          left: "50px",
          zIndex: 1,
          position: "absolute"
        }}
        src={DJHorizontalRegistered}
        alt="DJ Horizontal Registered"
      ></img>
      <div
        data-testid="title-photo-text"
        id="title-text-area"
        style={{
          position: "absolute",
          top: "250px",
          left: "50px",
          zIndex: 1,
          textAlign: "left",
          width: 500
        }}
      >
        <div
          className="title-photo-title-line-1"
          style={stepThreeStyles.titlePhotoTitleLine1}
        >
          Content Brief
        </div>
        <div
          className="title-photo-title-line-2"
          style={
            (stepThreeStyles.titlePhotoTitleLine2, stepThreeStyles.wordWrap)
          }
        >
          {term}
        </div>
        {typeOfTerm === "Pillar" && (
          <div
            className="title-photo-title-line-3"
            style={stepThreeStyles.titlePhotoTitleLine3}
          >
            Pillar Page
          </div>
        )}
        {typeOfTerm === "Sub Pillar" && (
          <div
            className="title-photo-title-line-3"
            style={stepThreeStyles.titlePhotoTitleLine3}
          >
            Sub-Pillar Page
          </div>
        )}
        {typeOfTerm === "Blog" && (
          <div
            className="title-photo-title-line-3"
            style={stepThreeStyles.titlePhotoTitleLine3}
          >
            Supporting Blog
          </div>
        )}
        {typeOfTerm === "Ad Hoc" && (
          <div
            className="title-photo-title-line-3"
            style={stepThreeStyles.titlePhotoTitleLine3}
          >
            Supporting Blog
          </div>
        )}
      </div>
    </div>
  );
};
export default TitlePhoto;
