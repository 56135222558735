function SkeletonRows() {
  return (
    <>
      <tr className="align-middle" style={{ height: "15px" }} key="1">
        <th style={{ height: "15px", paddingLeft: "22px" }}>
          <div
            className="placeholder-wave"
            style={{ width: "300px", padding: "3px" }}
          >
            <span className="placeholder col-10"></span>
          </div>
        </th>
        <th style={{ height: "15px" }}>
          <div
            className="placeholder-wave"
            style={{ width: "100px", padding: "3px" }}
          >
            <span className="placeholder col-10"></span>
          </div>
        </th>
        <th style={{ height: "15px" }}>
          <div
            className="placeholder-wave"
            style={{ width: "125px", padding: "3px" }}
          >
            <span className="placeholder col-10"></span>
          </div>
        </th>
        <th style={{ height: "15px" }}>
          <div
            className="placeholder-wave"
            style={{ width: "175px", padding: "3px" }}
          >
            <span className="placeholder col-10"></span>
          </div>
        </th>
      </tr>
      {[1, 2, 3].map((i) => (
        <tr className="align-middle" key={`2-${i}`} style={{ height: "15px" }}>
          <th style={{ height: "15px", paddingLeft: "68px" }}>
            <div
              className="placeholder-wave"
              style={{ width: "300px", padding: "3px" }}
            >
              <span className="placeholder col-10"></span>
            </div>
          </th>
          <th style={{ height: "15px" }}>
            <div
              className="placeholder-wave"
              style={{ width: "100px", padding: "3px" }}
            >
              <span className="placeholder col-10"></span>
            </div>
          </th>
          <th style={{ height: "15px" }}>
            <div
              className="placeholder-wave"
              style={{ width: "125px", padding: "3px" }}
            >
              <span className="placeholder col-10"></span>
            </div>
          </th>
          <th style={{ height: "15px" }}>
            <div
              className="placeholder-wave"
              style={{ width: "175px", padding: "3px" }}
            >
              <span className="placeholder col-10"></span>
            </div>
          </th>
        </tr>
      ))}
      <tr className="align-middle" key={20} style={{ height: "15px" }}>
        <th style={{ height: "15px", paddingLeft: "22px" }}>
          <div
            className="placeholder-wave"
            style={{ width: "300px", padding: "3px" }}
          >
            <span className="placeholder col-10"></span>
          </div>
        </th>
        <th style={{ height: "15px" }}>
          <div
            className="placeholder-wave"
            style={{ width: "100px", padding: "3px" }}
          >
            <span className="placeholder col-10"></span>
          </div>
        </th>
        <th style={{ height: "15px" }}>
          <div
            className="placeholder-wave"
            style={{ width: "125px", padding: "3px" }}
          >
            <span className="placeholder col-10"></span>
          </div>
        </th>
        <th style={{ height: "15px" }}>
          <div
            className="placeholder-wave"
            style={{ width: "175px", padding: "3px" }}
          >
            <span className="placeholder col-10"></span>
          </div>
        </th>
      </tr>
      {[6, 7, 8].map((j) => (
        <tr className="align-middle" key={j} style={{ height: "15px" }}>
          <th style={{ height: "15px", paddingLeft: "40px" }}>
            <div
              className="placeholder-wave"
              style={{ width: "300px", padding: "3px" }}
            >
              <span className="placeholder col-10"></span>
            </div>
          </th>
          <th style={{ height: "15px" }}>
            <div
              className="placeholder-wave"
              style={{ width: "100px", padding: "3px" }}
            >
              <span className="placeholder col-10"></span>
            </div>
          </th>
          <th style={{ height: "15px" }}>
            <div
              className="placeholder-wave"
              style={{ width: "125px", padding: "3px" }}
            >
              <span className="placeholder col-10"></span>
            </div>
          </th>
          <th style={{ height: "15px" }}>
            <div
              className="placeholder-wave"
              style={{ width: "175px", padding: "3px" }}
            >
              <span className="placeholder col-10"></span>
            </div>
          </th>
        </tr>
      ))}
      <tr className="align-middle" key={15} style={{ height: "15px" }}>
        <th style={{ height: "15px", paddingLeft: "22px" }}>
          <div
            className="placeholder-wave"
            style={{ width: "300px", padding: "3px" }}
          >
            <span className="placeholder col-10"></span>
          </div>
        </th>
        <th style={{ height: "15px" }}>
          <div
            className="placeholder-wave"
            style={{ width: "100px", padding: "3px" }}
          >
            <span className="placeholder col-10"></span>
          </div>
        </th>
        <th style={{ height: "15px" }}>
          <div
            className="placeholder-wave"
            style={{ width: "125px", padding: "3px" }}
          >
            <span className="placeholder col-10"></span>
          </div>
        </th>
        <th style={{ height: "15px" }}>
          <div
            className="placeholder-wave"
            style={{ width: "175px", padding: "3px" }}
          >
            <span className="placeholder col-10"></span>
          </div>
        </th>
      </tr>
      {[9, 10, 11].map((j) => (
        <tr className="align-middle" key={j} style={{ height: "15px" }}>
          <th style={{ height: "15px", paddingLeft: "40px" }}>
            <div
              className="placeholder-wave"
              style={{ width: "300px", padding: "3px" }}
            >
              <span className="placeholder col-10"></span>
            </div>
          </th>
          <th style={{ height: "15px" }}>
            <div
              className="placeholder-wave"
              style={{ width: "100px", padding: "3px" }}
            >
              <span className="placeholder col-10"></span>
            </div>
          </th>
          <th style={{ height: "15px" }}>
            <div
              className="placeholder-wave"
              style={{ width: "125px", padding: "3px" }}
            >
              <span className="placeholder col-10"></span>
            </div>
          </th>
          <th style={{ height: "15px" }}>
            <div
              className="placeholder-wave"
              style={{ width: "175px", padding: "3px" }}
            >
              <span className="placeholder col-10"></span>
            </div>
          </th>
        </tr>
      ))}
    </>
  );
}

export default SkeletonRows;
