import Alert from "@mui/material/Alert";
import { useAppStore } from "../../hooks";
import isEmpty from "lodash/isEmpty";
import { IAppStore } from "../../interfaces/app-store.interface";

const AlertBanner = () => {
  const { showAlertBanner, alertBannerOptions, setShowAlertBanner } =
    useAppStore((store: IAppStore) => ({
      showAlertBanner: store.showAlertBanner,
      alertBannerOptions: store.alertBannerOptions,
      setShowAlertBanner: (bool: boolean) => store.setShowAlertBanner(bool)
    }));

  return (
    <>
      {showAlertBanner && !isEmpty(alertBannerOptions) && (
        <Alert
          severity={alertBannerOptions.severity}
          style={{
            width: "400px",
            position: "fixed",
            top: "75px",
            right: "50px",
            zIndex: 1000
          }}
          onClose={() => setShowAlertBanner(false)}
        >
          {alertBannerOptions.message}
        </Alert>
      )}
    </>
  );
};

export default AlertBanner;
