import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { IAppStore } from "../../../../interfaces/app-store.interface";
import { useAppStore } from "../../../../hooks";
import { getEntitlements } from "../../../../api/get";
import BriefTable from "./BriefTable";
import ArchivedBriefTable from "./ArchivedBriefTable";
import AdHocCreateBrief from "./AdHocCreateBrief";
import { IBrief } from "@/interfaces/brief.interface";

const BriefTableSwitch = () => {
  const [useArchive, setUseArchive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hasAdHocContentBriefsEnabled, setHasAdHocContentBriefsEnabled] =
    useState(false);
  const { selectedPidObject, user } = useAppStore((store: IAppStore) => ({
    selectedPidObject: store.selectedPidObject,
    user: store.user
  }));
  const [briefs, setBriefs] = useState([] as IBrief[]);

  const toggleArchive = () => {
    setBriefs([]);
    setUseArchive(!useArchive);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (user.dj_employee) {
        setHasAdHocContentBriefsEnabled(true);
      } else {
        const entitlements = await getEntitlements(selectedPidObject.id);
        if (entitlements?.permissions?.ad_hock_content_briefs) {
          setHasAdHocContentBriefsEnabled(true);
        }
      }
      setLoading(false);
    };

    if (loading && selectedPidObject.id && user.id) {
      fetchData();
    }
  }, [selectedPidObject]);

  return (
    <div>
      {hasAdHocContentBriefsEnabled && (
        <AdHocCreateBrief
          userId={user.id}
          pid={selectedPidObject.id}
          briefs={briefs}
          setBriefs={setBriefs}
        />
      )}
      <Card
        style={{
          maxWidth: "95%",
          margin: "0 auto 64px auto",
          overflow: "visible"
        }}
      >
        <Card.Header style={{ borderBottom: "0px" }}>
          <h4>Content Briefs</h4>
        </Card.Header>
        <Card.Body>
          {!useArchive && (
            <BriefTable
              switchTable={toggleArchive}
              briefs={briefs}
              setBriefs={setBriefs}
            ></BriefTable>
          )}
          {useArchive && (
            <ArchivedBriefTable
              switchTable={toggleArchive}
            ></ArchivedBriefTable>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default BriefTableSwitch;
