const priority_colors = {
  critical: "#811211",
  very_high: "#FA1111",
  high: "#FC5D03",
  Medium: "#FFA400",
  Low: "#373F50"
};

const StepOne = (props: { dj_score: number }) => (
  <span>
    {props.dj_score >= 100 && (
      <span style={{ color: priority_colors.critical }}>Critical Priority</span>
    )}
    {props.dj_score < 100 && props.dj_score >= 97 && (
      <span style={{ color: priority_colors.very_high }}>
        Very High Priority
      </span>
    )}
    {props.dj_score < 97 && props.dj_score >= 92 && (
      <span style={{ color: priority_colors.high }}>High Priority</span>
    )}
    {props.dj_score < 92 && props.dj_score >= 85 && (
      <span style={{ color: priority_colors.Medium }}>Medium Priority</span>
    )}
    {props.dj_score < 85 && (
      <span style={{ color: priority_colors.Low }}>Low Priority</span>
    )}
  </span>
);

export default StepOne;
