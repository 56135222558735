/* eslint-disable indent */
import { Dispatch, SetStateAction, useState, useEffect } from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LeftNavListItem from "../components/NavListItem";
import NavBottomList from "../components/BottomList/NavBottomList";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { NavigationArrowBack } from "@/assets/icons/svgr";
import HelpMenu from "../components/BottomList/HelpMenu";
import SettingsMenu from "../components/BottomList/SettingsMenu";
import Bookmarks from "../components/BottomList/Bookmarks/Bookmarks";
import ProfileSettingsMenu from "../components/BottomList/ProfileSettingsMenu";
import { navItemD } from "../../../interfaces/navigation.interface";

const openedMixin = (theme: Theme): CSSObject => ({
  width: "fit-content",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden",
  border: "1px solid rgba(233, 235, 240, 1)",
  borderRadius: "8px",
  height: "fit-content"
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: "fit-content",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  height: "100vh",
  display: "flex",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  })
}));

const MobileNav = ({
  navJSON,
  isLoading,
  sideMenuOpen,
  handleDrawerOpen,
  handleDrawerClose,
  setSideMenuOpen
}: {
  navJSON: navItemD[] | null;
  isLoading: boolean;
  sideMenuOpen: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  setSideMenuOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [currentListJSON, setCurrentListJSON] = useState<navItemD[]>(
    navJSON || ([] as navItemD[])
  );
  const [navJSONStack] = useState<navItemD[][]>([]);
  const [helpMenuOpen, setHelpMenuOpen] = useState<boolean>(false);
  const [settingsMenuOpen, setSettingsMenuOpen] = useState<boolean>(false);
  const [bookmarksMenuOpen, setBookmarksMenuOpen] = useState<boolean>(false);
  const [profileSettingsMenuOpen, setProfileSettingsMenuOpen] =
    useState<boolean>(false);
  const handleListItemClick = (priority: number) => {
    navJSONStack.push(currentListJSON);
    setCurrentListJSON(currentListJSON[priority].children);
  };
  useEffect(() => {
    if (currentListJSON.length === 0 && navJSON) {
      setCurrentListJSON(navJSON);
    }
    setSideMenuOpen(false);
  }, []);
  const drawerContent = (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "fit-content",
        paddingY: "20px"
      }}
      test-id="drawer-content"
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          <ChevronRightIcon />
        </IconButton>
      </DrawerHeader>
      {isLoading &&
        [1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
          <div
            key={i}
            className="placeholder-wave"
            style={{
              height: "24px",
              margin: "8px 20px",
              borderRadius: "2px",
              minWidth: "240px"
            }}
          >
            <span
              className="placeholder col-12"
              style={{
                height: "100%",
                borderRadius: "2px",
                backgroundColor: "#D3D7E0"
              }}
            ></span>
          </div>
        ))}
      {currentListJSON && currentListJSON.length > 0 && !isLoading && (
        <>
          {!(currentListJSON[0].id === 1) && (
            <ListItem
              disablePadding
              sx={{
                color: "#373F50",
                display: "block",
                "&:hover": {
                  background: "#E1F5FF",
                  color: "#008CCE"
                }
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 37,
                  height: "37px",
                  px: 2.5,
                  paddingLeft: "30px",
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "transparent"
                  }
                }}
                onClick={() => {
                  if (navJSONStack[0]) {
                    setCurrentListJSON(navJSONStack.pop()!);
                  }
                }}
              >
                <NavigationArrowBack style={{ width: "24px" }} />
                <ListItemText
                  primary={currentListJSON[0].parentName}
                  primaryTypographyProps={{
                    fontFamily: "Source Sans Pro",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "17px",
                    lineHeight: "21px",
                    letterSpacing: "-0.015em"
                  }}
                />
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: "center"
                  }}
                ></ListItemIcon>
              </ListItemButton>
            </ListItem>
          )}

          {helpMenuOpen && <HelpMenu setHelpMenuOpen={setHelpMenuOpen} />}
          {settingsMenuOpen && (
            <SettingsMenu setSettingsMenuOpen={setSettingsMenuOpen} />
          )}
          {bookmarksMenuOpen && (
            <Bookmarks setBookmarksMenuOpen={setBookmarksMenuOpen} />
          )}
          {profileSettingsMenuOpen && (
            <ProfileSettingsMenu
              setProfileSettingsMenuOpen={setProfileSettingsMenuOpen}
            />
          )}
          {!helpMenuOpen &&
            !settingsMenuOpen &&
            !bookmarksMenuOpen &&
            !profileSettingsMenuOpen && (
              <>
                <List
                  sx={{
                    py: "0px"
                  }}
                >
                  {currentListJSON?.map(
                    (obj) =>
                      (obj.url || obj.children.length > 0) && (
                        <LeftNavListItem
                          object={obj}
                          drawerOpen={sideMenuOpen}
                          isMobile
                          handleListItemClick={handleListItemClick}
                        />
                      )
                  )}
                </List>
                {currentListJSON[0].id === 1 && (
                  <NavBottomList
                    sideMenuOpen={sideMenuOpen}
                    helpMenuOpen={helpMenuOpen}
                    settingsMenuOpen={settingsMenuOpen}
                    setProfileSettingsMenuOpen={setProfileSettingsMenuOpen}
                    setHelpMenuOpen={setHelpMenuOpen}
                    setSettingsMenuOpen={setSettingsMenuOpen}
                    setBookmarksMenuOpen={setBookmarksMenuOpen}
                    handleDrawerOpen={handleDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                  />
                )}
              </>
            )}
        </>
      )}
    </Grid>
  );

  return (
    <Drawer
      data-test-id="nav-drawer"
      variant="permanent"
      open={sideMenuOpen}
      sx={{
        display: sideMenuOpen ? "block" : "none",
        "& .MuiDrawer-paper": { marginLeft: "15px" }
      }}
    >
      {drawerContent}
    </Drawer>
  );
};

export default MobileNav;
