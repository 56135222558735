import Select from "react-select";
import { IMenuItem } from "../../interfaces/navigation.interface";

interface SelectProps {
  placeholder?: string;
  options?: IMenuItem[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (selection: any) => void;
  name?: string;
  isDisabled?: boolean;
}

const SelectDropDown: React.FC<SelectProps> = ({
  options,
  onChange,
  placeholder,
  name,
  isDisabled
}) => (
  <Select
    styles={{
      container: (styles) => ({
        ...styles,
        height: "44px",
        width: "272px",
        fontSize: "inherent",
        fontFamily: "'Source Sans Pro', sans-serif",
        color: "black",
        outline: "none",
        padding: "2px, 8px"
      }),
      menu: (styles) => ({
        ...styles,
        padding: "0px",
        boxShadow: "none",
        marginTop: "0px",
        outline: "none"
      }),
      menuList: (base) => ({
        ...base,
        backgroundColor: "#FFFFF",
        border: "none",
        width: "272px",
        maxHeight: "196px",
        borderRadius: "4px",
        padding: "0px",
        boxShadow: "0px 4px 8px rgba(55, 63, 80, 0.25)",
        "::-webkit-scrollbar": {
          width: "5px",
          opacity: "0"
        },
        "::-webkit-scrollbar-track": {
          bordeRadius: "10px"
        },
        "::-webkit-scrollbar-thumb": {
          background: "#909BB3",
          borderRadius: "2.5px"
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#373F50"
        }
      }),
      placeholder: (styles) => ({ ...styles, color: "black" }),
      control: (styles, { isFocused }) => ({
        ...styles,
        boxShadow: "none",
        height: "44px",
        border: isFocused ? "1px solid 008CCE" : "1px solid #D3D7E0",
        cursor: "pointer"
      }),
      indicatorSeparator: () => ({ display: "none" }),
      option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: isSelected || isFocused ? "#E1F5FF" : "#FFFFFF",
        color: "#373F50;",
        padding: "12px 8px",
        cursor: "pointer"
      }),
      dropdownIndicator: (styles, state) => ({
        ...styles,
        color: "#373F50",
        transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : ""
      })
    }}
    placeholder={placeholder}
    name={name}
    options={options}
    isDisabled={isDisabled}
    onChange={onChange}
  />
);

export default SelectDropDown;
