// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.note {
  color: #373F50;
}

.pencil-and-trash {
  color: #F7FDFF;
}

.pencil {
  padding-right: 10px;
}

.note:hover + .pencil-and-trash {
  color: #909BB3;
}

.trash:hover {
  color: #373F50;
}

.trash:hover + .svg-inline--fa:hover + .fa-pencil:hover + .pencil {
  color: #909BB3;
}

.pencil:hover {
  color: #373F50;
}

.pencil:hover + .trash {
  color: #909BB3;
}`, "",{"version":3,"sources":["webpack://./src/components/manage_content/content_brief/content_brief_workflow/step_three/components/notes.scss"],"names":[],"mappings":"AAAA;EACC,cAAA;AACD;;AACA;EACC,cAAA;AAED;;AAAA;EACC,mBAAA;AAGD;;AADA;EACE,cAAA;AAIF;;AAFA;EACC,cAAA;AAKD;;AAHA;EACC,cAAA;AAMD;;AAJA;EACC,cAAA;AAOD;;AALA;EACC,cAAA;AAQD","sourcesContent":[".note{\t\n\tcolor: #373F50;\n}\n.pencil-and-trash{\n\tcolor: #F7FDFF;\n}\n.pencil{\n\tpadding-right: 10px;\n}\n.note:hover + .pencil-and-trash{\n  color: #909BB3;\n}\n.trash:hover{\n\tcolor: #373F50;\n}\n.trash:hover + .svg-inline--fa:hover + .fa-pencil:hover + .pencil{\n\tcolor: #909BB3;\n}\n.pencil:hover{\n\tcolor: #373F50;\n}\n.pencil:hover + .trash{\n\tcolor: #909BB3;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
