/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */

import { ILimits } from "../../../../interfaces/entitlements.interface";
import CustomBadge from "../../pillar_topics/components/CustomBadge";
import { Card, Row, Col } from "react-bootstrap";
import { ICbSubscription } from "../../../../interfaces/subscription.interface";
import { Skeleton } from "@mui/material";
import { formatPlan } from "../../../../utilities/subscription";

interface SubscriptionCardProps {
  subscriptionLoading: boolean;
  entitlementsLoading: boolean;
  limits: ILimits | null;
  localSubscriptionObject?: ICbSubscription;
}

function SubscriptionCard({
  subscriptionLoading,
  entitlementsLoading,
  limits,
  localSubscriptionObject
}: SubscriptionCardProps) {
  let formattedDate = "n/a";
  if (localSubscriptionObject?.cb_next_billing_at) {
    const date = new Date(localSubscriptionObject.cb_next_billing_at);
    formattedDate =
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
  }

  return (
    <Card style={{ marginTop: "27px" }}>
      <div className="d-flex flex-row justify-content-between align-items-start w-100">
        <div>
          <div className="sub-item-heading">Current Plan</div>
          {subscriptionLoading ? (
            <Skeleton
              animation="wave"
              sx={{ width: "200px", height: "30px" }}
            />
          ) : (
            <div className="d-flex flex-row align-items-center">
              <div className="plan">
                {localSubscriptionObject &&
                  formatPlan(localSubscriptionObject.cb_plan_id)}
              </div>
              {localSubscriptionObject?.cb_status === "active" && (
                <CustomBadge text="Active" bg="#44af69" />
              )}
              {localSubscriptionObject?.cb_status === "cancelled" && (
                <CustomBadge text="Cancelled" bg="#B00020" />
              )}
            </div>
          )}
        </div>
        {subscriptionLoading ? (
          <Skeleton animation="wave" sx={{ width: "150px", height: "30px" }} />
        ) : localSubscriptionObject?.cb_plan_id.includes("free") ? (
          localSubscriptionObject?.cb_plan_id.includes("no-subscription") ? (
            <div className="sub-item-heading" style={{ maxWidth: "50%" }}>
              You don't have a subscription on file, please{" "}
              <a
                style={{ color: "#008CCE" }}
                target="_blank"
                rel="noreferrer"
                href={process.env.REACT_APP_SALES_SCHED}
              >
                contact sales
              </a>{" "}
              to select the correct subscription
            </div>
          ) : (
            <div className="sub-item-heading">
              <a
                style={{ color: "#008CCE" }}
                target="_blank"
                rel="noreferrer"
                href={process.env.REACT_APP_SALES_SCHED}
              >
                Contact sales
              </a>{" "}
              to upgrade your plan
            </div>
          )
        ) : (
          <></>
        )}
      </div>
      <div className="d-flex flex-row justify-content-center align-items-start mt-5">
        <Row style={{ width: "100%" }} className="justify-content-center">
          <Col lg={6} xl={3}>
            <div className="subscription-detail">
              <div className="sub-item-heading">Pillars</div>
              {entitlementsLoading ? (
                <Skeleton
                  animation="wave"
                  sx={{ width: "40px", height: "30px" }}
                />
              ) : (
                <div className="sub-item-detail">
                  {limits?.content_pillars ? limits.content_pillars : "0"}
                </div>
              )}
            </div>
          </Col>

          <Col lg={6} xl={3}>
            <div className="subscription-detail">
              <div className="sub-item-heading">Competitors</div>
              {entitlementsLoading ? (
                <Skeleton
                  animation="wave"
                  sx={{ width: "40px", height: "30px" }}
                />
              ) : (
                <div className="sub-item-detail">
                  {limits?.competitors ? limits.competitors : "0"}
                </div>
              )}
            </div>
          </Col>

          <Col lg={6} xl={3}>
            <div className="subscription-detail">
              <div className="sub-item-heading">Keywords</div>
              {entitlementsLoading ? (
                <Skeleton
                  animation="wave"
                  sx={{ width: "40px", height: "30px" }}
                />
              ) : (
                <div className="sub-item-detail">
                  {limits?.keywords ? limits.keywords : "0"}
                </div>
              )}
            </div>
          </Col>

          <Col lg={6} xl={3}>
            <div className="subscription-detail">
              <div className="sub-item-heading">Next Billing Date</div>
              {subscriptionLoading ? (
                <Skeleton
                  animation="wave"
                  sx={{ width: "40px", height: "30px" }}
                />
              ) : (
                <div className="sub-item-detail">
                  {localSubscriptionObject &&
                  !localSubscriptionObject.cb_plan_family.includes("free")
                    ? formattedDate
                    : "n/a"}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Card>
  );
}

export default SubscriptionCard;
