import useContentBriefsStore from "@/hooks/useContentBriefsStore";
import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";
import isEmpty from "lodash/isEmpty";
import "./outline.scss";
import { stepThreeStyles } from "../utils/style";

const IntroductionLinks = () => {
  const { pillar, subPillar, typeOfTerm }: IContentBriefsStore =
    useContentBriefsStore((store: IContentBriefsStore) => ({
      pillar: store.pillar,
      subPillar: store.subPillar,
      typeOfTerm: store.typeOfTerm
    }));

  return !isEmpty(pillar) || !isEmpty(subPillar) ? (
    <>
      <div
        className="row row-padding-top-20"
        style={stepThreeStyles.rowPaddingTop20}
      >
        <div
          className="outline-subtitle"
          style={stepThreeStyles.outlineSubtitle}
        >
          Links to include:
        </div>
      </div>

      <div
        className="row row-padding-top-20"
        style={(stepThreeStyles.rowPaddingTop20, { marginLeft: "20px" })}
      >
        <ul>
          {pillar && (
            <li className="outline-text" style={stepThreeStyles.outlineText}>
              Include a link to the pillar page using the term "{pillar}" as the
              anchor text.
            </li>
          )}
          {typeOfTerm === "Blog" && subPillar && (
            <li className="outline-text" style={stepThreeStyles.outlineText}>
              Include a link to the sub-pillar page using the term "{subPillar}"
              as the anchor text.
            </li>
          )}
        </ul>
      </div>
    </>
  ) : null;
};

export default IntroductionLinks;
