import React, { useState } from "react";
import { useAppStore, useContentBriefsStore, useEffectAsync } from "@/hooks";
import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faPencil,
  faTrashCan
} from "@fortawesome/free-solid-svg-icons";
import AddNoteModal from "./AddNoteModal";
import { getNotes } from "@/api/get";
import "./notes.scss";
import EditNoteModal from "./EditNoteModal";
import { INotes } from "@/interfaces/notes.interface";
import { deleteNote } from "@/api/delete";
import { IAppStore } from "@/interfaces/app-store.interface";
const Notes = () => {
  const {
    showAddNoteModal,
    setShowAddNoteModal,
    showEditNoteModal,
    setShowEditNoteModal,
    briefId,
    notes,
    setNotes
  }: IContentBriefsStore = useContentBriefsStore(
    (store: IContentBriefsStore) => ({
      showAddNoteModal: store.showAddNoteModal,
      showEditNoteModal: store.showEditNoteModal,
      briefId: store.briefId,
      notes: store.notes,
      setNotes: (value: INotes[]) => store.setNotes(value),
      setShowAddNoteModal: (value: boolean) => store.setShowAddNoteModal(value),
      setShowEditNoteModal: (value: boolean) =>
        store.setShowEditNoteModal(value)
    })
  );
  const { setShowAlertBanner } = useAppStore((store: IAppStore) => ({
    setShowAlertBanner: store.setShowAlertBanner
  }));
  const [selectedNote, setSelectedNote] = useState<INotes | null>(null);

  const handleAddNote = () => setShowAddNoteModal(true);

  const handleEditNote = (note: INotes) => {
    setShowEditNoteModal(true);
    setSelectedNote(note);
  };
  const handleDeleteNote = async (note: INotes) => {
    const deletedNote = await deleteNote(briefId, note.id);

    if (deletedNote) {
      // Filter the notes to remove the deleted note
      const updatedNotes = notes.filter(
        (existingNote) => existingNote.id !== note.id
      );
      setNotes(updatedNotes);
      setShowAlertBanner(true, {
        message: `You have successfully deleted this note`,
        severity: "success"
      });
    } else {
      setShowAlertBanner(true, {
        message: `Error deleting note, please try again. If problem persists please contact customer success.`,
        severity: "error"
      });
    }
  };

  useEffectAsync(async () => {
    if (briefId) {
      await getNotes(briefId)
        .then((note) => {
          setNotes(note);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [briefId]);

  return (
    <div
      data-testid="meta-information-section"
      style={{ padding: "40px 75px 40px  75px", background: "#F7FDFF" }}
    >
      <div className="title-photo-title-line-2">Notes</div>
      <div
        style={{
          paddingTop: "20px",
          paddingBottom: "20px"
        }}
      >
        <div
          style={{
            backgroundColor: "#E45D28",
            height: "4px",
            width: "120px"
          }}
        ></div>
      </div>
      {showAddNoteModal && <AddNoteModal />}
      <div style={{ height: "fit-content" }}>
        <ul>
          {notes.map((note, index) => (
            <div key={index}>
              <div style={{ display: "flex" }}>
                <li className="note" key={index} style={{ paddingRight: 20 }}>
                  {note.note}
                </li>
                <div className="pencil-and-trash">
                  <FontAwesomeIcon
                    className="pencil"
                    icon={faPencil}
                    onClick={() => handleEditNote(note)}
                  />
                  <FontAwesomeIcon
                    className="trash"
                    icon={faTrashCan}
                    onClick={() => handleDeleteNote(note)}
                  />
                </div>
              </div>
              {showEditNoteModal && selectedNote && (
                <EditNoteModal note={selectedNote} />
              )}
            </div>
          ))}
        </ul>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer"
        }}
        onClick={handleAddNote}
        id="add-notes"
      >
        <FontAwesomeIcon
          style={{ color: "#008CCE", paddingRight: "10px" }}
          icon={faPlus}
        />
        <div
          style={{
            color: "#008CCE"
          }}
        >
          Add note
        </div>
      </div>
    </div>
  );
};
export default Notes;
