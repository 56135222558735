/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
import { useEffect, useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { motion, AnimatePresence } from "framer-motion";
import List from "@mui/material/List";
import { useNavigate } from "react-router-dom";
import {
  IconHome,
  IconResearchAndDiscovery,
  IconManageContent,
  IconKeywordList,
  IconAnalytics,
  NavigationArrowRight,
  IconSinglePillar,
  IconAdmin,
  NavigationArrowDown
} from "@/assets/icons/svgr";
import UpgradeBadge from "./UpgradeBadge";
import { navItemD } from "../../../interfaces/navigation.interface";
function getArrayOfParentIdsOf(items: navItemD[], id: number) {
  const allIds = [] as number[];
  items.forEach((item) => {
    if (item.id === id) {
      allIds.push(item.id);
    } else if (item.children) {
      const ids = getArrayOfParentIdsOf(item.children, id);
      if (ids.length) {
        allIds.push(item.id);
      }
      ids.forEach((i) => allIds.push(i));
    }
  });
  return allIds;
}
const LeftNavListItem = ({
  object: obj,
  drawerOpen,
  isMobile,
  handleListItemClick,
  listTextFontSize,
  openItemsStack,
  setOpenItemsStack,
  navJSON
}: {
  object: navItemD;
  drawerOpen: boolean;
  isMobile: boolean;
  handleListItemClick?: (priority: number) => void;
  listTextFontSize?: number | undefined;
  openItemsStack?: number[];
  setOpenItemsStack?: React.Dispatch<React.SetStateAction<number[]>>;
  navJSON?: navItemD[];
}) => {
  const [listItemOpen, setListItemOpen] = useState<boolean>(false);
  const [listItemHighlight, setListItemHighlight] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    // and time the drawer is opened or closed, we check if the drawer is closed and close all list items if so.
    // this includes settings openItemsStack to empty array
    if (!drawerOpen) {
      setListItemOpen(false);
      if (setOpenItemsStack) {
        setOpenItemsStack([]);
      }
    }
  }, [drawerOpen]);
  const href = window.location.href;
  const objUrl = obj.url as string;
  useEffect(() => {
    // any time the openItemsStack changes, we need to decide whether this nav item should be open.
    if (obj) {
      if (openItemsStack?.includes(obj.id)) {
        setListItemOpen(true);
      } else {
        setListItemOpen(false);
        setListItemHighlight(false);
      }
      // highlighting the correct nav item
      if (objUrl) {
        if (
          href.includes(objUrl) &&
          href.split("/")[3].includes(objUrl.split("/")[1])
        ) {
          setListItemHighlight(true);
          setListItemOpen(true);
        } else {
          setListItemHighlight(false);
          setListItemOpen(false);
        }
      }
    }
  }, [openItemsStack]);

  useEffect(() => {
    setListItemOpen(false);
  }, []);

  return (
    <div>
      <ListItem
        key={obj?.id}
        disablePadding
        sx={{
          color: "#373F50",
          display: "block",
          "&:hover": {
            background: "#E1F5FF",
            color: "#008CCE",
            fontWeight: 600
          },
          fontWeight: listItemOpen ? "600" : "400"
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <ListItemButton
          disableRipple
          disableTouchRipple
          sx={{
            minHeight: 37,
            height: "37px",
            background: listItemHighlight ? "#E1F5FF" : "none",
            color: listItemHighlight ? "#008CCE" : "",
            justifyContent: drawerOpen ? "initial" : "center",
            "&.MuiButtonBase-root:hover": {
              bgcolor: "transparent"
            },
            px: 2.5
          }}
          onClick={() => {
            if (isMobile && handleListItemClick) {
              handleListItemClick(obj.priority);
            } else if (drawerOpen) {
              // ensure all the functions and data we need are available
              if (setOpenItemsStack && navJSON && openItemsStack) {
                // if the nav item is already open and user clicked it, just close it
                if (openItemsStack?.includes(obj.id)) {
                  setOpenItemsStack(
                    getArrayOfParentIdsOf(navJSON, obj.id).slice(0, -1)
                  );
                  // else if its not yet open, open it and close anything other nav item that is open.
                } else {
                  setOpenItemsStack(getArrayOfParentIdsOf(navJSON, obj.id));
                }
              }
            }
            if (obj.url) {
              navigate(obj.url);
            } else if (!drawerOpen && obj?.children[0]) {
              const redirectChild = obj.children[0].url
                ? obj.children[0]
                : obj.children[0].children[0];
              const redirect = redirectChild.url || "/";
              navigate(redirect);
            }
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: drawerOpen ? "15px" : "auto",
              justifyContent: "center",
              color:
                isHovered || listItemHighlight
                  ? "#008CCE"
                  : obj.showUpgradeBadge
                  ? "#909bb3"
                  : "#373F50"
            }}
          >
            {obj.icon === "IconHome" && (
              <IconHome
                style={{
                  width: "24px"
                }}
              />
            )}
            {obj.icon === "IconResearchAndDiscovery" && (
              <IconResearchAndDiscovery
                style={{
                  width: "24px"
                }}
              />
            )}
            {obj.icon === "IconManagePillarTopics" && (
              <IconSinglePillar style={{ width: "24px" }} />
            )}
            {obj.icon === "IconManageContent" && (
              <IconManageContent style={{ width: "24px" }} />
            )}
            {obj.icon === "IconKeywordList" && (
              <IconKeywordList style={{ width: "24px" }} />
            )}
            {obj.icon === "IconAnalytics" && (
              <IconAnalytics style={{ width: "24px" }} />
            )}
            {obj.icon === "IconAdmin" && (
              <IconAdmin style={{ width: "24px" }} />
            )}
          </ListItemIcon>
          <ListItemText
            primary={obj?.name}
            disableTypography
            sx={{
              opacity: drawerOpen ? 1 : 0,
              "&:hover": {
                color: "#008CCE",
                fontWeight: 600
              }
            }}
            primaryTypographyProps={{
              fontSize:
                obj?.showUpgradeBadge && obj?.name?.length > 15
                  ? 15
                  : listTextFontSize,
              fontFamily: "Source Sans Pro",
              fontStyle: "normal",
              fontWeight: listItemOpen ? 600 : 400,
              lineHeight: "21px",
              letterSpacing: "-0.015em",
              color: obj.showUpgradeBadge ? "#909bb3" : "#373F50"
            }}
          />
          {drawerOpen && obj.showUpgradeBadge && (
            <ListItemIcon>
              <UpgradeBadge />
            </ListItemIcon>
          )}
          {drawerOpen && obj.children && obj.children.length > 0 && (
            <ListItemIcon
              sx={{
                minWidth: 0,
                justifyContent: "center",
                color: isHovered ? "#008CCE" : "#373F50"
              }}
            >
              {isMobile ? (
                <NavigationArrowRight
                  style={{
                    width: "24px",
                    color: "black",
                    transition: "all 300ms linear 0s"
                  }}
                />
              ) : listItemOpen ? (
                <NavigationArrowDown
                  style={{
                    width: "24px",
                    color: isHovered ? "#008CCE" : "black",
                    transform: "rotate(180deg)",
                    transition: "transform 300ms linear 0s"
                  }}
                />
              ) : (
                <NavigationArrowDown
                  style={{
                    width: "24px",
                    color: isHovered ? "#008CCE" : "black",
                    transform: "rotate(0deg)",
                    transition: "transform 300ms linear 0s"
                  }}
                />
              )}
            </ListItemIcon>
          )}
        </ListItemButton>
      </ListItem>

      <AnimatePresence>
        {obj.children && obj.children.length > 0 && listItemOpen && (
          <List
            sx={{
              py: "0px",
              paddingLeft: "24px"
            }}
          >
            {obj.children.map((child) => (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{
                  opacity: 1,
                  height: "fit-content"
                }}
                exit={{ height: 0, opacity: 0 }}
                transition={{
                  duration: 0.3,
                  ease: "linear"
                }}
                key={child.id}
              >
                <LeftNavListItem
                  key={child.id}
                  object={child}
                  drawerOpen={drawerOpen}
                  isMobile={isMobile}
                  listTextFontSize={
                    listTextFontSize ? listTextFontSize - 1 : 17
                  }
                  openItemsStack={openItemsStack}
                  setOpenItemsStack={setOpenItemsStack}
                  navJSON={navJSON}
                />
              </motion.div>
            ))}
          </List>
        )}
      </AnimatePresence>
    </div>
  );
};
export default LeftNavListItem;
