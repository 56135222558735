import ContactUsLink from "@/components/shared/ContactUsLink";

interface VideosProps {
  background: string;
}
function Videos({ background }: VideosProps) {
  return (
    <div
      className={`marketing-card-container ${
        background === "gradient" ? "gradient" : null
      }`}
    >
      <div className="section-header">Videos</div>
      <div className="image-text-container">
        <div className="image-stack-container">
          <div className="image-stack">
            <div className="image image-bottom">
              <img
                src={require("../../../assets/rd_organic_channels_top_20_channels_by_keyword_count@4x.png")}
                alt="Keyword Count Ranking"
                style={{ width: "371px", height: "255px" }}
              />
            </div>
            <div className="image image-top">
              <img
                src={require("../../../assets/rd_organic_channels_top_20_channels_by_video_count@4x.png")}
                alt="Video Count Ranking"
                style={{ width: "371px", height: "255px" }}
              />
            </div>
          </div>
        </div>
        <div className="text-button">
          <div className="text">
            <div className="text-header">
              When you consider the sheer amount of video content created every
              day, focusing your page one strategy only on text content leaves a
              lot of potential on the table.
            </div>
            <br />

            <p>
              With our Videos dashboard, identify which videos are relevant to
              the keywords you care about to inform your own video strategy.
            </p>
            <p>
              Spot the top channels by the number of keywords they rank for and
              the number of videos created to find inspiration and identify gaps
              in the market.
            </p>
          </div>
          <ContactUsLink />
        </div>
      </div>
    </div>
  );
}

export default Videos;
