import { getCTAs, getSavedBrief } from "@/api/get";
import "./outline.scss";
import { IOrderedSections } from "@/interfaces/outline.interface";
import isEmpty from "lodash/isEmpty";
import { stepThreeStyles } from "../utils/style";
import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";
import { useAppStore, useContentBriefsStore, useEffectAsync } from "@/hooks";
import { useState } from "react";
import { ISavedBriefCTA } from "@/interfaces/saved_outline.interface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faPencil,
  faTrashCan
} from "@fortawesome/free-solid-svg-icons";
import AddCTAModal from "./AddCTAModal";
import EditCTAModal from "./EditCTAModal";
import { deleteCTA } from "@/api/delete";
import { IAppStore } from "@/interfaces/app-store.interface";
const Conclusion = ({
  orderedSections
}: {
  orderedSections: IOrderedSections;
}) => {
  const { briefId, CTAs, setCTAs }: IContentBriefsStore = useContentBriefsStore(
    (store: IContentBriefsStore) => ({
      briefId: store.briefId,
      CTAs: store.CTAs,
      setCTAs: (value: ISavedBriefCTA[]) => store.setCTAs(value)
    })
  );
  const [openAddCTAModal, setOpenAddCTAModal] = useState(false);
  const [openEditCTAModal, setOpenEditCTAModal] = useState(false);
  const [selectedCTA, setSelectedCTA] = useState<ISavedBriefCTA | null>(null);
  const { setShowAlertBanner } = useAppStore((store: IAppStore) => ({
    setShowAlertBanner: store.setShowAlertBanner
  }));
  const [savedId, setSavedId] = useState(0);

  useEffectAsync(() => {
    const fetchData = async () => {
      const savedBriefId = await getSavedBrief(briefId);
      if (savedBriefId.id) {
        setSavedId(savedBriefId.id);
        const CTAObj = await getCTAs(savedBriefId.id);
        setCTAs(CTAObj);
      }
    };
    fetchData();
  }, [briefId]);

  const handleEditCTA = (cta: ISavedBriefCTA) => {
    setOpenEditCTAModal(true);
    setSelectedCTA(cta);
  };
  const handleDeleteCTA = async (cta: ISavedBriefCTA) => {
    if (savedId && cta.id) {
      const deletedCTA = await deleteCTA(savedId, cta.id);
      if (deletedCTA) {
        // Filter the notes to remove the deleted note
        const updatedCTAs = CTAs.filter(
          (existingCTA) => existingCTA.id !== cta.id
        );
        setCTAs(updatedCTAs);
        setShowAlertBanner(true, {
          message: `You have successfully deleted this CTA`,
          severity: "success"
        });
      } else {
        setShowAlertBanner(true, {
          message: `Error deleting CTA, please try again. If problem persists please contact customer success.`,
          severity: "error"
        });
      }
    }
  };

  return (
    <div className="row row-padding-top-20">
      <div className="col-12">
        <div className="row">
          <div className="outline-title">Conclusion Section</div>
        </div>
        <div className="row row-padding-top-20">
          <div className="outline-subtitle">
            Call to action:{" "}
            <span className="outline-text">
              At the end of your article, include at least one relevant call to
              action.
            </span>
          </div>
          {openAddCTAModal && (
            <AddCTAModal setOpenAddCTAModal={setOpenAddCTAModal} />
          )}
          <div>
            <div className="outline-subtitle" style={{ paddingTop: 10 }}>
              Links to include in CTA:{" "}
            </div>
            <div>
              <ul>
                {CTAs.map((cta, index) => (
                  <div key={index}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <li
                        className="note"
                        key={index}
                        style={{ paddingRight: 20 }}
                      >
                        <div
                          style={{
                            color: "#008CCE",
                            marginTop: 5,
                            display: "flex"
                          }}
                        >
                          {cta.display_text}{" "}
                          <div
                            style={{
                              textDecoration: "underline",
                              paddingLeft: 5
                            }}
                          >
                            {cta.href}
                          </div>
                        </div>
                      </li>
                      <div className="pencil-and-trash">
                        <FontAwesomeIcon
                          className="pencil"
                          icon={faPencil}
                          onClick={() => handleEditCTA(cta)}
                        />
                        <FontAwesomeIcon
                          className="trash"
                          icon={faTrashCan}
                          onClick={() => handleDeleteCTA(cta)}
                        />
                      </div>
                    </div>
                    {openEditCTAModal && selectedCTA && (
                      <EditCTAModal
                        CTA={selectedCTA}
                        setOpenEditCTAModal={setOpenEditCTAModal}
                      />
                    )}
                  </div>
                ))}
              </ul>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer"
              }}
              onClick={() => setOpenAddCTAModal(true)}
              id="add-call-to-action"
            >
              <FontAwesomeIcon
                style={{ color: "#008CCE", paddingRight: "10px" }}
                icon={faPlus}
              />
              <div
                style={{
                  color: "#008CCE"
                }}
              >
                Add Call to Action
              </div>
            </div>
          </div>
        </div>
        {!isEmpty(orderedSections.conclusion) && (
          <>
            <div
              className="row row-padding-top-20"
              style={stepThreeStyles.rowPaddingTop20}
            >
              <div
                className="outline-subtitle"
                style={stepThreeStyles.outlineSubtitle}
              >
                Terms to include:
              </div>
            </div>
            <div
              className="row row-padding-top-20"
              style={(stepThreeStyles.rowPaddingTop20, { marginLeft: "20px" })}
            >
              {" "}
              <ul>
                {orderedSections.conclusion.map((conclusionTerm, index) => (
                  <li className="outline-text" key={index}>
                    {conclusionTerm?.search_term?.query && (
                      <span data-testid="conclusion-term">
                        {conclusionTerm.search_term.query}
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Conclusion;
