import PropTypes from "prop-types";

import LoadingElement from "./LoadingElement";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const InfinityScrollingLoading = (props: any) => (
  <div>{!props.parentLoading && <LoadingElement></LoadingElement>}</div>
);
InfinityScrollingLoading.propTypes = {
  parentLoading: PropTypes.bool
};

InfinityScrollingLoading.defaultProps = {
  parentLoading: true
};

export default InfinityScrollingLoading;
