import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgIconTrackKeywords = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <mask id="IconTrackKeywords_svg__a" fill="#fff">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.867 6.78c-.65.186-.91.975-.598 1.575A8.09 8.09 0 1 1 12.09 4c.503 0 .942-.387.942-.889v-.166c0-.511-.39-.943-.9-.945h-.041C6.518 2 2 6.518 2 12.09c0 5.574 4.518 10.092 10.09 10.092 5.574 0 10.092-4.518 10.092-10.091 0-1.778-.46-3.45-1.268-4.9a.903.903 0 0 0-1.047-.412Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.867 6.78c-.65.186-.91.975-.598 1.575A8.09 8.09 0 1 1 12.09 4c.503 0 .942-.387.942-.889v-.166c0-.511-.39-.943-.9-.945h-.041C6.518 2 2 6.518 2 12.09c0 5.574 4.518 10.092 10.09 10.092 5.574 0 10.092-4.518 10.092-10.091 0-1.778-.46-3.45-1.268-4.9a.903.903 0 0 0-1.047-.412Z"
      fill="currentColor"
    />
    <path
      d="M13.032 3.111h-1 1Zm7.882 4.08-.874.486.874-.487ZM19.27 8.354l-.886.462.886-.462Zm1.913 3.736c0-1.513-.37-2.942-1.026-4.199l-1.773.925c.51.978.799 2.091.799 3.274h2Zm-9.091 9.09a9.09 9.09 0 0 0 9.09-9.09h-2a7.09 7.09 0 0 1-7.09 7.09v2ZM3 12.092a9.09 9.09 0 0 0 9.09 9.09v-2A7.09 7.09 0 0 1 5 12.092H3ZM12.09 3A9.09 9.09 0 0 0 3 12.09h2A7.09 7.09 0 0 1 12.09 5V3Zm-.058-.055v.166h2v-.166h-2ZM12.09 3h.037l.008-2h-.045v2ZM3 12.09A9.09 9.09 0 0 1 12.09 3V1C5.967 1 1 5.966 1 12.09h2Zm9.09 9.092A9.09 9.09 0 0 1 3 12.09H1c0 6.125 4.966 11.09 11.09 11.09v-2Zm9.092-9.091a9.09 9.09 0 0 1-9.091 9.09v2c6.125 0 11.09-4.965 11.09-11.09h-2ZM20.04 7.677a9.046 9.046 0 0 1 1.142 4.414h2c0-1.953-.506-3.79-1.394-5.387l-1.748.973ZM12.09 5c.98 0 1.942-.762 1.942-1.889h-2c0-.022.005-.045.014-.066a.112.112 0 0 1 .025-.035c.01-.01.015-.01.02-.01v2Zm9.698 1.704c-.451-.81-1.387-1.118-2.197-.886l.552 1.923a.057.057 0 0 1-.036-.004.137.137 0 0 1-.067-.06l1.748-.973Zm-7.756-3.76c0-1.002-.778-1.94-1.896-1.944l-.008 2a.128.128 0 0 1-.085-.035c-.011-.012-.011-.02-.011-.02h2Zm6.124 4.948a.179.179 0 0 1-.01-.14c.008-.017.014-.023.012-.02a.043.043 0 0 1-.015.008l-.552-1.922c-1.372.394-1.757 1.948-1.208 3l1.773-.926Z"
      fill="currentColor"
      mask="url(#IconTrackKeywords_svg__a)"
    />
    <mask id="IconTrackKeywords_svg__b" fill="#fff">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.094 8.036c-.686.183-.923 1.079-.589 1.705a5 5 0 1 1-4.414-2.65c.454 0 .866-.34.868-.795v-.287c.002-.493-.366-.917-.859-.918h-.01a7 7 0 1 0 5.938 3.29c-.195-.312-.578-.44-.934-.345Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.094 8.036c-.686.183-.923 1.079-.589 1.705a5 5 0 1 1-4.414-2.65c.454 0 .866-.34.868-.795v-.287c.002-.493-.366-.917-.859-.918h-.01a7 7 0 1 0 5.938 3.29c-.195-.312-.578-.44-.934-.345Z"
      fill="currentColor"
    />
    <path
      d="m12.959 6.296-1-.003 1 .003Zm5.07 2.086.847-.53-.848.53Zm-5.07-2.373-1-.003 1 .003Zm3.546 3.732-.882.47.882-.47Zm1.586 2.35a5.978 5.978 0 0 0-.703-2.82l-1.765.94c.298.56.468 1.199.468 1.88h2Zm-6 6a6 6 0 0 0 6-6h-2a4 4 0 0 1-4 4v2Zm-6-6a6 6 0 0 0 6 6v-2a4 4 0 0 1-4-4h-2Zm6-6a6 6 0 0 0-6 6h2a4 4 0 0 1 4-4v-2Zm-.131-.085-.001.287 2 .006v-.287l-2-.006ZM12.1 4.09h-.01v2h.008l.002-2Zm-.01 0a8 8 0 0 0-8 8h2a6 6 0 0 1 6-6v-2Zm-8 8a8 8 0 0 0 8 8v-2a6 6 0 0 1-6-6h-2Zm8 8a8 8 0 0 0 8-8h-2a6 6 0 0 1-6 6v2Zm8-8c0-1.555-.445-3.01-1.215-4.24l-1.695 1.061c.576.921.91 2.01.91 3.179h2Zm-8-4c.893 0 1.865-.68 1.868-1.792l-2-.006c0-.09.045-.153.072-.177a.085.085 0 0 1 .06-.025v2Zm6.785-.24c-.455-.727-1.31-.975-2.039-.78l.514 1.932a.113.113 0 0 1-.067-.005.21.21 0 0 1-.103-.086l1.695-1.06Zm-4.916-1.84c.002-.964-.734-1.919-1.859-1.92l-.002 2a.179.179 0 0 1-.122-.051c-.017-.02-.017-.032-.017-.034l2 .006Zm3.428 3.26a.349.349 0 0 1-.017-.246c.01-.036.023-.048.02-.045a.095.095 0 0 1-.04.023l-.514-1.933c-.742.197-1.19.774-1.373 1.35a2.34 2.34 0 0 0 .159 1.792l1.765-.942Z"
      fill="currentColor"
      mask="url(#IconTrackKeywords_svg__b)"
    />
    <path
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      d="M12 3v7"
    />
    <circle cx={12} cy={12} r={1} stroke="currentColor" strokeWidth={2} />
  </svg>
);

export default SvgIconTrackKeywords;
