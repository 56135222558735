function PillarText() {
  const infoText = {
    fontFamily: "Source Sans Pro, sans-serif",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.005em",
    color: "#373F50",
    margin: "0px 5px 35px 0px",
    paddingLeft: "15px"
  };
  const textBold = { fontWeight: "bold" };

  return (
    <div className="help-text" style={infoText}>
      <br />
      <div>
        For a <span style={textBold}>Pillar Page</span>, follow these
        guidelines:
      </div>
      <br />
      <ul>
        <li>
          Choose <span style={textBold}>10 questions</span> from the list below
          to answer in your article
        </li>
        <li>
          Select <span style={textBold}>10 statement keywords</span> you can
          include naturally in your answers to those questions
        </li>
        <li>
          <span style={textBold}>Organize your questions</span> into a logical
          order
        </li>
        <li>
          Write an article around <span style={textBold}>3,000 words</span>
        </li>
      </ul>
    </div>
  );
}

export default PillarText;
