import BriefEditor from "./components/BriefEditor";

// eslint-disable-next-line arrow-body-style
const ContentBriefEditor = () => {
  return (
    <div className="mtlr-30-r" style={{ paddingLeft: "10px" }}>
      <BriefEditor></BriefEditor>
    </div>
  );
};

export default ContentBriefEditor;
