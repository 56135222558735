import * as React from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import DJForm from "@/components/shared/DJForm";
import DJTextField from "@/components/shared/DJTextField";
import Button from "@/components/shared/Button";
import { updatePassword } from "../../../api/post";
import { useAppStore } from "../../../hooks";
import { IPasswordValues } from "../../../interfaces/password-values.interface";

import "./resetPassword.scss";
import { IAppStore } from "../../../interfaces/app-store.interface";
import ShowPassword from "@/components/shared/ShowPassword";
import ShowConfirmPassword from "@/components/shared/ShowConfirmPassword";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [passError, setPassError] = useState(false);
  const [passInfo, setPassInfo] = useState(true);

  const [searchParams] = useSearchParams();
  const user_id = searchParams.get("user_id");
  const code = searchParams.get("code");

  const { setPasswordResetSuccessNotification } = useAppStore(
    (store: IAppStore) => ({
      setPasswordResetSuccessNotification: (value: boolean) =>
        store.setPasswordResetSuccessNotification(value)
    })
  );

  const handleMatchingRequirements = () => {
    setPassError(true);
    setPassInfo(true);
    return <div></div>;
  };
  const handlePassMatch = () => {
    setPassError(false);
    setPassInfo(false);
    return <div>Passwords do not match</div>;
  };

  const onClick = () => {
    navigate("/login");
  };

  const handleSubmit = async (values: IPasswordValues) => {
    if (code && user_id) {
      await updatePassword(
        code,
        parseInt(user_id),
        values.password,
        values.confirmPassword
      )
        .then(() => {
          setPasswordResetSuccessNotification(true);
          navigate("/login");
        })
        .catch((error) => {
          setOpen(true);
          console.error(error);
        });
    } else {
      console.error(
        "You are missing url params, please request a new password reset."
      );
    }
  };

  const validateSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, handleMatchingRequirements)
      .max(50, "Too Long!")
      .required(handleMatchingRequirements)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*():;<,>.?/|\\-_=+~`])(?=.{8,})/u,
        handleMatchingRequirements
      ),
    confirmPassword: Yup.string()
      .label("Password Confirm")
      .required(handlePassMatch)
      .oneOf([Yup.ref("password")], handlePassMatch)
  });

  return (
    <div style={{ fontFamily: "Source Sans Pro" }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <div style={{ width: "65%", textAlign: "left" }}>
          <Collapse in={open}>
            <Alert
              severity="warning"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              Your password has expired. Please enter new password.
            </Alert>
          </Collapse>
          <h2>Reset Your Password</h2>
          <div style={{ fontSize: "16px" }}>
            Create your new password then click the “Reset” button.
          </div>
          <DJForm
            initialValues={{ password: "", confirmPassword: "" }}
            validationSchema={validateSchema}
            onSubmit={handleSubmit}
          >
            <Grid style={{ height: "14vh" }}>
              <Grid item xs={12}>
                <DJTextField
                  id="password"
                  type="password"
                  label="New Password"
                  name="password"
                  required={true}
                />
                <ShowPassword />
                <DJTextField
                  id="confirmpassword"
                  type="password"
                  label="Confirm Password"
                  name="confirmPassword"
                  required={true}
                />
                <ShowConfirmPassword />
              </Grid>
              {passInfo && (
                <Grid
                  item
                  style={{
                    fontSize: "12px",
                    paddingLeft: "14px",
                    lineHeight: "20.11px",
                    marginTop: "3vh"
                  }}
                  className={passError ? "error" : ""}
                >
                  Password must contain at least 8 characters, one lowercase
                  character, one uppercase character, and one special case
                  character
                </Grid>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ paddingBottom: "30px" }}
            >
              <Grid
                item
                xs={8}
                sm={8}
                md={8}
                lg={8}
                xl={8}
                style={{ marginTop: "50px" }}
              ></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  alignSelf: "flex-end",
                  paddingTop: "40px"
                }}
              >
                <Grid
                  container
                  justifyContent={{
                    xs: "space-between",
                    sm: "space-between",
                    md: "space-between",
                    lg: "space-between",
                    xl: "space-between"
                  }}
                  alignItems="space-between"
                >
                  <Button
                    type="submit"
                    size="large"
                    color="primary"
                    variant="outlined"
                    text="Cancel"
                    onClick={onClick}
                  ></Button>
                  <Button
                    sx={{ padding: "12px 33px" }}
                    type="submit"
                    size="large"
                    color="primary"
                    variant="contained"
                    text="Reset"
                  ></Button>
                </Grid>
              </Grid>
            </Grid>
          </DJForm>
        </div>
      </Grid>
    </div>
  );
};

export default ResetPassword;
