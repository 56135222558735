export const formatPlan = (plan: string) => {
  let lowerCasePlan = "";
  if (plan) {
    lowerCasePlan = plan.toLowerCase();
  }
  if (lowerCasePlan.includes("enterprise")) {
    return "Enterprise";
  } else if (lowerCasePlan.includes("agency")) {
    if (lowerCasePlan.includes("prospecting")) {
      return "Agency Prospecting";
    } else if (lowerCasePlan.includes("pro")) {
      return "Agency Pro";
    } else if (lowerCasePlan.includes("premium")) {
      return "Agency Premium";
    } else if (lowerCasePlan.includes("plus")) {
      return "Agency Plus";
    }
    return "Agency";
  } else if (lowerCasePlan.includes("pro")) {
    return "Pro";
  } else if (lowerCasePlan.includes("premium")) {
    return "Premium";
  } else if (lowerCasePlan.includes("plus")) {
    return "Plus";
  } else if (lowerCasePlan.includes("no-subscription")) {
    return "No Subscription";
  }
  return "Free";
};
