import axios, { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";
import { SelectedItem } from "@/interfaces/pillar-strategy-store.interface";
import Cookies from "universal-cookie";
const cookies = new Cookies();

import { IUser } from "../interfaces/user.interface";
import {
  IBlogs,
  IBriefStrategyPutParentType,
  IBriefStrategyPutParentTypeRespone,
  IContentUrlUpdate
} from "@/interfaces/content-pillars.interface";

const putUrl = async (
  url: string,
  data = {},
  options = {},
  authorization = true
) => {
  const final_url = process.env.REACT_APP_NEO_URL + url;
  let device_uuid = cookies.get("device_id");
  if (!device_uuid) {
    device_uuid = uuidv4();
    cookies.set("device_id", device_uuid, { path: "/" });
  }
  let access_token = null;
  if (authorization) {
    access_token = cookies.get("Access_Token");
    if (!access_token) {
      window.location.href = "/login";
    }
  }

  return await axios
    .put(final_url, data, {
      ...options,
      headers: {
        authorization: `Bearer ${access_token}`,
        "X-DeviceUUID": device_uuid
      }
    })
    .then((response) => {
      if (authorization && response?.headers?.["x-access-token"]) {
        if (cookies.get("Access_Token")) {
          cookies.remove("Access_Token", { path: "/" });
          cookies.set("Access_Token", response.headers["x-access-token"], {
            path: "/",
            domain: process.env.REACT_APP_SUBDOMAIN
          });
        } else {
          // direct to login
          window.location.href = "/login";
        }
      }
      return response;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        cookies.remove("Access_Token", {
          path: "/",
          domain: process.env.REACT_APP_SUBDOMAIN
        });
        cookies.remove("Access_Token", { path: "/" });
        window.location.href = "/login";
      }
      throw err;
    });
};

export const putManageUser = async (payload: IUser) => {
  const url = "/api/v1/users/edit-invite";
  return await putUrl(url, payload)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });
};

export const putContentUrl = async (
  payload: IContentUrlUpdate,
  pid: number
): Promise<string> => {
  const url = `/api/v1/pillar-strategy-url?pid=${pid}`;
  return await putUrl(url, payload)
    .then((response: AxiosResponse): string => response.data as string)
    .catch((err) => {
      throw err;
    });
};

export const putBlog = async (payload: IBlogs): Promise<IBlogs> => {
  const url = "/api/v1/blog";
  return await putUrl(url, { blog: payload })
    .then((response: AxiosResponse): IBlogs => response.data as IBlogs)
    .catch((err) => {
      throw err;
    });
};

export const putMoveBlogs = async (payload: {
  type: string;
  id: number;
  items: SelectedItem;
}) => {
  const url = "/api/v1/move-blogs";
  return await putUrl(url, payload);
};

export const putPromoteBlogs = async (payload: {
  items: SelectedItem;
  pillar_id: number;
  pid: number;
}) => {
  const url = "/api/v1/promote-blogs";
  return await putUrl(url, payload);
};

export const putDemoteSubPillar = async (payload: {
  items: SelectedItem;
  pillar_id: number;
  pid: number;
}) => {
  const url = "/api/v1/demote-sub-pillar";
  return await putUrl(url, payload);
};

export const changePillarStrategyRowParentType = async (
  payload: IBriefStrategyPutParentType,
  pid: number
): Promise<IBriefStrategyPutParentTypeRespone> => {
  const url = `/api/v1/pillar-row-parent-type?pid=${pid}`;
  return await putUrl(url, payload)
    .then(
      (response: AxiosResponse): IBriefStrategyPutParentTypeRespone =>
        response.data as IBriefStrategyPutParentTypeRespone
    )
    .catch((err) => {
      throw err;
    });
};
