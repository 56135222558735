export const metaSection = (
  term: string,
  typeOfTerm: string,
  metaDescription: string,
  wordCount: string
) => {
  const tempArray = [];
  // Meta Information Section
  tempArray.push("Focus Search Term: " + term);
  tempArray.push("Target Word Count: " + wordCount);
  tempArray.push(`Article Type: ${typeOfTerm}`);
  tempArray.push("Title Tag: " + term);
  tempArray.push(`Meta Description: ${metaDescription}`);
  return tempArray;
};
