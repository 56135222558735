import { Row, Col } from "react-bootstrap";

import { IBriefSearchTerm } from "@/interfaces/outline.interface";

const StepOne = (props: {
  termLength: number;
  termType: "question" | "keyword";
  terms: IBriefSearchTerm[];
  termQuantity: number;
  submitRemoveTargeted: Function;
}) => {
  const submitRemoveTargeted = (brief: IBriefSearchTerm) => {
    props.submitRemoveTargeted(brief);
  };

  return (
    <Row
      style={{
        margin: "30px 0px"
      }}
    >
      <Col xs={6}>
        <h6>{props.termType.toUpperCase()}S</h6>
      </Col>
      <Col xs={6} style={{ textAlign: "right" }}>
        <span
          style={{
            color: props.termLength >= props.termQuantity ? "black" : "#B00020"
          }}
        >
          {props.termLength}
        </span>
        /{props.termQuantity} Targeted
      </Col>
      {props.terms.map((k) => (
        <span key={`selected-${props.termType}-${k.id}`}>
          {k.term_type === props.termType && (
            <div
              style={{
                color: "#373F50",
                borderBottom: "1px solid #E9EBF0",
                padding: "10px",
                display: "flex"
              }}
            >
              <div style={{ flex: 1 }}>{k.query}</div>
              <div
                onClick={() => {
                  submitRemoveTargeted(k);
                }}
                style={{
                  flex: 1,
                  textAlign: "right",
                  color: "grey",
                  cursor: "pointer"
                }}
              >
                x
              </div>
            </div>
          )}
        </span>
      ))}
    </Row>
  );
};

export default StepOne;
