import { useRef, useState, useEffect } from "react";

export const useOutOfBounds = (
  trackTop = true,
  trackBottom = true,
  trackLeft = true,
  trackRight = true
) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [isOutOfBounds, setIsOutOfBounds] = useState({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  });

  const mutationObserverCallback = () => {
    if (componentRef.current) {
      const rect = componentRef?.current?.getBoundingClientRect();
      const windowWidth = Math.min(
        document.documentElement.clientWidth,
        window.innerWidth
      );
      const windowHeight = Math.min(
        document.documentElement.clientHeight,
        window.innerHeight
      );
      const directions = {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      };

      let doSetOutOfBounds = false;

      if (trackTop) {
        if (rect.top < 0) {
          directions.top = Math.abs(0 - rect.top);
        }
        doSetOutOfBounds =
          doSetOutOfBounds || isOutOfBounds.top !== directions.top;
      }

      if (trackBottom) {
        if (rect.bottom > windowHeight) {
          directions.bottom = Math.abs(windowHeight - rect.bottom);
        }
        doSetOutOfBounds =
          doSetOutOfBounds || isOutOfBounds.bottom !== directions.bottom;
      }

      if (trackLeft) {
        if (rect.left < 0) {
          directions.left = Math.abs(0 - rect.left);
        }
        doSetOutOfBounds =
          doSetOutOfBounds || isOutOfBounds.left !== directions.left;
      }

      if (trackRight) {
        if (rect.right > windowWidth) {
          directions.right = Math.abs(windowWidth - rect.right);
        }
        doSetOutOfBounds =
          doSetOutOfBounds || isOutOfBounds.right !== directions.right;
      }

      if (doSetOutOfBounds) {
        setIsOutOfBounds(directions);
      }
    }
  };
  const observer = new MutationObserver(mutationObserverCallback);

  useEffect(() => {
    if (componentRef.current) {
      observer.observe(componentRef.current, {
        attributes: true,
        childList: true,
        subtree: true
      });
    }

    return () => observer.disconnect();
  }, [componentRef, observer]);

  return [componentRef, isOutOfBounds];
};
