import useContentBriefsStore from "@/hooks/useContentBriefsStore";
import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";
import { IBodyDroppableArea } from "@/interfaces/outline.interface";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { useHoverState } from "./utility";
import DeleteModal from "./DeleteModal";
import { useState } from "react";
import BodySectionsArea from "./BodySectionsArea";
import { RxDragHandleDots2 } from "react-icons/rx";
import "../stepTwo.scss";
// eslint-disable-next-line arrow-body-style
const BodyArea = () => {
  const {
    bodySectionDroppableAreas,
    showDeleteModal,
    setShowDeleteModal
  }: IContentBriefsStore = useContentBriefsStore(
    (store: IContentBriefsStore) => ({
      showDeleteModal: store.showDeleteModal,
      setShowDeleteModal: (value: boolean) => store.setShowDeleteModal(value),
      bodySectionDroppableAreas: store.bodySectionDroppableAreas
    })
  );
  const { hoveredStates, handleMouseEnter, handleMouseLeave } = useHoverState();
  const [clickedSection, setClickedSection] = useState<IBodyDroppableArea>();
  // selects the section for delete
  const handleSectionClick = (section: IBodyDroppableArea) => {
    setClickedSection(section);
  };

  return (
    <div>
      <div style={{ flex: 1 }}>
        <Droppable droppableId="ROOT" type="group">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {bodySectionDroppableAreas.map(
                (section, index) =>
                  section?.name?.includes("Body Section") && (
                    <Draggable
                      key={section.stringUUID}
                      index={index}
                      draggableId={section.stringUUID}
                    >
                      {(itemProvided) => (
                        <div
                          {...itemProvided.dragHandleProps}
                          {...itemProvided.draggableProps}
                          ref={itemProvided.innerRef}
                        >
                          <div
                            onMouseEnter={() => handleMouseEnter(section.name)}
                            onMouseLeave={() => handleMouseLeave(section.name)}
                            onClick={() => handleSectionClick(section)}
                            key={section.stringUUID}
                            style={{
                              borderRadius: 10,
                              margin: "40px 36px 40px 36px",
                              padding: 20
                            }}
                            className="body-sections"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between"
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <div>
                                  <RxDragHandleDots2
                                    style={{
                                      color: "#D9D9D9",
                                      marginRight: 10,
                                      marginBottom: 3
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    color: "#56627C",
                                    textTransform: "uppercase",
                                    fontSize: "16px"
                                  }}
                                >
                                  {section.name}
                                </div>
                              </div>
                              {hoveredStates[section.name] && (
                                <div
                                  onClick={() => setShowDeleteModal(true)}
                                  style={{
                                    color: "#909BB3",
                                    padding: "0px 30px",
                                    cursor: "default"
                                  }}
                                >
                                  x
                                </div>
                              )}
                              {clickedSection && showDeleteModal && (
                                <DeleteModal
                                  sectionName={clickedSection.name}
                                  sectionId={clickedSection.stringUUID}
                                />
                              )}
                            </div>
                            <BodySectionsArea {...section} />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  )
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
};
export default BodyArea;
