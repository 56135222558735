import { useEffect, useState } from "react";
import { IAppStore } from "../../../interfaces/app-store.interface";
import { useAppStore } from "../../../hooks";

export default function WelcomeCard() {
  const [userName, setUserName] = useState("");

  const { user } = useAppStore((store: IAppStore) => ({
    user: store.user
  }));

  useEffect(() => {
    if (user?.name) {
      setUserName(user.name);
    }
  }, [user]);
  return (
    <div className="welcome-card-container">
      <div
        style={{
          fontSize: "48px"
        }}
        className="name-container"
      >
        Welcome {userName.length ? userName.split(" ")[0] : ""}
      </div>
      <div
        style={{
          fontSize: "16px",
          lineHeight: "20px",
          letterSpacing: "0.0015em"
        }}
      >
        What would you like to do today?
      </div>
    </div>
  );
}
