import { AxiosResponse } from "axios";
import { postAuthorizeMfa } from "../api/post";

const authorizeMfa = async (mfa_code: string): Promise<Boolean> =>
  await postAuthorizeMfa(mfa_code)
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        return true;
      }
      return false;
    })
    .catch((error: Error) => {
      console.error(error);
      return false;
    });

export default authorizeMfa;
