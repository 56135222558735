import { useState, useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { Button, Row, Col, Dropdown } from "react-bootstrap";

import { useAppStore } from "@/hooks";
import { getSavedSearchTerms, getKeywordLists } from "@/api/get";
import { postContentBriefsStepOne } from "@/api/post";
import { IAppStore } from "@/interfaces/app-store.interface";
import {
  IBriefSearchTerm,
  ContentBriefs
} from "@/interfaces/outline.interface";
import useContentBriefsStore from "@/hooks/useContentBriefsStore";
import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";

import StepOneRow from "./components/StepOneRow";
import SelectedTerm from "./components/SelectedTerm";
import BriefInstruction from "./components/BriefInstructions";

import FunnelIcon from "@/assets/icons/funnel-fill.svg";
import "./step-one.scss";
import BreadcrumbsHeader from "../components/BreadcrumbsHeader";

const getTermQuantity = (
  briefType: "blog" | "sub-pillar" | "pillar" | "ad-hoc"
) => {
  let term_quantity = 6;
  switch (briefType) {
    case "pillar":
      term_quantity = 10;
      break;
    case "sub-pillar":
      term_quantity = 8;
      break;
    case "blog":
    default:
      term_quantity = 4;
      break;
  }
  return term_quantity;
};

const getBriefType = (brief: ContentBriefs) => {
  let brief_type = "ad-hoc";
  if (brief.pillar_id) {
    brief_type = "pillar";
  } else if (brief.sub_pillar_id) {
    brief_type = "sub-pillar";
  } else if (brief.blog_id) {
    brief_type = "blog";
  }
  return brief_type as "blog" | "sub-pillar" | "pillar" | "ad-hoc";
};

const dropdownTerms = {
  backgroundColor: "white",
  color: "gray",
  border: "1px solid gray",
  borderRadius: "5px"
};

const dropdownSort = {
  backgroundColor: "white",
  color: "gray",
  border: "none"
};

const StepOne = () => {
  const [terms, setTerms] = useState([] as IBriefSearchTerm[]);
  const [search, setSearch] = useState("");
  const [selectedTerms, setSelectedTerms] = useState([] as IBriefSearchTerm[]);
  const [loading, setLoading] = useState(true);
  const [briefType, setBriefType] = useState(
    "blog" as "blog" | "sub-pillar" | "pillar" | "ad-hoc"
  );
  const [termFilterText, setTermFilterText] = useState("Questions & Keywords");
  const [termTypeFilter, setTermTypeFilter] = useState(["question", "keyword"]);
  const [sortOption, setSortOption] = useState("Priority");
  const [blocklist, setBlocklist] = useState([] as string[]);
  const [useBlocklist, setUseBlocklist] = useState(false);
  const navigate = useNavigate();
  const { selectedPidObject, setShowAlertBanner } = useAppStore(
    (store: IAppStore) => ({
      selectedPidObject: store.selectedPidObject,
      setShowAlertBanner: store.setShowAlertBanner
    })
  );
  const {
    isStepOneActive,
    setIsStepOneComplete,
    setIsStepTwoComplete,
    setIsStepThreeComplete,
    setIsStepOneActive,
    keywordsLength,
    pillar,
    briefId,
    setBriefId,
    questionsLength,
    termQuantity,
    setTermQuantity,
    setKeywordsLength,
    setQuestionsLength,
    setIsStepTwoActive,
    setTargetedKeywords,
    setTargetedQuestions
  } = useContentBriefsStore((store: IContentBriefsStore) => ({
    isStepOneActive: store.isStepOneActive,
    pillar: store.pillar,
    briefId: store.briefId,
    questionsLength: store.questionsLength,
    keywordsLength: store.keywordsLength,
    termQuantity: store.termQuantity,
    isStepOneComplete: store.isStepOneComplete,
    setIsStepOneComplete: (value: boolean) => store.setIsStepOneComplete(value),
    setIsStepTwoComplete: (value: boolean) => store.setIsStepTwoComplete(value),
    setIsStepThreeComplete: (value: boolean) =>
      store.setIsStepThreeComplete(value),
    setTermQuantity: (value: number) => store.setTermQuantity(value),
    setBriefId: (value: number) => store.setBriefId(value),
    setKeywordsLength: (value: number) => store.setKeywordsLength(value),
    setQuestionsLength: (value: number) => store.setQuestionsLength(value),
    setIsStepOneActive: (value: boolean) => store.setIsStepOneActive(value),
    setIsStepTwoActive: (value: boolean) => store.setIsStepTwoActive(value),
    setTargetedKeywords: (value: IBriefSearchTerm[]) =>
      store.setTargetedKeywords(value),
    setTargetedQuestions: (value: IBriefSearchTerm[]) =>
      store.setTargetedQuestions(value)
  }));
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.split("/");
    const brief_id = parseInt(path[path.length - 1]) || 0;
    setBriefId(brief_id);
    setIsStepOneActive(true);

    const fetchData = async () => {
      const brief = await getSavedSearchTerms(selectedPidObject.id, briefId);
      if (brief.pid !== selectedPidObject.id) {
        navigate("/pillar-strategy");
        return;
      }
      if ([2, 3, 4].includes(brief.current_step)) {
        setIsStepOneComplete(true);
      }
      if ([3, 4].includes(brief.current_step)) {
        setIsStepTwoComplete(true);
      }
      if (brief.current_step === 4) {
        setIsStepThreeComplete(true);
      }

      const kw_lists = await getKeywordLists(selectedPidObject.id);
      const bl = kw_lists?.filter((kw) => kw?.list_type?.name === "blocklist");
      const blkw = bl?.[0]?.keywords as string[];
      if (brief.keywords) {
        const termList = brief.keywords;
        const st = termList.filter((term) => term.targeted);
        setTerms(termList);
        setSelectedTerms(st);
        setQuestionsLength(st.filter((k) => k.term_type === "question").length);
        setKeywordsLength(st.filter((k) => k.term_type === "keyword").length);
      }
      if (blkw?.length > 0) {
        setBlocklist([...blkw]);
      }
      const brief_type = getBriefType(brief);
      setBriefType(brief_type);
      setTermQuantity(getTermQuantity(brief_type));
      setLoading(false);
    };
    if (selectedPidObject.id && briefId !== 0) {
      fetchData();
    }
  }, [selectedPidObject, briefId, pillar]);

  const updateSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const substringContainedInArray = (bl: string[], term: string) =>
    bl.some((item) =>
      term.toLowerCase().trim().includes(item.toLowerCase().trim())
    );

  const selectTerm = (term: IBriefSearchTerm) => {
    const sk = selectedTerms;
    if (sk.includes(term)) {
      const sk_splice = sk.filter((k) => k.id !== term.id);
      setSelectedTerms([...sk_splice]);
      setQuestionsLength(
        sk_splice.filter((k) => k.term_type === "question").length
      );
      setKeywordsLength(
        sk_splice.filter((k) => k.term_type === "keyword").length
      );
    } else {
      sk.push(term);
      setSelectedTerms([...sk]);
      setQuestionsLength(sk.filter((k) => k.term_type === "question").length);
      setKeywordsLength(sk.filter((k) => k.term_type === "keyword").length);
    }
  };

  const handleSubmit = async () => {
    if (selectedTerms.length === 0) {
      setShowAlertBanner(true, {
        message: "You must select at least 1 Question or Keyword to proceed.",
        severity: "error"
      });
    } else {
      const req = selectedTerms.map((e) => ({ ...e, targeted: true }));
      await postContentBriefsStepOne(
        selectedPidObject.id,
        briefId,
        req as IBriefSearchTerm[]
      )
        .then((res) => {
          setTargetedKeywords(res.targeted_keywords);
          setTargetedQuestions(res.targeted_questions);
          setIsStepOneComplete(true);
          setIsStepOneActive(false);
          setIsStepTwoActive(true);
          // addOrUpdateUrlParam("current_step", "2");
          navigate(
            `/content-briefs-workflow/step-two/${briefId}${location.search}`
          );
          setShowAlertBanner(true, {
            message: "Your targeted keywords have been saved!",
            severity: "success"
          });
        })
        .catch((e) => {
          console.error(e);
          setShowAlertBanner(true, {
            message: "There was an error saving your targeted keywords.",
            severity: "error"
          });
        });
    }
  };

  const sortByProperty = (a: IBriefSearchTerm, b: IBriefSearchTerm) => {
    if (sortOption === "Priority") {
      const aDjScore = parseFloat(a.dj_score.toString());
      const bDjScore = parseFloat(a.dj_score.toString());
      return aDjScore > bDjScore ? -1 : 1;
    } else if (sortOption === "A-Z") {
      return a.query.toLowerCase() > b.query.toLowerCase() ? 1 : -1;
    } else if (sortOption === "Your Rank") {
      const aRank = a.your_rank || -1;
      const bRank = b.your_rank || -1;
      return aRank > bRank ? 1 : -1;
    } else if (sortOption === "Competitor Rank") {
      const aCRank = a.competitor_rank || -1;
      const bCRank = b.competitor_rank || -1;
      return aCRank > bCRank ? 1 : -1;
    } else if (sortOption === "Targeted Count") {
      return (a.total_targeted || 0) < (b.total_targeted || 0) ? 1 : -1;
    }
    return 0;
  };

  const applyBlocklist = () => {
    setUseBlocklist(!useBlocklist);
  };

  return (
    <div style={{ width: "100%" }}>
      <>
        <BreadcrumbsHeader handleSubmit={handleSubmit} />
        {isStepOneActive && !loading && (
          <div style={{ paddingTop: "86px", marginTop: 15 }}>
            <Row style={{ marginLeft: "10px" }}>
              <Col
                xl={{ order: 1, span: 8 }}
                lg={{ order: 2 }}
                style={{ borderRight: "1px solid #E9EBF0" }}
              >
                <BriefInstruction
                  briefType={briefType}
                  termQuantity={termQuantity}
                ></BriefInstruction>
                <Row style={{ marginLeft: "5px" }}>
                  <Col xl={3} lg={6} style={{ paddingBottom: "10px" }}>
                    <input
                      className="form-control"
                      placeholder="Search by name"
                      onChange={updateSearch}
                    ></input>
                  </Col>
                  <Col xl={3} lg={6}>
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        style={dropdownTerms}
                        size="sm"
                      >
                        {termFilterText}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            setTermFilterText("Questions & Keywords");
                            setTermTypeFilter(["question", "keyword"]);
                          }}
                        >
                          Questions & Keywords
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setTermFilterText("Questions Only");
                            setTermTypeFilter(["question"]);
                          }}
                        >
                          Questions Only
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setTermFilterText("Keywords Only");
                            setTermTypeFilter(["keyword"]);
                          }}
                        >
                          Keywords Only
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col xl={2} lg={2}>
                    <Button
                      onClick={applyBlocklist}
                      style={{
                        border: useBlocklist ? "1px solid #008cce" : "none",
                        color: useBlocklist ? "#008cce" : "gray",
                        backgroundColor: "white",
                        fontSize: "small",
                        padding: "4px"
                      }}
                    >
                      <img
                        src={FunnelIcon}
                        alt="filter icon"
                        style={{
                          paddingRight: "5px"
                        }}
                      ></img>
                      Apply Blocklist
                    </Button>
                  </Col>
                  <Col xl={2} lg={2}>
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        style={dropdownSort}
                        size="sm"
                      >
                        Sort: {sortOption}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            setSortOption("Priority");
                          }}
                        >
                          Priority
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setSortOption("A-Z");
                          }}
                        >
                          A-Z
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setSortOption("Your Rank");
                          }}
                        >
                          Your Rank
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setSortOption("Competitor Rank");
                          }}
                        >
                          Competitor Rank
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setSortOption("Targeted Count");
                          }}
                        >
                          Targeted Count
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
                <div>
                  {terms.length > 0 &&
                    terms
                      .filter((t) =>
                        t.query.toLowerCase().includes(search.toLowerCase())
                      )
                      .filter((t) => termTypeFilter.includes(t.term_type))
                      .filter(
                        (t) =>
                          (useBlocklist &&
                            !substringContainedInArray(blocklist, t.query)) ||
                          !useBlocklist
                      )
                      .sort((a, b) => sortByProperty(a, b))
                      .map((t) => (
                        <StepOneRow
                          term={t}
                          key={t.id}
                          selectTerm={selectTerm}
                          selected={selectedTerms.includes(t)}
                        ></StepOneRow>
                      ))}
                </div>
              </Col>
              <Col
                xl={{ order: 2, span: 4 }}
                lg={{ order: 1 }}
                style={{ color: "grey" }}
              >
                <div className="conditionalMaxHeight">
                  <Row>
                    <Col xl={12} lg={6}>
                      <SelectedTerm
                        termLength={questionsLength}
                        termType="question"
                        terms={selectedTerms}
                        termQuantity={termQuantity}
                        submitRemoveTargeted={selectTerm}
                      ></SelectedTerm>
                    </Col>
                    <Col xl={12} lg={6}>
                      <SelectedTerm
                        termLength={keywordsLength}
                        termType="keyword"
                        terms={selectedTerms}
                        termQuantity={termQuantity}
                        submitRemoveTargeted={selectTerm}
                      ></SelectedTerm>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </>
    </div>
  );
};

export default StepOne;
