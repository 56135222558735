import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../competitors.scss";

const upgradeCardStyle = {
  color: "white",
  width: "325px",
  height: "188px",
  flexGrow: "0",
  paddingBottom: "3rem",
  textAlign: "center",
  margin: "200px 0px 150px 0px",
  borderRadius: "8px",
  background:
    "linear-gradient(rgb(54, 69, 90) 3%, rgb(53, 149, 208) 72%, rgba(0, 140, 206, 0.7) 100%)"
};

const UpgradeConfirmCancelColumn = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Card className="d-inline-block" style={upgradeCardStyle}>
        <Card.Body>
          <div>Do you want to add more competitors to your list?</div>
          Upgrade to our Premium Plan to see what your other competitors are
          doing.
          <div className="p-3">
            <button
              onClick={() => navigate("/settings/subscription")}
              className="btn btn-success"
            >
              Upgrade
            </button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default UpgradeConfirmCancelColumn;
