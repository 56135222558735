import { useContext } from "react";
import { useObserver } from "mobx-react-lite";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStore = (context: any, storeSelector: any, dataSelector: any) => {
  const value = useContext(context);
  if (!value) {
    throw new Error("Provider is required when using context");
  }
  const store = storeSelector(value);
  return useObserver(() => dataSelector(store));
};

export default useStore;
