import {
  IBlogs,
  IContentPillars,
  ISubPillars
} from "@/interfaces/content-pillars.interface";
import Collapse from "react-bootstrap/Collapse";
import StatusBadge from "./StatusBadge";
import { useEffect, useState } from "react";
import UrlCell from "./UrlCell";
import { Form } from "react-bootstrap";

import { useAppStore, usePillarStrategyStore } from "@/hooks";
import EditRow from "./EditRow";
import { postBriefToStrategy } from "@/api/post";
import { IAppStore } from "@/interfaces/app-store.interface";
import { IPillarStrategyStore } from "@/interfaces/pillar-strategy-store.interface";
import { putContentUrl } from "@/api/put";
import NewItemAlert from "./NewItemAlert";
import dayjs from "dayjs";

interface BlogRowProps {
  expanded: boolean;
  blog: IBlogs;
  writeAccess: boolean;
  parentPillar: IContentPillars;
  parentSubPillar: ISubPillars;
  selectAll: String;
  selected: Function;
}
type HandleChange = (event: React.ChangeEvent<HTMLInputElement>) => void;
function BlogRowSecondTier({
  expanded,
  blog,
  writeAccess,
  parentPillar,
  parentSubPillar,
  selected,
  selectAll
}: BlogRowProps) {
  const [hovered, setHovered] = useState(false);
  const [edit, setEdit] = useState(false);
  const [submittingBrief, setSubmittingBrief] = useState<boolean>(false);
  const [savingUrl, setSavingUrl] = useState<boolean>(false);
  const [checked, setChecked] = useState(false);
  const [blogData, setBlogData] = useState<IBlogs>({
    id: null,
    topic: "",
    pillar_id: 0,
    sub_pillar_id: 0,
    url: "",
    outline_status: null
  });

  const { setShowAlertBanner, selectedPidObject } = useAppStore(
    (store: IAppStore) => ({
      setShowAlertBanner: store.setShowAlertBanner,
      selectedPidObject: store.selectedPidObject
    })
  );

  const {
    selectedTopics,
    searchedTopic,
    selectedPidPillarTopics,
    setSelectedPidPillarTopics
  } = usePillarStrategyStore((store: IPillarStrategyStore) => ({
    searchedTopic: store.searchedTopic,
    selectedTopics: store.selectedTopics,
    selectedPidPillarTopics: store.selectedPidPillarTopics,
    setSelectedPidPillarTopics: (value: IContentPillars[]) =>
      store.setSelectedPidPillarTopics(value)
  }));

  useEffect(() => {
    setBlogData({ ...blog });
    return () => {
      setBlogData({
        id: null,
        topic: "",
        pillar_id: 0,
        sub_pillar_id: 0,
        url: "",
        outline_status: null
      });
    };
  }, [blog]);

  useEffect(() => {
    const s = selectAll === "true";
    if (s !== checked) {
      setChecked(s);
      selected(blog, "blog");
    }
  }, [selectAll]);

  useEffect(() => {
    setChecked(false);
    selected(blog, "blog", false);
  }, [searchedTopic]);

  useEffect(() => {
    if (selectedTopics.blog.length === 0) {
      setChecked(false);
    } else if (selectedTopics.blog.includes(blog.id)) {
      setChecked(true);
    }
  }, [selectedTopics]);

  const handleChange: HandleChange = (e) => {
    const { name, value } = e.target;
    setBlogData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCancel = () => {
    setBlogData({ ...blog });
    setEdit(false);
  };

  const handleRowSelect = () => {
    setChecked(!checked);
    selected(blog, "blog");
  };

  const addBriefToStrategy = async () => {
    setSubmittingBrief(true);
    try {
      if (blogData.id) {
        const body = {
          id: blogData.id,
          type: "blog",
          topic: blogData.topic,
          pid: selectedPidObject.id
        };
        const outlineStatus = await postBriefToStrategy(body);
        if (outlineStatus) {
          setSelectedPidPillarTopics(
            selectedPidPillarTopics.map((pillar: IContentPillars) => {
              if (pillar.id === parentPillar.id) {
                return {
                  ...pillar,
                  sub_pillars: pillar?.sub_pillars?.map((subPillar) => {
                    if (subPillar.id === parentSubPillar.id) {
                      return {
                        ...subPillar,
                        blogs: subPillar?.blogs?.map((b) => {
                          if (b.id === blogData.id) {
                            return {
                              ...b,
                              outline_status: outlineStatus
                            };
                          }
                          return b;
                        })
                      };
                    }
                    return subPillar;
                  })
                };
              }
              return pillar;
            })
          );
        }

        setShowAlertBanner(true, {
          message: "Brief added to strategy.",
          severity: "success"
        });
      } else {
        setShowAlertBanner(true, {
          message: "Error creating brief, please try again later",
          severity: "error"
        });
      }
    } catch (error) {
      console.error(error);
      setShowAlertBanner(true, {
        message: "Error creating brief, please try again later",
        severity: "error"
      });
    }
    setSubmittingBrief(false);
  };

  const saveUrl = async () => {
    setSavingUrl(true);
    try {
      if (blogData.id) {
        const body = {
          id: blogData.id,
          url: blogData.url,
          type: "blog"
        };
        const response = await putContentUrl(body, selectedPidObject.id);
        if (response) {
          setSelectedPidPillarTopics(
            selectedPidPillarTopics.map((pillar: IContentPillars) => {
              if (pillar.id === parentPillar.id) {
                return {
                  ...pillar,
                  sub_pillars: pillar?.sub_pillars?.map((subPillar) => {
                    if (subPillar.id === parentSubPillar.id) {
                      return {
                        ...subPillar,
                        blogs: subPillar?.blogs?.map((b) => {
                          if (b.id === blogData.id) {
                            return {
                              ...b,
                              url: blogData.url
                            };
                          }
                          return b;
                        })
                      };
                    }
                    return subPillar;
                  })
                };
              }
              return pillar;
            })
          );
          setShowAlertBanner(true, {
            message: "Url saved.",
            severity: "success"
          });
        } else {
          setBlogData((prevState) => ({
            ...prevState,
            url: blog.url
          }));
          setShowAlertBanner(true, {
            message: "Error saving url, please try again later",
            severity: "error"
          });
        }
      } else {
        setBlogData((prevState) => ({ ...prevState, url: blog.url }));
        setShowAlertBanner(true, {
          message: "Error saving url, please try again later",
          severity: "error"
        });
      }
    } catch (error) {
      console.error(error);
      setBlogData((prevState) => ({ ...prevState, url: blog.url }));
      setShowAlertBanner(true, {
        message: "Error saving url, please try again later",
        severity: "error"
      });
    }
    setSavingUrl(false);
  };

  return (
    <Collapse in={expanded}>
      <tr
        style={{ height: "58px" }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className="align-middle"
        data-testid="blog-row-two"
      >
        {edit ? (
          <EditRow
            setEdit={setEdit}
            originalBlog={blogData}
            originalType="blog"
            parentType="subPillar"
            parentSubPillar={parentSubPillar}
            handleCancel={handleCancel}
            handleChange={handleChange}
            parentPillar={parentPillar}
            url={blogData.url ? blogData.url : ""}
          />
        ) : (
          <>
            <th className="fw-400" style={{ paddingLeft: "96px" }}>
              <Form.Check
                style={{
                  position: "relative",
                  left: "-77px",
                  float: "left"
                }}
                className="rowCheckbox"
                id="flexCheckDefault"
                type={"checkbox"}
                checked={checked}
                onChange={handleRowSelect}
              />
              <span style={{ textAlign: "left", display: "grid" }}>
                <div style={{ display: "flex" }}>
                  {blog.created_at &&
                    Math.abs(
                      dayjs().utc().diff(dayjs(blog.created_at), "day")
                    ) < 1 && (
                      <div
                        style={{
                          width: "20px",
                          float: "left",
                          marginLeft: "-20px"
                        }}
                      >
                        <NewItemAlert />
                      </div>
                    )}
                  <div style={{ float: "left" }}>{blog.topic}</div>
                </div>
              </span>
            </th>
            <th className="fw-400">Blog</th>
            <th className="fw-400">
              <StatusBadge
                submittingBrief={submittingBrief}
                hovered={hovered}
                callbackFunction={addBriefToStrategy}
                briefData={blogData.outline_status}
              />
            </th>
            <UrlCell
              saveUrl={saveUrl}
              savingUrl={savingUrl}
              isEditableRow={true}
              handleEdit={() => setEdit(true)}
              writeAccess={writeAccess}
              type="blog"
              id={blog.id || 0}
              hovered={hovered}
              handleCancel={handleCancel}
              handleChange={handleChange}
              url={blogData.url ? blogData.url : ""}
            />
          </>
        )}
      </tr>
    </Collapse>
  );
}

export default BlogRowSecondTier;
