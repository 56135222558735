import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import queryString from "query-string";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { useAppStore, useEffectAsync } from "../../../../hooks";
import { getSavedOutline, getBriefStatus } from "../../../../api/get";
import { postSavedOutline } from "../../../../api/post";
import IncludedKeywords from "./IncludedKeywords";
import HelpText from "./HelperText";
import DownloadBrief from "./DownloadBrief";
import EmailBrief from "./EmailBrief";

import { IAppStore } from "../../../../interfaces/app-store.interface";
import {
  IProcessedOutline,
  ISavedOutline
} from "../../../../interfaces/saved_outline.interface";

import "./editor.scss";
import { useSearchParams } from "react-router-dom";
import { determineBriefType } from "../../utils";

const BriefEditor = () => {
  const [contentTitle, setContentTitle] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [filteredKeywords, setFilteredKeywords] = useState<string[]>([]);
  const [outline, setOutline] = useState<ISavedOutline>({} as ISavedOutline);
  const [fetchedOutline, setFetchedOutline] = useState(
    [] as IProcessedOutline[]
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const { selectedPidObject, setShowAlertBanner } = useAppStore(
    (store: IAppStore) => ({
      selectedPidObject: store.selectedPidObject,
      setShowAlertBanner: store.setShowAlertBanner
    })
  );

  const toolbarOverride = {
    inline: {
      options: ["bold", "italic", "underline", "superscript", "subscript"]
    },
    list: {
      options: ["ordered", "unordered"]
    },
    options: ["inline", "link", "list", "blockType", "textAlign"],
    textAlign: {
      inDropdown: true,
      options: ["left", "center", "right", "justify"]
    }
  };

  const saveOutline = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    if (outline) {
      outline.html_body = markup;
      outline.content_length = 5;
      outline.filtered_keywords = filteredKeywords;
      outline.body_json = "";
      outline.filtered_outline = fetchedOutline;
      postSavedOutline(outline)
        .then(() => {
          setShowAlertBanner(true, {
            message: "Your content brief was succesfully saved!",
            severity: "success"
          });
        })
        .catch((e: Error) => {
          console.error(e);
          setShowAlertBanner(true, {
            message: "There was ane error saving your content brief.",
            severity: "error"
          });
        });
    }
  };

  const onEditorStateChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
  };

  useEffectAsync(async () => {
    const parsed = queryString.parse(location.search);
    const pathArray = window.location.pathname.split("/");
    const brief_id = pathArray[pathArray.length - 1];
    let phrase = parsed.phrase;
    let type = parsed.type;
    if (!phrase || !type) {
      const fetchedBrief = await getBriefStatus(
        parseInt(brief_id),
        selectedPidObject.id
      );
      phrase = fetchedBrief.h1;
      type = determineBriefType(fetchedBrief);
      setContentTitle(phrase);

      setSearchParams({
        type,
        phrase
      });
    } else if (typeof phrase === "string" && phrase.length) {
      setContentTitle(decodeURIComponent(phrase));
    }

    const fetchData = async () => {
      if (typeof brief_id === "string" && typeof phrase === "string") {
        const savedOutline = await getSavedOutline(
          parseInt(brief_id),
          phrase,
          selectedPidObject.id
        ).catch((e: Error) => {
          console.error(e);
          setShowAlertBanner(true, {
            message: "There was ane error retrieving your content brief.",
            severity: "error"
          });
        });
        if (savedOutline) {
          if (typeof savedOutline.filtered_outline === "string") {
            setFetchedOutline(JSON.parse(savedOutline.filtered_outline));
          } else {
            setFetchedOutline(savedOutline.filtered_outline);
          }
          const blocksFromHtml = htmlToDraft(savedOutline.html_body);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          );
          const newEditorState = EditorState.createWithContent(contentState);
          setEditorState(newEditorState);
          if (typeof savedOutline.filtered_keywords === "string") {
            setFilteredKeywords(JSON.parse(savedOutline.filtered_keywords));
          } else {
            setFilteredKeywords(savedOutline.filtered_keywords);
          }
          setOutline(savedOutline);
        }
      }
    };
    if (selectedPidObject.id) {
      fetchData();
    }
  }, [contentTitle, selectedPidObject]);

  return (
    <div>
      <div style={{ height: "100%", margin: "0 auto", maxWidth: "100%" }}>
        <div className="card-body">
          <div className="row" style={{ paddingTop: "15px" }}>
            <div className="col-sm-9 col-lg-9">
              <div className="d-flex">
                <h3 style={{ marginRight: "10px" }}>
                  Brief Type: {searchParams.get("type")}
                </h3>
              </div>
              <HelpText
                type={decodeURI(searchParams.get("type") || "ad-hoc")}
              ></HelpText>
              <div className="row">
                <div className="col-sm-9">
                  <div className="card-header-title font-size-lg font-weight-normal">
                    <h2 id="article_title" className="content_title">
                      {contentTitle}
                    </h2>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="outline-creation-actions-container">
                        <div className="action_button">
                          <DownloadBrief
                            rawContentState={convertToRaw(
                              editorState.getCurrentContent()
                            )}
                            contentTitle={contentTitle}
                          />
                        </div>
                        <div className="action_button">
                          <EmailBrief
                            rawContentState={convertToRaw(
                              editorState.getCurrentContent()
                            )}
                            contentTitle={contentTitle}
                          />
                        </div>
                        <button
                          className="btn btn-outline-primary action_button"
                          id="save_outline"
                          onClick={() => saveOutline()}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row editor_top_div">
                <div className="col-sm-12" style={{ paddingRight: "0" }}>
                  <div id="editor-container" style={{ height: "850px" }}>
                    <Editor
                      wrapperStyle={{
                        border: "solid 1px lightgrey"
                      }}
                      editorStyle={{ height: "800px" }}
                      editorState={editorState}
                      onEditorStateChange={onEditorStateChange}
                      toolbar={toolbarOverride}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3 col-lg-3">
              <IncludedKeywords
                keywords={filteredKeywords}
                editor_state={editorState}
              ></IncludedKeywords>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BriefEditor;
