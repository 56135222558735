import PropTypes from "prop-types";
import Select from "react-select";

const langObjects = [
  { value: "af", label: "Afrikaans" },
  { value: "ak", label: "Akan" },
  { value: "sq", label: "Albanian" },
  { value: "am", label: "Amharic" },
  { value: "ar", label: "Arabic" },
  { value: "hy", label: "Armenian" },
  { value: "az", label: "Azerbaijani" },
  { value: "eu", label: "Basque" },
  { value: "be", label: "Belarusian" },
  { value: "bem", label: "Bemba" },
  { value: "bn", label: "Bengali" },
  { value: "bh", label: "Bihari" },
  { value: "bs", label: "Bosnian" },
  { value: "br", label: "Breton" },
  { value: "bg", label: "Bulgarian" },
  { value: "km", label: "Cambodian" },
  { value: "ca", label: "Catalan" },
  { value: "chr", label: "Cherokee" },
  { value: "ny", label: "Chichewa" },
  { value: "zh-cn", label: "Chinese (Simplified)" },
  { value: "zh-tw", label: "Chinese (Traditional)" },
  { value: "co", label: "Corsican" },
  { value: "hr", label: "Croatian" },
  { value: "cs", label: "Czech" },
  { value: "da", label: "Danish" },
  { value: "nl", label: "Dutch" },
  { value: "en", label: "English" },
  { value: "eo", label: "Esperanto" },
  { value: "et", label: "Estonian" },
  { value: "ee", label: "Ewe" },
  { value: "fo", label: "Faroese" },
  { value: "tl", label: "Filipino" },
  { value: "fi", label: "Finnish" },
  { value: "fr", label: "French" },
  { value: "fy", label: "Frisian" },
  { value: "gaa", label: "Ga" },
  { value: "gl", label: "Galician" },
  { value: "ka", label: "Georgian" },
  { value: "de", label: "German" },
  { value: "el", label: "Greek" },
  { value: "gn", label: "Guarani" },
  { value: "gu", label: "Gujarati" },
  { value: "ht", label: "Haitian Creole" },
  { value: "ha", label: "Hausa" },
  { value: "haw", label: "Hawaiian" },
  { value: "iw", label: "Hebrew" },
  { value: "hi", label: "Hindi" },
  { value: "hu", label: "Hungarian" },
  { value: "is", label: "Icelandic" },
  { value: "ig", label: "Igbo" },
  { value: "value", label: "Indonesian" },
  { value: "ia", label: "Interlingua" },
  { value: "ga", label: "Irish" },
  { value: "it", label: "Italian" },
  { value: "ja", label: "Japanese" },
  { value: "jw", label: "Javanese" },
  { value: "kn", label: "Kannada" },
  { value: "kk", label: "Kazakh" },
  { value: "rw", label: "Kinyarwanda" },
  { value: "rn", label: "Kirundi" },
  { value: "kg", label: "Kongo" },
  { value: "ko", label: "Korean" },
  { value: "kri", label: "Krio (Sierra Leone)" },
  { value: "ku", label: "Kurdish" },
  { value: "ckb", label: "Kurdish (Soranî)" },
  { value: "ky", label: "Kyrgyz" },
  { value: "lo", label: "Laothian" },
  { value: "la", label: "Latin" },
  { value: "lv", label: "Latvian" },
  { value: "ln", label: "Lingala" },
  { value: "lt", label: "Lithuanian" },
  { value: "loz", label: "Lozi" },
  { value: "lg", label: "Luganda" },
  { value: "ach", label: "Luo" },
  { value: "mk", label: "Macedonian" },
  { value: "mg", label: "Malagasy" },
  { value: "ms", label: "Malay" },
  { value: "ml", label: "Malayalam" },
  { value: "mt", label: "Maltese" },
  { value: "mi", label: "Maori" },
  { value: "mr", label: "Marathi" },
  { value: "mfe", label: "Mauritian Creole" },
  { value: "mo", label: "Moldavian" },
  { value: "mn", label: "Mongolian" },
  { value: "sr-me", label: "Montenegrin" },
  { value: "ne", label: "Nepali" },
  { value: "pcm", label: "Nigerian Pvaluegin" },
  { value: "nso", label: "Northern Sotho" },
  { value: "no", label: "Norwegian" },
  { value: "nn", label: "Norwegian (Nynorsk)" },
  { value: "oc", label: "Occitan" },
  { value: "or", label: "Oriya" },
  { value: "om", label: "Oromo" },
  { value: "ps", label: "Pashto" },
  { value: "fa", label: "Persian" },
  { value: "pl", label: "Polish" },
  { value: "pt", label: "Portuguese" },
  { value: "pt-br", label: "Portuguese (Brazil)" },
  { value: "pt-pt", label: "Portuguese (Portugal)" },
  { value: "pa", label: "Punjabi" },
  { value: "qu", label: "Quechua" },
  { value: "ro", label: "Romanian" },
  { value: "rm", label: "Romansh" },
  { value: "nyn", label: "Runyakitara" },
  { value: "ru", label: "Russian" },
  { value: "gd", label: "Scots Gaelic" },
  { value: "sr", label: "Serbian" },
  { value: "sh", label: "Serbo-Croatian" },
  { value: "st", label: "Sesotho" },
  { value: "tn", label: "Setswana" },
  { value: "crs", label: "Seychellois Creole" },
  { value: "sn", label: "Shona" },
  { value: "sd", label: "Sindhi" },
  { value: "si", label: "Sinhalese" },
  { value: "sk", label: "Slovak" },
  { value: "sl", label: "Slovenian" },
  { value: "so", label: "Somali" },
  { value: "es", label: "Spanish" },
  { value: "es-419", label: "Spanish (Latin American)" },
  { value: "su", label: "Sundanese" },
  { value: "sw", label: "Swahili" },
  { value: "sv", label: "Swedish" },
  { value: "tg", label: "Tajik" },
  { value: "ta", label: "Tamil" },
  { value: "tt", label: "Tatar" },
  { value: "te", label: "Telugu" },
  { value: "th", label: "Thai" },
  { value: "ti", label: "Tigrinya" },
  { value: "to", label: "Tonga" },
  { value: "lua", label: "Tshiluba" },
  { value: "tum", label: "Tumbuka" },
  { value: "tr", label: "Turkish" },
  { value: "tk", label: "Turkmen" },
  { value: "tw", label: "Twi" },
  { value: "ug", label: "Uighur" },
  { value: "uk", label: "Ukrainian" },
  { value: "ur", label: "Urdu" },
  { value: "uz", label: "Uzbek" },
  { value: "vi", label: "Viettextse" },
  { value: "cy", label: "Welsh" },
  { value: "wo", label: "Wolof" },
  { value: "xh", label: "Xhosa" },
  { value: "yi", label: "Yvaluedish" },
  { value: "yo", label: "Yoruba" },
  { value: "zu", label: "Zulu" }
];
interface languageSelectProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parentCallback(res: object): any;
  language: { value: string; label: string };
}
const LanguageSelect = ({ parentCallback, language }: languageSelectProps) => {
  // const [language, setLanguage, ] = useState({value: "en", label: "English", });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setLanguageOverride = (e: any) => {
    // setLanguage(e);
    const res = {
      language: e.value
    };
    parentCallback(res);
  };

  return (
    <div id="language-select" data-testid="language-select">
      <Select
        menuPosition="fixed"
        classNamePrefix={"insights-language"}
        options={langObjects}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(e: any) => setLanguageOverride(e)}
        styles={{
          indicatorSeparator: () => ({ display: "none" }),
          container: (styles) => ({
            ...styles,
            height: "48px",
            width: "176px",
            fontSize: "20px",
            fontFamily: "'Source Sans Pro', sans-serif",
            color: "#373F50"
          }),
          menu: (styles) => ({
            ...styles,
            padding: "0px",
            boxShadow: "none",
            marginTop: "0px"
          }),
          menuList: (base) => ({
            ...base,
            backgroundColor: "#FFFFF",
            border: "none",
            width: "176px",
            height: "196px",
            borderRadius: "4px",
            padding: "0px",
            boxShadow: "0px 4px 8px rgba(55, 63, 80, 0.25)",
            "::-webkit-scrollbar": {
              width: "5px"
            },
            "::-webkit-scrollbar-track": {
              bordeRadius: "10px"
            },
            "::-webkit-scrollbar-thumb": {
              background: "#909BB3",
              borderRadius: "2.5px"
            },
            "::-webkit-scrollbar-thumb:hover": {
              background: "#373F50"
            }
          }),
          control: (styles, { isFocused }) => ({
            ...styles,
            boxShadow: "none",
            border: isFocused ? "1px solid #008CCE" : "1px solid #D3D7E0"
          }),
          option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isSelected || isFocused ? "#E1F5FF" : "#FFFFFF",
            color: "#373F50;",
            padding: "12px 8px"
          }),
          dropdownIndicator: (styles, state) => ({
            ...styles,
            color: "#373F50",
            transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : ""
          })
        }}
        placeholder={"Select a Language"}
        value={language}
      />
    </div>
  );
};

LanguageSelect.propTypes = {
  parentCallback: PropTypes.func,
  language: PropTypes.object
};

LanguageSelect.defaultProps = {};

export default LanguageSelect;
