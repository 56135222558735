/* eslint-disable indent */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import trashIcon from "../../../assets/images/trash.png";
import { deleteFromStrategy } from "@/api/delete";
import { useAppStore, usePillarStrategyStore } from "@/hooks";
import { IAlertBannerOptions } from "@/interfaces/alert-banner-options";
import { IAppStore } from "@/interfaces/app-store.interface";
import { Spinner } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";
import {
  IBlogs,
  IContentPillars,
  ISubPillars
} from "@/interfaces/content-pillars.interface";
import {
  IPillarStrategyStore,
  SelectedItem
} from "@/interfaces/pillar-strategy-store.interface";

const DeleteFromStrategyModal = ({
  blogIds,
  subPillarIds,
  setShowDeleteModal
}: {
  blogIds: number[] | undefined;
  subPillarIds: number[] | undefined;
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [currentSelectedTopicTabIndex, setCurrentSelectedTopicTabIndex] =
    useState<number | undefined>();
  // alert banner setup
  const { setShowAlertBanner, selectedPidObject }: IAppStore = useAppStore(
    (store: IAppStore) => ({
      selectedPidObject: store.selectedPidObject,
      setShowAlertBanner: (
        bool: boolean,
        alertBannerOptions: IAlertBannerOptions
      ) => store.setShowAlertBanner(bool, alertBannerOptions)
    })
  );

  const {
    selectedTopicTabIndex,
    selectedPidPillarTopics,
    selectedTopics,
    setSelectedTopics,
    setSelectedPidPillarTopics
  }: IPillarStrategyStore = usePillarStrategyStore(
    (store: IPillarStrategyStore) => ({
      selectedTopicTabIndex: store.selectedTopicTabIndex,
      selectedPidPillarTopics: store.selectedPidPillarTopics,
      selectedTopics: store.selectedTopics,
      setSelectedTopics: (value: SelectedItem) =>
        store.setSelectedTopics(value),
      setSelectedPidPillarTopics: (value: IContentPillars[]) =>
        store.setSelectedPidPillarTopics(value)
    })
  );

  // this closes the modal if the user unselects all the selected items
  useEffect(() => {
    if (isEmpty(blogIds) && isEmpty(subPillarIds)) {
      setShowDeleteModal(false);
    }
  }, [blogIds, subPillarIds]);

  // this useEffect closes the modal if the user clicks on a different pillar topic tab
  useEffect(() => {
    if (
      typeof currentSelectedTopicTabIndex === "number" &&
      selectedTopicTabIndex !== currentSelectedTopicTabIndex
    ) {
      setShowDeleteModal(false);
      setCurrentSelectedTopicTabIndex(selectedTopicTabIndex);
    }
  }, [selectedTopicTabIndex]);

  // modal with red trash can icon
  // cancel and delete buttons
  // text "Delete X sub-pillar(s) and Y blog(s)?"

  const handleDelete = async () => {
    // delete sub-pillars
    // delete blogs
    if (subPillarIds?.length || blogIds?.length) {
      try {
        setDeleteInProgress(true);
        await deleteFromStrategy(blogIds, subPillarIds, selectedPidObject?.id);
        // remove sub-pillar and blog ids from selected topic ids object
        setSelectedTopics({
          blog: selectedTopics.blog.filter(
            (id: number) => !blogIds?.includes(id)
          ),
          sub_pillar: selectedTopics.sub_pillar.filter(
            (id: number) => !subPillarIds?.includes(id)
          )
        });
        // remove the deleted sub-pillars and blog rows from the UI
        const newBlogsList = selectedPidPillarTopics[
          selectedTopicTabIndex
        ]?.blogs?.filter(
          (blog: IBlogs) => blog.id && !blogIds?.includes(blog.id)
        );
        const newSubPillarsList = selectedPidPillarTopics[
          selectedTopicTabIndex
        ]?.sub_pillars?.filter(
          (sub_pillar: ISubPillars) =>
            sub_pillar.id && !subPillarIds?.includes(sub_pillar.id)
        );

        // remove the deleted blogs from the sub-pillars
        const newSubPillarsAfterDeletingBlogs = newSubPillarsList?.map(
          (sub_pillar: ISubPillars) => {
            const newSubPillarBlogs = sub_pillar.blogs?.filter(
              (blog: IBlogs) => blog.id && !blogIds?.includes(blog.id)
            );
            return {
              ...sub_pillar,
              blogs: newSubPillarBlogs
            };
          }
        );

        // create a new main array to hold all the newly filtered data
        const newSelectedPidPillarTopicsArray = selectedPidPillarTopics;
        newSelectedPidPillarTopicsArray[selectedTopicTabIndex].blogs =
          newBlogsList;
        newSelectedPidPillarTopicsArray[selectedTopicTabIndex].sub_pillars =
          newSubPillarsAfterDeletingBlogs;
        // update the old array to the new one
        setSelectedPidPillarTopics(newSelectedPidPillarTopicsArray);
        setShowAlertBanner(true, {
          message: "Items were successfully deleted from your strategy!",
          severity: "success"
        });
        setDeleteInProgress(false);
        setShowDeleteModal(false);
      } catch (err) {
        setShowAlertBanner(true, {
          message:
            "There was a problem deleting items from your pillar strategy",
          severity: "error"
        });
        setDeleteInProgress(false);
        setShowDeleteModal(false);
      }
    } else {
      // show alert banner
      setShowAlertBanner(true, {
        message: "No sub-pillars or blogs selected",
        severity: "error"
      });
      setDeleteInProgress(false);
    }
  };

  return (
    <div
      className="shadow bg-body rounded"
      style={{
        position: "fixed",
        width: "364px",
        height: "384px",
        left: "calc(50% - 182px)",
        top: "calc(50% - 167px)",
        background: "#FFFFFF",
        borderRadius: "3px",
        border: "1px solid #E9EBF0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: 4
      }}
    >
      <button
        style={{
          position: "relative",
          left: "155px",
          top: "16px"
        }}
        className="btn btn-close"
        onClick={() => setShowDeleteModal(false)}
      />
      <div
        style={{
          position: "relative",
          top: "16px",
          width: "67px",
          height: "67px",
          background: "#FFEDED",
          borderRadius: "50%"
        }}
      >
        <div
          style={{
            position: "relative",
            left: "16px",
            top: "16px"
          }}
        >
          <img src={trashIcon} alt="trash" />
        </div>
      </div>
      <div
        style={{
          position: "relative",
          width: "364px",
          height: "24px",
          fontFamily: "Source Sans Pro",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "24px",
          lineHeight: "43px",
          alignItems: "center",
          textAlign: "center",
          paddingBottom: "48px",
          paddingTop: "32px",
          letterSpacing: "0.0025em",
          color: "#373F50"
        }}
      >
        Delete{" "}
        {!isEmpty(subPillarIds) && (
          <>
            {subPillarIds?.length} Sub-Pillar
            {subPillarIds && subPillarIds.length > 1 && "s"}
          </>
        )}
        {!isEmpty(subPillarIds) && !isEmpty(blogIds) && <> and </>}
        {!isEmpty(blogIds) && (
          <>
            {blogIds?.length} blog
            {blogIds && blogIds.length > 1 && "s"}
          </>
        )}
        ?
      </div>
      <div
        style={{
          position: "relative",
          width: "307px",
          height: "41px",
          fontFamily: "Source Sans Pro",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "20px",
          textAlign: "left",
          letterSpacing: "0.005em",
          color: "#000000",
          padding: "15px"
        }}
      >
        <p>This action will permanantly delete:</p>
        <ul style={{ textAlign: "left" }}>
          <li>all blogs attached to the Sub-Pillars</li>
          <li>all associated Content Briefs</li>
        </ul>
      </div>

      <div
        style={{
          position: "relative",
          paddingTop: "64px",
          top: "32px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "307px"
        }}
      >
        <button
          style={{
            width: "135px",
            height: "40px",
            background: "#FFFFFF",
            fontFamily: "Source Sans Pro",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "20px",
            textAlign: "center",
            letterSpacing: "0.005em",
            color: "#373F50"
          }}
          className="btn btn-secondary"
          onClick={() => setShowDeleteModal(false)}
        >
          Cancel
        </button>
        <button
          style={{
            width: "135px",
            height: "40px",
            background: "#B00020",
            borderRadius: "3px",
            fontFamily: "Source Sans Pro",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "20px",
            textAlign: "center",
            letterSpacing: "0.005em",
            color: "#FFFFFF"
          }}
          className="btn btn-danger"
          onClick={() => handleDelete()}
        >
          {deleteInProgress ? <Spinner /> : "Delete"}
        </button>
      </div>
    </div>
  );
};
export default DeleteFromStrategyModal;
