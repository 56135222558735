import ContactUsLink from "@/components/shared/ContactUsLink";

interface PillarRankingsProps {
  background: string;
}
function PillarRankings({ background }: PillarRankingsProps) {
  return (
    <div
      className={`marketing-card-container ${
        background === "gradient" ? "gradient" : null
      }`}
    >
      <div className="section-header">Pillar Rankings</div>
      <div className="sub-header">
        Interested in learning which keywords and questions are the most
        important within your chosen Pillar Topic Network?
      </div>
      <div className="image-text-container">
        <div className="image">
          <img
            src={require("../../../assets/track_keywords_keywords_positions_for_competitors@4x.png")}
            alt="Pillar Rankings"
            style={{
              width: "434px",
              height: "322px",
              margin: "31px 147px 0 0"
            }}
          />
        </div>
        <div className="text-button">
          <div className="text">
            <p>
              Our Pillar Rankings dashboard provides a snapshot of your
              rankings—and your competitors’ rankings—for the most critical
              keywords presented in priority order.
            </p>
            <p>
              Learn which keywords have the most potential to drive qualified
              traffic to your domain and identify opportunities to move to Page
              One with optimized content.
            </p>
          </div>
          <ContactUsLink />
        </div>
      </div>
    </div>
  );
}

export default PillarRankings;
