/* eslint-disable complexity */
/* eslint-disable max-params */
import { postContentBriefsStepTwo } from "@/api/post";
import {
  IBodyDroppableArea,
  IBriefSearchTerm,
  IDroppableArea,
  IOrderedSearchtermFormatted
} from "@/interfaces/outline.interface";
import { Dispatch, SetStateAction, useState } from "react";

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useHoverState = () => {
  const [hoveredStates, setHoveredStates] = useState<{
    [key: string]: boolean;
  }>({});

  const handleMouseEnter = (itemId: string) => {
    setHoveredStates((prevStates) => ({ ...prevStates, [itemId]: true }));
  };

  const handleMouseLeave = (itemId: string) => {
    setHoveredStates((prevStates) => ({ ...prevStates, [itemId]: false }));
  };

  return {
    hoveredStates,
    handleMouseEnter,
    handleMouseLeave,
    setHoveredStates
  };
};
export const formatAndPost = (
  pid: number,
  briefId: number,
  droppableAreas: IBodyDroppableArea[],
  targetedKeywords: IBriefSearchTerm[],
  targetedQuestions: IBriefSearchTerm[],
  destinationKey: string,
  setShowSaved: (value: boolean) => void
) => {
  // array of items for the post request
  const introduction: IOrderedSearchtermFormatted[] = [];
  const conclusion: IOrderedSearchtermFormatted[] = [];
  const body: IOrderedSearchtermFormatted[] = [];
  const introductionArea = droppableAreas.filter(
    (item) => item.name === "Introduction"
  );
  const conclusionArea = droppableAreas.filter(
    (item) => item.name === "Conclusion"
  );
  const bodyAreas = droppableAreas.filter((item) => item.name.includes("Body"));
  if (introductionArea) {
    // check and accumulate bottomItems in observable array
    const introductionItems = introductionArea.reduce(
      (acc: IBriefSearchTerm[], item) => {
        if (item?.bottomItems) {
          acc = acc.concat(...item.bottomItems);
        }
        return acc;
      },
      []
    );

    // format the dragged item into an object and assign order_within_section based on the length of introductionItems
    if (introductionItems) {
      introductionItems.map((item, index) => {
        // find the keyword or question that matches the term and reassign the id for the post request
        let searchTermId;
        const isKeyword = targetedKeywords.filter(
          (keyword) => item.query === keyword.query
        );
        const isQuestion = targetedQuestions.filter(
          (question) => item.query === question.query
        );
        // assign the search term id to the id of the one that matches the keyword or question
        if (isKeyword.length > 0) {
          searchTermId = isKeyword[0].id;
        }
        if (isQuestion.length > 0) {
          searchTermId = isQuestion[0].id;
        }
        if (searchTermId) {
          introduction.push({
            search_term_id: searchTermId,
            search_term: item,
            order_within_section: index
          });
        }
        return item;
      });
    }
  }
  if (conclusionArea) {
    // check and accumulate bottomItems in observable array
    const conclusionItems = conclusionArea.reduce(
      (acc: IBriefSearchTerm[], item) => {
        if (item?.bottomItems) {
          acc = acc.concat(...item.bottomItems);
        }
        return acc;
      },
      []
    );
    // format the dragged item into an object and assign order_within_section based on the length of conclusion items
    if (conclusionItems) {
      conclusionItems.map((item, index) => {
        // find the keyword or question that matches the term and reassign the id for the post request
        let searchTermId;
        const isKeyword = targetedKeywords.filter(
          (keyword) => item.query === keyword.query
        );
        const isQuestion = targetedQuestions.filter(
          (question) => item.query === question.query
        );
        // assign the search term id to the id of the one that matches the keyword or question
        if (isKeyword.length > 0) {
          searchTermId = isKeyword[0].id;
        }
        if (isQuestion.length > 0) {
          searchTermId = isQuestion[0].id;
        }
        if (searchTermId) {
          conclusion.push({
            search_term_id: searchTermId,
            search_term: item,
            order_within_section: index
          });
        }
        return item;
      });
    }
  }
  if (bodyAreas) {
    // check and accumulate topitems in mobx array
    const bodyTopItemsArray = bodyAreas.reduce(
      (acc: IBriefSearchTerm[], item) => {
        if (item?.topItems) {
          acc = acc.concat(...item.topItems);
        }
        return acc;
      },
      []
    );
    // mapping the newly dropped item to assign the correct body order and order within section
    bodyAreas.map((item, idx) => {
      // find the index of the new item where stringUUID matches the destination key

      if (item.stringUUID === destinationKey) {
        item.bottomItems.forEach((bottomItem, index) => {
          let searchTermId;
          const isKeyword = targetedKeywords.find(
            (keyword) => bottomItem.query === keyword.query
          );
          const isQuestion = targetedQuestions.find(
            (question) => bottomItem.query === question.query
          );
          if (isKeyword) {
            searchTermId = isKeyword.id;
          }
          if (isQuestion) {
            searchTermId = isQuestion.id;
          }
          if (searchTermId) {
            body.push({
              search_term_id: searchTermId,
              search_term: bottomItem,
              body_order: idx,
              order_within_section: index + 1
            });
          }
        });
      } else {
        // mapping all body items and making sure to maintain the index in the previous state of the array for the body order
        item.bottomItems.forEach((bottomItem, index) => {
          let searchTermId;
          // find the keyword or question that matches the term and reassign the id for the post request
          const isKeyword = targetedKeywords.find(
            (keyword) => bottomItem.query === keyword.query
          );
          const isQuestion = targetedQuestions.find(
            (question) => bottomItem.query === question.query
          );
          // assign the search term id to the id of the one that matches the keyword or question
          if (isKeyword) {
            searchTermId = isKeyword.id;
          }
          if (isQuestion) {
            searchTermId = isQuestion.id;
          }
          if (searchTermId) {
            body.push({
              search_term_id: searchTermId,
              search_term: bottomItem,
              body_order: idx,
              order_within_section: index + 1
            });
          }
        });
      }
      return item;
    });
    if (bodyTopItemsArray) {
      bodyAreas.map((item, idx) => {
        item.topItems?.forEach((topItem) => {
          // find the keyword or question that matches the term and reassign the id for the post request
          let searchTermId;
          const isKeyword = targetedKeywords.filter(
            (keyword) => topItem.query === keyword.query
          );
          const isQuestion = targetedQuestions.filter(
            (question) => topItem.query === question.query
          );
          // assign the search term id to the id of the one that matches the keyword or question
          if (isKeyword.length > 0) {
            searchTermId = isKeyword[0].id;
          }
          if (isQuestion.length > 0) {
            searchTermId = isQuestion[0].id;
          }
          if (searchTermId) {
            body.push({
              search_term_id: searchTermId,
              search_term: topItem,
              body_order: idx,
              order_within_section: 0
            });
          }
        });
        return item;
      });
    }
  }
  postContentBriefsStepTwo(pid, briefId, { body, introduction, conclusion });
  setShowSaved(true);
  setTimeout(() => {
    setShowSaved(false);
  }, 5000);
};
export const handleDeleteBodySection = (
  sectionName: string,
  sectionKey: string,
  bodySectionDroppableAreas: IBodyDroppableArea[],
  setBodySectionDroppableAreas: (value: IBodyDroppableArea[]) => void,
  setHoveredStates: Dispatch<SetStateAction<{ [key: string]: boolean }>>,
  setDraggedItems: (value: string[]) => void,
  pid: number,
  briefId: number,
  targetedKeywords: IBriefSearchTerm[],
  targetedQuestions: IBriefSearchTerm[],
  setShowSaved: (value: boolean) => void
) => {
  // set hovered state to false on click of the x
  setHoveredStates((prevStates) => ({
    ...prevStates,
    [sectionName]: false
  }));
  // search all of the body sections and find the one that matches the section key of the section clicked to delete
  const bodySectionToRemove = bodySectionDroppableAreas.find(
    (area) => area.stringUUID === sectionKey
  );
  if (bodySectionToRemove) {
    // filter out the section removed
    const updatedDroppableAreas = bodySectionDroppableAreas.filter(
      (area) => area.stringUUID !== sectionKey
    );
    // create an array of the body areas that are left
    const bodyAreas: IBodyDroppableArea[] = [];
    updatedDroppableAreas.map((area) => {
      if (area.name.includes("Body")) {
        bodyAreas.push(area);
      }
      return area;
    });
    if (bodyAreas.length > 0) {
      bodyAreas.forEach((item, index) => {
        for (const area of updatedDroppableAreas) {
          if (area.stringUUID === item.stringUUID) {
            area.name = `Body Section ${index + 1}`;
          }
        }
      });
    }
    // set body sections to updated areas
    setBodySectionDroppableAreas([...updatedDroppableAreas]);
    const topDraggedItems: string[] = [];
    const bottomDraggedItems: string[] = [];
    // get all of the terms that have been used in the brief and setdraggeditems to which items have been used so that the green checkmarks are accurate
    updatedDroppableAreas.map((item) => {
      if (item.topItems) {
        item.topItems.map((obj) => {
          if (obj.query) {
            topDraggedItems.push(obj.query);
          }
          return obj;
        });
      }
      if (item.bottomItems) {
        item.bottomItems.map((obj) => {
          if (obj.query) {
            bottomDraggedItems.push(obj.query);
          }
          return obj;
        });
      }
      return item;
    });
    if (topDraggedItems || bottomDraggedItems) {
      setDraggedItems([...topDraggedItems, ...bottomDraggedItems]);
    }
    formatAndPost(
      pid,
      briefId,
      updatedDroppableAreas,
      targetedKeywords,
      targetedQuestions,
      sectionKey,
      setShowSaved
    );
  }
};

export const handleDeleteBodyItem = (
  draggableId: string,
  sectionKey: string,
  bodySectionDroppableAreas: IBodyDroppableArea[],
  setBodySectionDroppableAreas: (value: IBodyDroppableArea[]) => void,
  setHoveredStates: Dispatch<SetStateAction<{ [key: string]: boolean }>>,
  setDraggedItems: (value: string[]) => void,
  draggedItems: string[],
  pid: number,
  briefId: number,
  targetedKeywords: IBriefSearchTerm[],
  targetedQuestions: IBriefSearchTerm[],
  setShowSaved: (value: boolean) => void
) => {
  // look up the item in the bodySectionDroppableAreas array by sectionKey
  const sectionToRemoveItemFrom = bodySectionDroppableAreas.find(
    (area) => area.stringUUID === sectionKey
  );
  if (!sectionToRemoveItemFrom) {
    return;
  }
  const bottomItemToDelete = sectionToRemoveItemFrom.bottomItems.find(
    (item: any) => item?.id.toString() === draggableId
  );
  const topItemToDelete = sectionToRemoveItemFrom?.topItems?.find(
    (item: any) => item?.id.toString() === draggableId
  );

  if (bottomItemToDelete) {
    // remove the item from the bottomItems array and save the new bodySectionDroppableAreas array
    setBodySectionDroppableAreas(
      bodySectionDroppableAreas.map((area) => {
        if (area.stringUUID === sectionKey) {
          return {
            ...area,
            bottomItems: sectionToRemoveItemFrom.bottomItems.filter(
              (item) => item.id.toString() !== draggableId
            )
          };
        }
        return area;
      })
    );
    let indexToDelete;
    const indexToRemove = sectionToRemoveItemFrom.bottomItems.findIndex(
      (item: IBriefSearchTerm) => {
        const stringId = item.id.toString();
        indexToDelete = draggedItems.indexOf(item.query);
        return stringId === draggableId;
      }
    );
    if (sectionToRemoveItemFrom.bottomItems && indexToRemove !== -1) {
      sectionToRemoveItemFrom.bottomItems.splice(indexToRemove, 1);
      // make sure index is a number and remove it from dragged items so that the green check marks are accurate
      if (typeof indexToDelete === "number") {
        const updatedDraggedItemIds = [...draggedItems];
        updatedDraggedItemIds.splice(indexToDelete, 1);
        setDraggedItems(updatedDraggedItemIds);
      }
      setHoveredStates((prevStates) => ({
        ...prevStates,
        [bottomItemToDelete.id.toString()]: false
      }));
    }
    const bottomItemsArray: IBriefSearchTerm[] = [];
    if (sectionToRemoveItemFrom.name.includes("Body")) {
      bottomItemsArray.push(...sectionToRemoveItemFrom.bottomItems);
    }
    formatAndPost(
      pid,
      briefId,
      bodySectionDroppableAreas,
      targetedKeywords,
      targetedQuestions,
      sectionKey,
      setShowSaved
    );
  }
  if (topItemToDelete && sectionToRemoveItemFrom.topItems) {
    // remove the item from the topItems array and save the new bodySectionDroppableAreas array
    setBodySectionDroppableAreas(
      bodySectionDroppableAreas.map((area) => {
        if (area.stringUUID === sectionKey) {
          return {
            ...area,
            topItems: sectionToRemoveItemFrom?.topItems?.filter(
              (item) => item.id.toString() !== draggableId
            )
          };
        }
        return area;
      })
    );
    let indexToDelete;
    const indexToRemove = sectionToRemoveItemFrom.topItems.findIndex(
      (item: IBriefSearchTerm) => {
        const stringId = item.id.toString();
        indexToDelete = draggedItems.indexOf(item.query);
        return stringId === draggableId;
      }
    );
    if (sectionToRemoveItemFrom.topItems && indexToRemove !== -1) {
      sectionToRemoveItemFrom.topItems.splice(indexToRemove, 1);
      // make sure index is a number and remove it from dragged items so that the green check marks are accurate
      if (typeof indexToDelete === "number") {
        const updatedDraggedItemIds = [...draggedItems];
        updatedDraggedItemIds.splice(indexToDelete, 1);
        setDraggedItems(updatedDraggedItemIds);
      }

      setHoveredStates((prevStates) => ({
        ...prevStates,
        [topItemToDelete.id.toString()]: false
      }));
    }
    formatAndPost(
      pid,
      briefId,
      bodySectionDroppableAreas,
      targetedKeywords,
      targetedQuestions,
      sectionKey,
      setShowSaved
    );
  }
};

export const handleDeleteFromIntroOrConclusion = (
  draggableId: string,
  sectionKey: string,
  bodySectionDroppableAreas: IBodyDroppableArea[],
  setBodySectionDroppableAreas: (value: IBodyDroppableArea[]) => void,
  setHoveredStates: Dispatch<SetStateAction<{ [key: string]: boolean }>>,
  setDraggedItems: (value: string[]) => void,
  draggedItems: string[],
  pid: number,
  briefId: number,
  targetedKeywords: IBriefSearchTerm[],
  targetedQuestions: IBriefSearchTerm[],
  setShowSaved: (value: boolean) => void
) => {
  // look up the area in the bodySectionDroppableAreas array by sectionKey
  const sectionToRemoveItemFrom = bodySectionDroppableAreas.find(
    (area) => area.stringUUID === sectionKey
  );
  if (!sectionToRemoveItemFrom) {
    return;
  }
  // Into and conclusion sections only have bottomItems
  const bottomItemToDelete = sectionToRemoveItemFrom.bottomItems.find(
    (item: any) => item?.id.toString() === draggableId
  );
  if (bottomItemToDelete) {
    setHoveredStates((prevStates) => ({
      ...prevStates,
      [bottomItemToDelete.id.toString()]: false
    }));
  }
  // remove the item from the bottomItems array
  let indexToDelete;
  const indexToRemove = sectionToRemoveItemFrom.bottomItems.findIndex(
    (item: IBriefSearchTerm) => {
      const stringId = item.id.toString();
      indexToDelete = draggedItems.indexOf(item.query);
      return stringId === draggableId;
    }
  );
  if (sectionToRemoveItemFrom?.bottomItems && indexToRemove !== -1) {
    sectionToRemoveItemFrom.bottomItems.splice(indexToRemove, 1);
    // make sure index is a number and remove it from dragged items so that the green check marks are accurate
    if (typeof indexToDelete === "number") {
      const updatedDraggedItemIds = [...draggedItems];
      updatedDraggedItemIds.splice(indexToDelete, 1);
      setDraggedItems(updatedDraggedItemIds);
    }
    // assign the new bodySectionDroppableAreas array
    setBodySectionDroppableAreas(
      bodySectionDroppableAreas.map((area) => {
        if (area.stringUUID === sectionKey) {
          return {
            ...area,
            bottomItems: sectionToRemoveItemFrom.bottomItems
          };
        }
        return area;
      })
    );
    formatAndPost(
      pid,
      briefId,
      bodySectionDroppableAreas,
      targetedKeywords,
      targetedQuestions,
      sectionKey,
      setShowSaved
    );

    const formattedBottomItems = [] as IOrderedSearchtermFormatted[];
    formattedBottomItems.push(
      ...sectionToRemoveItemFrom.bottomItems.map(
        (item: IBriefSearchTerm, index: number) => ({
          search_term_id: item.id || 0,
          search_term: item,
          order_within_section: index
        })
      )
    );
  }
};

export const handleDragBodySections = (
  result: any,
  bodySectionDroppableAreas: IBodyDroppableArea[],
  setBodySectionDroppableAreas: (value: IBodyDroppableArea[]) => void,
  pid: number,
  briefId: number,
  targetedKeywords: IBriefSearchTerm[],
  targetedQuestions: IBriefSearchTerm[],
  setShowSaved: (value: boolean) => void
) => {
  const { source, destination, type } = result;
  if (!destination) {
    return;
  }
  if (
    source.droppableId === destination.droppableId &&
    source.index === destination.index
  ) {
    return;
  }

  if (type === "group") {
    const reorderedSections = [...bodySectionDroppableAreas];
    const sourceIndex = source.index;
    const destinationIndex = destination.index;
    const destinationKey = destination.droppableId;
    const [removedBodySection] = reorderedSections.splice(sourceIndex, 1);
    reorderedSections.splice(destinationIndex, 0, removedBodySection);
    // getting body areas and changing the state of body section number when sections are reordered
    const bodyAreas: IBodyDroppableArea[] = [];
    reorderedSections.map((area) => {
      if (area.name.includes("Body")) {
        bodyAreas.push(area);
      }
      return area;
    });

    if (bodyAreas.length > 0) {
      bodyAreas.forEach((item, index) => {
        for (const area of reorderedSections) {
          if (area.stringUUID === item.stringUUID) {
            area.name = `Body Section ${index + 1}`;
          }
        }
      });
    }
    setBodySectionDroppableAreas(reorderedSections);
    formatAndPost(
      pid,
      briefId,
      reorderedSections,
      targetedKeywords,
      targetedQuestions,
      destinationKey,
      setShowSaved
    );
  }
};

export const handleDragBodyItems = (
  result: any,
  areasYouCanDropItemsFrom: IDroppableArea[],
  setAreasYouCanDropItemsFrom: (value: IDroppableArea[]) => void,
  setBodySectionDroppableAreas: (value: IBodyDroppableArea[]) => void,
  bodySectionDroppableAreas: IBodyDroppableArea[],
  draggedItems: string[],
  setDraggedItems: (value: string[]) => void,
  briefId: number,
  pid: number,
  targetedKeywords: IBriefSearchTerm[],
  targetedQuestions: IBriefSearchTerm[],
  setShowSaved: (value: boolean) => void
) => {
  const { source, destination } = result;
  if (!result.destination) {
    console.error("No destination");
    return;
  }
  if (source.droppableId === "ROOT") {
    handleDragBodySections(
      result,
      bodySectionDroppableAreas,
      setBodySectionDroppableAreas,
      pid,
      briefId,
      targetedKeywords,
      targetedQuestions,
      setShowSaved
    );
    return;
  }

  // destArea eg: {stringUUID: '0abe5c3c-ff12-41ae-b4bd-58684ebe3db5', name: 'Body Section 1', topItems: Array(1), bottomItems: Array(0)}
  let destAreaName;
  // droppableId may end with "-top" or "-bottom" if it is being dragged from a intro, body, or conclusion section
  // we want to remove the "-top" or "-bottom" from the end of the string to get the proper key
  let sourceKey: string;
  let destinationKey: string;
  if (
    source.droppableId.includes("-top") ||
    source.droppableId.includes("-bottom") ||
    destination.droppableId.includes("-top") ||
    destination.droppableId.includes("-bottom")
  ) {
    const sourceParts = source.droppableId.split("-");
    const destinationParts = destination.droppableId.split("-");
    sourceKey = sourceParts.slice(0, -1).join("-");
    destinationKey = destinationParts.slice(0, -1).join("-");
  } else {
    sourceKey = source.droppableId;
    destinationKey = destination.droppableId;
  }

  // Determine the DroppableArea that the item was dragged from aka the sourceArea
  // and the item that was dragged aka the draggedItem
  let sourceArea: IDroppableArea | IBodyDroppableArea | undefined;
  let draggedItem: IBriefSearchTerm | undefined;

  // see if areasYouCanDropItemsFrom has an element with property stringUUID that matches the source.droppableId - questions and keywords
  if (
    areasYouCanDropItemsFrom
      .map((area) => area.stringUUID)
      .includes(source.droppableId)
  ) {
    sourceArea = areasYouCanDropItemsFrom.find(
      (area) => area.stringUUID === source.droppableId
    );
    draggedItem = sourceArea?.items[source.index];
  }
  // do the same for bodySectionDroppableAreas
  if (
    bodySectionDroppableAreas.map((area) => area.stringUUID).includes(sourceKey)
  ) {
    sourceArea = bodySectionDroppableAreas.find(
      (area) => area.stringUUID === sourceKey
    );
    if (source.droppableId.includes("top") && sourceArea?.topItems) {
      draggedItem = sourceArea?.topItems[source.index];
    }
    if (source.droppableId.includes("bottom")) {
      draggedItem = sourceArea?.bottomItems[source.index];
    }
  }
  // if we checked both areasYouCanDropItemsFrom and bodySectionDroppableAreas and we still don't have a sourceArea, return
  if (!sourceArea || !draggedItem) {
    console.error("sourceArea or draggedItem is undefined");
    return;
  }
  // if the sourceArea is the body, we don't want to allow dragging from it
  if (sourceArea?.name.includes("Body")) {
    return;
  }

  // WHAT: ** decide what the name of destination area is **
  // EXAMPLE: ** if the destination area is the introduction section, the name will be "Introduction" **
  // WHY: ** there is some logic that is different depending on the destination area **
  // HOW: ** we check if the destination area is in bodySectionDroppableAreas **

  // This is when we are dropping into the body, introduction, or conclusion sections
  if (
    bodySectionDroppableAreas
      .map((area) => area.stringUUID)
      .includes(destinationKey)
  ) {
    destAreaName = bodySectionDroppableAreas.find(
      (area) => area.stringUUID === destinationKey
    )?.name;
  }
  // if we don't have a destAreaName, return
  if (!destAreaName) {
    console.error("destAreaName is undefined");
    return;
  }
  // dont allow dragging from keywords to questions or vice versa
  if (
    (sourceArea?.name === "Keywords" && destAreaName === "Questions") ||
    (sourceArea?.name === "Questions" && destAreaName === "Keywords")
  ) {
    return;
  }

  // if the sourceArea is the keywords or questions section
  if (sourceArea?.name === "Keywords" || sourceArea?.name === "Questions") {
    if (destAreaName === "Introduction" || destAreaName === "Conclusion") {
      // We call the intro and conclusion items "bottomItems" because they are the only items in the section
      //  and the top item is always supplied
      // by the UI, or non-existent in the case of the conclusion and introduction
      const updatedBodySectionDroppableAreas = bodySectionDroppableAreas.map(
        (area) => {
          if (area.stringUUID === destinationKey) {
            return {
              ...area,
              bottomItems: draggedItem
                ? area.bottomItems.concat(draggedItem)
                : area.bottomItems
            };
          }
          return area;
        }
      );
      // update dragged items to render green check marsk correctly
      const alldraggedItems = [...draggedItems, draggedItem.query];
      setDraggedItems(alldraggedItems);
      setBodySectionDroppableAreas(updatedBodySectionDroppableAreas);
      formatAndPost(
        pid,
        briefId,
        updatedBodySectionDroppableAreas,
        targetedKeywords,
        targetedQuestions,
        destinationKey,
        setShowSaved
      );
    }
    // if the destination area is the body
    if (destAreaName.includes("Body")) {
      // prevent drops into the topItems when topItems is already full
      if (
        destination.droppableId.includes("top") &&
        bodySectionDroppableAreas.find(
          (area) => area.stringUUID === destinationKey
        )?.topItems?.length
      ) {
        return;
      }
      // update dragged items to render green check marsk correctly
      const alldraggedItems = [...draggedItems, draggedItem.query];
      setDraggedItems(alldraggedItems);
      const bodyResult = bodySectionDroppableAreas.map((area) => {
        if (area.stringUUID === destinationKey) {
          return destination.droppableId.includes("top")
            ? {
                ...area,
                topItems: draggedItem
                  ? area?.topItems?.concat(draggedItem)
                  : area.topItems
              }
            : {
                ...area,
                bottomItems: draggedItem
                  ? area.bottomItems.concat(draggedItem)
                  : area.bottomItems
              };
        }
        return area;
      });
      setBodySectionDroppableAreas(bodyResult);
      formatAndPost(
        pid,
        briefId,
        bodyResult,
        targetedKeywords,
        targetedQuestions,
        destinationKey,
        setShowSaved
      );
      // function call to format and post the bodysection droppable area
    }

    // ** Now we must reset the areasYouCanDropItemsFrom array because we want the Keywords and Questions to always
    //    contains their original items **
    // replace the item from the sourceArea array with a clone of the same item, but a new id 4 digit uuid
    const updatedSourceArea: IDroppableArea =
      "items" in sourceArea
        ? {
            ...sourceArea,
            items: sourceArea.items.map((item) => {
              if (item.id === draggedItem?.id) {
                return {
                  ...item,
                  id: Math.floor(Math.random() * 10000)
                };
              }
              return item;
            })
          }
        : ({} as IDroppableArea);

    // update the areasYouCanDropItemsFrom array with the updatedSourceArea
    if (updatedSourceArea) {
      setAreasYouCanDropItemsFrom(
        areasYouCanDropItemsFrom.map((area) => {
          if (area.stringUUID === sourceArea?.stringUUID) {
            return updatedSourceArea;
          }
          return area;
        })
      );
    }
  } else {
    return;
  }
};

export const createSlugUrl = (term: string) => {
  const items = term.toLowerCase().split("");
  const lastItem = items[items.length - 1];
  // check the that the last item is not a letter or number if checkItem is true splice it off to create a proper slug
  const checkItem = !/^[a-zA-Z0-9]+$/.test(lastItem);
  if (checkItem) {
    items.splice(-1);
    return items.join("").split(" ").join("-");
  }
  return term.toLowerCase().split(" ").join("-");
};
