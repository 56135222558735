import React, { useState } from "react";
import DJLogo from "../../assets/DJ-Horizontal-registered.png";
import { ISignupDetails } from "../../interfaces/signup-details.interface";
import DJLoadingGif from "../shared/DJLoadingGif";
import StepOne from "./components/StepOne";
import StepThree from "./components/StepThree";
import StepTwo from "./components/StepTwo";
import "./signup.scss";

type HandleChange = (event: React.ChangeEvent<HTMLInputElement>) => void;

const Signup = () => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [signupDetails, setSignupDetails] = useState<ISignupDetails>({
    acceptedTOS: false,
    domain: "",
    email: "",
    firstName: "",
    hsCookie: "",
    keyword: "",
    lastName: "",
    password: ""
  });
  const [competitors, setCompetitors] = useState<string[]>([]);
  const [emailAlreadyExists, setEmailAlreadyExists] = useState(false);
  const [pid, setPid] = useState(0);

  const handleChange: HandleChange = (e) => {
    const { name } = e.target;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setSignupDetails((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleDomainFormatChange = (formattedDomain: string) => {
    setSignupDetails((prevState) => ({
      ...prevState,
      domain: formattedDomain
    }));
  };
  return (
    <div className="signup-page-container">
      <div className="signup-header">
        <img className="signup-logo" src={DJLogo} alt="DJ Logo" />
      </div>
      {loading ? (
        <DJLoadingGif />
      ) : (
        <div>
          {step === 1 && (
            <StepOne
              handleDomainFormatChange={handleDomainFormatChange}
              signupDetails={signupDetails}
              handleChange={handleChange}
              setStep={setStep}
            />
          )}
          {step === 2 && (
            <StepTwo
              handleChange={handleChange}
              setStep={setStep}
              signupDetails={signupDetails}
              setCompetitors={setCompetitors}
              emailAlreadyExists={emailAlreadyExists}
              setEmailAlreadyExists={setEmailAlreadyExists}
              setPid={setPid}
            />
          )}
          {step === 3 && (
            <StepThree
              signupDetails={signupDetails}
              competitors={competitors}
              setLoading={setLoading}
              pid={pid}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Signup;
