/* eslint-disable max-lines */
import { useNavigate, Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "react-responsive";
import { useCookies } from "react-cookie";
import { useEffectAsync, useAppStore } from "../../hooks";
import { getNavJSON } from "../../api/get";
import NavListItem from "./components/NavListItem";
import NavBottomList from "./components/BottomList/NavBottomList";
import MobileNav from "./MobileNav/MobileNav";
import { DjLogoStandardSize } from "@/assets/icons/svgr";
import ExpandedItemsContainer from "./components/BottomList/ExpandedItemsContainer";
import PDomainSelector from "./components/PidSelector/PDomainSelector";
import AlertBanner from "../shared/AlertBanner";
import { reduceUrls } from "../../utilities/reduceDomains";
import { navItemD } from "../../interfaces/navigation.interface";
import VerifiedEmailBanner from "../shared/VerifiedEmailBanner";
import { IAppStore } from "../../interfaces/app-store.interface";
import { motion, AnimatePresence } from "framer-motion";

const drawerWidth = 266;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden",
  border: "1px solid rgba(233, 235, 240, 1)",
  borderRadius: "8px",
  height: "fit-content"
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

interface AppBarProps extends MuiAppBarProps {
  sideMenuOpen?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})<AppBarProps>(({ theme, sideMenuOpen }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(sideMenuOpen && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  height: "100vh",
  display: "flex",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

export default function MiniDrawer({
  children
}: {
  children?: React.ReactElement;
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
  const [navJSON, setNavJSON] = useState<navItemD[] | null>(null);
  const [helpMenuOpen, setHelpMenuOpen] = useState<boolean>(false);
  const [settingsMenuOpen, setSettingsMenuOpen] = useState<boolean>(false);
  const [bookmarksMenuOpen, setBookmarksMenuOpen] = useState<boolean>(false);
  const [profileSettingsMenuOpen, setProfileSettingsMenuOpen] =
    useState<boolean>(false);
  const [cookies, setCookie, removeCookie] = useCookies([
    "DJ_nav_is_collapsed"
  ]);
  const {
    selectedPidObject,
    user,
    setAllowedUrls,
    sideMenuOpen,
    setSideMenuOpen
  } = useAppStore((store: IAppStore) => ({
    selectedPidObject: store.selectedPidObject,
    user: store.user,
    sideMenuOpen: store.sideMenuOpen,
    setSideMenuOpen: (value: boolean) => store.setSideMenuOpen(value),
    setAllowedUrls: (value: string[]) => store.setAllowedUrls(value)
  }));
  const [pidSelectorMenuOpen, setPidSelectorMenuOpen] = useState(false);
  const [navLoading, setNavLoading] = useState(true);
  const [openItemsStack, setOpenItemsStack] = useState<number[]>(
    [] as number[]
  );
  const [userVerifiedBanner, setUserVerifiedBanner] = useState(false);

  useEffect(() => {
    if (cookies.DJ_nav_is_collapsed) {
      setSideMenuOpen(false);
    }
  }, [cookies]);

  useEffectAsync(async () => {
    // Dj employee gets same nav no matter what pid, but for
    // non dj employees we should reload nav on every pid change
    if ((!navJSON || !user.dj_employee) && selectedPidObject.id) {
      setNavLoading(true);
      const response = await getNavJSON(selectedPidObject.id);
      setNavJSON(response);
      const allowedUrls = reduceUrls(response);
      setAllowedUrls(allowedUrls);
      setNavLoading(false);
    }
  }, [selectedPidObject, user]);

  const handleDrawerOpen = () => {
    setSideMenuOpen(true);
    setPidSelectorMenuOpen(false);
    if (cookies.DJ_nav_is_collapsed) {
      removeCookie("DJ_nav_is_collapsed");
    }
  };
  useEffect(() => {
    if (user.verified === false) {
      setUserVerifiedBanner(true);
    }
  }, [user]);

  const handleDrawerClose = () => {
    setSideMenuOpen(false);
    setCookie("DJ_nav_is_collapsed", true);
  };

  const drawerContent = (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        height: { xs: "fit-content", sm: "100vh" }
      }}
      test-id="drawer-content"
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List
        sx={{
          pb: "0px",
          pt: "20px"
        }}
      >
        <AnimatePresence>
          {navJSON && !navLoading && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1
              }}
              exit={{ opacity: 0 }}
            >
              {navJSON.map(
                (obj) =>
                  (obj.url || obj.children.length > 0) && (
                    <NavListItem
                      key={`list-item-key-${obj.id}`}
                      object={obj}
                      drawerOpen={sideMenuOpen}
                      isMobile={isMobile}
                      listTextFontSize={17}
                      openItemsStack={openItemsStack}
                      setOpenItemsStack={setOpenItemsStack}
                      navJSON={navJSON}
                    />
                  )
              )}
            </motion.div>
          )}
          {navLoading && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1
              }}
              exit={{ opacity: 0 }}
            >
              {[1, 2, 3, 4, 5].map((i) => (
                <div
                  key={i}
                  className="placeholder-wave"
                  style={{
                    height: "24px",
                    margin: "8px 20px",
                    borderRadius: "2px"
                  }}
                >
                  <span
                    className="placeholder col-12"
                    style={{
                      height: "100%",
                      borderRadius: "2px",
                      backgroundColor: "#D3D7E0"
                    }}
                  ></span>
                </div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </List>
      <Grid sx={{ flexGrow: { xs: 0, sm: 1 } }}></Grid>

      <NavBottomList
        sideMenuOpen={sideMenuOpen}
        helpMenuOpen={helpMenuOpen}
        setHelpMenuOpen={setHelpMenuOpen}
        settingsMenuOpen={settingsMenuOpen}
        setSettingsMenuOpen={setSettingsMenuOpen}
        bookmarksMenuOpen={bookmarksMenuOpen}
        profileSettingsMenuOpen={profileSettingsMenuOpen}
        setBookmarksMenuOpen={setBookmarksMenuOpen}
        setProfileSettingsMenuOpen={setProfileSettingsMenuOpen}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      />
    </Grid>
  );

  return (
    <Box sx={{ display: "flex", flexGrow: 1 }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ boxShadow: "none" }}>
        <Toolbar
          sx={
            isMobile
              ? { backgroundColor: "#373F50" }
              : {
                  backgroundColor: "#373F50",
                  justifyContent: "space-between"
                }
          }
        >
          <div className="d-flex flex-row">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={sideMenuOpen ? handleDrawerClose : handleDrawerOpen}
              edge="start"
              sx={{ marginRight: 5, display: isMobile ? "" : "none" }}
            >
              {sideMenuOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
            <IconButton onClick={() => navigate("/home")}>
              <DjLogoStandardSize style={{ width: "121px" }} />
            </IconButton>
          </div>
          <PDomainSelector
            handleNavClose={handleDrawerClose}
            pidSelectorMenuOpen={pidSelectorMenuOpen}
            setPidSelectorMenuOpen={setPidSelectorMenuOpen}
          />
        </Toolbar>
      </AppBar>
      <>
        {!isMobile && (
          <Drawer
            data-test-id="nav-drawer"
            variant="permanent"
            open={sideMenuOpen}
            sx={{
              display: {
                xs: sideMenuOpen ? "block" : "none",
                sm: "flex"
              },
              transition: "300ms",
              "& .MuiDrawer-paper": {
                marginLeft: { xs: "15px", sm: "0px" },
                transition: "300ms"
              }
            }}
          >
            {drawerContent}
          </Drawer>
        )}
        <AnimatePresence>
          {isMobile && (
            <MobileNav
              navJSON={navJSON}
              isLoading={navLoading}
              sideMenuOpen={sideMenuOpen}
              handleDrawerClose={handleDrawerClose}
              handleDrawerOpen={handleDrawerOpen}
              setSideMenuOpen={setSideMenuOpen}
            />
          )}
        </AnimatePresence>
        <ExpandedItemsContainer
          sideMenuOpen={sideMenuOpen}
          helpMenuOpen={helpMenuOpen}
          setOpenItemsStack={setOpenItemsStack}
          setHelpMenuOpen={setHelpMenuOpen}
          settingsMenuOpen={settingsMenuOpen}
          setSettingsMenuOpen={setSettingsMenuOpen}
          setBookmarksMenuOpen={setBookmarksMenuOpen}
          bookmarksMenuOpen={bookmarksMenuOpen}
          profileSettingsMenuOpen={profileSettingsMenuOpen}
          setProfileSettingsMenuOpen={setProfileSettingsMenuOpen}
        />
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, zIndex: "1", padding: "0px" }}
        >
          <DrawerHeader />
          <AlertBanner />

          {userVerifiedBanner ? <VerifiedEmailBanner /> : <></>}
          <Outlet />
          {children}
        </Box>
      </>
    </Box>
  );
}
