import ContactUsLink from "@/components/shared/ContactUsLink";

function PaidSearch() {
  return (
    <div className="marketing-card-container">
      <div className="section-header">Paid Search</div>
      <div className="sub-header">
        Paid Search is a highly competitive channel where any information can
        help you outmaneuver others to ensure you are showing up at the top of
        the page.
      </div>
      <div className="image-text-container">
        <div className="image">
          <img
            src={require("../../../assets/rd_paid_channels_text_ad_market_share_by_ad_position@4x.png")}
            alt="Paid Search"
            style={{
              width: "577px",
              height: "291px",
              margin: "10px 44px 0px 0"
            }}
          />
        </div>
        <div className="text-button">
          <div className="text">
            <p>
              DemandJump’s Paid Search dashboard helps you identify which
              competitors are showing paid ads for the most relevant keywords
              and questions around a given topic.
            </p>
            <p>
              After identifying the companies showing paid ads for these,
              DemandJump also shows you the specific ad copy that populates
              those specific auctions, arming you with intel into how you can
              better frame up your own ad copy.
            </p>
          </div>
          <ContactUsLink />
        </div>
      </div>
    </div>
  );
}

export default PaidSearch;
