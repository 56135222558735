import { IBodyDroppableArea } from "@/interfaces/outline.interface";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { useHoverState, handleDeleteFromIntroOrConclusion } from "./utility";
import { FaAnchor } from "react-icons/fa";
import useContentBriefsStore from "@/hooks/useContentBriefsStore";
import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";
import { Link } from "react-router-dom";
import { useAppStore } from "@/hooks";
import { IAppStore } from "@/interfaces/app-store.interface";

const IntroductionArea = () => {
  const {
    hoveredStates,
    setHoveredStates,
    handleMouseEnter,
    handleMouseLeave
  } = useHoverState();
  const {
    bodySectionDroppableAreas,
    setBodySectionDroppableAreas,
    setDraggedItems,
    draggedItems,
    pillar,
    blog,
    pillarPublishedUrl,
    subPillarPublishedUrl,
    subPillar,
    targetedQuestions,
    targetedKeywords,
    briefId,
    setShowSaved
  }: IContentBriefsStore = useContentBriefsStore(
    (store: IContentBriefsStore) => ({
      briefId: store.briefId,
      blog: store.blog,
      targetedQuestions: store.targetedQuestions,
      targetedKeywords: store.targetedKeywords,
      bodySectionDroppableAreas: store.bodySectionDroppableAreas,
      draggedItems: store.draggedItems,
      pillar: store.pillar,
      subPillar: store.subPillar,
      subPillarPublishedUrl: store.subPillarPublishedUrl,
      pillarPublishedUrl: store.pillarPublishedUrl,
      setShowSaved: (value: boolean) => store.setShowSaved(value),
      setBodySectionDroppableAreas: (value: IBodyDroppableArea[]) =>
        store.setBodySectionDroppableAreas(value),
      setDraggedItems: (value: string[]) => store.setDraggedItems(value)
    })
  );
  const { selectedPidObject } = useAppStore((store: IAppStore) => ({
    selectedPidObject: store.selectedPidObject
  }));
  return (
    <div data-testid="introduction-section">
      <div style={{ padding: "30px" }}>
        <div
          style={{
            color: "#56627C",
            textTransform: "uppercase",
            fontSize: "16px",
            paddingTop: 10
          }}
        >
          Step 2
        </div>
        <div style={{ color: "#373F50", fontSize: "20px" }}>
          Organize Your Search Terms
        </div>
        <div style={{ color: "#373F50", fontSize: "16px", paddingTop: 10 }}>
          In Pillar-Based Marketing, content is structured around key audience
          questions as H2s. Organize them and strategically place your keywords
          within the outline.{" "}
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <div
          style={{
            borderRadius: 10,
            margin: "40px 36px 40px 36px",
            padding: 20,
            backgroundColor: "#F8F8FA"
          }}
        >
          <div
            style={{
              color: "#56627C",
              textTransform: "uppercase",
              fontSize: "16px"
            }}
          >
            Introduction
          </div>

          {pillar && subPillar && !blog && (
            <>
              <div
                style={{
                  color: "#56627C",
                  fontStyle: "italic",
                  fontSize: "14px"
                }}
              >
                In the first 100 or so words of your article, you will include
                the following phrase. This phrase will serve as anchor text for
                a link to your Pillar Page.{" "}
              </div>
              <div
                style={{
                  marginTop: 24,
                  fontSize: "16px",
                  backgroundColor: "#E9EBF0",
                  borderRadius: 12,
                  color: "#373F50",
                  fontStyle: "italic",
                  width: "85%",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <FaAnchor
                  style={{
                    fontSize: 42,
                    padding: 12,
                    color: "white",
                    backgroundColor: "#56627C",
                    borderTopLeftRadius: 12,
                    borderBottomLeftRadius: 12,
                    marginRight: 24
                  }}
                />
                {pillarPublishedUrl ? (
                  <Link style={{ color: "#008CCE" }} to={pillarPublishedUrl}>
                    {pillar}
                  </Link>
                ) : (
                  <div>{pillar}</div>
                )}
              </div>
            </>
          )}
          {pillar && subPillar && blog && (
            <>
              <div
                style={{
                  color: "#56627C",
                  fontStyle: "italic",
                  fontSize: "14px"
                }}
              >
                In the first 100 or so words of your article, you will include
                the following phrases. Those phrases will serve as anchor text
                for links to your Pillar Page and Sub-Pillar Page.
              </div>
              <div
                style={{
                  marginTop: 24,
                  fontSize: "16px",
                  backgroundColor: "#E9EBF0",
                  borderRadius: 12,
                  color: "#373F50",
                  fontStyle: "italic",
                  width: "85%",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <FaAnchor
                  style={{
                    fontSize: 42,
                    padding: 12,
                    color: "white",
                    backgroundColor: "#56627C",
                    borderTopLeftRadius: 12,
                    borderBottomLeftRadius: 12,
                    marginRight: 24
                  }}
                />
                {pillarPublishedUrl ? (
                  <Link style={{ color: "#008CCE" }} to={pillarPublishedUrl}>
                    {pillar}
                  </Link>
                ) : (
                  <div>{pillar}</div>
                )}
              </div>
              <div
                style={{
                  marginTop: 24,
                  fontSize: "16px",
                  backgroundColor: "#E9EBF0",
                  borderRadius: 12,
                  color: "#373F50",
                  fontStyle: "italic",
                  width: "85%",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <FaAnchor
                  style={{
                    fontSize: 42,
                    padding: 12,
                    color: "white",
                    backgroundColor: "#56627C",
                    borderTopLeftRadius: 12,
                    borderBottomLeftRadius: 12,
                    marginRight: 24
                  }}
                />
                {subPillarPublishedUrl ? (
                  <Link style={{ color: "#008CCE" }} to={subPillarPublishedUrl}>
                    {subPillar}
                  </Link>
                ) : (
                  <>
                    <div>{subPillar}</div>
                  </>
                )}
              </div>
            </>
          )}
          {pillar && !subPillar && blog && (
            <>
              <div
                style={{
                  color: "#56627C",
                  fontStyle: "italic",
                  fontSize: "14px"
                }}
              >
                In the first 100 or so words of your article, you will include
                the following phrase. This phrase will serve as anchor text for
                a link to your Pillar Page.{" "}
              </div>
              <div
                style={{
                  marginTop: 24,
                  fontSize: "16px",
                  backgroundColor: "#E9EBF0",
                  borderRadius: 12,
                  color: "#373F50",
                  fontStyle: "italic",
                  width: "85%",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <FaAnchor
                  style={{
                    fontSize: 42,
                    padding: 12,
                    color: "white",
                    backgroundColor: "#56627C",
                    borderTopLeftRadius: 12,
                    borderBottomLeftRadius: 12,
                    marginRight: 24
                  }}
                />
                {pillarPublishedUrl ? (
                  <Link style={{ color: "#008CCE" }} to={subPillarPublishedUrl}>
                    {pillar}
                  </Link>
                ) : (
                  <>
                    <div>{pillar}</div>
                  </>
                )}
              </div>
            </>
          )}

          {bodySectionDroppableAreas?.map((section) =>
            section.name === "Introduction" ? (
              <Droppable
                data-testid="body-droppable"
                droppableId={`${section.stringUUID}-bottom`}
                key={section.stringUUID}
              >
                {(droppableProvided, droppableSnapshot) => (
                  <div
                    className={
                      section?.bottomItems?.length > 0
                        ? ""
                        : "body-droppable-placeholder"
                    }
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                    style={{
                      background: droppableSnapshot.isDraggingOver
                        ? "#FBFBFD"
                        : "#F8F8FA",
                      width: "90%",
                      height: "fit-content",
                      borderRadius: 12,
                      paddingTop: 10
                    }}
                  >
                    {section.bottomItems.map((item, index) => {
                      let draggableId = "";
                      if (item.id) {
                        draggableId = item.id.toString();
                      }
                      return (
                        <Draggable
                          key={item.id}
                          draggableId={draggableId}
                          index={index}
                        >
                          {(draggableProvided) => (
                            <div
                              onMouseEnter={() => handleMouseEnter(draggableId)}
                              onMouseLeave={() => handleMouseLeave(draggableId)}
                              key={item.id}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                              ref={draggableProvided.innerRef}
                              style={{
                                userSelect: "none",
                                borderRadius: 12,
                                backgroundColor: "#F8F8FA",
                                color: "#373F50",
                                ...draggableProvided.draggableProps.style
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  margin: "10px 0px 10px 25px"
                                }}
                              >
                                <div>
                                  <div
                                    style={{
                                      width: 40,
                                      height: 40,
                                      backgroundColor: "#2ABBFF",
                                      borderTopLeftRadius: 12,
                                      borderBottomLeftRadius: 12,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-around"
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      color: "white",
                                      fontSize: 16
                                    }}
                                  ></div>
                                </div>
                                <div
                                  style={{
                                    padding: "5px 0 0 20px",
                                    borderBottomRightRadius: 12,
                                    borderTopRightRadius: 12,
                                    width: "90%",
                                    borderTop: "1px solid #E9EBF0",
                                    borderBottom: "1px solid #E9EBF0",
                                    borderRight: "1px solid #E9EBF0",
                                    backgroundColor: "white"
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    <div
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                      }}
                                    >
                                      {item.query}
                                    </div>
                                    {hoveredStates[draggableId] && (
                                      <div
                                        onClick={() =>
                                          handleDeleteFromIntroOrConclusion(
                                            draggableId,
                                            section.stringUUID,
                                            bodySectionDroppableAreas,
                                            setBodySectionDroppableAreas,
                                            setHoveredStates,
                                            setDraggedItems,
                                            draggedItems,
                                            selectedPidObject.id,
                                            briefId,
                                            targetedKeywords,
                                            targetedQuestions,
                                            setShowSaved
                                          )
                                        }
                                        style={{
                                          color: "#909BB3",
                                          marginRight: "20px",
                                          paddingLeft: 10
                                        }}
                                      >
                                        x
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {section?.bottomItems.length > 0 && (
                      <div className="body-droppable-div">
                        Drop a term here...
                      </div>
                    )}
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            ) : null
          )}
        </div>
      </div>
    </div>
  );
};

export default IntroductionArea;
