export default function InfoMessage() {
  return (
    <div className="insights-info-message">
      Our technology is going to work mapping the entire network of search
      behavior surrounding your topic. Depending on the topic, a report might
      take 10-15 minutes to complete. We will send an email once your report is
      complete, or you can check back here on its progress.
    </div>
  );
}
