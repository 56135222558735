import { getSavedBrief } from "@/api/get";
import { useAppStore, useContentBriefsStore, useEffectAsync } from "@/hooks";
import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";
import { ISavedBrief } from "@/interfaces/saved_outline.interface";
import { useState } from "react";
import "../step-three.scss";
import { IAppStore } from "@/interfaces/app-store.interface";
import { INotes } from "@/interfaces/notes.interface";
import { addOrUpdateNotes } from "@/api/post";

interface IProps {
  note: INotes;
}
const EditNoteModal = ({ note }: IProps) => {
  const {
    showEditNoteModal,
    setShowEditNoteModal,
    briefId,
    notes,
    setNotes
  }: IContentBriefsStore = useContentBriefsStore(
    (store: IContentBriefsStore) => ({
      showEditNoteModal: store.showEditNoteModal,
      briefId: store.briefId,
      notes: store.notes,
      setNotes: (value: INotes[]) => store.setNotes(value),
      setShowEditNoteModal: (value: boolean) =>
        store.setShowEditNoteModal(value)
    })
  );
  const { setShowAlertBanner } = useAppStore((store: IAppStore) => ({
    setShowAlertBanner: store.setShowAlertBanner
  }));
  const [brief, setBrief] = useState<ISavedBrief>();
  const [newNote, setNewNote] = useState("");

  useEffectAsync(async () => {
    if (briefId) {
      const foundBrief = await getSavedBrief(briefId);
      if (foundBrief) {
        setBrief(foundBrief);
      }
    }
  }, [briefId]);
  const closeModal = () => {
    setShowEditNoteModal(false);
  };
  const handleEditNote = async () => {
    if (brief?.outline_status_id && note.id && newNote) {
      const resp = await addOrUpdateNotes(
        brief.outline_status_id,
        note.id,
        newNote
      );
      if (resp) {
        const updatedNotes = notes.map((existingNote) => {
          if (existingNote.id === resp.data.id) {
            // Update the note with the same ID
            return { ...existingNote, note: resp.data.note };
          }
          return existingNote;
        });
        setNotes(updatedNotes);
        setShowEditNoteModal(false);
        setShowAlertBanner(true, {
          message: `Successfully edited the note`,
          severity: "success"
        });
      } else {
        setShowAlertBanner(true, {
          message: `Error saving note, please try again. If problem persists please contact customer success.`,
          severity: "error"
        });
      }
    } else if (brief?.outline_status_id && note.id && !newNote) {
      setShowAlertBanner(true, {
        message: `Note cannot be empty`,
        severity: "error"
      });
    }
  };

  return (
    <>
      {showEditNoteModal && (
        <div
          className="shadow bg-body rounded"
          style={{
            position: "fixed",
            width: "548px",
            height: "310px",
            left: "calc(50% - 182px)",
            top: "calc(50% - 167px)",
            background: "#FFFFFF",
            borderRadius: "3px",
            border: "1px solid #E9EBF0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 4
          }}
        >
          <div
            style={{
              display: "flex",
              width: "548px",
              height: 20,
              justifyContent: "flex-end"
            }}
          >
            <button
              className="btn btn-close"
              style={{
                padding: 15,
                fontSize: 14
              }}
              onClick={closeModal}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <div
              style={{
                color: "#373F50",
                paddingBottom: "10px",
                fontSize: 24
              }}
            >
              <div>Edit Note</div>
            </div>
            <textarea
              style={{
                width: "431px",
                height: "128px",
                fontSize: "14px",
                color: "#373F50",
                border: "1px solid #D3D7E0",
                borderRadius: 4,
                resize: "none",
                outline: "none",
                paddingTop: "10px",
                paddingLeft: "10px"
              }}
              defaultValue={note.note}
              onChange={(e) => setNewNote(e.target.value)}
            ></textarea>
          </div>
          <div
            style={{
              position: "relative",
              paddingTop: "34px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "240px"
            }}
          >
            <button
              style={{
                width: "100px",
                height: "48px",
                background: "#FFFFFF",
                fontFamily: "Source Sans Pro",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20px",
                textAlign: "center",
                letterSpacing: "0.005em",
                color: "#373F50",
                borderRadius: 3
              }}
              className="btn btn-secondary"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              style={{
                width: "121px",
                height: "48px",
                background: "#008CCE",
                borderRadius: "3px",
                fontFamily: "Source Sans Pro",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20px",
                textAlign: "center",
                letterSpacing: "0.005em",
                color: "#FFFFFF"
              }}
              className="btn"
              onClick={handleEditNote}
            >
              Update Note
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default EditNoteModal;
