import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "../../Competitors/competitors.scss";
import isValidDomain from "is-valid-domain";
import { ICompetitorsItem } from "@/interfaces/competitors.interface";
interface competitorModalProps {
  show: boolean;
  handleClose: () => void;
  submitFunction: () => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
  competitors: ICompetitorsItem[];
  setCompetitors: (value: ICompetitorsItem[]) => void;
}

function CompetitorModal({
  show,
  handleClose,
  submitFunction,
  loading,
  setLoading,
  competitors,
  setCompetitors
}: competitorModalProps) {
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    await submitFunction();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add A Competitor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: "flex",
            width: "100%",
            flexFlow: "column nowrap",
            alignItems: "center"
          }}
        >
          <p>
            At least one competitor is needed for insights and pillars to run
          </p>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexFlow: "column nowrap"
            }}
          >
            <Form onSubmit={handleSubmit}>
              {[1, 2, 3].map((key, value) => (
                <div
                  key={key}
                  style={{
                    display: "flex",
                    width: "100%",
                    flexFlow: "row nowrap",
                    alignItems: "center"
                  }}
                >
                  <label
                    className="col-form-label competitor-label"
                    style={{ width: "25px" }}
                  >
                    {value + 1}.
                  </label>
                  <input
                    className="form-control"
                    type={"text"}
                    value={competitors[value].name}
                    onChange={(e) => {
                      setCompetitors(
                        competitors.map((item, index) => {
                          if (index === value) {
                            return {
                              ...item,
                              name: e.target.value
                            };
                          }
                          return item;
                        })
                      );
                    }}
                    style={{
                      width: "200px",
                      marginBottom: "10px",
                      display: "initial"
                    }}
                  />
                  {competitors[value]?.name?.length > 0 &&
                    !isValidDomain(competitors[value].name) && (
                      <div
                        style={{
                          marginLeft: "5px",
                          color: "#db3960"
                        }}
                      >
                        Invalid domain
                      </div>
                    )}
                </div>
              ))}
            </Form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          type="submit"
          disabled={loading || !isValidDomain(competitors[0]?.name)}
          style={{ backgroundColor: "#008cce" }}
          onClick={handleSubmit}
        >
          {loading ? "Saving" : "Save Changes"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CompetitorModal;
