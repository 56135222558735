import { Grid } from "@mui/material";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import WindowOne from "./gallery-components/window-one";
import WindowTwo from "./gallery-components/window-two";
import WindowThree from "./gallery-components/window-three";

const gradient: React.CSSProperties = {
  background:
    "radial-gradient(200.51% 200.51% at 119.03% 124.35%, #001C29 9.76%, #004667 23.65%, #006290 37.25%, #008CCE 50.62%)",
  minHeight: "100vh"
};

const items = [
  <WindowOne></WindowOne>,
  <WindowTwo></WindowTwo>,
  <WindowThree></WindowThree>
];

const SideGallery = () => (
  <Grid
    style={gradient}
    container
    direction="column"
    alignItems="center"
    justifyContent="center"
  >
    <AliceCarousel
      autoPlay={true}
      autoPlayInterval={5000}
      infinite={true}
      mouseTracking
      items={items}
    />
  </Grid>
);

export default SideGallery;
