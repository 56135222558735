import React, { SyntheticEvent, useEffect, useState } from "react";
import { DjInput } from "../../shared/DjInput";
import { useNavigate } from "react-router-dom";
import { ISignupDetails } from "../../../interfaces/signup-details.interface";
import GetUserByToken from "../../../routines/get-user-by-token";
import { getPidSubscription, getUserAccountsAndPids } from "../../../api/get";
import { useAppStore } from "../../../hooks";
import { IUser } from "../../../interfaces/user.interface";
import { checkDomain, formatDomain } from "../../../utilities/domain";
import { addCompetitors } from "../../../api/post";
import { ICompetitorsItem } from "../../../interfaces/competitors.interface";
import { ArbitraryObject } from "../../../interfaces/arbitrary-object.interface";
import { IAppStore } from "../../../interfaces/app-store.interface";
import { IAccount } from "../../../interfaces/account.interface";
import { IPDomain } from "../../../interfaces/pdomain.interface";
import { ICbSubscription } from "../../../interfaces/subscription.interface";

type HandleChange = (event: React.ChangeEvent<HTMLInputElement>) => void;

interface stepThreeProps {
  setLoading: (value: boolean) => void;
  competitors: string[];
  signupDetails: ISignupDetails;
  pid: number;
}
const StepThree = ({
  setLoading,
  competitors,
  signupDetails,
  pid
}: stepThreeProps) => {
  const [localCompetitors, setLocalCompetitors] = useState<ArbitraryObject>({});
  const [malformedCompetitors, setMalformedCompetitors] = useState<string[]>(
    []
  );
  const navigate = useNavigate();

  const {
    setUser,
    setSelectedPidObject,
    setUserAccounts,
    setSelectedAccountObject,
    setSelectedSubscriptionObject
  } = useAppStore((store: IAppStore) => ({
    setUser: (value: IUser) => store.setUser(value),
    setSelectedPidObject: (value: IPDomain) =>
      store.setSelectedPidObject(value),
    setUserAccounts: (value: IAccount[]) => store.setUserAccounts(value),
    setSelectedAccountObject: (value: IAccount) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      store.setSelectedAccountObject(value),
    setSelectedSubscriptionObject: (value: ICbSubscription) =>
      store.setSelectedSubscriptionObject(value),
    userAccounts: store.userAccounts,
    selectedPidObject: store.selectedPidObject,
    selectedAccountObject: store.selectedAccountObject,
    selectedSubscriptionObject: store.selectedSubscriptionObject
  }));

  useEffect(() => {
    if (competitors?.length) {
      const compObject: ArbitraryObject = {};
      competitors.forEach((comp, index) => {
        if (index < 3) {
          const key = index.toString();
          compObject[key] = comp;
        }
      });
      setLocalCompetitors(compObject);
    }
  }, [competitors]);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    setLoading(true);

    // submit competitors
    const failedCompetitors: string[] = [];
    const formattedCompetitors: ICompetitorsItem[] = [];
    Object.values(localCompetitors).forEach((comp, i) => {
      if (checkDomain(comp)) {
        formattedCompetitors.push({
          name: formatDomain(comp),
          priority_rank: i,
          is_direct_competitor: true
        });
      } else {
        failedCompetitors.push(i.toString());
      }
    });

    if (failedCompetitors.length) {
      setMalformedCompetitors(failedCompetitors);
      setLoading(false);
      return;
    }
    // Need to save competitors, making ticket for that now.
    await addCompetitors({ competitors: formattedCompetitors, pid });
    // login
    const udb = await GetUserByToken();
    setUser(udb);
    await getUserAccountsAndPids()
      .then((resp) => {
        setUserAccounts(resp);
        setSelectedAccountObject(resp[0]);
        setSelectedPidObject(resp[0].pdomains[0]);
        return resp[0].pdomains[0];
      })
      .then((pdomainData) => {
        getPidSubscription(pdomainData.id).then((resp) => {
          setSelectedSubscriptionObject(resp[0]);
        });
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
    setLoading(false);
    navigate("/home");
  };

  const handleChange: HandleChange = (e) => {
    const { name, value } = e.target;
    setLocalCompetitors((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <div className="step-container">
      <div className="su-sub-header">Last Step</div>
      <div className="help-text">
        We pulled your biggest competitors based on the phrase you provided.
        Please edit to your liking.
      </div>
      <form className="su-form" onSubmit={handleSubmit}>
        {Object.keys(localCompetitors).map((item: string, index) => (
          <div className="comp-input-label" key={index}>
            <DjInput
              className=""
              type="text"
              width="402px"
              height="48px"
              id={`competitor-${index}`}
              name={`${index}`}
              placeholder={`Competitor ${index + 1}`}
              value={localCompetitors[item]}
              onChange={handleChange}
              error={malformedCompetitors.includes(item)}
              required
            />
            {malformedCompetitors.includes(item) ? (
              <label
                className="competitor-label error-label"
                htmlFor={`competitor-${index}`}
              >
                Please format as valid domain. Ex: demandjump.com
              </label>
            ) : (
              <label
                className="competitor-label"
                htmlFor={`competitor-${index}`}
              >
                Competitor website {index + 1}
              </label>
            )}
          </div>
        ))}
        <div className="keyword-entered">
          Keyword you entered:{" "}
          <span className="entered-keyword">{signupDetails.keyword}</span>
        </div>

        <div className="button-no-text">
          <button
            className="signup-button"
            type="submit"
            style={{ justifySelf: "flex-end" }}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default StepThree;
