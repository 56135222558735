import { Table } from "react-bootstrap";
import "./pillar_strategy_table.scss";
import SkeletonRows from "./SkeletonRows";
import { Link } from "react-router-dom";

function PillarStrategyTableEmpty({ loading }: { loading: boolean }) {
  return (
    <Table id="ps-table" hover borderless>
      <thead className="ps-table-heading">
        <tr>
          <th style={{ width: "44%", paddingLeft: "22px" }}>Name</th>
          <th style={{ width: "15%" }}>Type</th>
          <th style={{ width: "15%" }}>Content Brief</th>
          <th style={{ width: "26%" }}>Published Url</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <SkeletonRows />
        ) : (
          <tr>
            <td colSpan={4} style={{ textAlign: "center" }}>
              <div className="main-message">No Pillar Topic Yet</div>
              <div className="secondary-message">
                To learn more about selecting a pillar topic,{" "}
                <Link to="https://youtu.be/trISrXJZn6I" target="_blank">
                  click here
                </Link>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

export default PillarStrategyTableEmpty;
