import { useAppStore } from "@/hooks";
import { IAppStore } from "@/interfaces/app-store.interface";
import { Grid, Paper, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

export default function RDContentCard() {
  const [isFreeUser, setIsFreeUser] = useState(false);

  const headerStyle = {
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "30px",
    letterSpacing: "0.0015em",
    color: "#373F50"
  };
  const linkStyle = {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.0015em",
    textDecorationLine: "underline",
    color: "#008CCE"
  };
  const flexRowAC = {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center"
  };
  const { selectedSubscriptionObject } = useAppStore((store: IAppStore) => ({
    selectedSubscriptionObject: store.selectedSubscriptionObject
  }));
  useEffect(() => {
    setIsFreeUser(
      selectedSubscriptionObject?.cb_plan_id?.includes("free") ||
        isEmpty(selectedSubscriptionObject)
    );
  }, [selectedSubscriptionObject]);

  return (
    <div id="rd-content-container">
      <Paper elevation={0} id="paper-gray-border">
        <div style={flexRowAC}>
          {/* <img src={IconLightbulbLogo} alt="Lightbulb Logo" style = {{marginRight: "8px",}}/> */}
          <div style={headerStyle}>Research & Discovery</div>
        </div>
        <Grid
          container
          rowSpacing="10px"
          sx={{
            margin: "20px 0px 40px 0px"
          }}
        >
          <Grid item>
            <Stack spacing={"10px"}>
              <Link to="/insights" style={linkStyle}>
                Get Topic Insights
              </Link>
              <Link
                to={`/dashboard/topic-summary${isFreeUser ? "-free" : ""}`}
                style={linkStyle}
              >
                Topic Summary
              </Link>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={"10px"}>
              <Link
                to={`/dashboard/compare-topics${isFreeUser ? "-free" : ""}`}
                style={linkStyle}
              >
                Compare Topics
              </Link>
              <Link
                to={`/dashboard/competitor-rankings${
                  isFreeUser ? "-free" : ""
                }`}
                style={linkStyle}
              >
                Competitor Rankings
              </Link>
            </Stack>
          </Grid>
        </Grid>
        <div style={flexRowAC}>
          {/* <img src={IconContentLogo} alt="Lightbulb Logo" style = {{marginRight: "8px",}}/> */}
          <div style={headerStyle}>Manage Content</div>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Link to="/content-briefs" style={linkStyle}>
            Content Briefs
          </Link>
        </div>
      </Paper>
    </div>
  );
}
