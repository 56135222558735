import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";

import { IBriefSearchTerm } from "@/interfaces/outline.interface";
import "./tooltip.scss";

import DJScoreElement from "./DJScoreElement";

import KeyIcon from "@/assets/icons/keywords.svg";
// import RankingIconDark from "@/assets/icons/ranking-star-dark.svg";
// import CompetitorIconDark from "@/assets/icons/competitors-dark.svg";
import BullseyeIconDark from "@/assets/icons/bullseye-dark.svg";
import StepOneToolTip from "./StepOneToolTip";

const StepOne = (props: {
  term: IBriefSearchTerm;
  selectTerm: Function;
  selected: Boolean;
}) => {
  const termRowStyle = {
    border: props.selected ? "1px solid #008CCE" : "1px solid #E9EBF0",
    borderRadius: "16px",
    padding: "15px",
    minHeight: "52px",
    margin: "10px"
  };

  const selectRow = (term: IBriefSearchTerm, selectTerm: Function) => {
    selectTerm(term);
  };

  return (
    <div
      onClick={() => selectRow(props.term, props.selectTerm)}
      className="step-one-row"
    >
      <Row style={termRowStyle}>
        <Col sm={7}>
          {props.term.term_type === "keyword" && (
            <span style={{ margin: "0px 15px 0px 10px" }}>
              <img src={KeyIcon} alt="keyword icon" />
            </span>
          )}
          {props.term.term_type === "question" && (
            <span style={{ margin: "0px 15px 0px 10px", paddingLeft: "10px" }}>
              ?
            </span>
          )}
          {props.term.query}
        </Col>
        <Col sm={5}>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              alignItems: "right"
            }}
          >
            <OverlayTrigger
              delay={{ hide: 450, show: 300 }}
              overlay={(p) => (
                <Tooltip {...p} className="ltt">
                  <StepOneToolTip term={props.term}></StepOneToolTip>
                </Tooltip>
              )}
              placement="left"
            >
              <span>
                {/* <span style={{ margin: "0px 10px 0px 10px" }}>
                  <img
                    src={RankingIconDark}
                    alt="your ranking icon"
                    style={{ margin: "5px" }}
                  ></img>
                  {props.term.your_rank || "-"}
                </span>
                <span style={{ margin: "0px 10px 0px 10px" }}>
                  <img
                    src={CompetitorIconDark}
                    alt="competitor ranking icon"
                    style={{ margin: "5px" }}
                  ></img>
                  {props.term.competitor_rank || "-"}
                </span> */}
                <span style={{ margin: "0px 10px 0px 10px" }}>
                  <img
                    src={BullseyeIconDark}
                    alt="targeted icon"
                    style={{ margin: "0px 10px 0px 10px" }}
                  ></img>
                  {props.term.total_targeted || 0}
                </span>
              </span>
            </OverlayTrigger>
            <span style={{ margin: "0px 0px 10px 20px" }}>
              <DJScoreElement dj_score={props.term.dj_score}></DJScoreElement>
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default StepOne;
