// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.medium.secondary.no-icon label {
  width: 60px;
  height: 24px;
  flex-grow: 0;
  font-family: SourceSansPro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
}

button.medium.secondary.no-icon:enabled {
  width: 128px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
  border-radius: 3px;
  border: solid 1px #008cce;
}

button.medium.secondary.no-icon:enabled label {
  color: #008cce;
}`, "",{"version":3,"sources":["webpack://./src/components/settings/pillar_topics/common/contentPillars.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,YAAA;EACA,0BAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,kBAAA;EACA,mBAAA;EACA,qBAAA;EACA,kBAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":["button.medium.secondary.no-icon label {\n  width: 60px;\n  height: 24px;\n  flex-grow: 0;\n  font-family: SourceSansPro;\n  font-size: 16px;\n  font-weight: 600;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n  letter-spacing: 0.2px;\n  text-align: center;\n}\n\nbutton.medium.secondary.no-icon:enabled {\n  width: 128px;\n  height: 40px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  padding: 8px 12px;\n  border-radius: 3px;\n  border: solid 1px #008cce;\n}\n\nbutton.medium.secondary.no-icon:enabled label {\n  color: #008cce;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
