import ContactUsLink from "@/components/shared/ContactUsLink";

function BacklinkOpportunities() {
  return (
    <div className="marketing-card-container">
      <div className="section-header">Backlink Opportunities</div>
      <div className="sub-header">
        We all know how hard it is to find good websites to request backlinks.
      </div>
      <div className="image-text-container">
        <div className="text-button">
          <div className="text">
            <p>
              Our Backlink Opportunities dashboard makes it easy for you to
              identify thought leaders around the topics you care about so you
              can streamline the process of identifying the website and specific
              webpage.
            </p>

            <br />
            <p>
              Quickly identify websites that have the most page one rankings and
              focus your backlinking efforts on those sites that are most likely
              to move the needle for your domain.
            </p>
          </div>
          <ContactUsLink />
        </div>
        <div className="image">
          <img
            src={require("../../../assets/rd_organic_channels_websites_in_search_table@4x.png")}
            alt="Backlink Opportunites"
            style={{
              width: "579px",
              height: "359px",
              margin: "-55px 0 29px 64px"
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default BacklinkOpportunities;
