import { handleDeleteBodySection, useHoverState } from "./utility";
import trashIcon from "../../../../../../assets/images/trash.png";
import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";
import { useAppStore, useContentBriefsStore } from "@/hooks";
import { IBodyDroppableArea } from "@/interfaces/outline.interface";
import { IAppStore } from "@/interfaces/app-store.interface";

interface IProps {
  sectionName: string;
  sectionId: string;
}
const DeleteModal = ({ sectionName, sectionId }: IProps) => {
  const {
    bodySectionDroppableAreas,
    setBodySectionDroppableAreas,
    setDraggedItems,
    targetedQuestions,
    targetedKeywords,
    briefId,
    setShowSaved,
    setShowDeleteModal
  }: IContentBriefsStore = useContentBriefsStore(
    (store: IContentBriefsStore) => ({
      briefId: store.briefId,
      setShowDeleteModal: (value: boolean) => store.setShowDeleteModal(value),
      targetedQuestions: store.targetedQuestions,
      targetedKeywords: store.targetedKeywords,
      bodySectionDroppableAreas: store.bodySectionDroppableAreas,
      setShowSaved: (value: boolean) => store.setShowSaved(value),
      setDraggedItems: (value: string[]) => store.setDraggedItems(value),
      setBodySectionDroppableAreas: (value: IBodyDroppableArea[]) =>
        store.setBodySectionDroppableAreas(value)
    })
  );
  const { selectedPidObject }: IAppStore = useAppStore((store: IAppStore) => ({
    selectedPidObject: store.selectedPidObject
  }));
  const { setHoveredStates } = useHoverState();
  const handleDelete = () => {
    setShowDeleteModal(false);
    handleDeleteBodySection(
      sectionName,
      sectionId,
      bodySectionDroppableAreas,
      setBodySectionDroppableAreas,
      setHoveredStates,
      setDraggedItems,
      selectedPidObject.id,
      briefId,
      targetedKeywords,
      targetedQuestions,
      setShowSaved
    );
  };
  return (
    <div
      className="shadow bg-body rounded"
      style={{
        position: "fixed",
        width: "364px",
        height: "344px",
        left: "calc(50% - 182px)",
        top: "calc(50% - 167px)",
        background: "#FFFFFF",
        borderRadius: "3px",
        border: "1px solid #E9EBF0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: 4
      }}
    >
      <button
        style={{
          position: "relative",
          left: "155px",
          top: "16px"
        }}
        className="btn btn-close"
        onClick={() => setShowDeleteModal(false)}
      />
      <div
        style={{
          position: "relative",
          top: "16px",
          width: "67px",
          height: "67px",
          background: "#FFEDED",
          borderRadius: "50%"
        }}
      >
        <div
          style={{
            position: "relative",
            left: "16px",
            top: "16px"
          }}
        >
          <img src={trashIcon} alt="trash" />
        </div>
      </div>
      <div
        style={{
          position: "relative",
          width: "364px",
          height: "24px",
          fontFamily: "Source Sans Pro",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "24px",
          lineHeight: "43px",
          alignItems: "center",
          textAlign: "center",
          paddingBottom: "48px",
          paddingTop: "32px",
          letterSpacing: "0.0025em",
          color: "#373F50"
        }}
      >
        <div>Delete body section?</div>
      </div>
      <div
        style={{
          position: "relative",
          width: "307px",
          height: "41px",
          fontFamily: "Source Sans Pro",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "20px",
          textAlign: "center",
          letterSpacing: "0.005em",
          color: "#000000",
          padding: "15px"
        }}
      >
        <div>
          This section will be lost, but you can still use these terms
          elsewhere.
        </div>
      </div>

      <div
        style={{
          position: "relative",
          paddingTop: "34px",
          top: "32px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "290px"
        }}
      >
        <button
          style={{
            width: "135px",
            height: "40px",
            background: "#FFFFFF",
            fontFamily: "Source Sans Pro",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "20px",
            textAlign: "center",
            letterSpacing: "0.005em",
            color: "#373F50",
            borderRadius: 3
          }}
          className="btn btn-secondary"
          onClick={() => setShowDeleteModal(false)}
        >
          Cancel
        </button>
        <button
          style={{
            width: "135px",
            height: "40px",
            background: "#B00020",
            borderRadius: "3px",
            fontFamily: "Source Sans Pro",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "20px",
            textAlign: "center",
            letterSpacing: "0.005em",
            color: "#FFFFFF"
          }}
          className="btn btn-danger"
          onClick={handleDelete}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteModal;
