import useContentBriefsStore from "@/hooks/useContentBriefsStore";
import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";
import {
  IBodyDroppableArea,
  IBriefSearchTerm
} from "@/interfaces/outline.interface";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { useHoverState, handleDeleteBodyItem } from "./utility";
import isEmpty from "lodash/isEmpty";
import { useAppStore } from "@/hooks";
import { IAppStore } from "@/interfaces/app-store.interface";

// eslint-disable-next-line arrow-body-style
const BodySectionsArea = ({
  stringUUID,
  bottomItems,
  topItems
}: IBodyDroppableArea) => {
  const {
    bodySectionDroppableAreas,
    setBodySectionDroppableAreas,
    setDraggedItems,
    draggedItems,
    targetedQuestions,
    targetedKeywords,
    briefId,
    setShowSaved
  }: IContentBriefsStore = useContentBriefsStore(
    (store: IContentBriefsStore) => ({
      draggedItems: store.draggedItems,
      briefId: store.briefId,
      targetedQuestions: store.targetedQuestions,
      targetedKeywords: store.targetedKeywords,
      bodySectionDroppableAreas: store.bodySectionDroppableAreas,
      setShowSaved: (value: boolean) => store.setShowSaved(value),
      setDraggedItems: (value: string[]) => store.setDraggedItems(value),
      setBodySectionDroppableAreas: (value: IBodyDroppableArea[]) =>
        store.setBodySectionDroppableAreas(value)
    })
  );
  const { selectedPidObject } = useAppStore((store: IAppStore) => ({
    selectedPidObject: store.selectedPidObject
  }));
  const {
    hoveredStates,
    setHoveredStates,
    handleMouseEnter,
    handleMouseLeave
  } = useHoverState();

  const placeholder = "Drop an H2 here...";

  return (
    <div>
      <div style={{ flex: 1 }}>
        <div>
          <Droppable
            data-testid="body-droppable"
            droppableId={`${stringUUID}-top`}
          >
            {(topDroppableProvided, topDroppableSnapshot) => (
              <div
                {...topDroppableProvided.droppableProps}
                ref={topDroppableProvided.innerRef}
                style={{
                  borderRadius: 12,
                  color: "#909BB3",
                  height: topItems?.length && topItems[0] ? 44 : 46,
                  marginTop: 10,
                  display: "flex",
                  alignItems: "center",
                  border:
                    topItems?.length && topItems[0] ? "" : "2px dashed #FFA400",
                  // eslint-disable-next-line no-nested-ternary
                  background: topDroppableSnapshot.isDraggingOver
                    ? "#FBFBFD"
                    : topItems?.length && topItems[0]
                    ? "none"
                    : "white"
                }}
              >
                {" "}
                {isEmpty(topItems) && (
                  <div style={{ paddingLeft: "30px" }}>{placeholder}</div>
                )}
                {topItems?.map((item: IBriefSearchTerm, index: number) => {
                  if (!item) {
                    return null;
                  }
                  let draggableId = "";
                  if (item) {
                    draggableId = item.id.toString();
                  }
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={draggableId}
                      index={index}
                    >
                      {(draggableProvided) => (
                        <div
                          onMouseEnter={() => handleMouseEnter(draggableId)}
                          onMouseLeave={() => handleMouseLeave(draggableId)}
                          key={item.id}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          ref={draggableProvided.innerRef}
                          style={{
                            userSelect: "none",
                            borderRadius: 12,
                            color: "#373F50",
                            height: 44,
                            ...draggableProvided.draggableProps.style
                          }}
                        >
                          <div
                            style={{
                              display: "flex"
                            }}
                          >
                            <div
                              style={{
                                width: 40,
                                height: 44,
                                backgroundColor: "#008CCE",
                                borderTopLeftRadius: 12,
                                borderBottomLeftRadius: 12,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-around"
                              }}
                            >
                              <div
                                style={{
                                  color: "white",
                                  fontSize: 16
                                }}
                              >
                                H2:
                              </div>
                            </div>
                            <div
                              style={{
                                padding: "0 20px 0 20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                borderBottomRightRadius: 12,
                                borderTopRightRadius: 12,
                                width: "37vw",
                                backgroundColor: "white",
                                border: "1px solid #E9EBF0"
                              }}
                            >
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis"
                                }}
                              >
                                {item.query}
                              </div>
                              {hoveredStates[draggableId] && (
                                <div
                                  onClick={() =>
                                    handleDeleteBodyItem(
                                      draggableId,
                                      stringUUID,
                                      bodySectionDroppableAreas,
                                      setBodySectionDroppableAreas,
                                      setHoveredStates,
                                      setDraggedItems,
                                      draggedItems,
                                      selectedPidObject.id,
                                      briefId,
                                      targetedKeywords,
                                      targetedQuestions,
                                      setShowSaved
                                    )
                                  }
                                  style={{
                                    color: "#909BB3",
                                    padding: "0px 30px",
                                    cursor: "auto"
                                  }}
                                >
                                  x
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {topDroppableProvided.placeholder}
              </div>
            )}
          </Droppable>
          <Droppable
            data-testid="body-droppable"
            droppableId={`${stringUUID}-bottom`}
            key={stringUUID}
          >
            {(bottomDroppableProvided, bottomDroppableSnapshot) => (
              <div
                className={
                  bottomItems && bottomItems.length === 0
                    ? "body-droppable-placeholder"
                    : ""
                }
                {...bottomDroppableProvided.droppableProps}
                ref={bottomDroppableProvided.innerRef}
                style={{
                  background: bottomDroppableSnapshot.isDraggingOver
                    ? "#FBFBFD"
                    : "#F8F8FA",
                  borderRadius: 12,
                  paddingTop: 10
                }}
              >
                {bottomItems?.map((item: IBriefSearchTerm, index: number) => {
                  if (!item) {
                    return null;
                  }
                  let draggableId = "";
                  if (item) {
                    draggableId = item.id.toString();
                  }
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={draggableId}
                      index={index}
                    >
                      {(draggableProvided) => (
                        <div
                          onMouseEnter={() => handleMouseEnter(draggableId)}
                          onMouseLeave={() => handleMouseLeave(draggableId)}
                          key={item.id}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          ref={draggableProvided.innerRef}
                          style={{
                            userSelect: "none",
                            borderRadius: 12,
                            color: "#373F50",
                            ...draggableProvided.draggableProps.style
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              margin: "10px 0px 10px 25px",
                              borderRadius: 12
                            }}
                          >
                            <div
                              style={{
                                display: "flex"
                              }}
                            >
                              <div
                                style={{
                                  width: 40,
                                  height: 40,
                                  backgroundColor: "#2ABBFF",
                                  borderTopLeftRadius: 12,
                                  borderBottomLeftRadius: 12,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around"
                                }}
                              ></div>
                              <div
                                style={{
                                  color: "white",
                                  fontSize: 16
                                }}
                              ></div>
                            </div>
                            <div
                              style={{
                                padding: "5px 10px 0 20px",
                                borderBottomRightRadius: 12,
                                borderTopRightRadius: 12,
                                width: "85%",
                                backgroundColor: "white",
                                border: "1px solid #E9EBF0"
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between"
                                }}
                              >
                                <div
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                  }}
                                >
                                  {item.query}
                                </div>
                                {hoveredStates[draggableId] && (
                                  <div
                                    onClick={() =>
                                      handleDeleteBodyItem(
                                        draggableId,
                                        stringUUID,
                                        bodySectionDroppableAreas,
                                        setBodySectionDroppableAreas,
                                        setHoveredStates,
                                        setDraggedItems,
                                        draggedItems,
                                        selectedPidObject.id,
                                        briefId,
                                        targetedKeywords,
                                        targetedQuestions,
                                        setShowSaved
                                      )
                                    }
                                    style={{
                                      color: "#909BB3",
                                      padding: "0px 30px",
                                      cursor: "auto"
                                    }}
                                  >
                                    x
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {bottomItems.length > 0 && (
                  <div className="body-droppable-div" style={{ width: "89%" }}>
                    Drop a term here...
                  </div>
                )}
                {bottomDroppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </div>
  );
};

export default BodySectionsArea;
