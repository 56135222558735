import useContentBriefsStore from "@/hooks/useContentBriefsStore";
import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";
import isEmpty from "lodash/isEmpty";
import "./outline.scss";
import { stepThreeStyles } from "../utils/style";
const UnassignedKeywords = () => {
  const { unusedItems }: IContentBriefsStore = useContentBriefsStore(
    (store: IContentBriefsStore) => ({
      unusedItems: store.unusedItems
    })
  );

  return isEmpty(unusedItems) ? null : (
    <div
      className="row row-padding-top-20"
      style={stepThreeStyles.rowPaddingTop20}
    >
      <div className="col-12">
        <div className="row">
          <div className="outline-title" style={stepThreeStyles.outlineTitle}>
            Unassigned Keywords
          </div>
        </div>

        <div
          className="row row-padding-top-20"
          style={stepThreeStyles.rowPaddingTop20}
        >
          <div className="outline-text" style={stepThreeStyles.outlineText}>
            The following keywords have not been assigned to a specific section
            of this outline, but should be used at your discretion somewhere
            within the article.
          </div>
        </div>

        <div
          className="row row-padding-top-20"
          style={stepThreeStyles.rowPaddingTop20}
        >
          <div
            className="outline-subtitle"
            style={stepThreeStyles.outlineSubtitle}
          >
            Search terms to include:
          </div>
        </div>

        <div
          className="row row-padding-top-20"
          style={(stepThreeStyles.rowPaddingTop20, { marginLeft: "20px" })}
        >
          <ul>
            {unusedItems.map((item: string, index) => (
              <li
                key={index}
                className="outline-text"
                style={stepThreeStyles.outlineText}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UnassignedKeywords;
