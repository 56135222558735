import PropTypes from "prop-types";
import "../competitors.scss";

const LoadingRow = (props) => (
  <div className="demandjump-competitors-control" style={{ padding: "0px" }}>
    <label
      className="col-form-label competitor-label"
      style={{ width: "25px" }}
    >
      {props.rowNumber}.
    </label>
    <input
      className="form-control skeleton-block"
      readOnly
      type={"text"}
      value={""}
      style={{ maxWidth: "500px", display: "initial" }}
    ></input>
  </div>
);

LoadingRow.propTypes = {
  rowNumber: PropTypes.number
};

LoadingRow.defaultProps = {
  rowNumber: 1
};

export default LoadingRow;
