/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";

import { getAccountPdomain } from "../../../../api/get";
import { addCompetitors, postNewPdomain } from "../../../../api/post";
import Spinner from "react-bootstrap/Spinner";
import { IAccount } from "../../../../interfaces/account.interface";
import CompetitorModal from "./CompetitorModal";
import { ICompetitorsItem } from "@/interfaces/competitors.interface";

const inputStyle = { width: "200px", marginRight: "5px" };

export interface IProps {
  setAllItems: any;
  allItems: IAccount[];
  accountId: number;
  newPid: number;
  setNewPid: any;
  currentFilteredItems: any;
  setCurrentFilteredItems: any;
}

function PdomainForm(props: IProps) {
  const [domain, setDomain] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [subscription, setSubscription] = useState("freemium");
  const [newPid, setNewPid] = useState(0);

  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [competitors, setCompetitors] = useState([
    { name: "", priority_rank: 1, is_direct_competitor: true },
    { name: "", priority_rank: 2, is_direct_competitor: true },
    { name: "", priority_rank: 3, is_direct_competitor: true }
  ]);

  const showCompetitorModal = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowModal(true);
  };

  const submitPdomain = async () => {
    setLoading(true);
    setShowSuccess(false);

    const data = {
      account_id: props.accountId,
      display_name: displayName,
      domain_url: domain,
      chargify_handle: subscription
    };

    const fetchData = async () => {
      const apdb = await getAccountPdomain().catch((err) => {
        console.error(err);
        return null;
      });
      if (apdb) {
        props.setAllItems(apdb.accounts);
      } else {
        props.setAllItems([]);
      }
      setLoading(false);
    };
    if (loading) {
      fetchData();
    }

    await postNewPdomain(data)
      .then(async (response) => {
        const filteredComps: ICompetitorsItem[] = competitors.filter(
          (comp) => comp.name.length > 0
        );
        await addCompetitors({
          pid: response.data.pdomain.id,
          competitors: filteredComps
        });
        setCompetitors([
          { name: "", priority_rank: 1, is_direct_competitor: true },
          { name: "", priority_rank: 2, is_direct_competitor: true },
          { name: "", priority_rank: 3, is_direct_competitor: true }
        ]);
        setNewPid(response.data.pdomain.id);
        props.setAllItems(
          props.allItems.map((item: any) => {
            if (item.id === props.accountId) {
              return {
                ...item,
                pdomains: [
                  ...item.pdomains,
                  {
                    ...response.data.pdomain,
                    domain: response.data.domain
                  }
                ]
              };
            }
            return item;
          })
        );
        props.setCurrentFilteredItems(
          props.currentFilteredItems.map((item: any) => {
            if (item.id === props.accountId) {
              return {
                ...item,
                pdomains: [
                  ...item.pdomains,
                  {
                    ...response.data.pdomain,
                    domain: response.data.domain
                  }
                ]
              };
            }
            return item;
          })
        );
        setShowModal(false);
        setDisplayName("");
        setDomain("");
        setLoading(false);
        setShowError(false);
        setShowSuccess(true);
        fetchData();
        return response.data;
      })
      .catch((err: Error) => {
        setShowSuccess(false);
        setShowError(true);
        setShowModal(false);
        setLoading(false);
        setCompetitors([
          { name: "", priority_rank: 1, is_direct_competitor: true },
          { name: "", priority_rank: 2, is_direct_competitor: true },
          { name: "", priority_rank: 3, is_direct_competitor: true }
        ]);
        console.error(err);
      });
  };

  return (
    <div style={{ paddingTop: "5px" }}>
      <h6>Add New Pdomain:</h6>
      <form onSubmit={(e) => showCompetitorModal(e)}>
        <label>
          Display Name:
          <input
            className="form-control"
            style={inputStyle}
            type="text"
            placeholder="Nike Shoes"
            value={displayName}
            required
            onChange={(e) => setDisplayName(e.target.value)}
          />
        </label>
        <label>
          Domain:
          <input
            className="form-control"
            style={inputStyle}
            type="text"
            placeholder="nike.com"
            value={domain}
            required
            onChange={(e) => setDomain(e.target.value)}
          />
        </label>
        <label>
          Chargify Subscription:
          <select
            className="form-control"
            style={inputStyle}
            value={subscription}
            onChange={(e) => setSubscription(e.target.value)}
          >
            <option value="freemium">Freemium</option>
            <option value="pro-v4-demo">Pro v4 Demo</option>
          </select>
        </label>
        <input className="btn btn-success" type="submit" value="Submit" />
      </form>
      {showSuccess && (
        <span>New Pdomain have been created! The new PID is {newPid}.</span>
      )}
      {showError && (
        <span className="color-error">
          Error adding new Pdomain. Ensure the new display name and domain are
          unique for this account
        </span>
      )}
      {loading && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
      <CompetitorModal
        competitors={competitors}
        setCompetitors={setCompetitors}
        submitFunction={submitPdomain}
        loading={loading}
        setLoading={setLoading}
        show={showModal}
        handleClose={() => setShowModal(false)}
      />
    </div>
  );
}

export default PdomainForm;
