/* eslint-disable @typescript-eslint/no-explicit-any, no-undefined */
import { ICompetitorsItem } from "@/interfaces/competitors.interface";
import { ICompetitorsStore } from "@/interfaces/competitors-store.interface";

const createCompetitorsStore: () => ICompetitorsStore = () => ({
  competitors: [] as ICompetitorsItem[],
  setCompetitors(value: ICompetitorsItem[]) {
    this.competitors = value;
  }
});

export default createCompetitorsStore;
