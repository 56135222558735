// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fzf-parent {
  width: 100%;
  margin-top: 200px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  font-family: "Source Sans Pro", sans-serif;
  color: #373F50;
}
.fzf-parent .main-message {
  margin-top: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
}
.fzf-parent .small-message {
  margin: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/HTTPError.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,aAAA;EACA,wBAAA;EACA,mBAAA;EACA,uBAAA;EACA,0CAAA;EACA,cAAA;AACF;AAAE;EACE,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;AAEJ;AAAE;EACE,YAAA;AAEJ","sourcesContent":[".fzf-parent{\n  width: 100%;\n  margin-top: 200px;\n  display: flex;\n  flex-flow: column nowrap;\n  align-items: center;\n  justify-content: center;\n  font-family: 'Source Sans Pro', sans-serif;\n  color: #373F50;\n  .main-message{\n    margin-top: 20px;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 40px;\n  }\n  .small-message{\n    margin: 15px;\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
