import ContactUsLink from "@/components/shared/ContactUsLink";

function OrganicSearch() {
  return (
    <div className="marketing-card-container">
      <div className="section-header">Organic Search</div>
      <div className="sub-header">
        Knowledge is power when earning page one rankings for terms within your
        mission-critical Pillar Topic Networks.
      </div>
      <div className="image-text-container">
        <div className="image">
          <img
            src={require("../../../assets/rd_organic_channels_organic_keywords_table@4x.png")}
            alt="Organic Keywords Table"
            style={{
              width: "588px",
              height: "340px",
              margin: "35px 56px 0 0"
            }}
          />
        </div>
        <div className="text-button">
          <div className="text">
            <p>
              With our Organic Search Dashboard, you can identify how current
              competitors, potential competitors, and other thought leaders rank
              for the topics you care most about—then use that information to
              outmaneuver websites that outrank you.
            </p>
            <p>
              Uncover the exact landing pages that websites are ranking for,
              including their metadata, to optimize your title tags and meta
              descriptions and develop net new content that’s better than the
              rest.
            </p>
          </div>
          <ContactUsLink />
        </div>
      </div>
    </div>
  );
}

export default OrganicSearch;
