// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-table tbody tr:nth-child(odd):not(:hover) {
  background-color: #f8f9fa;
}

.custom-table tbody tr:nth-child(even):not(:hover) {
  background-color: #fff;
}

.custom-table tbody tr:hover,
.custom-table tbody tr:hover > td,
.custom-table tbody tr:hover > th {
  background-color: #e1f5ff;
}`, "",{"version":3,"sources":["webpack://./src/components/manage_content/content_brief_list/components/BriefRow.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;;;EAGE,yBAAA;AACF","sourcesContent":[".custom-table tbody tr:nth-child(odd):not(:hover) {\n  background-color: #f8f9fa;\n}\n\n.custom-table tbody tr:nth-child(even):not(:hover) {\n  background-color: #fff;\n}\n\n.custom-table tbody tr:hover,\n.custom-table tbody tr:hover > td,\n.custom-table tbody tr:hover > th {\n  background-color: #e1f5ff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
