// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `iframe#webpack-dev-server-client-overlay {
  display: none;
}

#toggle-sidebar-btn {
  display: none !important;
}

.close-sidebar-btn {
  display: none !important;
}

.dashboard-container {
  height: 90%;
  position: absolute;
  width: 75%;
}

.dashboard-iframe {
  height: 100%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/iframe.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,wBAAA;AACF;;AACA;EACE,wBAAA;AAEF;;AACA;EACE,WAAA;EACA,kBAAA;EACA,UAAA;AAEF;;AACA;EACE,YAAA;EACA,WAAA;AAEF","sourcesContent":["iframe#webpack-dev-server-client-overlay {\n  display: none;\n}\n\n#toggle-sidebar-btn{\n  display: none !important;\n}\n.close-sidebar-btn {\n  display: none !important;\n}\n\n.dashboard-container{\n  height: 90%;\n  position: absolute;\n  width: 75%;\n}\n\n.dashboard-iframe{\n  height: 100%;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
