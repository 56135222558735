import ContactUsLink from "@/components/shared/ContactUsLink";

function PillarTrends() {
  return (
    <div className="marketing-card-container">
      <div className="section-header">Pillar Trends</div>
      <div className="sub-header">
        The journey to Page One is not a one-way trip; it requires ongoing
        publishing and measurement to continue driving your domain higher in
        Search Engine Results Pages.
      </div>
      <div className="image-text-container">
        <div className="text">
          <p>
            Our Pillar Trends dashboard shows you a week-over-week look at your
            rankings so you can watch your progress as you publish Pillar
            Content.
          </p>
        </div>
        <div className="image">
          <img
            src={require("../../../assets/manage_pillar_topics_1st_page_keyword_rank_chart@4x.png")}
            alt="First Page Chart"
            style={{
              width: "441px",
              height: "291.9px",
              margin: "32px 0 13.1px 64px"
            }}
          />
        </div>
      </div>
      <div className="image-text-container">
        <div className="text-button">
          <div className="text">
            <p>
              With a detailed breakdown of keywords within your chosen Pillar
              Topic Network that you’re ranking for on Page One, Two, and
              beyond, this powerful tool is the best way to prove the value of
              your content—and identify low-hanging fruit to swiftly move from
              lower rankings up to Page One.
            </p>
          </div>
          <ContactUsLink />
        </div>
        <div className="image">
          <img
            src={require("../../../assets/manage_pillar_topics_top_100_keyword_rank_chart@4x.png")}
            alt="Top 100 Keyword"
            style={{
              width: "441px",
              height: "292px",
              margin: "13.1px 0 0 64px"
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default PillarTrends;
