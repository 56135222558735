import { Row, Col } from "react-bootstrap";

import "../../shared/application.scss";
import SideGallery from "./SideGallery";
import MfaElement from "./MfaElement";

function MFAValidate() {
  return (
    <div>
      <Row>
        <Col sm={6}>
          <MfaElement></MfaElement>
        </Col>
        <Col sm={6}>
          <SideGallery />
        </Col>
      </Row>
    </div>
  );
}
export default MFAValidate;
