import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faX,
  faPenToSquare,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import { useAppStore } from "@/hooks";
import { IAppStore } from "@/interfaces/app-store.interface";
import { Spinner } from "react-bootstrap";
interface urlCellProps {
  hovered: boolean;
  type: string;
  id?: number;
  handleCancel: () => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  url: string;
  writeAccess: boolean;
  handleAdd?: () => void;
  handleEdit: () => void | null;
  isEditableRow?: boolean;
  saveUrl?: () => Promise<void>;
  savingUrl?: boolean;
}
function UrlCell({
  hovered,
  id,
  type,
  handleCancel,
  handleChange,
  url,
  writeAccess,
  handleAdd,
  handleEdit,
  isEditableRow,
  saveUrl,
  savingUrl
}: urlCellProps) {
  const [edit, setEdit] = useState(false);

  const { setShowAlertBanner } = useAppStore((store: IAppStore) => ({
    setShowAlertBanner: store.setShowAlertBanner
  }));

  const handleSaveUrl = async () => {
    if (id) {
      try {
        if (saveUrl) {
          await saveUrl();
        }
      } catch (e) {
        console.error(e);
        setShowAlertBanner({
          show: true,
          message: "Error saving url",
          type: "error"
        });
      }
    }
    setEdit(false);
  };
  return (
    <th>
      {edit ? (
        <div
          className="fw-400 d-flex align-items-center justify-content-start"
          style={{ height: "30px" }}
        >
          <input
            style={{ width: "230px" }}
            type="text"
            onChange={handleChange}
            name="url"
            value={url}
          />
          {savingUrl ? (
            <span
              style={{
                color: "#373f50",
                width: "20px",
                height: "20px",
                margin: "0px 5px"
              }}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </span>
          ) : (
            <span
              onClick={() => {
                handleSaveUrl();
              }}
              style={{ padding: "0px 5px" }}
            >
              <FontAwesomeIcon className="icon-button" icon={faCheck} />
            </span>
          )}
          <span
            onClick={() => {
              handleCancel();
              setEdit(false);
            }}
            style={{ padding: "0px 5px" }}
          >
            <FontAwesomeIcon className="icon-button" icon={faX} />
          </span>
        </div>
      ) : (
        <div
          className="fw-400 d-flex align-items-center justify-content-between"
          style={{ height: "30px" }}
        >
          {url ? (
            // if url exists, link to it-adding // if http or https doesnt exist to redirect to actual site
            <a
              className={`published-link ${hovered ? "hovered" : ""}`}
              target="_blank"
              rel="noopener"
              href={
                url.indexOf("http://") === 0 || url.indexOf("https://") === 0
                  ? url
                  : "//" + url
              }
            >
              {url}
            </a>
          ) : (
            <div></div>
          )}
          {hovered ? (
            <div style={{ justifySelf: "flex-end" }}>
              <span data-testid="edit-icon" style={{ padding: "5px" }}>
                <FontAwesomeIcon
                  className="icon-button"
                  icon={faPenToSquare}
                  style={{
                    transition: "all 300ms linear 0s"
                  }}
                  onClick={() => {
                    if (writeAccess && handleEdit && isEditableRow) {
                      handleEdit();
                    } else {
                      setEdit(true);
                    }
                  }}
                />
              </span>
              {writeAccess && type !== "blog" && (
                <span data-testid="add-icon" style={{ padding: "5px" }}>
                  <FontAwesomeIcon
                    className="icon-button"
                    icon={faPlus}
                    style={{
                      transition: "all 300ms linear 0s"
                    }}
                    onClick={() => {
                      if (handleAdd) {
                        handleAdd();
                      }
                    }}
                  />
                </span>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </th>
  );
}

export default UrlCell;
