// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.keyword_header {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.keyword_container {
  height: 100vh;
  padding-left: 5px;
  overflow: scroll;
}

.keyword_row {
  background-color: #F0F0F0;
  padding: 10px;
  margin: 5px 10px 10px 10px;
  border-radius: 5px;
  font-size: medium;
}

.keyword_circle {
  color: #3f6ad8;
  font-size: x-large;
  text-align: center;
  align-self: center;
}

.keyword_text {
  font-weight: bold;
  font-size: medium;
}

.keyword_bar {
  padding-bottom: 20px;
  padding-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/manage_content/content_brief/components/keyword.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kMAAA;EACA,eAAA;EACA,iBAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,yBAAA;EACA,aAAA;EACA,0BAAA;EACA,kBAAA;EACA,iBAAA;AACF;;AAEA;EACE,cAAA;EACA,kBAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AAEA;EACE,iBAAA;EACA,iBAAA;AACF;;AAEA;EACE,oBAAA;EACA,iBAAA;AACF","sourcesContent":[".keyword_header{\n  text-align: center;\n  font-family: -apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\";  \n  font-size: 16px;\n  font-weight: bold;\n  color: black;\n}\n\n.keyword_container{\n  height: 100vh;\n  padding-left: 5px;\n  overflow: scroll;\n}\n\n.keyword_row{\n  background-color: #F0F0F0 ;\n  padding: 10px;\n  margin: 5px 10px 10px 10px;\n  border-radius: 5px;\n  font-size: medium;\n}\n\n.keyword_circle{\n  color: #3f6ad8;\n  font-size: x-large;\n  text-align: center;\n  align-self: center;\n}\n\n.keyword_text{\n  font-weight: bold;\n  font-size: medium;\n}\n\n.keyword_bar{\n  padding-bottom: 20px;\n  padding-top:10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
