import { Dropdown } from "react-bootstrap";
import { emailOutline } from "./BriefToFile";
import { RawDraftContentState } from "draft-js";

import { useAppStore } from "../../../../hooks";

import { IAppStore } from "../../../../interfaces/app-store.interface";

const DownloadDropdownComponent = (props: {
  contentTitle: string;
  rawContentState: RawDraftContentState;
}) => {
  const { setShowAlertBanner } = useAppStore((store: IAppStore) => ({
    setShowAlertBanner: store.setShowAlertBanner
  }));

  const email = (filetype: string) => {
    emailOutline(props.contentTitle, props.rawContentState, filetype)
      .then(() => {
        setShowAlertBanner(true, {
          message: "Your content brief has been sent to your email address!",
          severity: "success"
        });
      })
      .catch((err) => {
        console.error(err);
        setShowAlertBanner(true, {
          message:
            "There was an error sending an email with your content brief.",
          severity: "error"
        });
      });
  };

  return (
    <Dropdown id="content-outline-download">
      <Dropdown.Toggle color="primary" id="content-outline-download-toggle">
        Email <i className="fa fa-download" aria-hidden="true"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu id="content-outline-download-menu">
        <Dropdown.Item
          id="content-outline-download-word-docx"
          onClick={() => email("docx")}
        >
          Word Document (.docx)
        </Dropdown.Item>
        <Dropdown.Item
          id="content-outline-download-word-pdf"
          onClick={() => email("pdf")}
        >
          PDF
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DownloadDropdownComponent;
