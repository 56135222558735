/* eslint-disable max-params */
import { IOrderedSections } from "@/interfaces/outline.interface";
import { instructionsSectionTextBlock } from "./instructionsSectionTextBlock";
import { metaSection } from "./metaSection";
import { outlineAsText } from "./outlineAsText";
import { INotes } from "@/interfaces/notes.interface";
import { ISavedBriefCTA } from "@/interfaces/saved_outline.interface";

const copyPageTextToClipboard = (
  pillarTopic: string,
  subPillar: string,
  term: string,
  typeOfTerm: string,
  metaDescription: string,
  orderedSections: IOrderedSections,
  wordCount: string,
  notes: INotes[],
  CTAs: ISavedBriefCTA[],
  Unused: string[]
) => {
  let textlinesArray = [];
  textlinesArray.push("Instructions:");
  textlinesArray = textlinesArray.concat(
    instructionsSectionTextBlock(pillarTopic, wordCount, typeOfTerm)
  );
  // Meta Information Section
  textlinesArray.push("Meta Information:");
  textlinesArray = textlinesArray.concat(
    metaSection(term, typeOfTerm, metaDescription, wordCount)
  );
  textlinesArray.push("\n");
  // Outline Section
  textlinesArray = textlinesArray.concat(
    outlineAsText(
      orderedSections,
      term,
      typeOfTerm,
      pillarTopic,
      subPillar,
      notes,
      CTAs,
      Unused
    )
  );

  window.navigator.clipboard.writeText(textlinesArray.join("\n"));
};

export default copyPageTextToClipboard;
