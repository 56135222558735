import draftToHtml from "draftjs-to-html";
import { RawDraftContentState } from "draft-js";
import axios from "axios";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";
import jsPDF from "jspdf";

const cookies = new Cookies();

interface bodyInterface {
  file_type: string;
  html_body?: string;
  array_buffer_body?: String;
  title: string;
}

const postDownloadBrief = (url: string, title: string, body: bodyInterface) => {
  const final_url = process.env.REACT_APP_NEO_URL + url;
  let device_uuid = cookies.get("device_id");
  if (!device_uuid) {
    device_uuid = uuidv4();
    cookies.set("device_id", device_uuid, { path: "/" });
  }
  const access_token = cookies.get("Access_Token");

  return axios
    .post(final_url, body, {
      headers: {
        authorization: `Bearer ${access_token}`,
        "X-DeviceUUID": device_uuid,
        "Content-Type": "application/json"
      },
      responseType: "blob"
    })
    .then((res) => {
      const download_url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = download_url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err: Error) => {
      console.error(err);
      throw err;
    });
};

const downloadPDF = (markup: string, title: string) => {
  const doc = new jsPDF("p", "pt", "a4");
  doc.setFontSize(14);
  doc.setFont("Inter-Regular", "normal");

  doc.html(`<div style="width:500px">${markup}</div>`, {
    margin: [40, 60],
    html2canvas: {
      scale: 1
    },
    callback(pdf) {
      pdf.save(title);
    }
  });
};

export const downloadOutline = async (
  contentTitle: string,
  rawContent: RawDraftContentState,
  format: string
) => {
  let title = contentTitle.replace(/ /g, "_");

  switch (format) {
    case "docx":
      title += ".docx";
      break;
    case "pdf":
      title += ".pdf";
      break;
    default:
      return;
  }

  const markup = draftToHtml(rawContent);

  if (format === "docx") {
    const body = {
      file_type: format,
      html_body: markup,
      title
    };

    const url = "/api/v1/download-outline";
    await postDownloadBrief(url, title, body);
  } else {
    await downloadPDF(markup, title);
  }
};

const postEmailBrief = (url: string, title: string, body: bodyInterface) => {
  const final_url = process.env.REACT_APP_NEO_URL + url;
  let device_uuid = cookies.get("device_id");
  if (!device_uuid) {
    device_uuid = uuidv4();
    cookies.set("device_id", device_uuid, { path: "/" });
  }
  const access_token = cookies.get("Access_Token");

  return axios
    .post(final_url, body, {
      headers: {
        authorization: `Bearer ${access_token}`,
        "X-DeviceUUID": device_uuid,
        "Content-Type": "application/json"
      }
    })
    .catch((err: Error) => {
      console.error(err);
      throw err;
    });
};

const emailPDF = (markup: string, title: string) => {
  const doc = new jsPDF("p", "pt", "a4");
  doc.setFontSize(14);
  doc.setFont("Inter-Regular", "normal");

  doc.html(`<div style="width:500px">${markup}</div>`, {
    margin: [40, 60],
    html2canvas: {
      scale: 1
    },
    async callback(pdf) {
      const array_buffer_body = new TextDecoder().decode(
        pdf.output("arraybuffer")
      );
      const body = {
        file_type: "pdf",
        array_buffer_body,
        title
      };

      const url = "/api/v1/email-outline";

      await postEmailBrief(url, title, body);
    }
  });
};

export const emailOutline = async (
  contentTitle: string,
  rawContent: RawDraftContentState,
  format: string
) => {
  let title = contentTitle.replace(/ /g, "_");

  switch (format) {
    case "docx":
      title += ".docx";
      break;
    case "pdf":
      title += ".pdf";
      break;
    default:
      return;
  }

  const markup = draftToHtml(rawContent);

  if (format === "pdf") {
    emailPDF(markup, title);
  } else {
    const body = {
      file_type: format,
      html_body: markup,
      title
    };

    const url = "/api/v1/email-outline";

    await postEmailBrief(url, title, body);
  }
};
