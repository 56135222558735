import { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import Select from "react-select";
import { deleteUserFromPid } from "../../../../api/post";
import PropTypes from "prop-types";
import { useAppStore } from "../../../../hooks";

function DeleteUserModal(props) {
  // store imports
  const { setShowAlertBanner } = useAppStore((store) => ({
    setShowAlertBanner: (bool, alertBannerOptions) =>
      store.setShowAlertBanner(bool, alertBannerOptions)
  }));
  // hooks
  const [formData, setFormData] = useState({
    user_id: props.user.id,
    pids: [],
    status: props.user.status
  });

  useEffect(() => {
    if (props.user) {
      setFormData((prevState) => ({
        ...prevState,
        user_id: props.user.id,
        status: props.user.status
      }));
    }
  }, [props.user]);

  const handleSelectDeletePids = (selectedOption) => {
    const pidArray = selectedOption.map((option) => option.id);
    setFormData((prevState) => ({
      ...prevState,
      pids: pidArray
    }));
  };

  const handleRemoveFromPids = async () => {
    try {
      props.setLoading(true);
      if (formData.pids.length === 0) {
        await deleteUserFromPid({
          ...formData,
          account_id: props.account_id,
          pids: [props.user.primaryDomains[0].id],
          status: props.user.status,
          email: props.user.email
        });
      } else {
        await deleteUserFromPid({
          ...formData,
          account_id: props.account_id,
          email: props.user.email,
          status: props.user.status
        });
      }
      // if user is no longer on any pids, remove them from the account users list
      const final_pids = props.user.primaryDomains.filter(
        (pDomain) => !formData.pids.includes(pDomain.id)
      );
      if (final_pids.length === 0 || props.user.status === 1) {
        props.setSelectedPidAccountUsers(
          props.selectedPidAccountUsers.filter(
            (user) => user.id !== props.user.id
          )
        );
      }
      props.setLoading(false);
      props.onHide();
      setShowAlertBanner(true, {
        message: "User invite was deleted successfully",
        severity: "success"
      });
    } catch {
      props.setLoading(false);
      props.onHide();
      setShowAlertBanner(true, {
        message: "Error attempting to delete the user's invite",
        severity: "error"
      });
    }
  };
  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        style={{ paddingTop: "100px" }}
      >
        <Modal.Header>
          <Modal.Title>Remove User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.user.status === 0 ? (
            <form id="remove-user-pids">
              <label htmlFor="pids-select" className="col-form-label">
                Please choose which domains to remove{" "}
                <strong>
                  {props.user.name ? props.user.name : props.user.email}
                </strong>{" "}
                from:
              </label>
              <Select
                isMulti
                id="pids-select"
                name="pids"
                options={props.user?.primaryDomains?.map((p) => {
                  let name = `ID - ${p.domain_name}`;
                  if (p.display_name) {
                    name += ` (${p.display_name})`;
                  }
                  return {
                    id: p.id,
                    label: name,
                    value: p.id
                  };
                })}
                onChange={handleSelectDeletePids}
                isDisabled={props.loadingRequest}
                closeMenuOnSelect={false}
              />
            </form>
          ) : (
            <div>
              Delete <strong>{props.user.email}</strong>&apos;s invite?
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={props.onHide}
            variant="secondary"
            disabled={props.loadingRequest}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleRemoveFromPids}>
            {props.loadingRequest ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Delete"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
DeleteUserModal.propTypes = {
  show: PropTypes.bool,
  user: PropTypes.object,
  onHide: PropTypes.func,
  loadingRequest: PropTypes.bool,
  setLoading: PropTypes.func,
  account_id: PropTypes.number,
  selectedPidAccountUsers: PropTypes.object,
  setSelectedPidAccountUsers: PropTypes.func
};

export default DeleteUserModal;
