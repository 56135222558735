export const getChangedCompetitors = (initialCompetitors, newCompetitors) => {
  const diff = [];
  if (newCompetitors.length > 0) {
    const initialCompetitorsDomainArray = [];
    initialCompetitors.forEach((obj) => {
      initialCompetitorsDomainArray.push(obj.name);
    });
    newCompetitors.forEach((obj) => {
      if (!initialCompetitorsDomainArray.includes(obj.name)) {
        diff.push(obj);
      }
    });
  }
  return diff;
};
