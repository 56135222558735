import Header from "./components/Header";
import SubHeader from "./components/SubHeader";
import OrganicSearch from "./components/OrganicSearch";
import BacklinkOpportunities from "./components/BacklinkOpportunities";
import Videos from "./components/Videos";
import Products from "./components/Products";

function OrganicChannels() {
  return (
    <div>
      <div className="header-first-component-container gradient">
        <Header />
        <SubHeader title="Organic Channels" />
        <OrganicSearch />
      </div>
      <BacklinkOpportunities />
      <Videos background="gradient" />
      <Products />
    </div>
  );
}

export default OrganicChannels;
