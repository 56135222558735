import PropTypes from "prop-types";
import { useLocalStore } from "mobx-react-lite";
import StoreContext from "./StoreContext";
import createAppStore from "./appStore";
import createInsightsStore from "./insightsStore";
import createManageUsersStore from "./manageUsersStore";
import createCompetitorsStore from "./competitorsStore";
import createPillarStrategyStore from "./pillarStrategyStore";
import createContentBriefsStore from "./contentBriefsStore";

const StoreProvider = ({ children }) => {
  const appStore = useLocalStore(createAppStore);
  const insightsStore = useLocalStore(createInsightsStore);
  const manageUsersStore = useLocalStore(createManageUsersStore);
  const competitorsStore = useLocalStore(createCompetitorsStore);
  const pillarStrategyStore = useLocalStore(createPillarStrategyStore);
  const contentBriefsStore = useLocalStore(createContentBriefsStore);

  return (
    <StoreContext.Provider
      value={{
        appStore,
        insightsStore,
        manageUsersStore,
        competitorsStore,
        pillarStrategyStore,
        contentBriefsStore
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

StoreProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ])
};

export default StoreProvider;
