import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const ShowPassword = () => {
  const [eyeIcon, setEyeIcon] = useState(faEyeSlash);
  const passwordField = document.querySelector("#password");

  const eyeIconClick = () => {
    const type =
      passwordField?.getAttribute("type") === "password" ? "text" : "password";
    passwordField?.setAttribute("type", type);
    if (type === "text") {
      setEyeIcon(faEye);
    }
    if (type === "password") {
      setEyeIcon(faEyeSlash);
    }
  };

  return (
    <button
      type="button"
      id="show-or-hide-password-eye-icon"
      aria-label="show-or-hide-password-eye-icon"
      style={{
        position: "relative",
        float: "right",
        bottom: "38px",
        padding: "6px",
        cursor: "pointer",
        color: "#9E9E9E",
        backgroundColor: "transparent",
        border: "none"
      }}
      onClick={eyeIconClick}
    >
      <FontAwesomeIcon icon={eyeIcon} />
    </button>
  );
};

export default ShowPassword;
