import "./DjInput.scss";

export interface DjInputProps {
  type?: string;
  width?: string;
  height?: string;
  placeholder?: string;
  name?: string;
  id?: string;
  value?: string;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
}

export const DjInput = ({
  type,
  width,
  height,
  placeholder,
  id,
  className,
  name,
  value,
  autoFocus,
  required,
  error,
  disabled,
  onChange
}: DjInputProps) => (
  <div style={{ width, height }}>
    <input
      type={type}
      placeholder={placeholder}
      id={id}
      className={
        error ? "dj-input-error dj-input " + className : "dj-input " + className
      }
      name={name}
      autoFocus={autoFocus}
      required={required}
      value={value}
      disabled={disabled}
      onChange={onChange}
    />
  </div>
);
