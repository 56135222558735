import { IContentPillars } from "@/interfaces/content-pillars.interface";
import {
  IPillarStrategyStore,
  SelectedItem
} from "../interfaces/pillar-strategy-store.interface";

const createPillarStrategyStore: () => IPillarStrategyStore = () => ({
  selectedPidPillarTopics: [] as IContentPillars[],
  searchedTopic: "",
  expandAll: true,
  collapseAll: false,
  selectedTopics: { blog: [], sub_pillar: [] },
  selectedTopicTabIndex: 0,
  setSelectedTopicTabIndex(value: number) {
    this.selectedTopicTabIndex = value;
  },
  setSelectedPidPillarTopics(topics) {
    this.selectedPidPillarTopics = topics;
  },
  setSearchedTopic(topic: string) {
    this.searchedTopic = topic;
    this.selectedTopics = { blog: [], sub_pillar: [] };
  },
  setExpandAll(value: boolean) {
    this.expandAll = value;
  },
  setCollapseAll(value: boolean) {
    this.collapseAll = value;
  },
  setSelectedTopics(value: SelectedItem) {
    this.selectedTopics = value;
  }
});

export default createPillarStrategyStore;
