import graphThree from "../../../../assets/pillar_strategy_product_market_design@4x_(light).png";

const imageStyle: React.CSSProperties = {
  width: "100%",
  zIndex: 1
};

import { Grid } from "@mui/material";

const WindowTwo = () => (
  <div>
    <Grid container direction="row" alignItems="center" justifyContent="center">
      <Grid item xs={10}>
        <img
          style={{ ...imageStyle }}
          src={graphThree}
          alt="Manage pillar topics 1st page keyword rank chart"
        />
      </Grid>
    </Grid>
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <div style={{ width: "80%", color: "white", textAlign: "center" }}>
        <h3>
          “DemandJump makes it really easy to pick content to write about that
          is relevant to your business and helps you write it in a way that is
          more likely to rank on search engines.”
        </h3>
        <p style={{ marginBottom: "0px" }}>
          <small>Bailey Witt</small>
        </p>
        <p style={{ marginTop: "5px" }}>
          <small>Director of Marketing at MediaFuel</small>
        </p>
      </div>
    </Grid>
  </div>
);

export default WindowTwo;
