import { IconTrackKeywords } from "@/assets/icons/svgr";

function Header() {
  return (
    <div className="header">
      <IconTrackKeywords
        style={{
          height: "50px",
          width: "50px",
          color: "#fff"
        }}
      />
      Track Keywords
    </div>
  );
}

export default Header;
