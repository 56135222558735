import { stepThreeStyles } from "../utils/style";
import Conclusion from "./Conclusion";
import H2List from "./H2List";
import Introduction from "./Introduction";
import UnassignedKeywords from "./UnassignedKeywords";
import "./outline.scss";
import { IOrderedSections } from "@/interfaces/outline.interface";

const Outline = ({
  orderedSections
}: {
  orderedSections: IOrderedSections;
}) => (
  <div
    data-testid="outline-section"
    style={{ padding: "40px 75px 40px  75px ", background: "#FFFFFF" }}
  >
    <div
      className="title-photo-title-line-2"
      style={stepThreeStyles.titlePhotoTitleLine2}
    >
      Outline
    </div>
    <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
      <div
        style={{ backgroundColor: "#E45D28", height: "4px", width: "120px" }}
      ></div>
      <Introduction orderedSections={orderedSections} />
      <H2List orderedSections={orderedSections} />
      <Conclusion orderedSections={orderedSections} />
      <UnassignedKeywords />
    </div>
  </div>
);

export default Outline;
