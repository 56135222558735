import { useSearchParams } from "react-router-dom";
import DJLogo from "../../../assets/DJ-Horizontal-registered.png";

const AcceptInvitationHeader = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  return (
    <div className="d-flex flex-column justify-content-center align-items-center text-center align-content-center">
      <div style={{ width: "80%" }}>
        <div
          style={{
            paddingBottom: "24px"
          }}
        >
          <img
            style={{
              padding: "10px",
              width: "250px"
            }}
            src={DJLogo}
            alt="DJ Logo"
          />
        </div>
        <div>
          <h3
            style={{
              color: "rgb(73, 80, 87)"
            }}
          >
            <b>
              <div>Welcome to DemandJump!</div>
            </b>
          </h3>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center text-center align-content-center">
          <h6
            style={{
              color: "rgb(73, 80, 87)",
              width: "65%",
              paddingBottom: "20px"
            }}
          >
            Thanks for confirming your email address: {email}
          </h6>
        </div>
        <h6 className="pb-3">
          Please enter your name and choose a password for your account.
        </h6>
      </div>
    </div>
  );
};

export default AcceptInvitationHeader;
