import { Grid } from "@mui/material";
import DJLogo from "../../assets/DJ-Horizontal-registered.png";
import ResetPassword from "./Component/ResetPassword";
import SideGallery from "./Component/SideGallery";

const imageStyle: React.CSSProperties = {
  position: "absolute",
  padding: "10px",
  width: "300px"
};

const ResetPasswordContainer = () => (
  <div>
    <img
      style={{ ...imageStyle }}
      className="login-logo"
      src={DJLogo}
      alt="DJ Logo"
    />
    <Grid container justifyContent="center" alignItems="center">
      <Grid item md={6} xs={12}>
        <ResetPassword></ResetPassword>
      </Grid>
      <Grid item md={6} xs={12}>
        <SideGallery></SideGallery>
      </Grid>
    </Grid>
  </div>
);

export default ResetPasswordContainer;
