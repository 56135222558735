import { navItemD } from "../interfaces/navigation.interface";

export const reduceUrls = (navJson: navItemD[]): string[] => {
  let urls: string[] = [];
  navJson.forEach((navItem) => {
    if (navItem?.children?.length) {
      urls = [...urls, ...reduceUrls(navItem.children)];
    } else if (navItem.url) {
      urls.push(navItem.url);
    }
  });

  return urls;
};
