/* eslint-disable prefer-named-capture-group */
/* eslint-disable require-unicode-regexp */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import CheckBox from "@material-ui/core/Checkbox";
import { Button, Modal, Spinner } from "react-bootstrap";
import { postInvitedUsers } from "../../../../api/post";
import isEmpty from "lodash/isEmpty";
import { useAppStore } from "../../../../hooks";
const InviteUserModal = ({
  setSelectedPidAccountUsers,
  propspids,
  setInviteModalShow,
  account_id
}) => {
  const { setShowAlertBanner, selectedAccountObject } = useAppStore(
    (store) => ({
      selectedAccountObject: store.selectedAccountObject,
      setShowAlertBanner: (bool, alertBannerOptions) =>
        store.setShowAlertBanner(bool, alertBannerOptions)
    })
  );

  const [formData, setFormData] = useState({
    emails: "",
    pids: [],
    is_admin: false
  });
  const [pidsList, setPidsList] = useState([]);
  const [emailError, setEmailError] = useState(false);
  const [pidError, setPidError] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);

  useEffect(() => {
    if (propspids) {
      setPidsList(
        propspids.map((p) => {
          const name = `ID - ${p.display_name} (${p.id})`;
          return { ...p, label: name, value: p.id };
        })
      );
    }
  }, [propspids]);

  const handleSave = async (e) => {
    e.preventDefault();
    setEmailError(false);
    setPidError(false);
    const emailListRegex =
      /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
    const emails = formData.emails
      .split(",")
      .map((e2) => e2.trim())
      .filter((email) => email);
    const invalidEmails = emails.filter(
      (e2) => !RegExp(emailListRegex).test(e2)
    );
    if (emails.length === 0 || invalidEmails.length > 0) {
      setEmailError(true);
      return;
    } else if (formData.pids.length === 0) {
      setPidError(true);
      return;
    }
    setLoadingRequest(true);
    const pDomainIds = formData.pids.map((pid) => pid.id);
    const finalPayload = {
      emails,
      pids: pDomainIds,
      account_id,
      is_admin: formData.is_admin ? 1 : 0,
      website_url: selectedAccountObject.company_name
    };
    const updatedAccountUsers = await postInvitedUsers(finalPayload);
    const { data } = updatedAccountUsers;
    setSelectedPidAccountUsers([...data]);
    setLoadingRequest(false);
    setInviteModalShow(false);
    setShowAlertBanner(true, {
      message: "User was sent an invitation.",
      severity: "success"
    });
  };
  const handleEmailChange = (e) => {
    e.persist();
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };
  const handleIsAdminChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      is_admin: e.target.checked
    }));
  };
  const handleSelectPidChange = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      pids: selectedOption
    }));
  };
  return (
    <Modal
      show
      aria-labelledby="contained-modal-title-vcenter"
      style={{ paddingTop: "100px" }}
    >
      <Modal.Header>
        <Modal.Title>Invite User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <b>
          Enter valid email addresses and an invitation to this domain will be
          emailed to the new user(s).
        </b>
        <form id="invite-user-modal-form" className="mt-2">
          <label htmlFor="emails" className="col-form-label">
            Email Addresses:
          </label>
          <input
            type="text"
            className={
              emailError ? "form-control border-danger" : "form-control"
            }
            name="emails"
            id="emails"
            value={formData.emails}
            placeholder="Please separate emails with a comma to add multiple..."
            onChange={handleEmailChange}
            required
            disabled={loadingRequest}
          />
          <div
            className="error-msg text-danger"
            style={{ display: emailError ? "block" : "none" }}
          >
            Please enter valid email addresses separated by commas.
          </div>
          <label htmlFor="groups-select" className="col-form-label">
            Add users as administrators.
          </label>
          <>
            <br />
          </>
          <small>
            NOTE: Checking this box will make users administrators of the
            primary domains.
          </small>
          <CheckBox
            checked={formData.is_admin}
            onChange={handleIsAdminChange}
            color="primary"
          />
          <br />
          <label htmlFor="pids-select" className="col-form-label">
            Select the domains that these users should have access to:
          </label>
          {!isEmpty(pidsList) && (
            <Select
              isMulti
              id="pids-select"
              name="pids"
              options={pidsList}
              value={formData.pids}
              onChange={handleSelectPidChange}
              isDisabled={loadingRequest}
              closeMenuOnSelect={false}
            />
          )}
          <div
            className="error-msg text-danger"
            style={{ display: pidError ? "block" : "none" }}
          >
            Please choose at least one domain to give users access to
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => setInviteModalShow(false)}
          variant="secondary"
          disabled={loadingRequest}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={loadingRequest}
        >
          {loadingRequest ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Save"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InviteUserModal;
