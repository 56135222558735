import { IAppStore } from "../../../interfaces/app-store.interface";
import { useAppStore } from "../../../hooks";
import { IInsightsMorpheusRequest } from "../../../interfaces/insights.interface";
import LanguageSelect from "../../shared/LanguageSelect";
import LocationSelect from "../../shared/LocationSelect";
import { languageConvert } from "../utilities/language";

interface AdvancedOptionsProps {
  isProspecting: boolean;
  insightRequest: IInsightsMorpheusRequest;
  setInsightRequest(request: object): void;
}
export default function AdvancedOptions({
  isProspecting,
  insightRequest,
  setInsightRequest
}: AdvancedOptionsProps) {
  const { user } = useAppStore((store: IAppStore) => ({
    user: store.user
  }));

  const handleCallback = (data: object) => {
    setInsightRequest((prevState: IInsightsMorpheusRequest) => ({
      ...prevState,
      ...data
    }));
  };
  return (
    <div className="advanced-options-container">
      <div className="location-language-container">
        <LocationSelect
          parentCallback={handleCallback}
          location={{
            value: insightRequest.location_name,
            label: insightRequest.location_name
          }}
        />
        <LanguageSelect
          parentCallback={handleCallback}
          language={{
            value: insightRequest.language,
            label: languageConvert(insightRequest.language)
          }}
        />
      </div>
      {(user.dj_employee || isProspecting) && (
        <>
          <label className="dj-label" htmlFor="prospect-domain">
            Prospect Domain
          </label>
          <input
            onChange={(e) => {
              setInsightRequest((prevState: IInsightsMorpheusRequest) => ({
                ...prevState,
                override_domain: e.target.value
              }));
            }}
            name="override_domain"
            className="dj-input"
            type="text"
            id="prospect-domain"
            placeholder="ex: yourdomain.com"
            value={insightRequest.override_domain}
          />
          <div className="helper-text" style={{ marginBottom: "15px" }}>
            Enter domain
          </div>
          <label className="dj-label" htmlFor="prospect-comp-domain">
            Prospect Competitor Domain(s)
          </label>
          <input
            onChange={(e) => {
              setInsightRequest((prevState: IInsightsMorpheusRequest) => ({
                ...prevState,
                override_competitors: e.target.value
              }));
            }}
            className="dj-input"
            name="override_competitors"
            type="text"
            id="prospect-comp-domain"
            placeholder="ex: nike.com"
            value={insightRequest.override_competitors}
          />
          <div className="helper-text">
            Enter or paste competitor domains separated by commas to include in
            this report.
          </div>
        </>
      )}
    </div>
  );
}
