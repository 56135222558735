import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";

import copyPageTextToClipboard from "./utils/copyPageTextToClipboard";
import copyAIPrompt from "./utils/copyAIPrompt";
import { downloadBrief } from "./utils/BriefToFile";
import useContentBriefsStore from "@/hooks/useContentBriefsStore";
import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";
import { IOrderedSections } from "@/interfaces/outline.interface";
import { useAppStore } from "@/hooks";
import { IAppStore } from "@/interfaces/app-store.interface";
import { updateContentBriefStep } from "@/api/post";

const Header = ({
  orderedSections,
  pid,
  wordCount
}: {
  orderedSections: IOrderedSections;
  pid: string;
  wordCount: string;
}) => {
  // return two divs, flexed horizontally, space between
  const {
    term,
    pillar,
    subPillar,
    typeOfTerm,
    metaDescription,
    briefId,
    setIsStepThreeComplete,
    notes,
    CTAs,
    unusedItems
  }: IContentBriefsStore = useContentBriefsStore(
    (store: IContentBriefsStore) => ({
      term: store.term,
      pillar: store.pillar,
      subPillar: store.subPillar,
      typeOfTerm: store.typeOfTerm,
      metaDescription: store.metaDescription,
      briefId: store.briefId,
      notes: store.notes,
      CTAs: store.CTAs,
      unusedItems: store.unusedItems,
      setIsStepThreeComplete: (value: boolean) =>
        store.setIsStepThreeComplete(value)
    })
  );
  const { setShowAlertBanner }: IAppStore = useAppStore((store: IAppStore) => ({
    setShowAlertBanner: store.setShowAlertBanner
  }));

  // the share link should have the format:
  // /public/content-brief/61575?phrase=What%20are%20the%20four%20best%20practices&type=Blog&pid=51
  const copyShareLinkToClipboard = () => {
    const url =
      process.env.REACT_APP_MORPHEUS_URL +
      `/public/content-brief/${briefId}?phrase=${term}&type=${typeOfTerm}&pid=${pid}&pillar=${pillar}`;
    navigator.clipboard.writeText(url);
  };

  const setStepThreeComplete = async () => {
    await updateContentBriefStep(briefId.toString(), 4);
    setIsStepThreeComplete(true);
  };

  const exportBrief = (format: "pdf" | "docx" | "txt") => {
    setIsStepThreeComplete(true);
    const notesSection = document.getElementById("add-notes");
    if (notesSection) {
      notesSection.style.display = "none";
    }
    const ctaSections = document.getElementById("add-call-to-action");
    if (ctaSections) {
      ctaSections.style.display = "none";
    }
    const editClass = document.getElementsByClassName("pencil-and-trash");
    Array.from(editClass as HTMLCollectionOf<HTMLElement>).forEach((item) => {
      item.style.display = "none";
    });

    const parentElement = document.getElementById("step-three-container");

    const titleArray = document.getElementsByClassName(
      "title-photo-title-line-2"
    );
    const title = titleArray[0].innerHTML;
    if (parentElement) {
      downloadBrief(title, parentElement, format);
    }
    if (notesSection) {
      notesSection.style.display = "block";
    }
    if (ctaSections) {
      ctaSections.style.display = "block";
    }
    Array.from(editClass as HTMLCollectionOf<HTMLElement>).forEach((item) => {
      item.style.display = "block";
    });
  };

  return (
    <div
      data-testid="header-section"
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "30px",
        borderBottom: "1px solid rgb(233, 235, 240)"
      }}
    >
      <div className="header__left">
        <div
          className="header__left__title"
          style={{ textTransform: "uppercase", color: "#56627C" }}
        >
          Step 3
        </div>
        <div className="header__left__subtitle" style={{ color: "#373F50" }}>
          <h4>Review and Export Your Brief</h4>
        </div>
      </div>

      <div style={{ paddingTop: "50px", paddingRight: "50px" }}>
        <div style={{ float: "left" }}>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              id="dropdown-basic"
              style={{
                backgroundColor: "white",
                color: "#373F50",
                border: "1px solid #373F50",
                marginRight: "5px"
              }}
            >
              Export as...
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  exportBrief("pdf");
                }}
              >
                Adobe PDF (.pdf)
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  exportBrief("txt");
                }}
              >
                Notepad (.txt)
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown as={ButtonGroup}>
            <Button
              style={{
                backgroundColor: "white",
                color: "#373F50",
                border: "1px solid #373F50"
              }}
              onClick={() => {
                setStepThreeComplete();
                copyPageTextToClipboard(
                  pillar,
                  subPillar,
                  term,
                  typeOfTerm,
                  metaDescription,
                  orderedSections,
                  wordCount,
                  notes,
                  CTAs,
                  unusedItems
                );
                setShowAlertBanner(true, {
                  message: "Outline copied to clipboard.",
                  severity: "success"
                });
              }}
            >
              Copy as Text
            </Button>

            <Dropdown.Toggle
              split
              style={{
                backgroundColor: "white",
                color: "#373F50",
                border: "1px solid #373F50"
              }}
              id="dropdown-split-basic"
            />
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setStepThreeComplete();
                  copyAIPrompt(
                    pillar,
                    subPillar,
                    term,
                    typeOfTerm,
                    metaDescription,
                    orderedSections,
                    wordCount,
                    notes,
                    CTAs,
                    unusedItems
                  );
                  // alert message
                  setShowAlertBanner(true, {
                    message:
                      "AI prompt copied to clipboard. To continue, paste into a text editor, NOT directly into an AI tool.",
                    severity: "success"
                  });
                }}
              >
                Copy AI Prompt
              </Dropdown.Item>
              {/* the share link will have the query params: origination=share_link,  */}
              <Dropdown.Item
                onClick={() => {
                  setStepThreeComplete();
                  copyShareLinkToClipboard();
                  setShowAlertBanner(true, {
                    message: "Share link copied to clipboard.",
                    severity: "success"
                  });
                }}
              >
                Copy Share Link
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Header;
