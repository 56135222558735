import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import DJForm from "@/components/shared/DJForm";
import DJTextField from "@/components/shared/DJTextField";
import Button from "@/components/shared/Button";
import AuthorizeEmail from "../../../routines/authorize-email";
import { useAppStore } from "../../../hooks";
import { IEmailValues } from "../../../interfaces/email-values.interface";
import { IAppStore } from "../../../interfaces/app-store.interface";
import AlertBanner from "@/components/shared/AlertBanner";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY || "";
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [showReCaptchaError, setShowReCaptchaError] = useState(false);
  const { setShowAlertBanner } = useAppStore((store: IAppStore) => ({
    setShowAlertBanner: store.setShowAlertBanner
  }));

  const { setPasswordResetNotification } = useAppStore((store: IAppStore) => ({
    setPasswordResetNotification: (value: boolean) =>
      store.setPasswordResetNotification(value)
  }));

  const handleSubmit = async (values: IEmailValues) => {
    if (captchaRef?.current) {
      const token = captchaRef.current.getValue();
      captchaRef.current.reset();
      setShowReCaptchaError(false);
      if (token) {
        await AuthorizeEmail(values.email, token)
          .then(() => {
            setPasswordResetNotification(true);
            navigate("/login");
          })
          .catch((error) => {
            setShowAlertBanner(true, {
              message:
                "There was an error sending your password reset, please try again or contact support.",
              severity: "error"
            });
            console.error(error);
            navigate("/login");
            setPasswordResetNotification(false);
          });
        return;
        // eslint-disable-next-line no-else-return
      } else {
        setShowReCaptchaError(true);
        setShowAlertBanner(true, {
          message:
            "You have not checked the captcha, please do so and request the password reset again",
          severity: "error"
        });
      }
    } else {
      setShowReCaptchaError(true);
      setShowAlertBanner(true, {
        message:
          "You have not checked the captcha, please do so and request the password reset again",
        severity: "error"
      });
    }
  };

  const onClick = () => {
    navigate("/login");
  };

  const validateSchema = Yup.object().shape({
    email: Yup.string()
      .required("Required")
      .email("Invalid email address format")
  });

  return (
    <div style={{ fontFamily: "Source Sans Pro" }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <div style={{ width: "65%", textAlign: "left" }}>
          <h2>Forgot Password</h2>
          <small>
            Enter the email associated with your account and if it matches our
            records, we’ll send an email with instructions to reset your
            password.
          </small>
          <DJForm
            initialValues={{ email: "" }}
            validationSchema={validateSchema}
            onSubmit={handleSubmit}
          >
            <Grid item xs={12} style={{ paddingBottom: "16px" }}>
              <DJTextField
                type="email"
                label="Email"
                name="email"
                required={true}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ paddingBottom: "30px" }}
            >
              <Grid
                item
                xs={8}
                sm={8}
                md={8}
                lg={8}
                xl={8}
                style={{ marginTop: "50px" }}
              >
                <ReCAPTCHA sitekey={recaptchaKey} ref={captchaRef} />
                {showReCaptchaError && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{
                      color: "#B00020",
                      marginLeft: "15px"
                    }}
                  >
                    <ErrorOutlineIcon />
                    <small
                      style={{
                        padding: "5px",
                        fontSize: "11px"
                      }}
                    >
                      Please select the checkbox above.
                    </small>
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  alignSelf: "flex-end",
                  paddingTop: "40px"
                }}
              >
                <Grid
                  container
                  justifyContent={{
                    xs: "space-between",
                    sm: "space-between",
                    md: "space-between",
                    lg: "space-between",
                    xl: "space-between"
                  }}
                  alignItems="space-between"
                >
                  <Button
                    type="submit"
                    size="large"
                    color="primary"
                    variant="outlined"
                    text="Cancel"
                    onClick={onClick}
                  ></Button>
                  <Button
                    type="submit"
                    size="large"
                    color="primary"
                    variant="contained"
                    text="Request"
                  ></Button>
                </Grid>
              </Grid>
            </Grid>
          </DJForm>
        </div>
      </Grid>
      <AlertBanner />
    </div>
  );
};

export default ForgotPassword;
