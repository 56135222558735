// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verification-email-link {
  cursor: pointer;
  text-decoration: underline;
  padding-left: 4px;
  color: #008CCE;
  text-align: center;
}

.verification-email-link:hover {
  color: #2ABBFF;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/application.scss"],"names":[],"mappings":"AAMA;EACE,eAAA;EACA,0BAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;AALF;;AAOA;EACE,cAAA;AAJF","sourcesContent":["$demandjump-blue: #008cce;\n$demandjump-green: #44af69;\n$secondary-tint-1: #e9ebf0;\n$metal-gray: #373f50;\n$light-gray: #909BB3;\n\n.verification-email-link{\n  cursor: pointer;\n  text-decoration: underline;\n  padding-left: 4px;\n  color: #008CCE;\n  text-align: center;\n}\n.verification-email-link:hover{\n  color: #2ABBFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
