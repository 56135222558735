function UpgradeBadge() {
  const badgeStyle = {
    color: "#909bb3",
    lineHeight: "17px",
    marginLeft: "15px",
    marginRight: "-15px",
    padding: "0px 7px",
    fontSize: "10px",
    border: "1px solid #909bb3",
    borderRadius: "9px"
  };
  return <span style={badgeStyle}>Upgrade</span>;
}

export default UpgradeBadge;
