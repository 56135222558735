/* eslint-disable max-params */
import { IOrderedSections } from "@/interfaces/outline.interface";
import { instructionsSectionTextBlock } from "./instructionsSectionTextBlock";
import { metaSection } from "./metaSection";
import { outlineAsText } from "./outlineAsText";
import { INotes } from "@/interfaces/notes.interface";
import { ISavedBriefCTA } from "@/interfaces/saved_outline.interface";

const copyAIPrompt = (
  pillarTopic: string,
  subPillar: string,
  term: string,
  typeOfTerm: string,
  metaDescription: string,
  orderedSections: IOrderedSections,
  wordCount: string,
  notes: INotes[],
  CTAs: ISavedBriefCTA[],
  Unused: string[]
) => {
  const showNotesSection = false;
  let textLinesArray = [];

  textLinesArray.push("Directions for the Human:");
  textLinesArray.push(
    "Below are a series of messages to send to your chosen AI tool to give the appropriate background for their task and to clearly define your expectations.Because all AI tools are different, these prompts should be sufficient for more articles, but pay close attention to the AI responses to ensure more information or clarification is not needed."
  );
  textLinesArray.push("\n");
  textLinesArray.push("Message 1:");
  textLinesArray.push(
    "You are an SEO content writer. I have prepared a detailed content brief that you will use to write an article. Please use as many tokens as necessary to produce a finished article that is as close to the target word count, which I will include in the instructions, as possible. Do not begin writing until I prepare you with all of the instructions and confirm that it is time to begin. Do you understand?"
  );
  textLinesArray.push("\n");
  textLinesArray.push(
    "Note to the human: At this stage, it is likely that you will not need to provide any further clarification before continuing with the following messages. If, however, your chosen AI tool requests clarification on the assignment that will not come with subsequent instructions, now is the time to provide it."
  );
  textLinesArray.push("\n");
  textLinesArray.push("Message 2:");
  textLinesArray.push("First, your basic writing instructions.");

  const tempArrayReturned = instructionsSectionTextBlock(
    pillarTopic,
    wordCount,
    typeOfTerm
  );
  textLinesArray = textLinesArray.concat(tempArrayReturned);

  textLinesArray.push("\n");
  textLinesArray.push("Message 3:");
  textLinesArray.push(
    "Next, the meta information about the article you’ll be writing, including target word count."
  );
  textLinesArray = textLinesArray.concat(
    metaSection(term, typeOfTerm, metaDescription, wordCount)
  );
  textLinesArray.push("\n");

  // There will be an optional notes section here
  if (showNotesSection) {
    textLinesArray.push("Message 4:");
    textLinesArray.push(
      "Next, there are some additional notes that can help provide context."
    );
  }
  if (showNotesSection) {
    textLinesArray.push("Message 5:");
  } else {
    textLinesArray.push("Message 4:");
  }

  textLinesArray.push(
    "Finally, here is a detailed outline for the article, containing section headers and keywords to include in each section. This is the most critical set of instructions to follow precisely. All keywords must be used at least one time in their respective sections. Any section that contains no keywords or headings can be written up as you see fit. There may be a section of “Unassigned Keywords” included in this outline; do not treat that as a section of the article, but as a list of keywords that you must use in the article, but are free to use in any location that makes sense organically."
  );
  textLinesArray.push("\n");

  // Outline Section
  textLinesArray = textLinesArray.concat(
    outlineAsText(
      orderedSections,
      term,
      typeOfTerm,
      pillarTopic,
      subPillar,
      notes,
      CTAs,
      Unused
    )
  );

  textLinesArray.push("\n");
  if (showNotesSection) {
    textLinesArray.push("Message 6");
  } else {
    textLinesArray.push("Message 5");
  }
  textLinesArray.push(
    "Do you require any further information to complete this assignment?"
  );
  textLinesArray.push("\n");
  textLinesArray.push(
    "Note to the Human: Depending on what you have entered into the Notes section of your DemandJump Content Brief, you may be asked for information about your target audience and brand voice at this step in the instruction process. Either provide that information now, or go back into your Content Brief in DemandJump, add that information to the Notes section, then copy as AI prompt again."
  );
  textLinesArray.push("\n");
  textLinesArray.push("Confirmation message:");
  textLinesArray.push("You may now begin writing.");
  textLinesArray.push("\n");
  textLinesArray.push(
    "Note to the Human: Remember that the output you receive is unlikely to match your word count requirements, and that is expected behavior. We strongly recommend closing any word count gap by adding new, original content to the AI output. This will maximize the potential of your article and give it the Experience (from Google’s E-E-A-T framework for rankability) it needs to outrank the competition."
  );
  textLinesArray.push("\n");
  textLinesArray.push(
    "Final Note to the Human: Additionally, if you want to continue this assignment with your AI tool for additional material, here are some prompt ideas to consider:"
  );
  textLinesArray.push("\n");
  textLinesArray.push(
    "Now, write an email that introduces this article and invites the recipient to read the published piece."
  );
  textLinesArray.push("\n");
  textLinesArray.push(
    "Now, provide 10 LinkedIn posts promoting this article and highlighting its contents."
  );
  textLinesArray.push("\n");
  textLinesArray.push(
    "Now, provide 5 relevant quotes or statistics from reputable sources that help to drive home the main point of this article, including links (or URLs) to the original sources."
  );
  textLinesArray.push("\n");
  textLinesArray.push(
    "Now, write a 160-character-or-less (including spaces and punctuation) SEO meta description for this article that incorporates its focus keyword."
  );
  textLinesArray.push("\n");
  textLinesArray.push(
    "Now, provide a list of graphic suggestions for this article, including pull quotes, statistics, illustrations, and photos."
  );
  // write the textlinesArray to the clipboard
  window.navigator.clipboard.writeText(textLinesArray.join("\n"));
};

export default copyAIPrompt;
