import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useMediaQuery } from "react-responsive";
import Cookies from "universal-cookie";
const cookies = new Cookies();
import { useEffectAsync, useAppStore } from "../../../../hooks";
import {
  getAccountAdmins,
  getPidSubscription,
  getUserAccountAndPid,
  getUserAccountsAndPidsPaginated
} from "../../../../api/get";
import { ActionMore, NavigationArrowDown } from "@/assets/icons/svgr";
import { IPDomain } from "../../../../interfaces/pdomain.interface";
import { IAccount } from "../../../../interfaces/account.interface";
import { IAppStore } from "../../../../interfaces/app-store.interface";
import SelectorBox from "./SelectorBox";
import PDomainBadge from "./PdomainBadge";
import { motion, AnimatePresence } from "framer-motion";
import { ICbSubscription } from "../../../../interfaces/subscription.interface";
import queryString from "query-string";

const StyledButton = styled(Button)(`
  text-transform: none;
`);

const PDomainSelector = ({
  handleNavClose,
  pidSelectorMenuOpen,
  setPidSelectorMenuOpen
}: {
  handleNavClose: () => void;
  pidSelectorMenuOpen: boolean;
  setPidSelectorMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const {
    user,
    selectedPidObject,
    selectedAccountObject,
    setSelectedPidObject,
    setSelectedAccountObject,
    setUserAccounts,
    userAccounts,
    setIsAdmin,
    setShowAlertBanner,
    setSelectedSubscriptionObject,
    selectedSubscriptionObject
  } = useAppStore((store: IAppStore) => ({
    user: store.user,
    selectedPidObject: store.selectedPidObject,
    selectedAccountObject: store.selectedAccountObject,
    userAccounts: store.userAccounts,
    setIsAdmin: (value: boolean) => store.setIsAdmin(value),
    setSelectedPidObject: (value: IPDomain) =>
      store.setSelectedPidObject(value),
    setUserAccounts: (value: IAccount[]) => store.setUserAccounts(value),
    setSelectedAccountObject: (value: IAccount) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      store.setSelectedAccountObject(value),
    setShowAlertBanner: store.setShowAlertBanner,
    setSelectedSubscriptionObject: (value: ICbSubscription) =>
      store.setSelectedSubscriptionObject(value),
    selectedSubscriptionObject: store.selectedSubscriptionObject
  }));

  const updateSubscription = async (pid: number) => {
    const pidSubscription = await getPidSubscription(pid);
    setSelectedSubscriptionObject(pidSubscription[0]);
  };

  useEffectAsync(async () => {
    cookies.remove("Topic");
    if (!userAccounts.length) {
      const accountsResponse = await getUserAccountsAndPidsPaginated(1, 10);
      setUserAccounts(accountsResponse.data);
      setTotalPages(accountsResponse.totalPages);
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    const parsed = queryString.parse(location.search);
    // To get pid, first check url, then appStore, then localstorage.
    const queryPid =
      parsed.pid || selectedPidObject.id || localStorage.getItem("selectedPid");
    const setAccountPidSubscription = async () => {
      if (userAccounts.length) {
        const defaultAccount = userAccounts[0];
        const defaultPid = defaultAccount.pdomains[0];
        if ((queryPid && defaultPid.id === parseInt(queryPid)) || !queryPid) {
          setSelectedPidObject(defaultPid);
          setSelectedAccountObject(defaultAccount);
          if (
            !selectedSubscriptionObject?.pid ||
            selectedSubscriptionObject.pid !== Number(queryPid)
          ) {
            const pidSubscription = await getPidSubscription(Number(queryPid));
            setSelectedSubscriptionObject(pidSubscription[0]);
          }
        } else {
          let currentPid = defaultPid;
          let currentAccount = defaultAccount;
          userAccounts.forEach((account: IAccount) => {
            account.pdomains.forEach((pdomain) => {
              if (queryPid && pdomain.id === parseInt(queryPid)) {
                currentPid = pdomain;
                currentAccount = account;
              }
            });
          });
          // If current pid cant be found reach out to API
          if (currentPid === defaultPid) {
            try {
              const accountAndPid = await getUserAccountAndPid(
                Number(queryPid)
              );
              const pdomainApi = accountAndPid?.pdomains?.filter(
                (pd) => Number(pd.id) === Number(queryPid)
              )[0];
              currentPid = pdomainApi;
              currentAccount = accountAndPid;
            } catch (error) {
              setShowAlertBanner(true, {
                message: "Cant Access Pid",
                severity: "error"
              });
            }
          }
          setSelectedPidObject(currentPid);
          setSelectedAccountObject(currentAccount);
          if (
            !selectedSubscriptionObject?.pid ||
            selectedSubscriptionObject.pid !== Number(queryPid)
          ) {
            const pidSubscription = await getPidSubscription(Number(queryPid));
            setSelectedSubscriptionObject(pidSubscription[0]);
          }
        }
      } else if (queryPid) {
        try {
          const accountAndPid = await getUserAccountAndPid(Number(queryPid));
          const pdomainApi = accountAndPid?.pdomains?.filter(
            (pd) => Number(pd.id) === Number(queryPid)
          )[0];
          setSelectedPidObject(pdomainApi);
          setSelectedAccountObject(accountAndPid);
          if (
            !selectedSubscriptionObject?.pid ||
            selectedSubscriptionObject.pid !== Number(queryPid)
          ) {
            const pidSubscription = await getPidSubscription(Number(queryPid));
            setSelectedSubscriptionObject(pidSubscription[0]);
          }
        } catch (error) {
          setShowAlertBanner(true, {
            message: "Cant Access Pid",
            severity: "error"
          });
        }
      }
      setLoading(false);
    };
    if (
      !selectedPidObject?.id ||
      (queryPid && Number(selectedPidObject.id) !== Number(queryPid))
    ) {
      setAccountPidSubscription();
    } else if (
      !selectedSubscriptionObject?.pid ||
      selectedSubscriptionObject.pid !== selectedSubscriptionObject.id
    ) {
      updateSubscription(selectedPidObject.id);
    } else {
      setLoading(false);
    }
  }, [selectedPidObject, selectedAccountObject, userAccounts]);

  // fetch the admins for the account and set the isAdmin flag for this user
  useEffectAsync(async () => {
    if (selectedAccountObject.id && user.id) {
      const admins = await getAccountAdmins(selectedAccountObject.id);
      setIsAdmin(admins.includes(user.id));
    }
  }, [selectedAccountObject, user]);

  return (
    <div style={{ width: "100%" }}>
      {(userAccounts.length > 1 ||
        (userAccounts.length > 0 && userAccounts[0]?.pdomains?.length > 1)) && (
        <div style={{ float: "right" }}>
          <Box>
            <StyledButton
              disableRipple
              disableTouchRipple
              variant="text"
              sx={{
                display: "flex",
                flexDirection: "row",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent"
                }
              }}
              onClick={() => {
                if (isMobile) {
                  handleNavClose();
                }
                setPidSelectorMenuOpen(!pidSelectorMenuOpen);
              }}
            >
              {isMobile ? (
                <ActionMore
                  style={{
                    width: "24px",
                    color: "white"
                  }}
                />
              ) : (
                <>
                  <PDomainBadge
                    account={selectedAccountObject}
                    pdomain={selectedPidObject}
                  ></PDomainBadge>

                  {pidSelectorMenuOpen ? (
                    <NavigationArrowDown
                      style={{
                        width: "24px",
                        color: "white",
                        transform: "rotate(180deg)",
                        transition: "all 300ms linear 0s"
                      }}
                    />
                  ) : (
                    <NavigationArrowDown
                      style={{
                        width: "24px",
                        color: "white",
                        transition: "all 300ms linear 0s"
                      }}
                    />
                  )}
                </>
              )}
            </StyledButton>
          </Box>
          <AnimatePresence>
            {pidSelectorMenuOpen && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1
                }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 0.3,
                  ease: "linear"
                }}
              >
                <SelectorBox
                  setPidSelectorMenuOpen={setPidSelectorMenuOpen}
                  totalPages={totalPages}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
      <AnimatePresence>
        {userAccounts.length === 1 &&
          userAccounts[0]?.pdomains?.length === 1 && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1
              }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.3,
                ease: "linear"
              }}
            >
              <Box sx={{ marginLeft: "auto" }}>
                <PDomainBadge
                  account={selectedAccountObject}
                  pdomain={selectedPidObject}
                ></PDomainBadge>
              </Box>
            </motion.div>
          )}
      </AnimatePresence>
      {((loading && !userAccounts.length) ||
        !selectedPidObject ||
        Object.keys(selectedPidObject).length === 0) && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1
          }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.3,
            ease: "linear"
          }}
        >
          <div
            className="placeholder-wave"
            style={{
              width: "100px",
              padding: "3px",
              textAlign: "right"
            }}
          >
            <span className="placeholder col-10"></span>
            <span className="placeholder col-12"></span>
          </div>
        </motion.div>
      )}
    </div>
  );
};
export default PDomainSelector;
