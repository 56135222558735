// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor_top_div {
  padding-top: 20px;
  height: 100%;
}

.MuiSlider-rail {
  height: 5px !important;
}

.MuiSlider-track {
  height: 5px !important;
}

.MuiSlider-thumb {
  width: 15px !important;
  height: 15px !important;
}

.text-center {
  text-align: center;
}

.content_wc {
  text-align: center;
  font-size: xx-large;
  color: #3f6ad8;
  font-weight: bold;
}

.center_small {
  text-align: center;
  font-size: small;
}

.rdw-editor-main {
  padding-left: 20px;
}

.outline-creation-actions-container {
  width: 100%;
}

.action_button {
  display: inline-block;
  margin-right: 5px;
}

#editor-container ul li {
  list-style-type: none;
}
#editor-container ul li:before {
  content: "- ";
  margin-left: -1em;
  float: left;
}`, "",{"version":3,"sources":["webpack://./src/components/manage_content/content_brief/components/editor.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,YAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,sBAAA;EACA,uBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,mBAAA;EACA,cAAA;EACA,iBAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,qBAAA;EACA,iBAAA;AACJ;;AAEA;EACE,qBAAA;AACF;AAAE;EACE,aAAA;EACA,iBAAA;EACA,WAAA;AAEJ","sourcesContent":[".editor_top_div{\n  padding-top:20px;\n  height: 100%;\n}\n\n.MuiSlider-rail{\n  height: 5px !important;\n}\n\n.MuiSlider-track{\n  height: 5px !important;\n}\n\n.MuiSlider-thumb{\n  width: 15px !important;\n  height: 15px !important;\n}\n\n.text-center{\n  text-align: center;\n}\n\n.content_wc{\n  text-align: center;\n  font-size: xx-large;\n  color: #3f6ad8;\n  font-weight: bold;\n}\n\n.center_small{\n  text-align: center;\n  font-size: small;\n}\n\n.rdw-editor-main{\n  padding-left: 20px;\n}\n\n.outline-creation-actions-container{\n    width:100%;\n}\n\n.action_button{\n    display: inline-block;\n    margin-right: 5px;\n}\n\n#editor-container ul li {\n  list-style-type: none;\n  &:before {\n    content: '- ';\n    margin-left: -1em;\n    float:left;\n  }\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
