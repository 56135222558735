import { useLocation, useNavigate } from "react-router-dom";
import DJLoadingGif from "../../shared/DJLoadingGif";
import "../../shared/application.scss";
import { useAppStore, useEffectAsync } from "../../../hooks";
import { IAppStore } from "../../../interfaces/app-store.interface";
import { updateProfile } from "../../../api/post";
import { getUserById } from "../../../api/get";

function VerifyEmailLoader() {
  const { setShowAlertBanner, setUser } = useAppStore((store: IAppStore) => ({
    setUser: store.setUser,
    setShowAlertBanner: store.setShowAlertBanner
  }));

  const navigate = useNavigate();
  const { search } = useLocation();

  useEffectAsync(async () => {
    const params = new URLSearchParams(search);
    const user_id = params.get("user_id");
    const code = params.get("code");
    if (user_id && code) {
      try {
        const user = await getUserById(parseInt(user_id));
        if (!user) {
          throw new Error("No user found");
        }
        user.verified = true;
        setUser(user);
        await updateProfile(
          { verified: true, verify_email_otp: code },
          user.id
        );
        setShowAlertBanner(true, {
          message: "You have verified your email address",
          severity: "success"
        });
        navigate("/home");
      } catch (e) {
        console.error(e);
        setShowAlertBanner(true, {
          message:
            "Something went wrong when trying to verify your email address",
          severity: "error"
        });
      }
    } else {
      navigate("/home");
      setShowAlertBanner(true, {
        message: "No email supplied in the URL",
        severity: "error"
      });
    }
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <DJLoadingGif />
    </div>
  );
}
export default VerifyEmailLoader;
