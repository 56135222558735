import * as React from "react";
import ContentPillarsHeader from "./ContentPillarsHeader";
import DetailWrapper from "./DetailWrapper";
import { Formik, Form, Field } from "formik";
import PropTypes from "prop-types";
import LocationSelect from "../../../shared/LocationSelect";

const LoadingCard = ({ contentPillarsLimit }) => (
  <div
    id="contentPillars"
    className="mtlr-30"
    style={{ padding: "0", height: "80vh" }}
  >
    <div className="card mb-3">
      <div className="card-body" style={{ padding: "20px 22px" }}>
        <ContentPillarsHeader />
        <>
          <DetailWrapper />
          <Formik>
            <Form className="form-wrapper">
              <div
                className="form-wrapper"
                style={
                  contentPillarsLimit === 0
                    ? {
                        zIndex: 2,
                        filter: "blur(2px)",
                        marginBottom: "0"
                      }
                    : { marginBottom: "0" }
                }
              >
                {Array.from(Array(5).keys()).map((int) => (
                  <div
                    key={int}
                    className="content-pillars-control row skeleton pb-3"
                  >
                    <div className="col-4">
                      <div className="inputWrapper">
                        <label
                          className="col-form-label"
                          style={{
                            fontSize: "16px",
                            fontFamily: "source-sans-pro, sans-serif",
                            padding: 0
                          }}
                        >
                          {int + 1}.
                        </label>
                        <Field
                          name={`keyword${int + 1}.keyword`}
                          type="text"
                          style={{
                            fontSize: "16px",
                            boxShadow: "none"
                          }}
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="locationWrapper col-4">
                      <LocationSelect isDisabled={true} />
                    </div>
                  </div>
                ))}
                <div
                  className="d-flex justify-content-end"
                  style={{
                    padding: "30px 0 0 0"
                  }}
                >
                  <button
                    id="cancel-btn"
                    type="button"
                    className="btn demandjump-button-outline-primary mx-3"
                    style={{
                      width: "128px",
                      height: "40px",
                      borderColor: "#373F504D",
                      color: "#373F504D"
                    }}
                    disabled
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    id="save-btn"
                    className="btn btn-primary demandjump-button-primary"
                    style={{
                      width: "170px",
                      height: "40px",
                      backgroundColor: "#D3D7E0",
                      color: "#373F504D",
                      border: "none"
                    }}
                    disabled
                  >
                    Update Pillars
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </>
      </div>
    </div>
  </div>
);

LoadingCard.propTypes = {
  contentPillarsLimit: PropTypes.number
};

export default LoadingCard;
