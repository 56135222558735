import { useAppStore } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import {
  GoogleOAuthProvider,
  GoogleLogin,
  CredentialResponse
} from "@react-oauth/google";
import GoogleAuthorize from "../../../routines/authorize-google";
import GetUserByToken from "../../../routines/get-user-by-token";
import {
  getPidSubscription,
  getUserAccountsAndPidsPaginated
} from "../../../api/get";
import { IUser } from "../../../interfaces/user.interface";
import { IPDomain } from "../../../interfaces/pdomain.interface";
import { IAppStore } from "../../../interfaces/app-store.interface";
import { IAccount } from "../../../interfaces/account.interface";
import { ICbSubscription } from "../../../interfaces/subscription.interface";
interface IGoogleLoginEl {
  extendedLogin: boolean;
  setShowError: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorText: React.Dispatch<React.SetStateAction<string>>;
  setLoading: (value: boolean) => void;
}

const GoogleLoginEl = ({
  extendedLogin,
  setShowError,
  setErrorText,
  setLoading
}: IGoogleLoginEl) => {
  const navigate = useNavigate();
  const clientId = process.env.REACT_APP_GOOGLE_SIGN_IN_CLIENT_ID;
  const {
    setUser,
    setSelectedPidObject,
    setUserAccounts,
    setSelectedAccountObject,
    setSelectedSubscriptionObject
  } = useAppStore((store: IAppStore) => ({
    setUser: (value: IUser) => store.setUser(value),
    setSelectedPidObject: (value: IPDomain) =>
      store.setSelectedPidObject(value),
    setUserAccounts: (value: IAccount[]) => store.setUserAccounts(value),
    setSelectedAccountObject: (value: IAccount) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      store.setSelectedAccountObject(value),
    setSelectedSubscriptionObject: (value: ICbSubscription) =>
      store.setSelectedSubscriptionObject(value)
  }));

  const onSuccess = async (response: CredentialResponse) => {
    const QueryString = window.location.search;
    const urlParams = new URLSearchParams(QueryString);
    const location = urlParams.get("location");
    const duration = extendedLogin ? 30 : 1;
    let token = "";
    if (response.credential) {
      token = response.credential;
    }

    await GoogleAuthorize(token, duration)
      .then(async () => {
        setLoading(true);
        setShowError(false);
        setErrorText("");
        try {
          const udb = await GetUserByToken();
          setUser(udb);
          const userAccountsPidsArray = await getUserAccountsAndPidsPaginated(
            1,
            10
          );
          const accounts = userAccountsPidsArray.data;
          setUserAccounts(accounts);
          setSelectedAccountObject(accounts[0]);
          setSelectedPidObject(accounts[0].pdomains[0]);
          const pdomainData = accounts[0].pdomains[0];
          const resp = await getPidSubscription(pdomainData.id);
          setSelectedSubscriptionObject(resp[0]);

          if (udb.otp_enabled) {
            setLoading(false);
            navigate("/mfa");
          } else {
            const final_location = location ? location : "/home";
            setLoading(false);
            navigate(final_location);
          }
        } catch (errror) {
          console.error(errror);
          setShowError(true);
          setErrorText(
            "There was a problem authenticating you. Please ensure you entered the correct user email and password."
          );
        }
      })
      .catch(() => {
        setShowError(true);
        setErrorText(
          "There was a problem authenticating you. Please ensure you entered the correct user email and password."
        );
      });
  };

  return (
    <>
      {clientId && (
        <GoogleOAuthProvider clientId={clientId}>
          <GoogleLogin
            onSuccess={onSuccess}
            onError={() => {
              console.error("Google Login Failed");
              setShowError(true);
              setErrorText(
                "Error authorizing with Google, please try again later."
              );
            }}
          />
        </GoogleOAuthProvider>
      )}
    </>
  );
};

export default GoogleLoginEl;
