/* eslint-disable complexity */
/* eslint-disable max-depth */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import {
  PiNumberCircleOneThin,
  PiNumberCircleTwoThin,
  PiNumberCircleThreeThin
} from "react-icons/pi";
import { AiOutlineLine, AiFillCheckCircle } from "react-icons/ai";
import { Button, Row, Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

import { IContentBriefsStore } from "@/interfaces/content-briefs-store.interface";
import useContentBriefsStore from "@/hooks/useContentBriefsStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { updateContentBriefStep } from "@/api/post";
import { useAppStore, useEffectAsync } from "@/hooks";
import { IAppStore } from "@/interfaces/app-store.interface";
import { getBriefStatus, getPillarStrategy } from "@/api/get";
import { NavigationArrowRight } from "@/assets/icons/svgr";
import {
  determineBriefType,
  formatUrl
} from "@/components/manage_content/utils";
import getUsableWidth from "@/routines/get-usable-width";
import "./BreadcrumbStyles.scss";

// eslint-disable-next-line arrow-body-style
const BreadcrumbsHeader = (props: { handleSubmit: Function }) => {
  const { selectedPidObject, sideMenuOpen } = useAppStore(
    (store: IAppStore) => ({
      selectedPidObject: store.selectedPidObject,
      sideMenuOpen: store.sideMenuOpen
    })
  );
  const {
    isStepOneActive,
    isStepTwoActive,
    isStepThreeActive,
    showSaved,
    blog,
    setBlog,
    isStepOneComplete,
    isStepTwoComplete,
    isStepThreeComplete,
    setIsStepOneComplete,
    pillar,
    setSubPillarPublishedUrl,
    setBlogPublishedUrl,
    setPillarPublishedUrl,
    subPillar,
    setSubPillar,
    typeOfTerm,
    setTerm,
    setTypeOfTerm,
    setPillar,
    setIsStepTwoComplete,
    setIsStepThreeComplete,
    setIsStepOneActive,
    setIsStepTwoActive,
    setIsStepThreeActive
  }: IContentBriefsStore = useContentBriefsStore(
    (store: IContentBriefsStore) => ({
      isStepOneActive: store.isStepOneActive,
      pillar: store.pillar,
      subPillar: store.subPillar,
      blog: store.blog,
      showSaved: store.showSaved,
      typeOfTerm: store.typeOfTerm,
      isStepTwoActive: store.isStepTwoActive,
      isStepThreeActive: store.isStepThreeActive,
      isStepOneComplete: store.isStepOneComplete,
      isStepTwoComplete: store.isStepTwoComplete,
      isStepThreeComplete: store.isStepThreeComplete,
      setIsStepOneComplete: (value: boolean) =>
        store.setIsStepOneComplete(value),
      setIsStepTwoComplete: (value: boolean) =>
        store.setIsStepTwoComplete(value),
      setIsStepThreeComplete: (value: boolean) =>
        store.setIsStepThreeComplete(value),
      setPillar: (value: string) => store.setPillar(value),
      setPillarPublishedUrl: (value: string) =>
        store.setPillarPublishedUrl(value),
      setSubPillarPublishedUrl: (value: string) =>
        store.setSubPillarPublishedUrl(value),
      setBlogPublishedUrl: (value: string) => store.setBlogPublishedUrl(value),
      setSubPillar: (value: string) => store.setSubPillar(value),
      setTypeOfTerm: (value: string) => store.setTypeOfTerm(value),
      setBlog: (value: string) => store.setBlog(value),
      setTerm: (value: string) => store.setTerm(value),
      setIsStepOneActive: (value: boolean) => store.setIsStepOneActive(value),
      setIsStepTwoActive: (value: boolean) => store.setIsStepTwoActive(value),
      setIsStepThreeActive: (value: boolean) =>
        store.setIsStepThreeActive(value)
    })
  );
  const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
  const [contentTitle, setContentTitle] = useState("");
  const [adHocTitle, setAdHocTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setSubPillar("");
    setPillar("");
    setBlog("");
    setSubPillarPublishedUrl("");
    setBlogPublishedUrl("");
    setPillarPublishedUrl("");
    if (typeOfTerm === "Pillar") {
      setPillar(contentTitle);
      setLoading(false);
    } else if (typeOfTerm === "Sub Pillar") {
      setSubPillar(contentTitle);
    } else if (typeOfTerm === "Blog") {
      setBlog(contentTitle);
    } else if (typeOfTerm === "Ad Hoc") {
      setAdHocTitle(contentTitle);
      setPillar("");
      setSubPillar("");
      setLoading(false);
    }
  }, [selectedPidObject, contentTitle, typeOfTerm]);

  const handleSubmit = () => {
    setIsStepThreeComplete(false);
    const path = location.pathname;
    const search = location.search;
    const queryParams = new URLSearchParams(search);
    queryParams.delete("complete");
    navigate(`${path}?${queryParams.toString()}`, { replace: true });
    props.handleSubmit();
  };

  useEffectAsync(async () => {
    const parsed = queryString.parse(location.search);
    let phrase = parsed.phrase;
    const phraseType = parsed.type;
    const pathArray = window.location.pathname.split("/");
    const brief_id = pathArray[pathArray.length - 1];
    if (selectedPidObject.id) {
      const pillarStrategy = await getPillarStrategy(
        selectedPidObject.id
      ).catch((e) => {
        console.error(e);
      });
      if (pillarStrategy && typeOfTerm !== "Ad Hoc") {
        // loop through pillar strategy for the pid. Check each item to find the corresponding pillar of the blog or sub-pillar
        for (const item of pillarStrategy) {
          // if pillar strategy has sub pillars, loop through to check if each item contains the contentTitle
          if (item.sub_pillars) {
            for (const sp of item.sub_pillars) {
              // if the sub-pillar topic matches contentTitle, set the pillar
              if (sp.topic === contentTitle) {
                setPillar(item.keyword);
                if (item.url) {
                  setPillarPublishedUrl(formatUrl(item.url));
                }
                if (sp.url) {
                  setSubPillarPublishedUrl(formatUrl(sp.url));
                }
                setLoading(false);
              } else {
                // if it is not found, loop through the blogs to check if the contentTitle matches any topics
                sp.blogs?.forEach((b) => {
                  if (b.topic === contentTitle) {
                    setSubPillar(sp.topic);
                    setPillar(item.keyword);
                    if (item.url) {
                      setPillarPublishedUrl(formatUrl(item.url));
                    }
                    if (b.url) {
                      setBlogPublishedUrl(formatUrl(b.url));
                    }
                    if (sp.url) {
                      setSubPillarPublishedUrl(formatUrl(sp.url));
                    }
                    setLoading(false);
                  }
                });
              }
            }
          }
          // check if a blog is not nested under a sub-pillar and set blog
          if (item.blogs) {
            for (const pillarBlog of item.blogs) {
              if (pillarBlog.topic === contentTitle) {
                setBlog(contentTitle);
                setPillar(item.keyword);
                // if pillar has a published url, format with https if needed and set it
                if (item.url) {
                  setPillarPublishedUrl(formatUrl(item.url));
                }
                // if blog has a published url, set the published url for the blog
                if (pillarBlog.url) {
                  setBlogPublishedUrl(formatUrl(pillarBlog.url));
                }
                setLoading(false);
              }
            }
          }
        }
      }
    } else {
      setLoading(false);
    }

    if (typeof phraseType === "string") {
      setTypeOfTerm(phraseType);
    } else {
      setTypeOfTerm("Ad Hoc");
    }
    if (!phrase || !phraseType) {
      const fetchedBrief = await getBriefStatus(
        parseInt(brief_id),
        selectedPidObject.id
      );
      phrase = fetchedBrief.h1;
      setTypeOfTerm(determineBriefType(fetchedBrief));
      setContentTitle(phrase);
      setTerm(phrase);
    } else if (typeof phrase === "string" && phrase.length) {
      setContentTitle(decodeURIComponent(phrase));
      setTerm(decodeURIComponent(phrase));
    }
  }, [selectedPidObject, subPillar, adHocTitle, blog, pillar]);

  useEffect(() => {
    const path = location.pathname;
    const urlParams = new URLSearchParams(location.search);
    const complete = urlParams.get("complete");

    let step = 1;
    if (complete === "true") {
      step = 4;
    } else if (path.includes("step-three")) {
      step = 3;
    } else if (path.includes("step-two")) {
      step = 2;
    } else if (path.includes("step-one")) {
      step = 1;
    }

    switch (step) {
      case 2:
        setIsStepOneActive(false);
        setIsStepTwoActive(true);
        setIsStepThreeActive(false);
        setIsStepOneComplete(true);
        setIsStepTwoComplete(false);
        setIsStepThreeComplete(false);
        break;
      case 3:
        setIsStepOneActive(false);
        setIsStepTwoActive(false);
        setIsStepThreeActive(true);
        setIsStepOneComplete(true);
        setIsStepTwoComplete(true);
        setIsStepThreeComplete(false);
        break;
      case 4:
        setIsStepOneActive(false);
        setIsStepTwoActive(false);
        setIsStepThreeActive(true);
        setIsStepOneComplete(true);
        setIsStepTwoComplete(true);
        setIsStepThreeComplete(true);
        break;
      case 1:
      default:
        setIsStepOneActive(true);
        setIsStepTwoActive(false);
        setIsStepThreeActive(false);
        setIsStepOneComplete(false);
        setIsStepTwoComplete(false);
        setIsStepThreeComplete(false);
        break;
    }
  }, [selectedPidObject, contentTitle, typeOfTerm]);

  const toggleStepThree = () => {
    if (isStepTwoComplete) {
      const stepThreeComplete = Boolean(isStepThreeComplete);
      setIsStepThreeComplete(!stepThreeComplete);
      const path = location.pathname;
      const pathArray = path.split("/");
      const brief_id = pathArray[pathArray.length - 1];
      const search = location.search;
      const queryParams = new URLSearchParams(search);
      if (stepThreeComplete) {
        queryParams.delete("complete");
        updateContentBriefStep(brief_id, 3);
        navigate(`${path}?${queryParams.toString()}`, { replace: true });
      } else if (isStepTwoComplete) {
        updateContentBriefStep(brief_id, 4);
        navigate(`${path}${search}&complete=true`, { replace: true });
      }
    }
  };

  const navigateToStep = (step: number) => {
    const path = location.pathname;
    const pathArray = path.split("/");
    const brief_id = pathArray[pathArray.length - 1];
    const search = location.search;
    // if not on the selected step navigate to selected step.
    if (step === 1 && !path.includes("step-one")) {
      const destination = `/content-briefs-workflow/step-one/${brief_id}${search}`;
      setIsStepOneActive(true);
      setIsStepTwoActive(false);
      setIsStepThreeActive(false);
      navigate(destination);
    } else if (step === 2 && !path.includes("step-two")) {
      const destination = `/content-briefs-workflow/step-two/${brief_id}${search}`;
      setIsStepOneActive(false);
      setIsStepTwoActive(true);
      setIsStepThreeActive(false);
      setIsStepOneComplete(true);
      navigate(destination);
    } else if (step === 3) {
      if (path.includes("step-three")) {
        toggleStepThree();
      } else {
        const destination = `/content-briefs-workflow/step-three/${brief_id}${search}`;
        setIsStepOneActive(false);
        setIsStepTwoActive(false);
        setIsStepThreeActive(true);
        setIsStepOneComplete(true);
        setIsStepTwoComplete(true);
        navigate(destination);
      }
    }
  };

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <div
          style={{
            padding: "10px 10px 20px 25px",
            borderBottom: "1px solid rgba(233, 235, 240, 1)",
            width: getUsableWidth(sideMenuOpen, isMobile),
            display: "flex",
            alignItems: "center",
            backgroundColor: "white",
            position: "fixed",
            zIndex: 2
          }}
        >
          <Row style={{ width: "100%" }}>
            <Col xl={5} lg={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderRight: showSaved
                    ? "1px solid rgba(233, 235, 240, 1)"
                    : "",
                  marginRight: showSaved ? 10 : 0
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center"
                  }}
                >
                  {pillar && (
                    <div
                      style={{
                        display: "flex",
                        paddingRight: "10px",
                        flexDirection: "column",
                        justifyContent: "center"
                      }}
                    >
                      <div
                        style={{
                          textTransform: "uppercase",
                          color: "#56627C",
                          fontSize: "14px"
                        }}
                      >
                        Pillar
                      </div>
                      <div style={{ color: "#465166", fontSize: "16px" }}>
                        {pillar}
                      </div>
                    </div>
                  )}
                  {pillar && subPillar && !blog && (
                    <>
                      <div>
                        <NavigationArrowRight
                          style={{ width: "24px", justifyContent: "center" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          paddingLeft: "10px"
                        }}
                      >
                        <div
                          style={{
                            textTransform: "uppercase",
                            color: "#56627C",
                            fontSize: "14px"
                          }}
                        >
                          Sub Pillar
                        </div>
                        <div
                          style={{
                            color: "#465166",
                            fontSize: "16px",
                            fontWeight: 520
                          }}
                        >
                          {subPillar}
                        </div>
                      </div>
                    </>
                  )}
                  {pillar && subPillar && blog && (
                    <>
                      <div>
                        <NavigationArrowRight
                          style={{ width: "24px", justifyContent: "center" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          paddingLeft: "10px"
                        }}
                      >
                        <div
                          style={{
                            textTransform: "uppercase",
                            color: "#56627C",
                            fontSize: "14px"
                          }}
                        >
                          Sub Pillar
                        </div>
                        <div
                          style={{
                            color: "#465166",
                            fontSize: "16px",
                            fontWeight: 520
                          }}
                        >
                          {subPillar}
                        </div>
                      </div>
                      <div>
                        <NavigationArrowRight
                          style={{ width: "24px", justifyContent: "center" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          paddingLeft: "10px"
                        }}
                      >
                        <div
                          style={{
                            textTransform: "uppercase",
                            color: "#56627C",
                            fontSize: "14px"
                          }}
                        >
                          Blog
                        </div>
                        <div
                          style={{
                            color: "#465166",
                            fontSize: "16px",
                            fontWeight: 520
                          }}
                        >
                          {blog}
                        </div>
                      </div>
                    </>
                  )}
                  {pillar && blog && !subPillar && (
                    <>
                      <div>
                        <NavigationArrowRight
                          style={{ width: "24px", justifyContent: "center" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          paddingLeft: "10px"
                        }}
                      >
                        <div
                          style={{
                            textTransform: "uppercase",
                            color: "#56627C",
                            fontSize: "14px"
                          }}
                        >
                          Blog
                        </div>
                        <div
                          style={{
                            color: "#465166",
                            fontSize: "16px",
                            fontWeight: 520
                          }}
                        >
                          {blog}
                        </div>
                      </div>
                    </>
                  )}
                  {adHocTitle && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingLeft: "10px"
                      }}
                    >
                      <div
                        style={{
                          textTransform: "uppercase",
                          color: "#56627C",
                          fontSize: "14px"
                        }}
                      >
                        Ad Hoc
                      </div>
                      <div
                        style={{
                          color: "#465166",
                          fontSize: "16px",
                          fontWeight: 520
                        }}
                      >
                        {contentTitle}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <>
                {showSaved && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: 10,
                      color: "#56627C",
                      fontSize: "16px"
                    }}
                  >
                    <div>Saved</div>
                    <FontAwesomeIcon
                      style={{ color: "#44AF69", paddingLeft: "10px" }}
                      icon={faCircleCheck}
                    />
                  </div>
                )}
              </>
            </Col>
            <Col xl={7} lg={12}>
              <div className="widthBasedFlex">
                {isStepOneComplete ? (
                  <>
                    <AiFillCheckCircle
                      style={{
                        color: "#008CCE",
                        fontSize: "32px",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        navigateToStep(1);
                      }}
                    />
                    <div
                      style={{
                        color: "#56627C",
                        fontSize: "16px",
                        margin: "5px",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        navigateToStep(1);
                      }}
                    >
                      Select
                    </div>
                  </>
                ) : (
                  <>
                    <PiNumberCircleOneThin
                      style={{
                        width: "35px",
                        height: "35px",
                        color: isStepOneActive ? "#008CCE" : "#56627C"
                      }}
                    />
                    <div
                      style={{
                        color: isStepOneActive ? "#008CCE" : "#56627C",
                        fontSize: "16px",
                        margin: "5px",
                        fontWeight: isStepOneActive ? 520 : 520
                      }}
                    >
                      Select
                    </div>
                  </>
                )}
                <AiOutlineLine style={{ margin: "10px" }} />
                {isStepTwoComplete ? (
                  <>
                    <AiFillCheckCircle
                      style={{
                        color: "#008CCE",
                        fontSize: "32px",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        navigateToStep(2);
                      }}
                    />
                    <div
                      style={{
                        color: "#56627C",
                        fontSize: "16px",
                        margin: "5px",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        navigateToStep(2);
                      }}
                    >
                      Organize
                    </div>
                  </>
                ) : (
                  <>
                    <PiNumberCircleTwoThin
                      style={{
                        width: "35px",
                        height: "35px",
                        color: isStepTwoActive ? "#008CCE" : "#56627C"
                      }}
                    />
                    <div
                      style={{
                        color: isStepTwoActive ? "#008CCE" : "#56627C",
                        fontSize: "16px",
                        margin: "5px",
                        fontWeight: isStepTwoActive ? 520 : 400
                      }}
                    >
                      Organize
                    </div>
                  </>
                )}
                <AiOutlineLine style={{ margin: "10px" }} />
                {isStepThreeComplete ? (
                  <>
                    <AiFillCheckCircle
                      style={{
                        color: "#008CCE",
                        fontSize: "32px",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        navigateToStep(3);
                      }}
                    />
                    <div
                      style={{
                        color: "#56627",
                        fontSize: "16px",
                        margin: "5px",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        navigateToStep(3);
                      }}
                    >
                      Review
                    </div>
                  </>
                ) : (
                  <>
                    <PiNumberCircleThreeThin
                      style={{
                        width: "35px",
                        height: "35px",
                        color: isStepThreeActive ? "#008CCE" : "#56627C",
                        cursor: isStepTwoComplete ? "pointer" : "default"
                      }}
                      onClick={() => {
                        if (isStepTwoActive) {
                          navigateToStep(3);
                        } else if (isStepTwoComplete) {
                          toggleStepThree();
                        }
                      }}
                    />
                    <div
                      style={{
                        color: isStepThreeActive ? "#008CCE" : "#56627C",
                        fontSize: "16px",
                        margin: "5px",
                        fontWeight: isStepThreeActive ? 520 : 400,
                        cursor: isStepTwoComplete ? "pointer" : "default"
                      }}
                      onClick={() => {
                        if (isStepTwoActive) {
                          navigateToStep(3);
                        } else if (isStepTwoComplete) {
                          toggleStepThree();
                        }
                      }}
                    >
                      Review
                    </div>
                  </>
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "right",
                    width: "165px",
                    justifyContent: "right"
                  }}
                >
                  {(isStepOneActive || isStepTwoActive) && (
                    <Button
                      variant="primary"
                      className="save-button"
                      disabled={loading}
                      onClick={handleSubmit}
                      style={{ marginRight: "40px" }}
                    >
                      Next
                      <FontAwesomeIcon
                        style={{ color: "white", paddingLeft: "10px" }}
                        icon={faArrowRight}
                      />
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default BreadcrumbsHeader;
