/* eslint-disable arrow-body-style */
/* eslint-disable indent */
/* eslint-disable max-lines */
import { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Row, Col, Container, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsUpDown } from "@fortawesome/free-solid-svg-icons";
import BriefRow from "./BriefRow";
import { getPaginatedBriefs } from "../../../../api/get";
import { updateUnfinishedBriefs } from "@/api/post";
import SkeletonRow from "./components/SkeletonRow";
import InfinityScrollingLoading from "./components/InfinityScrollingLoading";
import { useOutOfBounds } from "../../../../hooks/outOfBounds";
import { useAppStore } from "../../../../hooks";
import { IAppStore } from "../../../../interfaces/app-store.interface";
import { IBrief } from "../../../../interfaces/brief.interface";
import "./BriefRow.scss";

const BriefTable = ({
  switchTable,
  briefs,
  setBriefs
}: {
  switchTable: () => void;
  briefs: IBrief[];
  setBriefs: (b: IBrief[]) => void;
}) => {
  const [loading, setLoading] = useState(true);
  const [getDataAgain, setGetDataAgain] = useState(false);
  const [loadingAdditional, setLoadingAdditional] = useState(false);
  const [componentRef, outOfBounds] = useOutOfBounds(true, true, false, false);
  const [unfinishedBriefs, setUnfinishedBriefs] = useState([] as number[]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  type BriefKeys = "created_at" | "type" | "state" | "h1" | "user" | "status";
  const [sortProp, setSortProp] = useState("created_at" as BriefKeys);
  type SortDirection = "DESC" | "ASC";
  const [sortDirection, setSortDirection] = useState("DESC" as SortDirection);
  const workingStatuses = ["pending", "working"];
  const infoText = {
    fontFamily: "Source Sans Pro, sans-serif",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.005em",
    color: "#373F50",
    margin: "0px 5px 35px 0px"
  };

  const { selectedPidObject } = useAppStore((store: IAppStore) => ({
    selectedPidObject: store.selectedPidObject
  }));
  useEffect(() => {
    const checkUnfinished = setTimeout(async () => {
      if (unfinishedBriefs.length) {
        const localBriefIds: number[] = unfinishedBriefs;
        try {
          const response = await updateUnfinishedBriefs(
            selectedPidObject.id,
            unfinishedBriefs
          );
          const updatedBriefs = response.data;
          setBriefs(
            briefs.map((brief: IBrief) => {
              if (localBriefIds.includes(brief.id)) {
                const foundUpdatedBrief = updatedBriefs.find(
                  (uBrief: IBrief) => uBrief.id === brief.id
                );
                if (foundUpdatedBrief) {
                  return foundUpdatedBrief;
                }
              }
              return brief;
            })
          );
          const stillUnfinished = updatedBriefs
            .filter((uBrief: IBrief) => workingStatuses.includes(uBrief.status))
            .map((uBrief: IBrief) => uBrief.id);
          setUnfinishedBriefs(stillUnfinished);
        } catch (error) {
          console.error(error);
        }
      }
    }, 10000);
    return () => clearTimeout(checkUnfinished);
  }, [unfinishedBriefs]);

  const getMoreBriefs = async (pid: number) => {
    if (loading) {
      return;
    }
    setLoadingAdditional(true);
    const currentPage = page + 1;
    setPage(currentPage);
    let pb = await getPaginatedBriefs(
      pid,
      currentPage,
      20,
      sortProp,
      sortDirection
    );
    if (pb !== null && pb.length < 10) {
      setHasMore(false);
    } else if (pb === null) {
      setHasMore(false);
      pb = [];
    }
    const newBriefArray = briefs.concat(pb);
    setBriefs(newBriefArray);
    setLoadingAdditional(false);
  };

  const checkViewport = (pid: number) => {
    const oob = outOfBounds as {
      top: number;
      bottom: number;
      left: number;
      right: number;
    };
    if (
      !loading &&
      !loadingAdditional &&
      !search &&
      hasMore &&
      oob.bottom === 0 &&
      oob.top === 0
    ) {
      getMoreBriefs(pid);
    }
  };

  const archiveBrief = (briefId: number) => {
    const br = briefs;
    br.forEach((i, index) => {
      if (i.id === briefId) {
        br.splice(index, 1);
      }
    });
    setBriefs([...br]);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value.toLowerCase());
    checkViewport(selectedPidObject.id);
  };

  const sortFunction = (key: BriefKeys) => {
    const current_key = sortProp;
    if (current_key === key) {
      // eslint-disable-next-line no-unused-expressions
      sortDirection === "DESC"
        ? setSortDirection("ASC")
        : setSortDirection("DESC");
      setGetDataAgain(true);
    } else {
      setSortProp(key);
      setSortDirection("DESC");
      setGetDataAgain(true);
    }
  };
  useEffect(() => {
    let getFirstPage = false;
    const fetchData = async () => {
      if (getFirstPage || !briefs.length) {
        getFirstPage = false;
        let briefsRows = [] as IBrief[];
        if (briefsRows.length === 0) {
          briefsRows = await getPaginatedBriefs(
            selectedPidObject.id,
            1,
            20,
            sortProp,
            sortDirection
          );
        } else {
          setPage(Math.ceil(briefsRows.length / 10));
        }
        if (briefsRows !== null && briefsRows.length < 10) {
          setHasMore(false);
          setLoadingAdditional(false);
        } else if (briefsRows === null) {
          setHasMore(false);
          briefsRows = [];
        }
        setBriefs(briefsRows);
        setLoading(false);
      }
    };
    if (selectedPidObject?.id) {
      if (briefs.length) {
        // eslint-disable-next-line no-negated-condition
        if (Number(briefs[0].pid) !== Number(selectedPidObject.id)) {
          setBriefs([]);
          getFirstPage = true;
          setPage(1);
          setHasMore(true);
          setLoading(true);
          fetchData();
        }
        if (getDataAgain) {
          setBriefs([]);
          getFirstPage = true;
          setPage(1);
          setHasMore(true);
          setLoading(true);
          fetchData();
        }
        setGetDataAgain(false);
        setLoading(false);
      } else {
        fetchData();
      }
    }
    checkViewport(selectedPidObject.id);
  }, [outOfBounds, selectedPidObject.id, briefs, sortProp, sortDirection]);

  // this will run on page load when the briefs get set by the above useEffect.
  // this will run when the length of the briefs array changes due to an ad-hoc brief being added.
  // this will run when additional briefs (page 2, 3, etc) are fetched and added to the briefs array.a
  useEffect(() => {
    const unfinished = briefs
      .filter((uBrief: IBrief) => workingStatuses.includes(uBrief.status))
      .map((uBrief: IBrief) => uBrief.id);
    setUnfinishedBriefs(unfinished);
  }, [briefs]);
  const handleMouseEnter = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    (e.target as HTMLButtonElement).style.color = "white";
    (e.target as HTMLButtonElement).style.background = "#008CCE";
  };

  const handleMouseLeave = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    (e.target as HTMLButtonElement).style.color = "#008CCE";
    (e.target as HTMLButtonElement).style.background = "white";
  };
  return (
    <div
      ref={componentRef as React.RefObject<HTMLDivElement>}
      style={{ paddingTop: "0" }}
    >
      <Container style={{ margin: 0 }}>
        <Row>
          <Col sm={6}>
            <input
              id="search"
              type="text"
              className={"form-control"}
              placeholder="You can search by Title, Status, and Type"
              onChange={handleSearch}
            ></input>
          </Col>
          <Col sm={6}>
            <button
              className="btn btn-outline-primary"
              onClick={switchTable}
              style={{
                color: "#008CCE",
                border: "1px solid #008CCE"
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Show Archived Briefs
            </button>
          </Col>
        </Row>
      </Container>
      <br></br>
      <div style={infoText}>
        Ready to write content that ranks? Select a Content Brief below for
        recommended keywords and questions to include in your article to align
        with the most important information your target customers are interested
        in.
      </div>
      <InfiniteScroll
        dataLength={briefs.length}
        next={() => getMoreBriefs(selectedPidObject.id)}
        hasMore={hasMore}
        loader={
          <InfinityScrollingLoading
            parentLoading={!loadingAdditional}
          ></InfinityScrollingLoading>
        }
        scrollableTarget="scrollableArea"
        style={{ overflow: "visible" }}
      >
        <Table id="briefTable" className="custom-table">
          <thead>
            <tr>
              <th>
                <span
                  onClick={() => sortFunction("h1")}
                  style={{ width: "35%", cursor: "pointer" }}
                >
                  Title <FontAwesomeIcon icon={faArrowsUpDown} />
                </span>
              </th>
              <th>
                <span
                  style={{ width: "15%", cursor: "pointer" }}
                  onClick={() => sortFunction("state")}
                >
                  Status <FontAwesomeIcon icon={faArrowsUpDown} />
                </span>
              </th>
              <th>
                <span
                  style={{ width: "15%", cursor: "pointer" }}
                  onClick={() => sortFunction("type")}
                >
                  Type <FontAwesomeIcon icon={faArrowsUpDown} />
                </span>
              </th>
              <th>
                <span
                  style={{ width: "15%", cursor: "pointer" }}
                  onClick={() => sortFunction("status")}
                >
                  Progress <FontAwesomeIcon icon={faArrowsUpDown} />
                </span>
              </th>
              <th>
                <span
                  style={{ width: "20%", cursor: "pointer" }}
                  onClick={() => sortFunction("created_at")}
                >
                  Date <FontAwesomeIcon icon={faArrowsUpDown} />
                </span>
              </th>
            </tr>
          </thead>
          <tbody style={{ overflow: "visible" }}>
            {loading &&
              [...Array(10)].map((_elementInArray, index) => (
                <SkeletonRow key={index} columns={5}></SkeletonRow>
              ))}
            {!loading &&
              briefs
                .filter(
                  (item) =>
                    item.h1.toLowerCase().includes(search) ||
                    item.type.toLowerCase().includes(search) ||
                    item.state.toLowerCase().includes(search)
                )
                .sort((a, b) => {
                  return sortDirection === "ASC"
                    ? (a[sortProp]?.toString() || "").localeCompare(
                        b[sortProp]?.toString() || ""
                      )
                    : (b[sortProp]?.toString() || "").localeCompare(
                        a[sortProp]?.toString() || ""
                      );
                })
                .map((brief) => (
                  <BriefRow
                    key={`${brief.id}`}
                    brief={brief}
                    percentage={brief.percent_complete}
                    status={brief.status}
                    archiveBrief={archiveBrief}
                    archiveTable={false}
                    pid={selectedPidObject.id}
                    briefs={briefs}
                    setBriefs={setBriefs}
                  ></BriefRow>
                ))}
          </tbody>
        </Table>
      </InfiniteScroll>
    </div>
  );
};

export default BriefTable;
