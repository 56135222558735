/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import { useState } from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses
} from "@mui/material/LinearProgress";
import ConfirmDeleteInsightModal from "./ConfirmDeleteInsightModal";
import {
  IconComplete,
  IconError,
  ActionRefresh2,
  ActionTrash
} from "@/assets/icons/svgr";
import { PointedTooltip } from "../../shared/PointedTooltip";
import { refreshInsight } from "../../../api/post";
import {
  IErrorInsightArray,
  IInsightMessage,
  IInsights,
  IInsightsStore,
  IPendingInsight
} from "../../../interfaces/insights.interface";
import useInsightsStore from "../../../hooks/useInsightsStore";
import { useAppStore } from "../../../hooks";
import { IAppStore } from "@/interfaces/app-store.interface";

export interface StatusProps {
  status: string;
  percentComplete: number;
  daysSince: number;
  insight?: IInsights;
  erroredInsight?: IErrorInsightArray;
}

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  width: "75%",

  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E9EBF0"
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#008CCE"
  }
}));
const errorStatuses = [
  "ERROR-RES",
  "ERROR-ETL",
  "ERROR-PRE",
  "ERROR-STALE",
  "ERROR-DUP",
  "ERROR-ERR"
];

export default function Status({
  status,
  insight,
  percentComplete,
  daysSince
}: StatusProps) {
  const [showDeleteModal, setShowDeleteModal] = useState<undefined | boolean>();
  const {
    insights,
    pendingInsights,
    unfinishedInsights,
    setInsights,
    setPendingInsights,
    setUnfinishedInsights
  } = useInsightsStore((store: IInsightsStore) => ({
    insights: store.insights,
    pendingInsights: store.pendingInsights,
    unfinishedInsights: store.unfinishedInsights,
    setInsights: (value: IInsights[]) => store.setInsights(value),
    setPendingInsights: (value: IPendingInsight[]) =>
      store.setPendingInsights(value),
    setUnfinishedInsights: (value: number[]) =>
      store.setUnfinishedInsights(value),
    setInsightMessage: (value: IInsightMessage) =>
      store.setInsightMessage(value)
  }));

  const { user, setShowAlertBanner } = useAppStore((store: IAppStore) => ({
    user: store.user,
    setShowAlertBanner: store.setShowAlertBanner
  }));

  const handleRefreshInsight = async (insightRefresh: IInsights) => {
    if (insight) {
      if (insightRefresh?.id && insight.status === "complete") {
        setShowAlertBanner(true, {
          message: "Refreshing Insight",
          severity: "success"
        });
        setPendingInsights([
          {
            id: insightRefresh.id,
            query_string: insightRefresh.query_string,
            created_at: Date(),
            status: "pending"
          },
          ...pendingInsights
        ]);
        await refreshInsight(insightRefresh.id)
          .then((resp) => {
            const newInsight = resp.data?.result;
            setUnfinishedInsights([...unfinishedInsights, newInsight.cbm_id]);
            setPendingInsights(
              pendingInsights.filter(
                (pInsight: IInsights) => pInsight.id !== insightRefresh.id
              )
            );
            setInsights([
              {
                id: newInsight.cbm_id,
                query_string: newInsight.query,
                language: newInsight.language,
                location_name: newInsight.location_name,
                location_id: newInsight.location_id,
                pid: newInsight.pid,
                created_at: Date(),
                status: "pending"
              },
              ...insights.filter(
                (i: IInsights) => i.query_string !== newInsight.query
              )
            ]);
          })
          .catch((error) => {
            console.error(error);
            setPendingInsights(
              pendingInsights.filter(
                (pInsight: IInsights) => pInsight.id !== insightRefresh.id
              )
            );
            setShowAlertBanner(true, {
              message:
                "Unable to refresh insight report, please try again later",
              severity: "error"
            });
          });
      }
    }
  };
  return (
    <div>
      {showDeleteModal && insight && (
        <ConfirmDeleteInsightModal
          insightId={insight.id}
          setShowDeleteModal={setShowDeleteModal}
        />
      )}
      {status === "complete" && (
        <div
          style={{
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: "200px"
          }}
        >
          <IconComplete style={{ color: "#44AF69", width: "18px" }} />
          <PointedTooltip
            sx={{
              maxWidth: "160px",
              fontFamily: "'Roboto', sans-serif",
              fontSize: "10px",
              textAlign: "justify"
            }}
            title={
              daysSince > 30
                ? "After 30 days we suggest re-running your consumer insights to have the most up-to-date data."
                : "Click this button to refresh your insight!"
            }
          >
            <button
              style={{ backgroundColor: "white", border: "none" }}
              onClick={() => insight && handleRefreshInsight(insight)}
            >
              <ActionRefresh2
                style={{
                  width: "18px",
                  color: daysSince > 30 ? "red" : "#373F50"
                }}
              />
            </button>
          </PointedTooltip>
          {user?.dj_employee && (
            <button
              style={{ backgroundColor: "white", border: "none" }}
              onClick={() => insight?.id && setShowDeleteModal(true)}
            >
              <ActionTrash
                data-testid="delete-icon"
                style={{ color: "#373F50", width: "18px" }}
              />
            </button>
          )}
        </div>
      )}
      {status === "working" && (
        <div
          style={{
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: "200px"
          }}
        >
          <BorderLinearProgress variant="determinate" value={percentComplete} />
          <div>{percentComplete}%</div>
        </div>
      )}
      {status === "pending" && (
        <div
          style={{
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: "200px"
          }}
        >
          <BorderLinearProgress variant="determinate" value={0} />
          <div>{0}%</div>
        </div>
      )}

      {errorStatuses.includes(status) && (
        <div
          style={{
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: "200px"
          }}
        >
          <PointedTooltip
            sx={{
              maxWidth: "160px",
              fontFamily: "'Roboto', sans-serif",
              fontSize: "10px",
              textAlign: "justify"
            }}
            // eslint-disable-next-line no-nested-ternary
            title={
              status === "ERROR-PRE"
                ? "No results for this phrase. Please try another search term around the same topic."
                : status === "ERROR-ETL"
                ? "Hang tight, our team is working to correct the error for generating this insight report."
                : status === "ERROR-DUP"
                ? "You have already run this phrase, please wait at least 1 hour before running the same phrase again."
                : "Oops, something went wrong, please re-run this phrase."
            }
          >
            <div style={{ width: "fit-content" }}>
              <IconError style={{ color: "#B00020", width: "18px" }} />
            </div>
          </PointedTooltip>
          {user?.dj_employee && (
            <button
              style={{ backgroundColor: "white", border: "none" }}
              onClick={() => insight?.id && setShowDeleteModal(true)}
            >
              <ActionTrash
                data-testid="delete-icon"
                style={{ color: "#373F50", width: "18px" }}
              />
            </button>
          )}
        </div>
      )}
    </div>
  );
}
