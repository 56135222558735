/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useAppStore, usePillarStrategyStore } from "@/hooks";
import { IPillarStrategyStore } from "@/interfaces/pillar-strategy-store.interface";
import {
  IBlogs,
  IContentPillars,
  ISubPillars
} from "@/interfaces/content-pillars.interface";
import {
  changePillarStrategyRowParentType,
  putBlog,
  putContentUrl
} from "@/api/put";
import { IAppStore } from "@/interfaces/app-store.interface";
import { Spinner } from "react-bootstrap";
interface EditRowProps {
  setEdit: (value: boolean) => void;
  handleCancel: () => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  originalType: string;
  parentType: string;
  parentPillar: IContentPillars;
  parentSubPillar?: ISubPillars;
  url: string;
  originalBlog?: IBlogs;
  originalSubPillar?: ISubPillars;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectOptionType = { label: string; value: any };

function EditRow({
  setEdit,
  parentType,
  originalType,
  handleCancel,
  handleChange,
  url,
  parentPillar,
  parentSubPillar,
  originalBlog,
  originalSubPillar
}: EditRowProps) {
  const [parentOptions, setParentOptions] = useState<
    { value: any; label: string }[]
  >([]);
  const [selectedParent, setSelectedParent] = useState<SelectOptionType | null>(
    null
  );
  const [selectedType, setSelectedType] = useState<SelectOptionType | null>(
    null
  );
  const [requestPending, setRequestPending] = useState(false);
  const [blog, setBlog] = useState<IBlogs>({
    id: null,
    topic: "",
    pillar_id: 0,
    sub_pillar_id: 0,
    url: "",
    outline_status: null
  });
  const [subPillar, setSubPillar] = useState<ISubPillars>({
    id: null,
    topic: "",
    pillar_id: 0,
    url: "",
    outline_status: null
  });

  const { setShowAlertBanner, selectedPidObject } = useAppStore(
    (store: IAppStore) => ({
      setShowAlertBanner: store.setShowAlertBanner,
      selectedPidObject: store.selectedPidObject
    })
  );

  const { selectedPidPillarTopics, setSelectedPidPillarTopics } =
    usePillarStrategyStore((store: IPillarStrategyStore) => ({
      selectedPidPillarTopics: store.selectedPidPillarTopics,
      setSelectedPidPillarTopics: (value: IContentPillars[]) =>
        store.setSelectedPidPillarTopics(value)
    }));

  useEffect(() => {
    if (parentPillar.sub_pillars) {
      // these will be the options for selecting a new parent
      // with the original parent filtered out.
      setParentOptions([
        {
          value: parentPillar.id,
          label: `${parentPillar.keyword} (Main Pillar)`,
          ...parentPillar
        },
        ...parentPillar.sub_pillars
          .sort((a, b) => {
            if (a.id && b.id) {
              return b.id - a.id;
            }
            return 0;
          })
          .map((sp) => ({
            value: sp.id,
            label: `${sp.topic} (Sub Pillar)`,
            ...sp
          }))
          .filter(
            (sp) =>
              sp.id !== parentSubPillar?.id && sp.id !== originalSubPillar?.id
          )
      ]);
    } else {
      setParentOptions([
        {
          value: parentPillar.id,
          label: `${parentPillar.keyword} (Main Pillar)`,
          ...parentPillar
        }
      ]);
    }
  }, [parentPillar]);

  useEffect(() => {
    if (originalType === "blog" && originalBlog) {
      setBlog({ ...originalBlog });
    } else if (originalType === "subPillar" && originalSubPillar) {
      setSubPillar({ ...originalSubPillar });
    }
  }, [originalType, originalBlog, originalSubPillar]);

  const handleSelect = (newValue: SelectOptionType | null) => {
    if (newValue) {
      setSelectedParent(newValue);
      if (newValue.label.includes("Sub Pillar")) {
        setSelectedType({ value: "blog", label: "Blog" });
      }
    }
  };
  const handleSelectType = (newValue: SelectOptionType | null) => {
    if (newValue) {
      setSelectedType(newValue);
    }
  };

  const handlePutRow = async () => {
    setRequestPending(true);
    if (selectedParent) {
      if (selectedType) {
        if (selectedType.value === originalType) {
          if (originalType === "blog") {
            const body: IBlogs = {
              id: blog.id,
              topic: blog.topic,
              pillar_id: parentPillar.id || 0,
              sub_pillar_id: selectedParent.label.includes("Sub Pillar")
                ? selectedParent.value
                : null,
              url: blog.url,
              outline_status: blog.outline_status
            };
            await putBlog(body)
              .then((res) => {
                if (res) {
                  setSelectedPidPillarTopics(
                    selectedPidPillarTopics.map((p: IContentPillars) => {
                      if (p.id === parentPillar.id) {
                        // update current pillar with modified blog
                        if (res.sub_pillar_id) {
                          // if blog is now a sub pillar, add it to the sub pillar list and remove from old list
                          return {
                            ...p,
                            sub_pillars: p.sub_pillars
                              ? p.sub_pillars.map((sp: ISubPillars) => {
                                  if (sp.id === res.sub_pillar_id) {
                                    return {
                                      ...sp,
                                      blogs: sp.blogs
                                        ? [...sp.blogs, res]
                                        : [res]
                                    };
                                  } else if (sp.id === blog.sub_pillar_id) {
                                    return {
                                      ...sp,
                                      blogs: sp.blogs
                                        ? sp.blogs.filter(
                                            (b: IBlogs) => b.id !== blog.id
                                          )
                                        : []
                                    };
                                  }
                                  return sp;
                                })
                              : [],
                            // remove blog from pillar list if it was originally a pillar blog
                            blogs: p.blogs
                              ? p.blogs.filter((b: IBlogs) => b.id !== blog.id)
                              : []
                          };
                        }
                        // if blog is no longer a sub pillar, remove it from the sub pillar list and add to blogs
                        return {
                          ...p,
                          sub_pillars: p.sub_pillars
                            ? p.sub_pillars.map((sp: ISubPillars) => {
                                if (sp.id === blog.sub_pillar_id) {
                                  return {
                                    ...sp,
                                    blogs: sp.blogs
                                      ? sp.blogs.filter(
                                          (b: IBlogs) => b.id !== blog.id
                                        )
                                      : []
                                  };
                                }
                                return sp;
                              })
                            : [],
                          blogs: p.blogs ? [...p.blogs, res] : [res]
                        };
                      }
                      return p;
                    })
                  );
                  setEdit(false);
                }
              })
              .catch((e) => {
                console.error(e);
                setShowAlertBanner(true, {
                  message: "Error updating blog",
                  severity: "error"
                });
              });
          }
          // since sub pillar can only have pillar as parent, no need to check for sub pillar
        } else if (originalType === "blog") {
          try {
            // change blog to sub pillar
            const response = await changePillarStrategyRowParentType(
              {
                originalType,
                newType: selectedType.value,
                originalBlog: blog
              },
              selectedPidObject.id
            );
            setSelectedPidPillarTopics(
              selectedPidPillarTopics.map((p: IContentPillars) => {
                if (p.id === parentPillar.id) {
                  // Check if new type is sub pillar and add to sub pillar list
                  if (selectedType.value === "subPillar") {
                    return {
                      ...p,
                      // If sub pillar list exists, add to it, otherwise create it
                      // additionally if blog was originally a child of sub pillar, remove it from that list
                      // eslint-disable-next-line no-nested-ternary
                      sub_pillars: p.sub_pillars
                        ? blog.sub_pillar_id
                          ? [
                              ...p.sub_pillars.map((sp) => {
                                if (sp.id === blog.sub_pillar_id) {
                                  return {
                                    ...sp,
                                    blogs: sp.blogs
                                      ? sp.blogs.filter(
                                          (b: IBlogs) => b.id !== blog.id
                                        )
                                      : []
                                  };
                                }
                                return sp;
                              }),
                              response.sub_pillar
                            ]
                          : [...p.sub_pillars, response.sub_pillar]
                        : [response.sub_pillar],
                      // If blog was originally a child of pillar, remove it from that list
                      blogs: p.blogs
                        ? p.blogs.filter((b: IBlogs) => b.id !== blog.id)
                        : []
                    };
                  }
                  return p;
                }
                return p;
              })
            );
          } catch (error) {
            console.error(error);
            setShowAlertBanner(true, {
              message: "Error changing blog to sub pillar",
              severity: "error"
            });
          }
        } else {
          // Changing sub pillar to blog
          try {
            const response = await changePillarStrategyRowParentType(
              {
                originalType,
                newType: selectedType.value,
                originalSubPillar: subPillar,
                subPillarId: selectedParent.label.includes("Sub Pillar")
                  ? selectedParent.value
                  : null
              },
              selectedPidObject.id
            );
            setSelectedPidPillarTopics(
              selectedPidPillarTopics.map((p: IContentPillars) => {
                if (p.id === parentPillar.id) {
                  // Check if new type is blog and add to correct pillar or sub pillar
                  if (selectedType.value === "blog" && response.blog) {
                    const newBlog = response.blog;
                    const blogsList = p.blogs ? p.blogs : [];
                    // when you convert a subpillar to a blog under another sub-pillar, it should have the new sub_pillar_id
                    return {
                      ...p,
                      // If blog has no sub pillar id, add to pillar list, otherwise add to sub pillar list
                      blogs: newBlog.sub_pillar_id
                        ? blogsList
                        : [...blogsList, newBlog],
                      sub_pillars: p.sub_pillars
                        ? p.sub_pillars
                            .map((sp: ISubPillars) => {
                              if (sp.id === newBlog.sub_pillar_id) {
                                return {
                                  ...sp,
                                  blogs: sp.blogs
                                    ? [...sp.blogs, newBlog]
                                    : [newBlog]
                                };
                              }
                              return sp;
                            })
                            .filter((sp) => sp.id !== originalSubPillar?.id)
                        : []
                    };
                  }
                  // shouldn't hit this because
                  return p;
                }
                return p;
              })
            );
            setEdit(false);
          } catch (error) {
            console.error(error);
            setShowAlertBanner(true, {
              message: "Error changing sub pillar to blog.",
              severity: "error"
            });
          }
        }
      } else {
        setShowAlertBanner(true, {
          message: "Please select a type",
          severity: "error"
        });
      }
    } else {
      // Essentially just changing url in this case.
      try {
        const id = originalType === "blog" ? blog.id : subPillar.id;
        const type = originalType === "blog" ? "blog" : "subPillar";
        if (id) {
          await putContentUrl({ url, type, id }, selectedPidObject.id);
          setSelectedPidPillarTopics(
            selectedPidPillarTopics.map((p: IContentPillars) => {
              if (p.id === parentPillar.id) {
                // Check if new type is blog and add to correct pillar or sub pillar
                if (originalType === "blog" && blog.sub_pillar_id) {
                  return {
                    ...p,
                    sub_pillars: p.sub_pillars
                      ? p.sub_pillars.map((sp: ISubPillars) => {
                          if (sp.id === blog.sub_pillar_id) {
                            return {
                              ...sp,
                              blogs: sp.blogs
                                ? sp.blogs.map((b: IBlogs) => {
                                    if (b.id === blog.id) {
                                      return {
                                        ...b,
                                        url
                                      };
                                    }
                                    return b;
                                  })
                                : []
                            };
                          }
                          return sp;
                        })
                      : []
                  };
                } else if (originalType === "blog" && !blog.sub_pillar_id) {
                  return {
                    ...p,
                    blogs: p.blogs
                      ? p.blogs.map((b: IBlogs) => {
                          if (b.id === blog.id) {
                            return {
                              ...b,
                              url
                            };
                          }
                          return b;
                        })
                      : []
                  };
                } else if (originalType === "subPillar") {
                  return {
                    ...p,
                    sub_pillars: p.sub_pillars
                      ? p.sub_pillars.map((sp: ISubPillars) => {
                          if (sp.id === subPillar.id) {
                            return {
                              ...sp,
                              url
                            };
                          }
                          return sp;
                        })
                      : []
                  };
                }
              }
              return p;
            })
          );
          setShowAlertBanner(true, {
            message: "Url saved successfully",
            severity: "success"
          });
          setEdit(false);
        }
      } catch (e) {
        console.error(e);
        setShowAlertBanner(true, {
          message: "Error saving url",
          severity: "error"
        });
      }
    }
    setRequestPending(false);
  };
  return (
    <>
      <th
        data-testid="edit-row"
        style={{
          paddingLeft:
            originalType === "blog"
              ? parentType === "subPillar"
                ? "83px"
                : "66px"
              : "22px"
        }}
        className="fw-400 d-flex align-items-center justify-content-start"
      >
        <div style={{ maxWidth: "50%" }}>
          {originalType === "blog" ? blog.topic : subPillar.topic}
        </div>
        <div style={{ maxWidth: "50%", paddingLeft: "10px" }}>
          <Select
            id="parent-select"
            name="selectedParent"
            options={parentOptions}
            placeholder="Select New Parent"
            onChange={handleSelect}
            value={selectedParent}
            classNamePrefix="pillar-strategy-select"
          />
        </div>
      </th>
      <th>
        <div className="fw-400">
          <Select
            id="type-select"
            name=""
            options={[
              { value: "subPillar", label: "Sub-Pillar" },
              { value: "blog", label: "Blog" }
            ]}
            defaultValue={{ value: "blog", label: "Blog" }}
            isDisabled={
              !selectedParent || selectedParent.label.includes("Sub Pillar")
            }
            onChange={handleSelectType}
            value={selectedType}
            classNamePrefix="pillar-strategy-select"
          />
        </div>
      </th>
      <th></th>
      <th>
        <input
          type="text"
          name="url"
          id="url"
          placeholder="URL"
          value={url}
          onChange={handleChange}
          style={{ width: "220px" }}
        />
        <button
          style={{
            backgroundColor: "inherit",
            border: "none",
            padding: "0px"
          }}
        >
          {requestPending ? (
            <span
              style={{
                color: "#373f50",
                width: "20px",
                height: "20px",
                margin: "0px 5px",
                paddingLeft: "5px"
              }}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </span>
          ) : (
            <span
              onClick={() => {
                handlePutRow();
              }}
              style={{ padding: "0px 5px" }}
            >
              <FontAwesomeIcon
                className="icon-button"
                icon={faCheck}
                style={{ width: "24px" }}
              />
            </span>
          )}
        </button>
        <button
          style={{
            backgroundColor: "inherit",
            border: "none",
            padding: "0px"
          }}
          disabled={requestPending}
        >
          <span
            onClick={() => {
              handleCancel();
            }}
            style={{ padding: "5px" }}
          >
            <FontAwesomeIcon
              className="icon-button"
              icon={faX}
              style={{ width: "24px" }}
            />
          </span>
        </button>
      </th>
    </>
  );
}

export default EditRow;
