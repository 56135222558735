// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dj-input {
  width: 100%;
  height: 100%;
  padding: 16px;
  border: 1px solid #373F50;
  border-radius: 3px;
}
.dj-input:focus {
  outline: 2px solid #008CCE;
}

.dj-input-error {
  border: 1px solid #B00020;
  color: hsl(349, 100%, 35%);
}
.dj-input-error:focus {
  outline: 2px solid #B00020;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/DjInput.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACE,YAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;AACJ;AAAI;EACE,0BAAA;AAEN;;AACA;EACE,yBAAA;EACA,0BAAA;AAEF;AADE;EACE,0BAAA;AAGJ","sourcesContent":[".dj-input {\n  width: 100%;\n    height: 100%;\n    padding: 16px;\n    border: 1px solid #373F50;\n    border-radius: 3px;\n    &:focus{\n      outline: 2px solid #008CCE;\n    }\n}\n.dj-input-error{\n  border: 1px solid #B00020;\n  color: hsl(349, 100%, 35%);\n  &:focus{\n    outline: 2px solid #B00020;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
