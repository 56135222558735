import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#008CCE"
    },
    secondary: {
      main: "#2ABBFF"
    },
    success: {
      main: "#44AF69"
    },
    error: {
      main: "#B00020"
    },
    info: {
      main: "#FFA400"
    },
    warning: {
      main: "#FE4A49"
    }
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(",")
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "Source Sans Pro"
        }
      }
    }
  }
});

export default theme;
