import { useState, useEffect } from "react";

import "../../App.css";
import "./components/manageProfile.scss";

import { useAppStore } from "../../hooks";

import DJLoadingGif from "../shared/DJLoadingGif";
import { IAppStore } from "../../interfaces/app-store.interface";
import ProfileUpdate from "./components/ProfileUpdate";
import UserProfilePanel from "./components/UserProfilePanel";
import { IUser } from "../../interfaces/user.interface";
import { Row, Col } from "react-bootstrap";
// import MfaPanel from "./components/MfaPanel";

function ManageProfile(): JSX.Element {
  const [loading, setLoading] = useState(true);
  const { selectedPidObject, user, setUser } = useAppStore(
    (store: IAppStore) => ({
      selectedPidObject: store.selectedPidObject,
      user: store.user,
      setUser: store.setUser
    })
  );
  const [updatedUser, setUpdatedUser] = useState(user);
  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      if (selectedPidObject.id) {
        setLoading(false);
      }
      setUpdatedUser(user);
    };
    fetchData();
  }, [selectedPidObject, updatedUser]);

  const setUserProfile = (newUser: IUser) => {
    setUpdatedUser(newUser);
    setUser(newUser);
  };

  return (
    <div>
      {loading ? (
        <DJLoadingGif />
      ) : (
        <Row style={{ marginLeft: "20px" }}>
          <Col xl={4}>
            <Row style={{ marginBottom: "10px" }}>
              <UserProfilePanel user={updatedUser} />
            </Row>

            {/* <Row>
              <MfaPanel />
            </Row> */}
          </Col>
          <Col xl={8}>
            <ProfileUpdate setUserProfile={setUserProfile} />
          </Col>
        </Row>
      )}
    </div>
  );
}

export default ManageProfile;
