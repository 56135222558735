import { Dispatch, SetStateAction, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { NavigationArrowBack } from "@/assets/icons/svgr";
import Button from "../../../shared/Button";
import { useAppStore, useEffectAsync } from "../../../../hooks";
import logout from "../../../../routines/logout";
import { getUserGroups } from "../../../../api/get";
import { IAppStore } from "../../../../interfaces/app-store.interface";
import { IUserGroup } from "../../../../interfaces/user.interface";
import UserIcon from "../../../shared/UserIcon";

const ProfileSettingsMenu = ({
  setProfileSettingsMenuOpen,
  setOpenItemsStack
}: {
  setOpenItemsStack?: Dispatch<SetStateAction<number[]>>;
  setProfileSettingsMenuOpen: Dispatch<React.SetStateAction<boolean>>;
}) => {
  const isMobile = useMediaQuery({ query: `(max-width:  1024px)` });
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);

  const { user, userGroups, selectedPidObject, setUserGroups } = useAppStore(
    (store: IAppStore) => ({
      user: store.user,
      userGroups: store.userGroups,
      selectedPidObject: store.selectedPidObject,
      setUserGroups: store.setUserGroups
    })
  );
  // Determine if user is admin of selected pid object. This function will run on load of the page,
  // on re-opening of the ProfileSettingsMenu, and when a different pid is selected.
  useEffectAsync(async () => {
    if (userGroups.length <= 0) {
      try {
        const response = await getUserGroups();
        setUserGroups(response);
        response.forEach((group: IUserGroup) => {
          if (group.pid === selectedPidObject.id && group.name === "Admin") {
            // user is admin of the selected Pid
            setIsAdmin(true);
          }
        });
      } catch (err) {
        console.error(err);
      }
    } else if (userGroups.length > 0) {
      userGroups.forEach((group: IUserGroup) => {
        if (group.pid === selectedPidObject.id && group.name === "Admin") {
          // user is admin of the selected Pid
          setIsAdmin(true);
        }
      });
    }
  }, [selectedPidObject]);

  return (
    <Card
      sx={{
        width: "327px",
        height: "189px",
        paddingTop: isMobile ? "8px" : "30px",
        paddingBotton: "30px"
      }}
    >
      <Grid item xs={12}>
        <ListItem
          disablePadding
          sx={{
            display: "block",
            "&:hover": {
              background: isMobile ? "#E1F5FF" : "#FFFFFF",
              color: "#008CCE"
            }
          }}
          onClick={() => setProfileSettingsMenuOpen(false)}
        >
          {isMobile ? (
            <ListItemButton
              disableGutters
              sx={{
                minHeight: 37,
                px: 2.5,
                height: "37px",
                justifyContent: "initial",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent"
                }
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "24px",
                  mr: "15px",
                  justifyContent: "center",
                  color: "#373F50"
                }}
              >
                <NavigationArrowBack style={{ width: "24px" }} />
              </ListItemIcon>
              <ListItemText
                primary="Profile Settings"
                primaryTypographyProps={{
                  fontFamily: "'Source Sans Pro'",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "20px",
                  letterSpacing: "0.005em",
                  color: "#008CCE"
                }}
              />
            </ListItemButton>
          ) : (
            <ListItemText
              sx={{ paddingX: "30px" }}
              primary="Profile Settings"
              primaryTypographyProps={{
                fontFamily: "'Source Sans Pro'",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "20px",
                letterSpacing: "0.005em",
                color: "#008CCE"
              }}
            />
          )}
        </ListItem>
      </Grid>
      <Grid container sx={{ paddingTop: "20px", paddingX: "30px" }}>
        <UserIcon />
        <Grid item xs={7} sx={{ paddingLeft: "15px" }}>
          <Box
            sx={{
              fontFamily: "'Source Sans Pro'",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "20px",
              letterSpacing: "0.005em",
              color: "#373F50",
              maxWidth: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            }}
          >
            {user.name}
          </Box>
          {isAdmin && (
            <Box
              sx={{
                fontFamily: "'Source Sans Pro'",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "15px",
                letterSpacing: "0.004em",
                color: "#56627C"
              }}
            >
              Admin
            </Box>
          )}
        </Grid>
        <Grid
          item
          sx={{
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "row",
            width: "100%",
            paddingTop: "20px"
          }}
        >
          <Button
            type="submit"
            variant="outlined"
            size="large"
            text="Manage Profile"
            sx={{
              width: "141px",
              height: "32px",
              fontFamily: "'Source Sans Pro'",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "18px",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              letterSpacing: "0.0125em",
              textTransform: "capitalize",
              padding: 0
            }}
            onClick={() => {
              navigate("/manage-profile");
              setProfileSettingsMenuOpen(false);
              if (setOpenItemsStack) {
                setOpenItemsStack([]);
              }
            }}
          />
          <Button
            type="submit"
            variant="contained"
            size="large"
            text="Logout"
            sx={{
              width: "96px",
              height: "32px",
              fontFamily: "'Source Sans Pro'",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "18px",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              letterSpacing: "0.0125em",
              textTransform: "capitalize",
              padding: 0
            }}
            onClick={async () => {
              await logout().catch((err) => console.error(err));
              navigate("/login");
            }}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default ProfileSettingsMenu;
