// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-avatar {
  width: 42px;
  height: 42px;
  color: #373F50;
  border-radius: 50%;
}

.nav-user-avatar {
  width: 34px;
  height: 34px;
  color: #373F50;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/userIcon.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;AACF;;AACA;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;AAEF","sourcesContent":[".user-avatar{\n  width: 42px;\n  height: 42px;\n  color: #373F50;\n  border-radius: 50%\n}\n.nav-user-avatar{\n  width: 34px;\n  height: 34px;\n  color: #373F50;\n  border-radius: 50%\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
