import "../shared/marketingPages.scss";
import Header from "./components/Header";
import PillarSummary from "./components/PillarSummary";
import ContentRecommendations from "./components/ContentRecommendations";
import PillarRankings from "./components/PillarRankings";
import PillarTrends from "./components/PillarTrends";

function ManagePillarTopics() {
  return (
    <div>
      <div className="header-first-component-container gradient">
        <Header />
        <PillarSummary />
      </div>
      <ContentRecommendations />
      <PillarRankings background="gradient" />
      <PillarTrends />
    </div>
  );
}

export default ManagePillarTopics;
