import { Paper } from "@mui/material";
import GenerateInsight from "../../insights/components/GenerateInsight";
import { IErrorInsightArray } from "@/interfaces/insights.interface";

interface IProps {
  errorArr: IErrorInsightArray[];
  updateErrorArr: (errorItem: IErrorInsightArray[]) => void;
}

export default function GenerateInsightCard({
  updateErrorArr,
  errorArr
}: IProps) {
  return (
    <div id="generate-insights-card">
      <Paper elevation={0} id="paper-gray-border">
        <div className="title">Insight Reports</div>
        <div className="input-container">
          <GenerateInsight
            errorArr={errorArr}
            updateErrorArr={updateErrorArr}
          />
        </div>
        {/* <TextField variant="outlined" sx={{marginTop: "10px"}}/> */}
        <div className="sub-text">
          Enter the keyword or phrase you want to rank #1 for in SEO. The
          average runtime for reports is around 10-15 minutes.
        </div>
      </Paper>
    </div>
  );
}
