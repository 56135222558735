import { Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import DjUniCard from "./components/DjUniCard";
import GenerateInsightCard from "./components/GenerateInsightCard";
import HelpfulLinksCard from "./components/HelpfulLinksCard";
import RDContentCard from "./components/RDContentCard";
import TopicInsightsCard from "./components/TopicInsightsCard";
import WelcomeCard from "./components/WelcomeCard";
import "./home.scss";
import { IErrorInsightArray } from "@/interfaces/insights.interface";

export default function Home() {
  const [screenSize, setScreenSize] = useState("desktop");
  const [errorArr, setErrorArr] = useState<IErrorInsightArray[]>([]);
  const updateErrorArr = (errorItem: IErrorInsightArray[]): void => {
    setErrorArr(errorItem);
  };
  const updateScreen = () => {
    if (window.innerWidth >= 1500) {
      setScreenSize("desktop");
    } else if (window.innerWidth < 1500 && window.innerWidth >= 1280) {
      setScreenSize("laptop");
    } else if (window.innerWidth < 1280 && window.innerWidth >= 750) {
      setScreenSize("tablet");
    } else {
      setScreenSize("mobile");
    }
  };

  useEffect(() => {
    updateScreen();
    window.addEventListener("resize", updateScreen);
    return () => window.removeEventListener("resize", updateScreen);
  }, []);

  return (
    <div id="home-main-container">
      <Grid id="home-grid-parent" container spacing={3}>
        <Grid
          style={{ margin: "20px", paddingTop: 0 }}
          id="home-grid-child-one"
          item
        >
          <Stack spacing={3}>
            <WelcomeCard />
            <GenerateInsightCard
              errorArr={errorArr}
              updateErrorArr={updateErrorArr}
            />
            <TopicInsightsCard errorArr={errorArr} />
            {screenSize !== "desktop" && <DjUniCard />}
          </Stack>
        </Grid>
        <Grid id="home-grid-child-two" item>
          <Stack id="home-second-stack" spacing={3}>
            <div className="spacer"></div>
            <HelpfulLinksCard />
            <RDContentCard />
            {screenSize === "desktop" && <DjUniCard />}
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
