import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import { postSendSupportEmail } from "./api/post";
import { useAppStore } from "./hooks";
import { IAlertBannerOptions } from "./interfaces/alert-banner-options";
import { IAppStore } from "./interfaces/app-store.interface";

const EmailHelpForm = () => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailBody, setBody] = useState("");

  const {
    setShowAlertBanner
  }: {
    setShowAlertBanner(
      bool: boolean,
      alertBannerOptions?: IAlertBannerOptions
    ): void;
  } = useAppStore((store: IAppStore) => ({
    setShowAlertBanner: store.setShowAlertBanner
  }));

  const onClose = () => {
    setShow(false);
    setName("");
    setBody("");
    setEmail("");
  };

  const sendSupportEmail = async () => {
    await postSendSupportEmail(name, email, emailBody)
      .then(() => {
        setShowAlertBanner(true, {
          message: "Your support email has been sent!",
          severity: "success"
        });
      })
      .catch(() => {
        setShowAlertBanner(true, {
          message:
            "There was a problem sending your support email. Please contact your customer support representative.",
          severity: "error"
        });
      });
    onClose();
  };

  const toggleModal = () => {
    onClose();
    setShow(true);
  };

  return (
    <div>
      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      >
        <Modal show={show} onHide={() => onClose()} style={{ zIndex: "10000" }}>
          <Modal.Header closeButton>
            <Modal.Title>Send an Email to Customer Support</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Please enter your name, email and a short message. We will send an
              email to our customer service representatives.{" "}
            </p>
            <div style={{ padding: "5px" }}>
              <input
                onChange={(event: { target: { value: string } }) => {
                  setName(event.target.value);
                }}
                type="text"
                className="form-control"
                placeholder="Name"
                value={name}
              ></input>
            </div>
            <div style={{ padding: "5px" }}>
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                value={email}
                onChange={(event: { target: { value: string } }) => {
                  setEmail(event.target.value);
                }}
              ></input>
            </div>
            <div style={{ padding: "5px" }}>
              <textarea
                className="form-control"
                style={{ height: "100px" }}
                placeholder="Type your messages here..."
                value={emailBody}
                onChange={(event: { target: { value: string } }) => {
                  setBody(event.target.value);
                }}
              ></textarea>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => onClose()}>
              Close
            </Button>
            <Button variant="primary" onClick={() => sendSupportEmail()}>
              Send Email
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div
        onClick={() => toggleModal()}
        style={{
          color: "white",
          position: "absolute",
          bottom: "50px",
          right: "50px",
          background: "rgb(0, 140, 206)",
          width: "48px",
          height: "48px",
          borderRadius: "50%",
          textAlign: "center",
          lineHeight: "48px",
          zIndex: "100",
          fontSize: "x-large",
          cursor: "pointer"
        }}
      >
        <FontAwesomeIcon icon={faEnvelope} />
      </div>
    </div>
  );
};

export default EmailHelpForm;
