/* eslint-disable no-nested-ternary */
import { getBriefStatus } from "@/api/get";
import { determineBriefType } from "@/components/manage_content/utils";
import { useAppStore } from "@/hooks";
import { IAppStore } from "@/interfaces/app-store.interface";
import { IBrief } from "@/interfaces/brief.interface";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

interface StatusBadgeProps {
  briefData: IBrief | null;
  callbackFunction: () => void;
  hovered: boolean;
  submittingBrief: boolean;
}
function StatusBadge({
  briefData,
  callbackFunction,
  hovered,
  submittingBrief
}: StatusBadgeProps) {
  const [briefStatus, setBriefStatus] = useState<IBrief | null>(null);
  const [currentStep, setCurrentStep] = useState("step-one");
  const [complete, setComplete] = useState("");
  const [termType, setTermType] = useState("");

  const defaultStyle = {
    fontFamily: "'Inter', sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    width: "96px",
    height: "25px",
    backgroundColor: "#E9EBF0",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px"
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const statusStyles: any = {
    completed: {
      backgroundColor: "#ECF8F0",
      color: "#44AF69"
    },
    "in progress": {
      backgroundColor: "#FFF6E5",
      color: "#FFA400"
    },
    "not started": {
      backgroundColor: "#E1F5FF",
      color: "#008CCE"
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const statusStylesHovered: any = {
    completed: {
      border: "1px solid #44AF69",
      borderRadius: "8px"
    },
    "in progress": {
      border: "1px solid #FFA400",
      borderRadius: "8px"
    },
    "not started": {
      border: "1px solid #008CCE",
      borderRadius: "8px"
    }
  };
  const generateStyle = {
    textDecoration: "underline",
    color: "#373F50",
    fontSize: "14px",
    lineHeight: "17px",
    paddingLeft: "5px"
  };
  const { selectedPidObject } = useAppStore((store: IAppStore) => ({
    selectedPidObject: store.selectedPidObject
  }));
  useEffect(() => {
    setBriefStatus(briefData);
    if (briefData) {
      setTermType(determineBriefType(briefData));
      const step_number = briefData.current_step;
      if (step_number === 1) {
        setCurrentStep("step-one");
      } else if (step_number === 2) {
        setCurrentStep("step-two");
      } else if (step_number === 3 || step_number === 4) {
        setCurrentStep("step-three");
      }
      if (step_number === 4) {
        setComplete("&complete=true");
      }
    }
  }, [briefData, selectedPidObject]);
  useEffect(() => {
    if (
      briefStatus &&
      (briefStatus.status === "working" || briefStatus.status === "pending")
    ) {
      const getUpdatedStatus = async () => {
        try {
          const updatedBrief = await getBriefStatus(
            briefStatus.id,
            selectedPidObject.id
          );
          setBriefStatus(updatedBrief);
        } catch (err) {
          console.error(err);
        }
      };

      setTimeout(() => {
        getUpdatedStatus();
      }, 5000);
    }
  }, [briefData, briefStatus, selectedPidObject.id]);

  const formatStatus = (status: string | undefined) => {
    if (!status) {
      return "No Brief";
    }
    const formattedStatus = status
      .split(/[\s_]+/)
      .map((word) => word[0].toUpperCase() + word.substring(1).toLowerCase())
      .join(" ");
    if (["Complete", "Completed", "Published"].includes(formattedStatus)) {
      return "Completed";
    } else if (formattedStatus === "In Progress") {
      return formattedStatus;
    }
    return "Not Started";
  };

  return (
    <div>
      {briefData ? (
        <a
          style={{ textDecoration: "none", color: "inherit" }}
          href={
            briefStatus?.status === "complete"
              ? `/content-briefs-workflow/${currentStep}/${briefData.id}?pid=${
                  selectedPidObject.id
                }&type=${termType}${complete}&phrase=${encodeURIComponent(
                  briefData.h1
                )}`
              : "/content-briefs"
          }
        >
          <div
            style={
              hovered
                ? {
                    ...defaultStyle,
                    ...statusStyles[
                      formatStatus(briefData?.state).toLowerCase()
                    ],
                    ...statusStylesHovered[
                      formatStatus(briefData?.state).toLowerCase()
                    ]
                  }
                : {
                    ...defaultStyle,
                    ...statusStyles[
                      formatStatus(briefData?.state).toLowerCase()
                    ]
                  }
            }
          >
            <span>{formatStatus(briefData?.state)}</span>
          </div>
        </a>
      ) : (
        <button
          onClick={() => {
            callbackFunction();
          }}
          style={{
            backgroundColor: "inherit",
            border: "none",
            padding: "0px"
          }}
        >
          {submittingBrief ? (
            <span
              style={{
                color: "#373f50",
                width: "20px",
                height: "20px",
                margin: "0px 5px",
                paddingLeft: "33px"
              }}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </span>
          ) : hovered ? (
            <div style={generateStyle}>Generate Brief</div>
          ) : (
            <div style={{ ...defaultStyle }}>
              <span>No Brief</span>
            </div>
          )}
        </button>
      )}
    </div>
  );
}

export default StatusBadge;
