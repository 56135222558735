// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.save-button {
  background: #008CCE !important;
  color: #FFFFFF !important;
  font-family: "Source Sans Pro", sans-serif;
  border: none;
}
.save-button:active {
  background: #2ABBFF !important;
  box-shadow: 0px 0px 3px #008CCE !important;
}
.save-button:disabled {
  background: #D3D7E0 !important;
  color: rgba(55, 63, 80, 0.3) !important;
}

@media (max-width: 1199px) {
  .conditionalMaxHeight {
    max-height: 350px !important;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
@media (min-width: 1200px) {
  .conditionalMaxHeight {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/manage_content/content_brief/content_brief_workflow/step_one/step-one.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,yBAAA;EACA,0CAAA;EACA,YAAA;AACJ;AAAI;EACE,8BAAA;EACA,0CAAA;AAEN;AAAI;EACE,8BAAA;EACA,uCAAA;AAEN;;AAGI;EADF;IAEI,4BAAA;IACA,kBAAA;IACA,kBAAA;EACJ;AACF;AAAI;EANF;IAOI,gBAAA;IACA,gBAAA;IACA,kBAAA;EAGJ;AACF","sourcesContent":[".save-button {\n    background: #008CCE !important;\n    color: #FFFFFF !important;\n    font-family: 'Source Sans Pro', sans-serif;\n    border: none;\n    &:active{\n      background: #2ABBFF !important;\n      box-shadow: 0px 0px 3px #008CCE !important;\n    }\n    &:disabled{\n      background: #D3D7E0 !important;\n      color: rgba(55, 63, 80, 0.3) !important;\n    }\n  }\n\n  .conditionalMaxHeight {\n    @media (max-width: 1199px) {\n      max-height: 350px !important;\n      overflow-y: scroll;\n      overflow-x: hidden;\n    }\n    @media (min-width: 1200px){\n      max-height: 100%;\n      overflow-y: auto;\n      overflow-x: hidden;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
