import { useState } from "react";

import { Dropdown } from "react-bootstrap";

import { postSetBriefState } from "../../../../api/post";

const SetStatusElement = (props: { status: string; briefId: number }) => {
  const [status, setStatus] = useState(props.status as string);

  const updateStatus = (newStatus: string) => {
    postSetBriefState(props.briefId, newStatus);
    setStatus(newStatus);
  };

  const displayStatus = () =>
    status
      .replace(/_/gu, " ")
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  const handleMouseEnter = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    (e.target as HTMLButtonElement).style.color = "white";
    (e.target as HTMLButtonElement).style.background = "#008CCE";
  };

  const handleMouseLeave = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    (e.target as HTMLButtonElement).style.color = "#008CCE";
    (e.target as HTMLButtonElement).style.background = "white";
  };
  return (
    <Dropdown id="set-status-dropdown">
      <Dropdown.Toggle
        variant="outline-primary"
        id="set-status-toggle"
        style={{
          width: "120px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          color: "#008CCE",
          border: "1px solid #008CCE"
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {status ? displayStatus() : "To Do"}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item id="current" disabled={true}>
          {displayStatus()}
        </Dropdown.Item>
        <Dropdown.Item
          id="select-new"
          onClick={() => updateStatus("not_started")}
        >
          Not Started
        </Dropdown.Item>
        <Dropdown.Item
          id="select-in-progress"
          onClick={() => updateStatus("in_progress")}
        >
          In Progress
        </Dropdown.Item>
        <Dropdown.Item
          id="select-completed"
          onClick={() => updateStatus("completed")}
        >
          Completed
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SetStatusElement;
