import axios, { AxiosResponse } from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const deleteUrl = async (url: string, data = {}, authorization = true) => {
  const final_url = process.env.REACT_APP_NEO_URL + url;
  const device_uuid = cookies.get("device_id");

  let access_token = null;
  if (authorization) {
    access_token = cookies.get("Access_Token");
    if (!access_token) {
      window.location.href = "/login";
    }
  }

  return await axios
    .delete(final_url, {
      data,
      headers: {
        authorization: `Bearer ${access_token}`,
        "X-DeviceUUID": device_uuid
      }
    })
    .then((response) => {
      if (authorization && response?.headers?.["x-access-token"]) {
        if (cookies.get("Access_Token")) {
          cookies.remove("Access_Token", { path: "/" });
          cookies.set("Access_Token", response.headers["x-access-token"], {
            path: "/",
            domain: process.env.REACT_APP_SUBDOMAIN
          });
        } else {
          // direct to login
          window.location.href = "/login";
        }
      }
      return response;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        cookies.remove("Access_Token", {
          path: "/",
          domain: process.env.REACT_APP_SUBDOMAIN
        });
        cookies.remove("Access_Token", { path: "/" });
        window.location.href = "/login";
      }
      throw err;
    });
};

export const deleteBookmark = async (id: number): Promise<AxiosResponse> => {
  const body = { id };
  const url = `/api/v1/user_bookmarks`;
  return await deleteUrl(url, body);
};

export const deleteInsight = async (
  instance_id: number,
  topic: string,
  pid: number
): Promise<AxiosResponse> => {
  const body = { instance_id, topic, pid };
  const url = `/api/v1/insights`;
  return await deleteUrl(url, body);
};

export const deleteMFA = async (mfa_code: string) => {
  const url = "/api/v1/delete-mfa";
  return await deleteUrl(url, { mfa_code });
};

export const deleteFromStrategy = async (
  blog_ids: number[] | undefined,
  sub_pillar_ids: number[] | undefined,
  pid: number
): Promise<AxiosResponse> => {
  const body = { blog_ids, sub_pillar_ids, pid };
  const url = `/api/v1/delete-from-strategy`;
  return await deleteUrl(url, body);
};

export const deleteNote = async (
  outline_status_id: number,
  id: number
): Promise<AxiosResponse> => {
  const body = { outline_status_id, id };
  const url = `/api/v1/delete-note`;
  return await deleteUrl(url, body);
};

export const deleteCTA = async (
  saved_brief_id: number,
  id: number
): Promise<AxiosResponse> => {
  const body = { saved_brief_id, id };
  const url = `/api/v1/delete-CTA`;
  return await deleteUrl(url, body);
};
