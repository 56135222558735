import * as React from "react";
import Card from "@mui/material/Card";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import { deleteBookmark } from "../../../../../api/delete";
import Button from "../../../../shared/Button";
import IconButton from "@mui/material/IconButton";
import {
  IBookmark,
  IMenuItem
} from "../../../../../interfaces/navigation.interface";

const DeleteBookmarkModal = ({
  selectedBookmarkToDelete,
  setDeleteBookmarkModalOpen,
  menuItems,
  setMenuItems
}: {
  selectedBookmarkToDelete: IBookmark;
  setDeleteBookmarkModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  menuItems: IMenuItem[];
  setMenuItems: React.Dispatch<React.SetStateAction<IMenuItem[]>>;
}) => {
  const handleDelete = async () => {
    try {
      await deleteBookmark(selectedBookmarkToDelete.id).then(() => {
        // delete the bookmark from the menuOptions
        setMenuItems(
          menuItems.filter((obj) => obj.id !== selectedBookmarkToDelete.id)
        );
        setDeleteBookmarkModalOpen(false);
      });
    } catch (err) {
      //  Better error handling
    }
  };

  const handleModalClose = () => {
    setDeleteBookmarkModalOpen(false);
  };

  const titleStyle = {
    width: "307px",
    height: "36px",
    justifyContent: "center",
    fontFamily: "'Source Sans Pro'",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "34px",
    lineHeight: "43px",
    display: "flex",
    letterSpacing: "0.0025em",
    color: "#373F50",
    paddingBottom: "80px"
  };
  const textStyle = {
    height: "41px",
    fontFamily: "'Source Sans Pro'",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "center",
    letterSpacing: "0.005em",
    color: "#373F50"
  };
  return (
    <Card
      sx={{
        width: "411px",
        height: "351px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          padding: "10px"
        }}
      >
        <IconButton onClick={handleModalClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid
        container
        sx={{
          padding: "30px",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Grid sx={titleStyle}>Delete Bookmark?</Grid>
        <Grid sx={textStyle}>
          You are about to delete a report from your bookmark list. Do you want
          to continue?
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            paddingTop: "80px"
          }}
          onClick={handleModalClose}
        >
          <Button text="Cancel" size="large" sx={{ margin: 0 }} />
          <Button
            text="Delete"
            size="large"
            onClick={handleDelete}
            sx={{ margin: 0 }}
            color="error"
            variant="contained"
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default DeleteBookmarkModal;
