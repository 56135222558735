import * as React from "react";
import IconSuccessCompleteLogo from "../../../../assets/icons/icon-success-complete.svg";
import IconErrorLogo from "../../../../assets/icons/icon-error.svg";
import ProgressBar from "react-bootstrap/ProgressBar";
import CustomBadge from "./CustomBadge";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import ContactUsLink from "@/components/shared/ContactUsLink";

dayjs.extend(utc);
const AfterLocationSelect = ({
  isError,
  isNoDataError,
  showUpgrade,
  values,
  int,
  showReadyToUpdateText
}) => (
  <>
    {!showReadyToUpdateText &&
      values[`keyword${int + 1}`].cbmInfo?.status === "pending" && (
        <div
          className="col-3"
          style={{
            margin: "auto 0"
          }}
        >
          <CustomBadge text="PENDING" bg="#008cce" />
        </div>
      )}
    {!showReadyToUpdateText &&
      values[`keyword${int + 1}`].cbmInfo?.newPillar &&
      values[`keyword${int + 1}`].cbmInfo?.status === "complete" && (
        <div
          className="col-3"
          style={{
            margin: "auto 0"
          }}
        >
          <CustomBadge text="COMPLETE" bg="#44af69" />
        </div>
      )}
    {!showReadyToUpdateText &&
      values[`keyword${int + 1}`].cbmInfo?.status === "working" && (
        <div className="col-3" style={{ paddingTop: "6px", margin: "auto 0" }}>
          <ProgressBar
            style={{ height: "24px" }}
            animated
            now={values[`keyword${int + 1}`].cbmInfo?.percent_complete}
          />
          <div>
            Working..
            {values[`keyword${int + 1}`].cbmInfo?.percent_complete}%
          </div>
        </div>
      )}
    {isError && !showReadyToUpdateText && (
      <div
        className="col-3"
        style={{
          display: "flex",
          flexFlow: "row nowrap",
          alignItems: "center"
        }}
      >
        <img src={IconErrorLogo} alt="Error Exclamation Logo" />
        <div
          style={{
            fontFamily: "source-sans-pro, sans-serif",
            fontSize: "12px",
            color: "#b00020",
            marginLeft: "8px"
          }}
        >
          {isNoDataError &&
            !showReadyToUpdateText &&
            "No results for this phrase. Please try another search term around the same topic."}
          {values[`keyword${int + 1}`].cbmInfo?.status === "ERROR-ETL" &&
            "Hang tight, our team is working to correct the error for generating this Pillar Topic."}
        </div>
      </div>
    )}
    {showReadyToUpdateText && (
      <div
        className="col-3"
        style={{
          marginTop: "14px"
        }}
      >
        <img src={IconSuccessCompleteLogo} alt="Success Check Logo" />
      </div>
    )}
    {showUpgrade && (
      <div
        className="col-3"
        style={{
          marginTop: "14px"
        }}
      >
        <ContactUsLink
          sx={{
            borderRadius: "50px",
            color: "#6c757d",
            borderColor: "#6c757d",
            fontSize: "0.8rem",
            backgroundColor: "transparent",
            border: "1px solid",
            padding: "4px 8px"
          }}
          hoverSx={{ backgroundColor: "#6c757d", color: "white" }}
        />
      </div>
    )}
  </>
);

export default AfterLocationSelect;
