// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ltt > .tooltip-inner {
  max-width: 100%;
  width: 255px;
  font-size: small;
}

.step-one-row:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/manage_content/content_brief/content_brief_workflow/step_one/components/tooltip.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,YAAA;EACA,gBAAA;AACJ;;AAEA;EACE,eAAA;AACF","sourcesContent":[".ltt > .tooltip-inner {\n    max-width: 100%;\n    width: 255px;\n    font-size: small;\n  }\n\n.step-one-row:hover {\n  cursor: pointer\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
