import { useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

import { postGenerateBrief } from "../../../../api/post";
import { useAppStore } from "../../../../hooks";

import { IBrief } from "../../../../interfaces/brief.interface";
import { IAppStore } from "../../../../interfaces/app-store.interface";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
dayjs.extend(utc);

const AdHocCreateBrief = (props: {
  userId: number;
  pid: number;
  briefs: IBrief[];
  setBriefs: (briefs: IBrief[]) => void;
}) => {
  const [disabled, setDisabled] = useState(false);

  const { setShowAlertBanner } = useAppStore((store: IAppStore) => ({
    setShowAlertBanner: store.setShowAlertBanner
  }));

  const handleSubmit = async (values: { phrase: string }) => {
    setDisabled(true);
    try {
      const response = await postGenerateBrief(values.phrase, props.pid);
      if (response) {
        setShowAlertBanner(true, {
          message: "Your content brief was succesfully generated",
          severity: "success"
        });
        const { id } = response.data;
        const newBrief = {
          created_at: dayjs().toDate(),
          updated_at: dayjs().toDate(),
          h1: values.phrase,
          id,
          percent_complete: 0,
          pid: props.pid,
          state: "not_started",
          type: "Unassigned",
          status: "pending",
          ad_hoc: true
        } as IBrief;
        props.setBriefs([newBrief, ...props.briefs.slice(0, 20)]);
        values.phrase = "";
        setDisabled(false);
      }
    } catch (e) {
      values.phrase = "";

      console.error(e);
      setShowAlertBanner(true, {
        message: "There was an error generating your content brief",
        severity: "error"
      });
    }
  };

  // allowed characters = {space}-_0-9a-zA-Z@"#$%&*~+?!',/\[].
  const validationSchema = Yup.object().shape({
    phrase: Yup.string()
      .min(2, "Too Short!")
      .max(100, "Too Long!")
      .matches(
        /^[\\ -_0-9a-zA-Z@"#$%&*~+?!',/[\].]+$/,
        "Use only letters, numbers, and symbols."
      )
  });

  const titleStyle = {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "30px",
    color: "#373F50",
    borderBottom: "0px"
  };
  const helperTextStyle = {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "15px",
    letterSpacing: "0.0125em",
    color: "#373F50"
  };
  const placeholderStyle = {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.0125em",
    color: "#56627C"
  };
  const phraseTextStyle = {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.0125em"
  };
  return (
    <div
      className="main-card mt-3 mb-3 card card-w80"
      style={{ margin: "0 auto", width: "95%" }}
    >
      <div
        className="card-header-tab card-header"
        style={{ borderBottom: "0px" }}
      >
        <div className="" style={titleStyle}>
          Create a Content Brief
        </div>
      </div>
      <div className="card-body">
        <Formik
          initialValues={{
            phrase: ""
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, values }) => (
            <Form>
              <div className="row" style={{ textAlign: "center" }}>
                <div
                  className="col-sm-9 col-lg-9"
                  style={{ textAlign: "start" }}
                >
                  <Field
                    name="phrase"
                    id="phrase"
                    type="text"
                    style={values.phrase ? phraseTextStyle : placeholderStyle}
                    className={`form-control ${
                      errors.phrase ? "text-danger is-invalid" : ""
                    }`}
                    placeholder="What topic do you want to write about?"
                  />
                  {errors.phrase ? (
                    <span className="text-danger pl-3" style={helperTextStyle}>
                      {errors.phrase}
                    </span>
                  ) : (
                    <span className="pl-3" style={helperTextStyle}>
                      Your content will generate in the Content Briefs section
                      below.
                    </span>
                  )}
                </div>
                <div className="col-sm-3 col-lg-3">
                  <button
                    id="generateButton"
                    className="mb-1 btn btn-primary demandjump-button-primary btn-lg"
                    data-style="zoom-in"
                    type="submit"
                    disabled={disabled}
                    style={{
                      width: "fit-content",
                      height: "fit-content",
                      padding: 10
                    }}
                  >
                    <span className="ladda-label mp-ignore">
                      Generate Content Brief
                    </span>
                    <span className="ladda-spinner mp-ignore"> </span>
                  </button>
                  <br />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AdHocCreateBrief;
