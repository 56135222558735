import LoadingElement from "./LoadingElement";

interface IProps {
  parentLoading: boolean;
  children?: string[];
}

const InfinityScrollingLoading = ({ parentLoading }: IProps) => (
  <div>{!parentLoading && <LoadingElement></LoadingElement>}</div>
);

export default InfinityScrollingLoading;
